/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { get, isObject } from 'lodash';
import { push, replace } from 'react-router-redux';
import { NavLink } from 'react-router-dom';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select, message } from 'antd';

import { State, Message } from '../../../common/types';
import { Container } from '../../../lib/fui/react';
import { BaseUrls } from '../../app/Constants';
import {
  ActionTypes as AppActionTypes,
  createLoadAction,
  hideAppLoader,
  showAppLoader,
} from '../../../common/app/actions';
import {
  loadActiveInstance,
  loadProjectSettings,
  saveProjectSettings,
  pickProjectModel,
  exportProjectModel,
  saveProjectAgentList,
  saveProjectInstanceGroup,
  loadProjectLogStructure,
  saveLogEntryStructure,
  removeLogEntryStructure,
} from '../../../common/settings/actions';
import { parseLocation, buildLocation, parseQueryString, buildMatchLocation, buildUrl } from '../../../common/utils';

import { appFieldsMessages, appButtonsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { settingsMenusMessages, settingsMessages } from '../../../common/settings/messages';

import {
  BasicSetting,
  GeneralSetting,
  InstanceSetting,
  EndpointSettingAll,
  LogSensitivitySetting,
  AlertSensitivitySetting,
  MetricSetting,
  KeywordsSetting,
  ActionSetting,
  ModelSetting,
  DetectionStatus,
  LogToMetric,
  FieldSetting,
  ReplayStatusSetting,
  AutoScalingConfiguration,
  SystemCapacitySetting,
  LogToMetricBaseValue,
  CumulativeDistributionFunctionTabs,
  MetricModelSetting,
  LogModelSetting,
} from './components';

import '../settings.scss';

import GroupEditComponent from './components/GroupEditComponent';
import WebhookSetting from './components/WebhookSetting';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import ProjectAgentSetting from './components/ProjectAgentSetting';

type Props = {
  match: Object,
  location: Object,
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  isAdmin: Boolean,
  // eslint-disable-next-line
  isLocalAdmin: Boolean,
  credentials: Object,
  projects: Array<Object>,
  projectGroups: Array<String>,
  projectSettings: Object,
  currentLoadingComponents: Object,
  projectSettingsParams: Object,
  currentErrorMessage: ?Message,
  activeInstanceMap: Object,
  // eslint-disable-next-line
  loadActiveInstance: Function,
  loadProjectSettings: Function,
  saveProjectSettings: Function,
  pickProjectModel: Function,
  exportProjectModel: Function,
  saveProjectAgentList: Function,
  saveProjectInstanceGroup: Function,
  saveLogEntryStructure: Function,
  removeLogEntryStructure: Function,
  loadProjectLogStructure: Function,
  hideAppLoader: Function,
  showAppLoader: Function,
  userInfo: Object,
};

class ProjectSettingsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl } = props;

    this.state = {
      instanceListOptions: [],
      activeMenu: '',
      isMenuCollapse: false,

      showEditComponent: false,
      localInstanceGroupList: [],
      childReloadData: () => {},

      instanceDisplayNameMap: {},
    };

    // Helper function
    this.pickNotNil = R.pickBy((a) => !R.isNil(a));
    this.ifIn = (i, items) => items.indexOf(i) !== -1;
    this.defaultModelDays = 2;
    this.dateFormat = 'YYYY-MM-DD';

    this.metricSettingInfos = [
      { key: 'basic', name: 'Basic setting', component: BasicSetting, noRender: true },
      { key: 'general', name: intl.formatMessage(settingsMessages.general), component: GeneralSetting },
      { key: 'webhook', name: intl.formatMessage(settingsMessages.webhook), component: WebhookSetting },
      { key: 'endpoint', name: intl.formatMessage(settingsMessages.endpoint), component: EndpointSettingAll },
      { key: 'alert', name: intl.formatMessage(settingsMessages.sensitivity), component: AlertSensitivitySetting },
      { key: 'grouping', name: intl.formatMessage(settingsMessages.grouping), component: InstanceSetting },
      { key: 'threshold', name: intl.formatMessage(settingsMessages.metricConfiguration), component: MetricSetting },
      { key: 'actions', name: intl.formatMessage(settingsMessages.actions), component: ActionSetting },
      { key: 'metricModel', name: intl.formatMessage(settingsMessages.modelList), component: MetricModelSetting },
      // { key: 'model', name: intl.formatMessage(settingsMessages.regularModelList), component: ModelSetting },
      // { key: 'holidaymodel', name: intl.formatMessage(settingsMessages.holidayModelList), component: ModelSetting },
      {
        key: 'autoscalingconfiguration',
        name: intl.formatMessage(settingsMessages.autoScalingConfiguration),
        component: AutoScalingConfiguration,
      },
      { key: 'replaystatus', name: intl.formatMessage(settingsMessages.replayStatus), component: ReplayStatusSetting },
      {
        key: 'systemCapacitySetting',
        name: intl.formatMessage(settingsMessages.systemCapacitySetting),
        component: SystemCapacitySetting,
      },
      { key: 'agentsetting', name: intl.formatMessage(settingsMessages.agentSetting), component: ProjectAgentSetting },
    ];
    this.logSettingInfos = [
      { key: 'basic', name: 'Basic setting', component: BasicSetting, noRender: true },
      { key: 'general', name: intl.formatMessage(settingsMessages.general), component: GeneralSetting },
      { key: 'webhook', name: intl.formatMessage(settingsMessages.webhook), component: WebhookSetting },
      { key: 'endpoint', name: intl.formatMessage(settingsMessages.endpoint), component: EndpointSettingAll },
      { key: 'logthreshold', name: intl.formatMessage(settingsMessages.sensitivity), component: LogSensitivitySetting },
      { key: 'grouping', name: intl.formatMessage(settingsMessages.grouping), component: InstanceSetting },
      { key: 'keywords', name: intl.formatMessage(settingsMessages.labels), component: KeywordsSetting },
      { key: 'actions', name: intl.formatMessage(settingsMessages.actions), component: ActionSetting },
      { key: 'logModel', name: intl.formatMessage(settingsMessages.modelList), component: LogModelSetting },
      // { key: 'model', name: intl.formatMessage(settingsMessages.regularModelList), component: ModelSetting },
      // { key: 'holidaymodel', name: intl.formatMessage(settingsMessages.holidayModelList), component: ModelSetting },
      { key: 'detection', name: intl.formatMessage(settingsMessages.detectionStatus), component: DetectionStatus },
      { key: 'logToMetric', name: intl.formatMessage(settingsMessages.logToMetric), component: LogToMetric },
      {
        key: 'logToMetricBaseValue',
        name: intl.formatMessage(settingsMessages.logToMetricBaseValue),
        component: LogToMetricBaseValue,
      },
      {
        key: 'cdfSetting',
        name: intl.formatMessage(settingsMessages.cumulativeDistributionFunction),
        component: CumulativeDistributionFunctionTabs,
      },
      { key: 'fieldSetting', name: intl.formatMessage(settingsMessages.JsonKey), component: FieldSetting },
      {
        key: 'autoscalingconfiguration',
        name: intl.formatMessage(settingsMessages.autoScalingConfiguration),
        component: AutoScalingConfiguration,
      },
      { key: 'agentsetting', name: intl.formatMessage(settingsMessages.agentSetting), component: ProjectAgentSetting },
    ];

    this.settingInfos = [
      { key: 'basic', name: 'Basic Setting' },
      { key: 'advanced', name: 'Advanced Setting' },
    ];

    this.defaultMetricSetting = 'basic';
    this.defaultLogSetting = 'basic';
    this.defaultInstanceGroup = 'All';
    this.defaultLogInstanceGroup = 'rawLog';

    // The settings which need start/end time parameters
    this.timeRangeSettings = ['model'];

    // The settings which need instance parameters
    this.instanceNameSettings = ['actions', 'model'];

    // The settings which need instanceGroup parameters
    this.instanceGroupSettings = ['model', 'holidaymodel'];

    this.settingsLoader = 'project_setting_page';

    this.noTwiceHideLoader = ['basic'];
  }

  componentDidMount() {
    if (!this.applyParamsAndRedirect(this.props)) {
      const { match, projects } = this.props;
      const { projectName } = match.params;
      const project = R.find((p) => p.projectName === projectName, projects);
      if (project && !project.hasAllInfo) {
        this.reloadProjectDetails(this.props);
      } else {
        this.reloadData(this.props, true);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const query = parseLocation(this.props.location);
    const nextQuery = parseLocation(nextProps.location);
    if (nextProps.location !== this.props.location && !this.applyParamsAndRedirect(nextProps)) {
      const { match, projects } = nextProps;
      const { projectName } = match.params;
      const project = R.find((p) => p.projectName === projectName, projects);
      if (project && !project.hasAllInfo) {
        this.reloadProjectDetails(nextProps);
      } else {
        const notReload = nextQuery.instanceName !== query.instanceName;
        if (!notReload) {
          this.reloadData(nextProps);
        } else if (nextProps.hideAppLoader) nextProps.hideAppLoader();
      }
    } else if (nextProps.activeInstanceMap !== this.props.activeInstanceMap) {
      this.filterInstance(nextProps);
    }
  }

  applyParamsAndRedirect(props) {
    // This method will set the default parameters in different situation. If parameters
    // are changed, it will redirect to the new url. In this case, we don't need to load
    // data. After redirection, the data will be loaded.
    const { location, match, replace, projects } = props;
    const params = parseQueryString(location.search);
    const { projectName } = match.params;
    const { systemSearch, projectSearch, projectOwner, customerName, reloadProject } = params;
    const { instanceId, metricFilter, keywordCategory, onlyIsKpi, onlyComputeDifference, instance, isJump } = params;
    const { onlyThreshold, onlyIsNoneKpi } = params;
    let { setting, instanceGroup, instanceName, startTime, endTime, start, limit } = params;
    let redirect = false;

    // Get the project data type which will decide the settings' profile, if project not
    // exist, select the default metric profile.
    const project = R.find((p) => p.projectName === projectName, projects);
    const dataType = get(project, 'dataType', 'metric').toLowerCase();

    // If project changed, we need reset settings for instanceGroup, but keep the start/end time.
    if (projectName !== match.params.projectName) {
      instanceGroup = undefined;
      instanceName = undefined;
    }

    // If setting not selected or incorrect, use the default setting for each datatype.
    if (dataType === 'metric') {
      if (!setting || !R.find((i) => i.key === setting, this.metricSettingInfos)) {
        setting = this.defaultMetricSetting;
      }
    } else if (!setting || !R.find((i) => i.key === setting, this.logSettingInfos)) {
      setting = this.defaultLogSetting;
    }
    if (!start || !limit) {
      start = 0;
      limit = 10;
    }

    // If it's setting needs start/end time params, set the default or to valid value.
    // Otherwise, clean these params.
    if (this.ifIn(setting, this.timeRangeSettings)) {
      const mEndTime = endTime ? moment.utc(endTime, this.dateFormat) : moment.utc().endOf('day');
      const mStartTime = startTime
        ? moment.utc(startTime, this.dateFormat)
        : mEndTime.clone().subtract(this.defaultModelDays, 'days').startOf('day');
      startTime = mStartTime.format(this.dateFormat);
      endTime = mEndTime.format(this.dateFormat);
    } else {
      startTime = undefined;
      endTime = undefined;
    }

    // Also set the install group default
    if (this.ifIn(setting, this.instanceGroupSettings)) {
      if (dataType === 'metric') {
        instanceGroup = R.isNil(instanceGroup) ? this.defaultInstanceGroup : instanceGroup;
      } else {
        // For log, we don't set instanceGroup
        instanceGroup = this.defaultLogInstanceGroup;
      }
    } else {
      instanceGroup = undefined;
    }
    const newParams = this.pickNotNil({
      systemSearch,
      projectSearch,
      projectOwner,
      customerName,
      reloadProject,

      setting,
      instanceGroup,
      instanceName,
      instanceId,
      startTime,
      endTime,
      start,
      limit,
      metricFilter,
      keywordCategory,
      onlyIsKpi,
      onlyComputeDifference,
      onlyThreshold,
      onlyIsNoneKpi,
      instance,
      isJump,
    });

    // If projectName or params are changed, redirect to new location.
    if (projectName !== match.params.projectName || !R.equals(newParams, this.pickNotNil(params))) {
      redirect = true;
      replace(buildMatchLocation(match, { projectName }, newParams));
    }

    return redirect;
  }

  @autobind
  reloadProjectDetails(props) {
    const { createLoadAction, match } = props;
    const { projectName } = match.params;
    createLoadAction(
      AppActionTypes.LOAD_PROJECT_INFO,
      {
        projectName,
        withStats: true,
      },
      true,
      null,
      this.callbackHandle,
    );
  }

  @autobind
  callbackHandle() {
    const { location, replace } = this.props;
    const query = parseLocation(location);

    // this.props.hideAppLoader();
    replace(buildLocation(location.pathname, {}, { ...query, reloadProject: moment.utc().valueOf() }));
  }

  @autobind
  getInstaceDisplayNameData() {
    const { credentials, projects, match } = this.props;
    const { projectName } = match.params;
    const project = R.find((p) => p.projectName === projectName, projects) || {};
    return fetchGet(getEndpoint('instance-display-name'), {
      ...credentials,
      instanceDisplayNameRequestList: JSON.stringify([
        { projectName: project?.projectShortName, customerName: project?.owner },
      ]),
    })
      .then((d1) => {
        const instanceDisplayNameMap = {};
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, d1 || []);
        this.setState({ instanceDisplayNameMap });
      })
      .catch((err) => {
        this.setState({ instanceDisplayNameMap: {} });
        message.error(err.message || String(err));
      });
  }

  async reloadData(props, force = false) {
    // IMPORTANT: Need to compare the new params with last params. If they are the same
    // we cannot dispath the action, which will change the props again and cause a loop.
    this.props.showAppLoader();

    const { match, location, projectSettingsParams, loadProjectSettings, projects } = props;
    const params = parseQueryString(location.search);
    const { projectName } = match.params;
    const { setting } = params;
    const project = R.find((p) => p.projectName === projectName, projects) || {};
    const showInstance = !project.isMetric && this.ifIn(setting, this.instanceNameSettings);
    const nextProjectSettingsParams = this.pickNotNil({ projectName, ...params });

    if (project) await this.getInstaceDisplayNameData();
    if (force) {
      this.refreshTime = moment.utc().valueOf();
      await loadProjectSettings(projectName, params, true, () => {
        if (showInstance) {
          this.callback(props, project);
        }
      });
      if (!this.ifIn(setting, this.noTwiceHideLoader)) this.props.hideAppLoader();
    } else if (!R.equals(projectSettingsParams, nextProjectSettingsParams)) {
      await loadProjectSettings(projectName, params, false, () => {
        if (showInstance) {
          this.callback(props, project);
        }
      });
      if (!this.ifIn(setting, this.noTwiceHideLoader)) this.props.hideAppLoader();
    }
    this.props.hideAppLoader();
  }

  @autobind
  callback(props, project) {
    const { loadActiveInstance, location } = props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;

    const mStartTime = startTime
      ? moment.utc(startTime, this.dateFormat).startOf('day')
      : moment.utc().subtract(this.defaultModelDays, 'days').startOf('day');
    const mEndTime = endTime ? moment.utc(endTime, this.dateFormat).endOf('day') : moment.utc().endOf('day');

    if (project) {
      const projectName = project.projectName.split('@')[0];
      const params = {
        customerName: project.owner,
        startTime: mStartTime.valueOf(),
        endTime: mEndTime.valueOf(),
        projectNameList: JSON.stringify([{ projectName, projectType: project.dataType }]),
      };
      loadActiveInstance(params, false);
    }
  }

  @autobind
  filterInstance(props) {
    const { location, replace, match, projects, activeInstanceMap, projectSettings } = props;
    const { projectName } = match.params;
    const project = R.find((p) => p.projectName === projectName, projects) || {};
    const instanceList = [];
    let instanceListOptions = [];
    R.forEachObjIndexed((val, key) => {
      if (key === projectName.split('@')[0]) {
        const { activeInstanceList } = val;
        R.forEachObjIndexed((val) => {
          instanceList.push(val.id);
        }, activeInstanceList);
      }
    }, activeInstanceMap);
    if (!project.isMetric) {
      instanceListOptions = R.map(
        (p) => ({ label: p.instanceName, value: p.instanceId }),
        get(projectSettings, 'instanceList', []),
      );
      instanceListOptions = R.filter((option) => {
        return R.contains(option.value, instanceList);
      }, instanceListOptions);
    }
    this.setState({ instanceListOptions }, () => {
      // replace instance name
      const query = parseLocation(location);
      const instanceIds = R.map((item) => item.value, instanceListOptions);
      if (instanceIds.length > 0 && instanceIds.indexOf(query.instanceName) === -1) {
        replace(buildLocation(location.pathname, {}, { ...query, instanceName: instanceListOptions[0].value }));
      }
    });
  }

  @autobind
  handleProjectChange(projectName) {
    const { match, replace, location } = this.props;
    const params = parseQueryString(location.search);
    // Reset setting to default as project type might change.
    const setting = undefined;
    const instanceName = undefined;
    replace(
      buildMatchLocation(
        match,
        { projectName },
        { ...params, setting, instanceName, metricFilter: '', onlyIsKpi: false, onlyComputeDifference: false },
      ),
    );
  }

  @autobind
  handleInstanceGroupChange(instanceGroup) {
    const { match, replace, location } = this.props;
    const params = parseQueryString(location.search);
    replace(buildMatchLocation(match, match.params, { ...params, instanceGroup }));
  }

  @autobind
  handleInstanceNameChange(instanceName) {
    const { match, replace, location } = this.props;
    const params = parseQueryString(location.search);
    replace(buildMatchLocation(match, match.params, { ...params, instanceName }));
  }

  @autobind
  handleSettingChangeClick(e, setting, anchorId) {
    e.stopPropagation();
    e.preventDefault();
    const { match, replace, location } = this.props;
    const params = parseQueryString(location.search);
    replace(
      buildMatchLocation(match, match.params, {
        ...params,
        setting,
        metricFilter: '',
        onlyIsKpi: false,
        onlyComputeDifference: false,
        instance: undefined,
        isJump: undefined,
      }),
    );

    this.handleAnchor(e, anchorId);
  }

  @autobind
  handleDateRangeChange(dates, dateStrings) {
    const { location, replace } = this.props;
    const query = parseLocation(location);

    const [startTime, endTime] = dateStrings;
    replace(buildLocation(location.pathname, {}, { ...query, startTime, endTime }));
  }

  @autobind
  handleRefreshClick() {
    this.clearInstance();
    this.reloadData(this.props, true);
  }

  @autobind
  clearInstance() {
    const { location, replace } = this.props;
    const params = parseLocation(location);
    replace(
      buildLocation(
        location.pathname,
        {},
        {
          ...params,
          instance: undefined,
          isJump: undefined,
        },
      ),
    );
  }

  @autobind
  handleAnchor(e, anchorId) {
    e.stopPropagation();
    e.preventDefault();
    if (!anchorId) return;
    const el = document.getElementById(anchorId);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  @autobind
  onChangeShowEditComponent({ showEditComponent, localInstanceGroupList, childReloadData }) {
    this.setState({
      showEditComponent,
      ...(localInstanceGroupList ? { localInstanceGroupList } : {}),
      ...(childReloadData ? { childReloadData } : {}),
    });
  }

  render() {
    const {
      intl,
      match,
      location,
      credentials,
      projects,
      projectSettings,
      projectSettingsParams,
      currentLoadingComponents,
      saveProjectSettings,
      saveProjectAgentList,
      saveProjectInstanceGroup,
      saveLogEntryStructure,
      removeLogEntryStructure,
      currentErrorMessage,
      loadProjectSettings,
      pickProjectModel,
      exportProjectModel,
      loadProjectLogStructure,
      keywordsList,
      userInfo,
    } = this.props;
    const { projectName } = match.params;
    let { projectGroups } = this.props;
    const {
      systemSearch,
      projectSearch,
      projectOwner,
      customerName,
      setting,
      instanceGroup,
      instanceName,
      startTime,
      endTime,
    } = parseQueryString(location.search);

    const project = R.find((p) => p.projectName === projectName, projects) || {};
    const dataType = get(project, 'dataType', 'metric').toLowerCase();

    let settingInfos = dataType === 'metric' ? this.metricSettingInfos : this.logSettingInfos;
    if (project.cloudType !== 'Kubernetes') {
      settingInfos = R.filter((item) => item.key !== 'autoscalingconfiguration', settingInfos);
    }
    const settingInfo = R.find((info) => info.key === setting, settingInfos);
    const showTimeRange = this.ifIn(setting, this.timeRangeSettings);
    const showInstance = !project.isMetric && this.ifIn(setting, this.instanceNameSettings);
    const showInstanceGroup = project.isMetric && this.ifIn(setting, this.instanceGroupSettings);
    const showRefresh = !this.ifIn(setting, ['metricModel']);
    const hasError = !!currentErrorMessage;
    const {
      instanceListOptions,
      activeMenu,
      isMenuCollapse,
      showEditComponent,
      localInstanceGroupList,
      childReloadData,
      instanceDisplayNameMap,
    } = this.state;
    const { refreshTime } = this;
    if (!project.isMetric) projectGroups = [];

    return (
      <Container fullHeight withGutter className="flex-col settings">
        <div className={`full-height ${showEditComponent ? 'display-none' : 'flex-col'}`}>
          <Container breadcrumb className="flex-row" style={{ marginBottom: 0 }}>
            <div className="section" style={{ minHeight: 30 }}>
              <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
              <span className="divider">/</span>
              <NavLink
                to={buildUrl(BaseUrls.SystemSetting, {}, { systemSearch, projectSearch, projectOwner, customerName })}
              >
                <span className="label">{intl.formatMessage(settingsMenusMessages.systemSetting)}</span>
              </NavLink>
              <span className="divider">/</span>
              <Select
                showSearch
                size="small"
                style={{ width: 150 }}
                placeholder={intl.formatMessage(appFieldsMessages.project)}
                optionFilterProp="value"
                filterOption
                value={projectName}
                onChange={this.handleProjectChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.projectName} value={item.projectName}>
                      {`${item.projectDisplayName}${
                        userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName
                          ? `@${item.owner}`
                          : ''
                      }${item.systemName ? ` (${item.systemName})` : ''}`}
                    </Select.Option>
                  ),
                  projects || [],
                )}
              </Select>

              {showInstanceGroup && <span style={{ fontWeight: 500 }}>Env:</span>}
              {showInstanceGroup && (
                <Select
                  showSearch
                  size="small"
                  style={{ width: 150 }}
                  placeholder={intl.formatMessage(appFieldsMessages.group)}
                  optionFilterProp="value"
                  filterOption
                  value={instanceGroup}
                  onChange={this.handleInstanceGroupChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ),
                    projectGroups || [],
                  )}
                </Select>
              )}

              {showInstance && <span className="divider">/</span>}
              {showInstance && (
                <Select
                  showSearch
                  size="small"
                  style={{ width: 150 }}
                  placeholder={intl.formatMessage(appFieldsMessages.instance)}
                  optionFilterProp="value"
                  filterOption
                  value={instanceName}
                  onChange={this.handleInstanceNameChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ),
                    instanceListOptions || [],
                  )}
                </Select>
              )}
            </div>

            <div className="section flex-grow flex-row flex-center-align flex-end-justify">
              {showTimeRange && <span className="label">{intl.formatMessage(appFieldsMessages.dateRange)}:</span>}
              {showTimeRange && (
                <DatePicker.RangePicker
                  allowClear={false}
                  disabledDate={(current) => {
                    return current && current > moment.utc().add(1, 'days').endOf('day');
                  }}
                  value={
                    startTime && endTime
                      ? [moment.utc(startTime, this.dateFormat), moment.utc(endTime, this.dateFormat)]
                      : []
                  }
                  onChange={this.handleDateRangeChange}
                />
              )}
              {showRefresh && (
                <Button size="small" onClick={this.handleRefreshClick}>
                  {intl.formatMessage(appButtonsMessages.refresh)}
                </Button>
              )}
            </div>
          </Container>

          {hasError && (
            <Container fullHeight>
              <div
                className="ui error message"
                style={{ marginTop: 16 }}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(appMessages.errorsProjectNotFound, { projectName }),
                }}
              />
            </Container>
          )}
          {!hasError && (
            <Container
              className="flex-grow flex-row flex-min-height flex-min-width content-bg corner-10"
              style={{
                margin: '0 16px 8px 16px',
                padding: '16px 6px 16px 16px',
              }}
            >
              {isMenuCollapse && (
                <div
                  className="corner-10 project-filed-anchor-collapse clickable"
                  style={{ position: 'relative' }}
                  onClick={() => this.setState({ isMenuCollapse: !isMenuCollapse })}
                >
                  <div className="clickable" style={{ position: 'absolute', right: 4, top: 4 }}>
                    <DoubleRightOutlined />
                  </div>
                </div>
              )}
              {!isMenuCollapse && (
                <div className="corner-10 project-filed-anchor" style={{ position: 'relative' }}>
                  <div
                    className="clickable"
                    style={{ position: 'absolute', right: 8, top: 4 }}
                    onClick={() => this.setState({ isMenuCollapse: !isMenuCollapse })}
                  >
                    <DoubleLeftOutlined />
                  </div>
                  {R.addIndex(R.map)((item, index) => {
                    const active = item.key === setting;
                    return (
                      <div key={item.key} className="anchor-item">
                        <span
                          className={active ? 'anchor-item-active' : ''}
                          onClick={(e) => {
                            if (item.key === 'basic') {
                              this.handleSettingChangeClick(e, item.key);
                            }
                          }}
                        >
                          {item.name}
                        </span>
                        {item.key !== 'basic' && (
                          <div className="children-anchor-container">
                            {R.map((child) => {
                              const anchorId = `project-setting-anchor-${child.key}`;
                              const activeItem = child.key === setting;
                              if (child.noRender) return undefined;

                              return (
                                <div
                                  key={`project-setting-anchor-${child.key}`}
                                  className="children-anchor-item"
                                  onClick={(e) => {
                                    this.handleSettingChangeClick(e, child.key, anchorId);
                                  }}
                                >
                                  <span className={activeItem ? 'anchor-item-active' : ''}>{child.name}</span>
                                </div>
                              );
                            }, settingInfos)}
                          </div>
                        )}
                      </div>
                    );
                  }, this.settingInfos)}
                </div>
              )}

              <div className="flex-grow flex-min-height" style={{ overflow: 'hidden' }}>
                {settingInfo &&
                  React.createElement(settingInfo.component, {
                    intl,
                    projectName,
                    projects,
                    setting,
                    currentProject: project,
                    dataType,
                    projectSettingsParams,
                    saveProjectAgentList,
                    saveProjectInstanceGroup,
                    saveLogEntryStructure,
                    removeLogEntryStructure,
                    loadProjectSettings,
                    currentLoadingComponents,
                    data: projectSettings || {},
                    saveProjectSettings,
                    instanceGroup,
                    projectGroups,
                    credentials,
                    pickProjectModel,
                    exportProjectModel,
                    key: setting,
                    loadProjectLogStructure,
                    refreshTime,
                    keywordsList,
                    tabPosition: 'top',
                    settingsLoader: this.settingsLoader,
                    onChangeShowEditComponent: this.onChangeShowEditComponent,
                    getInstaceDisplayNameData: this.getInstaceDisplayNameData,
                    instanceDisplayNameMap,
                    handleRefreshClick: this.handleRefreshClick,
                  })}
              </div>
            </Container>
          )}
        </div>
        {showEditComponent && (
          <GroupEditComponent
            intl={intl}
            projectName={projectName}
            childReloadData={childReloadData}
            localInstanceGroupList={localInstanceGroupList}
            onChangeShowEditComponent={this.onChangeShowEditComponent}
          />
        )}
      </Container>
    );
  }
}

const ProjectSettings = injectIntl(ProjectSettingsCore);

export default connect(
  (state: State) => {
    const { location } = state.router;
    const { projects, currentLoadingComponents } = state.app;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isLocalAdmin } = state.auth.userInfo;
    const {
      projectGroups,
      projectSettings,
      projectSettingsParams,
      currentErrorMessage,
      activeInstanceMap,
      keywordsList,
    } = state.settings;
    return {
      location,
      isAdmin,
      isLocalAdmin,
      projects,
      credentials,
      projectGroups,
      projectSettings,
      currentLoadingComponents,
      projectSettingsParams,
      currentErrorMessage,
      activeInstanceMap,
      keywordsList,
      userInfo,
    };
  },
  {
    push,
    replace,
    createLoadAction,
    loadActiveInstance,
    loadProjectSettings,
    saveProjectSettings,
    pickProjectModel,
    exportProjectModel,
    saveProjectAgentList,
    saveProjectInstanceGroup,
    saveLogEntryStructure,
    removeLogEntryStructure,
    loadProjectLogStructure,
    hideAppLoader,
    showAppLoader,
  },
)(ProjectSettings);
