/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

/**
 * The urls used for creating routing, and also for generating links. Keep all
 * urls here will make change urls easy.
 */
const BaseUrls = {
  Help: '/help',
  AccountInfo: '/account-info',

  Global: '/global',
  GlobalSystemInfo: '/global/systeminfo',
  GlobalHealth: '/global/health',
  GlobalHealthSystem: '/global/health/system',
  GlobalHealthSystemNew: '/global/health/systemnew',
  GlobalCapacityPlanning: '/global/capacityplanning',
  GlobalSystemInsights: '/global/systeminsights',
  GlobalDashboard: '/global/dashboard',
  GlobalNewSystemInsights: '/global/newSysteminsights',
  GlobalSystemPrediction: '/global/systemprediction',
  GlobalSystemPredictionMatched: '/global/systemprediction/matchedlist',
  GlobalSystemRootCause: '/global/systemrootcause',
  GlobalSystemAlertCause: '/global/systemalertcause',
  GlobalSystemKnowledgeBase: '/global/systemknowledgebase',
  GlobalSystemKnowledgeBaseNew: '/global/systemknowledgebaseNew',
  GlobalSystemIgnoredIncident: '/global/system/ignoredincident',
  CapacityPlanningCalendar: '/global/capacityplanning/calendar',
  GlobalServiceMap: '/global/ServiceMap',
  GlobalAction: '/global/action',
  GlobalStart: '/global/start',
  GlobalActionWorkFlow: '/global/workFlow',

  Query: '/query',

  Metric: '/metric',
  MetricLineCharts: '/metric/linecharts',
  MetricEvents: '/metric/events',
  EventsHistory: '/metric/history',
  EventsDetails: '/metric/eventsdetails',

  Log: '/log',
  LogCalendar: '/log/calendar',
  LogAnalysis: '/log/analysis',

  Job: '/job',
  JobAnalysis: '/job/analysis',

  Causal: '/causal',
  CausalAnalysis: '/causal/analysis',
  CausalGroupAnalysis: '/causal/groupanalysis',

  Usecase: '/usecase/:system?',
  UsecaseDetails: '/usecasedetails',
  UsecaseBase: '/usecase',

  Settings: '/settings',
  SystemSetting: '/settings/system',
  SystemBatch: '/settings/batch',
  SettingsProject: '/settings/system/:projectName',
  SettingsIntegrations: '/settings/integrations',
  SettingsIntegrationsDataSource: '/settings/integrations/:dataSource',
  SettingsProjectWizard: '/settings/project-wizard',
  SettingsCopyProject: '/settings/copyproject',

  InsightfinderSetting: '/settings/insightfinder',
  ExternalServiceSetting: '/settings/extsvc',
  IdentityProvidersSetting: '/settings/identityProviders',
  StatusPageSetting: '/settings/statuspage',
  CmdbSetting: '/settings/cmdb',

  FileAnalysis: '/filetabs',
  FileAnalysisNewModel: '/filetabs/fileNewModel',
  FileAnalysisUploadModel: '/filetabs/uploadModel',
  FileAnalysisDetection: '/filetabs/filedetection',
  FileAnalysisUpdateModel: '/filetabs/fileupdatemodel',
  FileAnalysisDisplayModel: '/filetabs/filedisplaymodel',
  FileAnalysisUpload: '/filetabs/fileupload',
  FilesMonitoring: '/filetabs/filesMonitoring',
  FilesDetectionMonitoring: '/filetabs/filesdetectionMonitoring',

  Report: '/report',
  ReportLogRareEvent: '/report/lograreevent',
  ReportLogImportantEvent: '/report/logimportant',
  ReportMetric: '/report/metric',

  PagerDutyUrl:
    'https://connect.pagerduty.com/connect?vendor=ad2938f1f094d65d86fa&amp;callback=https://app.insightfinder.com/settings/extsvc',
};

export default BaseUrls;
