/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import VLink from 'valuelink';
import { get, isArray, toInteger } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { FileAddOutlined } from '@ant-design/icons';
import { Select as AntdSelect, Button, InputNumber, Switch, Tooltip, Spin, message } from 'antd';

import { Modal, Input, Select } from '../../../lib/fui/react';

import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { parseJSON } from '../../../common/utils';
import fetchPost from '../../../common/apis/fetchPost';
import EditPairingDurationModal from './EditPairingDurationModal';

type Props = {
  causalGroup: Object,
  causalName: String,
  causalKey: String,
  updateCausalGroup: Function,
  onClose: Function,

  intl: Object,
  currentLoadingComponents: Object,
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  errorMessage: ?Object,
  userInfo: Object,
  userList: Array<Object>,
  defaultCausalMetricPairingThreshold: Number,
};

const MAX_FATCH_NUM = 30;
class UpdateGroupModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { projects, causalGroup, userInfo, credentials, intl } = props;

    this.submitLoader = 'causal_update_group_submit';
    this.sumbitting = false;

    const { customerName, projectList, projectLevelSetting } = causalGroup || {};

    const selectProjects = R.map((item) => {
      const projectLevelObj = R.find((item2) => {
        let { projectName } = item2;
        if (projectName.includes('@')) {
          projectName = projectName.split('@')[0];
        }
        return projectName === item.projectName;
      }, projectLevelSetting || []);
      const {
        considerAnomalyAfterIncident,
        enableIncidentDurationLimitation,
        metricDuration,
        enableLastComponentCausalValidation,
      } = projectLevelObj || {};
      const { metricLevelValidPairingSet } = projectLevelObj || {};
      let { durationThreshold, anomalyTypeForSrc, logValidPairDuration } = projectLevelObj || {};
      durationThreshold = durationThreshold ?? 14400000;
      // default is 4 h
      let duration = durationThreshold;
      const hours = toInteger(duration / 3600000);
      duration %= 3600000;
      const minutes = String(toInteger(duration / 60000));

      logValidPairDuration = logValidPairDuration ?? 43200000;
      // default is 12 h
      let pairDuration = logValidPairDuration;
      const pairHours = toInteger(pairDuration / 3600000);
      pairDuration %= 3600000;
      const pairMinutes = String(toInteger(pairDuration / 60000));

      if (!anomalyTypeForSrc || (isArray(anomalyTypeForSrc) && anomalyTypeForSrc?.length === 0)) {
        anomalyTypeForSrc =
          item.type === 'Metric'
            ? ['Incident', 'MetricAlert', 'MetricAnomaly']
            : ['Incident', 'alert', 'hot', 'cold', 'newPattern', 'whiteList', 'rare', 'change', 'normal'];
      }
      let { projectName } = item;
      if (userInfo.isAdmin || userInfo.isLocalAdmin) {
        if (item.customerName) {
          projectName = `${projectName}@${item.customerName}`;
        } else {
          projectName = `${projectName}@${customerName}`;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (item.customerName && item.customerName !== credentials.userName) {
          projectName = `${projectName}@${item.customerName}`;
        }
      }

      return {
        ...item,
        durationThreshold,
        hours,
        minutes,
        considerAnomalyAfterIncident,
        anomalyTypeForSrc,
        projectName,
        ...(item.type === 'Metric'
          ? { enableIncidentDurationLimitation, metricDuration: metricDuration / (60 * 1000) }
          : {}),
        projectShortName: item.projectName,
        owner: item.customerName,
        enableLastComponentCausalValidation,
        metricLevelValidPairingSet: R.map(
          (_item) => ({ metricName: _item.m, duration: _item.d }),
          metricLevelValidPairingSet || [],
        ),
        logValidPairDuration,
        pairHours,
        pairMinutes,
      };
    }, projectList || []);

    const interval = get(props.causalGroup, ['interval']);
    const retentionTime = get(props.causalGroup, ['retentionTime']);
    const eventsRelationLookBackWindow = get(props.causalGroup, ['eventsRelationLookBackWindow']);
    let correlationInterval = get(props.causalGroup, ['correlationInterval']);
    let correlationUnit = 60 * 1000;
    if (correlationInterval >= 60 * 60 * 1000) {
      correlationInterval = correlationInterval / 60 / 60 / 1000;
      correlationUnit = 60 * 60 * 1000;
    } else {
      correlationInterval = correlationInterval / 60 / 1000;
    }
    const enableCorrelation = get(props.causalGroup, ['enableCorrelation']);
    const enableInterRelation = get(props.causalGroup, ['enableInterRelation']);
    const enableComponentIntra = get(props.causalGroup, ['componentLevelIntra']);
    const enableIncidentOnly = get(props.causalGroup, ['enableIncidentOnly']);
    const includeTargetAnomalyInPossibility = get(props.causalGroup, ['includeTargetAnomalyInPossibility']);
    this.state = {
      interval,
      retentionTime: retentionTime || '',
      correlationInterval,
      correlationUnit,
      enableCorrelation,
      eventsRelationLookBackWindow: eventsRelationLookBackWindow || '',
      enableInterRelation,
      enableComponentIntra,
      enableIncidentOnly,
      includeTargetAnomalyInPossibility,

      selectProjects,
      isGetLoading: false,
      showEditPairingDurationModal: false,
      activeEvent: null,
    };
    this.intervalUnitOptions = [
      { value: 60 * 1000, label: 'Mins' },
      { value: 60 * 60 * 1000, label: 'Hours' },
    ];
    this.projectList = [];
    this.metricOptions = [
      { label: 'Incident', value: 'Incident' },
      { label: 'Alert', value: 'MetricAlert' },
      { label: 'Metric Anomaly', value: 'MetricAnomaly' },
    ];
    this.logOptions = [
      { label: 'Incident', value: 'Incident' },
      { label: 'Alert', value: 'alert' },
      { label: 'Hot event', value: 'hot' },
      { label: 'Cold event', value: 'cold' },
      { label: 'New Pattern', value: 'newPattern' },
      { label: intl.formatMessage(logMessages.keywordAlerts), value: 'whiteList' },
      { label: 'Rare Event', value: 'rare' },
      { label: 'Change', value: 'change' },
      { label: 'Normal', value: 'normal' },
    ];
    this.isUpdateFlag = false;
  }

  async componentDidMount() {
    const { projects, userInfo, causalGroup } = this.props;
    const { customerName } = causalGroup || {};
    let projectList = R.sort((a, b) => (a.projectName || '').localeCompare(b.projectName), projects);
    if (userInfo.isAdmin || userInfo.isLocalAdmin) {
      projectList = R.filter((p) => p.owner === customerName, projectList);
    } else {
      projectList = R.filter((p) => p.owner === userInfo.userName, projectList);
    }
    projectList = R.filter((project) => project.ps !== 'Deleting' && project.ps !== 'In trash', projectList);
    projectList = R.filter((item) => item.cloudType !== 'LogFrequency', projectList || []);
    this.projectList = projectList;
    this.forceUpdate();

    // const { userInfo, userList, causalGroup } = this.props;
    // const { customerName } = causalGroup || {};
    // if (userInfo.isAdmin || userInfo.isLocalAdmin) {
    //   const userName = R.find((item) => item.userName === customerName, userList || []) || {};
    //   if (userName) {
    //     this.getProjectsList(userName);
    //   }
    // } else {
    //   const { userName } = userInfo;
    //   this.getProjectsList({ userName });
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLoadingComponents, errorMessage, intl } = nextProps;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      if (!errorMessage) {
        this.isUpdateFlag = true;
        message.success(intl.formatMessage(eventMessages.success));
        this.props.onClose(true);
      }
    }
  }

  componentWillUnmount() {
    this.isUpdateFlag = false;
  }

  @autobind
  getProjectsList(userNameList) {
    this.setState({ isGetLoading: true });
    const { credentials } = this.props;
    const { userName } = userNameList;
    fetchGet(getEndpoint('systemframework', 2), {
      ...credentials,
      customerName: userName,
      loadSameCompany: true,
    })
      .then(async (results) => {
        let { ownSystemArr, sameCompanySystemArray } = results || {};
        ownSystemArr = R.map((item) => {
          const system = parseJSON(item) || {};
          return { ...system };
        }, ownSystemArr || []);
        sameCompanySystemArray = R.map((item) => {
          const system = parseJSON(item) || {};
          return { ...system };
        }, sameCompanySystemArray || []);

        const systemsMap = {};
        const projectSystemKeyMap = {};
        let allProjects = [];
        R.forEach(
          (system) => {
            const { systemDisplayName, systemKey, projectDetailsList, ...rest } = system;
            const { environmentName, systemName: systemId, userName } = systemKey || {};
            let newProjects = parseJSON(projectDetailsList) || [];
            newProjects = R.map((item) => {
              const { userName, projectClassType, ...restItem } = item;
              return {
                ...restItem,
                customerName: userName,
                projectType: projectClassType,
              };
            }, newProjects);

            systemsMap[systemId] = {
              ...rest,
              environmentName,
              systemId,
              systemName: systemDisplayName || systemId,
              owner: userName,
              projectDetailsList: newProjects,
            };
            R.forEach((item) => {
              const { projectName, customerName } = item;
              const projectFullName = `${projectName}@${customerName}`;
              projectSystemKeyMap[projectFullName] = systemId;
            }, newProjects);

            allProjects = [...allProjects, ...newProjects];
          },
          // [...ownSystemArr, ...sameCompanySystemArray],
          [...ownSystemArr],
        );

        const projectList = R.map((item) => {
          const { customerName, projectName, projectType } = item;
          return { customerName, projectName, projectType };
        }, allProjects);
        const metaSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, projectList || []);
        const metaRequests = [];
        R.forEach((group) => {
          if (group.length > 0) {
            metaRequests.push(
              fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
                ...credentials,
                projectList: JSON.stringify(group),
              }),
            );
          }
        }, metaSplitEveryTakes || []);
        await Promise.all(metaRequests)
          .then((res) => {
            let groupMetaProject = [];
            R.forEach((data) => {
              groupMetaProject = [...groupMetaProject, ...get(data, 'data', [])];
            }, res || []);
            R.forEach((item) => {
              const findInfo = R.find((_item) => _item.projectKey === item.projectKey, groupMetaProject);
              if (findInfo) {
                item.projectDisplayName = findInfo.projectDisplayName;
                item.cloudType = findInfo.cloudType;
                item.projectKey = findInfo.projectKey;
              }
            }, allProjects);
          })
          .catch((err) => {
            console.error('[IF] Failed to get project stats', err);
            return {};
          });

        const uniqProjects = {};
        R.forEach((project) => {
          const { projectName } = project;
          if (!R.has(projectName, uniqProjects)) {
            uniqProjects[projectName] = project;
          }
        }, allProjects);

        let projectStats = {};
        const statusSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, R.values(uniqProjects) || []);
        const statusRequests = [];
        R.forEach((group) => {
          if (group.length > 0) {
            statusRequests.push(
              fetchPost(getEndpoint('getprojectstatus'), {
                ...credentials,
                projectList: JSON.stringify(group),
              }),
            );
          }
        }, statusSplitEveryTakes || []);
        await Promise.all(statusRequests)
          .then((res) => {
            let groupProjectStats = {};
            R.forEach((data) => {
              groupProjectStats = { ...groupProjectStats, ...get(data, 'data', {}) };
            }, res || []);
            projectStats = R.mapObjIndexed((item) => {
              return { ...item, projectStatus: parseJSON(item.projectStatus) };
            }, groupProjectStats);
          })
          .catch((err) => {
            console.error('[IF] Failed to get project stats', err);
            return {};
          });

        let projects = R.map((project) => {
          const { customerName, dataType, projectName, projectType } = project;
          let newProjectName = projectName;
          if (customerName !== credentials.userName) {
            newProjectName = `${projectName}@${customerName}`;
          }

          const isMetric = dataType && dataType.toLowerCase() === 'metric';
          const isLog = dataType && ['log', 'trace'].includes(dataType.toLowerCase());
          const isTrace = dataType && dataType.toLowerCase() === 'trace';
          const isDeployment = dataType && dataType.toLowerCase() === 'deployment';
          const isAlert = dataType && dataType.toLowerCase() === 'alert';
          const isIncident = dataType && dataType.toLowerCase() === 'incident';

          // systemInfo
          const projectFullName = `${projectName}@${customerName}`;
          const systemKey = projectSystemKeyMap[projectFullName];
          const { environmentName, systemId, systemName, timezone } = get(systemsMap, [systemKey], {});

          const { ps } = projectStats[project.projectKey]?.projectStatus || {};
          return {
            ...project,
            // system info
            systemKey,
            environmentName,
            systemId,
            systemName,
            timezone,

            // project meta info
            hasAllInfo: false,
            hasAllInstanceInfo: false,
            projectName: newProjectName,
            projectShortName: projectName,
            projectType: projectType.toLowerCase() === 'custom' ? 'Agent' : projectType,
            projectTypeRaw: projectType,
            dataType,
            owner: customerName,

            // project type
            isMetric,
            isLog,
            isTrace,
            isDeployment,
            isAlert,
            isIncident,

            ps,
          };
        }, R.values(uniqProjects));
        projects = R.sort((a, b) => (a.projectName || '').localeCompare(b.projectName), projects);
        projects = R.filter((project) => project.ps !== 'Deleting' && project.ps !== 'In trash', projects);

        projects = R.filter((item) => item.cloudType !== 'LogFrequency', projects || []);

        this.projectList = projects;
        this.setState({ isGetLoading: false });
      })
      .catch((err) => console.error(String(err)));
  }

  @autobind
  handleEditPairingDuration(rowData, index) {
    this.setState({ showEditPairingDurationModal: true, activeEvent: { ...rowData, index } });
  }

  @autobind
  renderListViewProjects(rowData, index) {
    if (!rowData) return null;

    const { intl, userInfo } = this.props;
    const {
      projectName,
      hours,
      minutes,
      considerAnomalyAfterIncident,
      anomalyTypeForSrc,
      type,
      enableIncidentDurationLimitation,
      metricDuration,
      enableLastComponentCausalValidation,
      pairHours,
      pairMinutes,
    } = rowData;

    return (
      <div
        key={index}
        className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
        style={{ minHeight: 40, padding: '6px 0' }}
      >
        <div className="row-column" style={{ width: 200 }}>
          <AntdSelect
            size="small"
            style={{ width: '100%' }}
            showSearch
            value={projectName}
            onChange={(projectName) => {
              const { selectProjects } = this.state;

              const project = R.find((item) => item.projectName === projectName, this.projectList || []);
              const { isMetric, dataType, projectTypeRaw, projectShortName, owner } = project || {};

              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: {
                    $set: {
                      ...rowData,
                      projectName,
                      grouping: isMetric ? 'All' : undefined,
                      type: dataType,
                      projectType: projectTypeRaw,
                      anomalyTypeForSrc: [],
                      projectShortName,
                      owner,
                      metricLevelValidPairingSet: [],
                      enableIncidentDurationLimitation: false,
                      metricDuration: 0,
                      logValidPairDuration: 43200000,
                      pairHours: 12,
                      pairMinutes: '0',
                    },
                  },
                }),
              });
            }}
            filterOption
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 650 }}
          >
            {R.map((item) => {
              return (
                <AntdSelect.Option key={item.projectName}>{`${item.projectDisplayName}${
                  userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName ? `@${item.owner}` : ''
                }`}</AntdSelect.Option>
              );
            }, this.projectList || [])}
          </AntdSelect>
        </div>
        <div className="row-column" style={{ width: 220 }}>
          <InputNumber
            size="small"
            style={{ width: 120, marginLeft: 4 }}
            addonAfter="Hours"
            min={0}
            precision={0}
            value={hours}
            onChange={(hours) => {
              const { selectProjects } = this.state;
              const durationThreshold = (hours || 0) * 3600000 + (minutes ? Number(minutes) : 0) * 60000;
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: { $set: { ...rowData, hours: hours || 0, durationThreshold } },
                }),
              });
            }}
          />
          <AntdSelect
            size="small"
            style={{ width: 84, marginLeft: 4, flexShrink: 0 }}
            placeholder="Minute"
            value={minutes || '0'}
            onChange={(minutes) => {
              const { selectProjects } = this.state;
              const durationThreshold = (hours ? Number(hours) : 0) * 3600000 + (minutes ? Number(minutes) : 0) * 60000;
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: { $set: { ...rowData, minutes, durationThreshold } },
                }),
              });
            }}
          >
            <AntdSelect.Option key="0">0 Mins</AntdSelect.Option>
            <AntdSelect.Option key="5">5 Mins</AntdSelect.Option>
            <AntdSelect.Option key="15">15 Mins</AntdSelect.Option>
            <AntdSelect.Option key="30">30 Mins</AntdSelect.Option>
            <AntdSelect.Option key="45">45 Mins</AntdSelect.Option>
          </AntdSelect>
        </div>
        <div className="row-column" style={{ width: 100 }}>
          <Switch
            size="small"
            checkedChildren={intl.formatMessage(appFieldsMessages.checkedOn)}
            unCheckedChildren={intl.formatMessage(appFieldsMessages.checkedOff)}
            checked={considerAnomalyAfterIncident}
            onChange={(considerAnomalyAfterIncident) => {
              const { selectProjects } = this.state;
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: { $set: { ...rowData, considerAnomalyAfterIncident } },
                }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 120 }}>
          <AntdSelect
            mode="multiple"
            size="small"
            allowClear
            maxTagCount={1}
            value={anomalyTypeForSrc}
            placeholder=""
            onChange={(anomalyTypeForSrc) => {
              const { selectProjects } = this.state;
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: { $set: { ...rowData, anomalyTypeForSrc } },
                }),
              });
            }}
            options={type === 'Metric' ? this.metricOptions : this.logOptions}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: 250 }}
          />
        </div>
        <div className="row-column" style={{ width: 185 }}>
          {type === 'Metric' && (
            <>
              <Switch
                size="small"
                checkedChildren={intl.formatMessage(appFieldsMessages.checkedOn)}
                unCheckedChildren={intl.formatMessage(appFieldsMessages.checkedOff)}
                checked={enableIncidentDurationLimitation}
                onChange={(enableIncidentDurationLimitation) => {
                  const { selectProjects } = this.state;
                  this.setState({
                    selectProjects: update(selectProjects, {
                      [index]: { $set: { ...rowData, enableIncidentDurationLimitation } },
                    }),
                  });
                }}
              />
              <div className="flex-row flex-center-align">
                <span style={{ margin: '0 4px' }}>Minutes:</span>
                <InputNumber
                  size="small"
                  min={0}
                  style={{ width: 75 }}
                  value={metricDuration}
                  onChange={(metricDuration) => {
                    const { selectProjects } = this.state;
                    this.setState({
                      selectProjects: update(selectProjects, {
                        [index]: { $set: { ...rowData, metricDuration: metricDuration || 0 } },
                      }),
                    });
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="row-column" style={{ width: 100 }}>
          <Switch
            size="small"
            checkedChildren={intl.formatMessage(appFieldsMessages.checkedOn)}
            unCheckedChildren={intl.formatMessage(appFieldsMessages.checkedOff)}
            checked={enableLastComponentCausalValidation}
            onChange={(enableLastComponentCausalValidation) => {
              const { selectProjects } = this.state;
              this.setState({
                selectProjects: update(selectProjects, {
                  [index]: { $set: { ...rowData, enableLastComponentCausalValidation } },
                }),
              });
            }}
          />
        </div>
        <div className="row-column" style={{ width: 220 }}>
          {type === 'Metric' && (
            <div>
              <Button size="small" onClick={() => this.handleEditPairingDuration(rowData, index)}>
                {intl.formatMessage(appButtonsMessages.edit)}
              </Button>
            </div>
          )}
          {type === 'Log' && (
            <>
              <InputNumber
                size="small"
                style={{ width: 120, marginLeft: 4 }}
                addonAfter="Hours"
                min={0}
                precision={0}
                value={pairHours}
                onChange={(pairHours) => {
                  const { selectProjects } = this.state;
                  const logValidPairDuration =
                    (pairHours || 0) * 3600000 + (pairMinutes ? Number(pairMinutes) : 0) * 60000;
                  this.setState({
                    selectProjects: update(selectProjects, {
                      [index]: { $set: { ...rowData, pairHours: pairHours || 0, logValidPairDuration } },
                    }),
                  });
                }}
              />
              <AntdSelect
                size="small"
                style={{ width: 84, marginLeft: 4, flexShrink: 0 }}
                placeholder="Minute"
                value={pairMinutes || '0'}
                onChange={(pairMinutes) => {
                  const { selectProjects } = this.state;
                  const logValidPairDuration =
                    (pairHours ? Number(pairHours) : 0) * 3600000 + (pairMinutes ? Number(pairMinutes) : 0) * 60000;
                  this.setState({
                    selectProjects: update(selectProjects, {
                      [index]: { $set: { ...rowData, pairMinutes, logValidPairDuration } },
                    }),
                  });
                }}
              >
                <AntdSelect.Option key="0">0 Mins</AntdSelect.Option>
                <AntdSelect.Option key="5">5 Mins</AntdSelect.Option>
                <AntdSelect.Option key="15">15 Mins</AntdSelect.Option>
                <AntdSelect.Option key="30">30 Mins</AntdSelect.Option>
                <AntdSelect.Option key="45">45 Mins</AntdSelect.Option>
              </AntdSelect>
            </>
          )}
        </div>
        <div className="row-column flex-end-justify" style={{ width: 70 }}>
          <Button
            size="small"
            className="button-color-grey"
            onClick={() => this.handleRemoveProjectClick(rowData, index)}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  handleAddProjectClick() {
    const { selectProjects } = this.state;
    this.setState(
      {
        selectProjects: [
          {
            projectName: undefined,
            durationThreshold: 14400000,
            hours: 4,
            minutes: '0',
            anomalyTypeForSrc: [],
            enableIncidentDurationLimitation: false,
            metricDuration: 0,
            metricLevelValidPairingSet: [],
            logValidPairDuration: 43200000,
            pairHours: 12,
            pairMinutes: '0',
          },
          ...selectProjects,
        ],
      },
      () => {
        if (this.listNode) this.listNode.scrollTo(0, 0);
      },
    );
  }

  @autobind
  handleRemoveProjectClick(rowData, index) {
    const { selectProjects } = this.state;
    this.setState({ selectProjects: R.remove(index, 1, selectProjects) });
  }

  @autobind
  handleOnClose() {
    this.props.onClose(false, this.isUpdateFlag);
  }

  @autobind
  handleSumbit() {
    const { updateCausalGroup, causalGroup } = this.props;
    const {
      interval,
      retentionTime,
      correlationInterval,
      correlationUnit,
      enableCorrelation,
      eventsRelationLookBackWindow,
      enableInterRelation,
      enableComponentIntra,
      enableIncidentOnly,
      includeTargetAnomalyInPossibility,
      selectProjects,
    } = this.state;

    const { customerName } = causalGroup;

    const projectList = [];
    const projectLevelSetting = [];
    const validateProjectList = [];

    R.forEach((item) => {
      const {
        projectName,
        grouping,
        type,
        projectType,
        enableIncidentDurationLimitation,
        metricDuration,
        metricLevelValidPairingSet,
        enableLastComponentCausalValidation,
      } = item;
      projectList.push({
        projectName: projectName.includes('@') ? projectName.split('@')[0] : projectName,
        customerName: projectName.includes('@') ? projectName.split('@')[1] : customerName,
        grouping,
        type,
        projectType,
      });
      if (enableLastComponentCausalValidation) {
        validateProjectList.push(projectName.includes('@') ? projectName.split('@')[0] : projectName);
      }
      const { durationThreshold, considerAnomalyAfterIncident, anomalyTypeForSrc, logValidPairDuration } = item;

      projectLevelSetting.push({
        projectName: projectName.includes('@') ? projectName.split('@')[0] : projectName,
        customerName: projectName.includes('@') ? projectName.split('@')[1] : customerName,
        durationThreshold: durationThreshold || 0,
        considerAnomalyAfterIncident,
        enableLastComponentCausalValidation,
        anomalyTypeForSrc: isArray(anomalyTypeForSrc) && anomalyTypeForSrc?.length > 0 ? anomalyTypeForSrc : undefined,
        ...(type === 'Metric'
          ? {
              enableIncidentDurationLimitation,
              metricDuration: metricDuration * 60 * 1000,
              metricLevelValidPairingSet: R.map(
                (item) => ({ m: item.metricName, d: item.duration }),
                metricLevelValidPairingSet || [],
              ),
            }
          : { logValidPairDuration: logValidPairDuration || 0 }),
      });
    }, selectProjects);

    const causalKey = (this.props.causalKey || '').trim();
    this.sumbitting = true;
    updateCausalGroup(
      {
        causalKey,
        interval: interval * 24 * 60 * 60 * 1000,
        retentionTime: retentionTime * 24 * 60 * 60 * 1000 || '',
        correlationInterval: correlationInterval * correlationUnit,
        enableCorrelation,
        eventsRelationLookBackWindow: eventsRelationLookBackWindow * 24 * 60 * 60 * 1000,
        enableInterRelation,
        enableComponentIntra,
        enableIncidentOnly,
        includeTargetAnomalyInPossibility,
        projectList: JSON.stringify(projectList),
        projectLevelSetting: JSON.stringify(projectLevelSetting),
        owner: customerName,
        validateProjectList: JSON.stringify(validateProjectList),
      },
      { [this.submitLoader]: true },
    );
  }

  render() {
    const { intl, errorMessage, causalName, currentLoadingComponents, credentials } = this.props;
    const {
      enableCorrelation,
      enableInterRelation,
      enableComponentIntra,
      enableIncidentOnly,
      includeTargetAnomalyInPossibility,
      selectProjects,
      isGetLoading,
    } = this.state;
    const { defaultCausalMetricPairingThreshold } = this.props;
    const { showEditPairingDurationModal, activeEvent } = this.state;

    const relationIntervalLink = VLink.state(this, 'interval').check(
      (value) => parseInt(value, 10) > 0,
      'Interval must larger than 0',
    );
    const retentionTimeLink = VLink.state(this, 'retentionTime').check(
      (value) => !value || parseInt(value, 10) > 0,
      'Interval must larger than 0',
    );
    const eventsRelationLookBackWindowLink = VLink.state(this, 'eventsRelationLookBackWindow').check(
      (value) => !value || parseInt(value, 10) > 0,
      'Range must larger than 0',
    );
    const correlationIntervalLink = VLink.state(this, 'correlationInterval').check(
      (value) => parseInt(value, 10) > 0,
      'Interval must larger than 0',
    );
    const correlationUnitLink = VLink.state(this, 'correlationUnit').check(
      (value) => Boolean(value),
      'select interval unit',
    );
    const hasError =
      relationIntervalLink.error ||
      retentionTimeLink.error ||
      correlationIntervalLink.error ||
      correlationUnitLink.error ||
      eventsRelationLookBackWindowLink.error ||
      selectProjects.length === 0 ||
      !R.reduce(
        R.and,
        true,
        R.map((item) => item.projectName, selectProjects),
      );
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    return (
      <>
        <Modal
          width={1440}
          title={intl.formatMessage(causalMessages.editCausalGroup)}
          visible
          onCancel={this.handleOnClose}
          okButtonProps={{ loading: isLoading, disabled: hasError }}
          onOk={this.handleSumbit}
          okText={intl.formatMessage(appButtonsMessages.update)}
        >
          <div className="content">
            <Spin spinning={isGetLoading}>
              <form className={`ui ${hasError ? 'error' : ''} form`}>
                <div className="flex-row flex-center-align" style={{ margin: '0 0 16px' }}>
                  <div className="light-label bold" style={{ width: 170 }}>
                    {intl.formatMessage(causalMessages.causalName)}:
                  </div>
                  {causalName}
                </div>
                <div className="flex-row flex-center-align" style={{ margin: '0 0 16px' }}>
                  <div className="light-label bold" style={{ width: 170 }}>
                    {intl.formatMessage(causalMessages.groupInterval)}:
                  </div>
                  <Input type="number" valueLink={relationIntervalLink} style={{ width: 100, height: 28 }} />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    {intl.formatMessage(causalMessages.correlationInterval)}:
                  </div>
                  <Input type="number" valueLink={correlationIntervalLink} style={{ width: 80, height: 28 }} />
                  <Select
                    name="correlationUnit"
                    valueLink={correlationUnitLink}
                    options={this.intervalUnitOptions}
                    style={{ width: 60 }}
                  />
                  <Tooltip title="Enable correlation" mouseEnterDelay={0.3} placement="top">
                    <Switch
                      size="small"
                      style={{ marginLeft: 8 }}
                      checkedChildren={intl.formatMessage(appFieldsMessages.checkedOn)}
                      unCheckedChildren={intl.formatMessage(appFieldsMessages.checkedOff)}
                      checked={enableCorrelation}
                      onChange={(enableCorrelation) => this.setState({ enableCorrelation })}
                    />
                  </Tooltip>
                </div>
                <div className="flex-row flex-center-align" style={{ margin: '0 0 16px' }}>
                  <div className="light-label bold" style={{ width: 170 }}>
                    {intl.formatMessage(causalMessages.retentionTime)}:
                  </div>
                  <Input type="number" valueLink={retentionTimeLink} style={{ width: 100, height: 28 }} />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    {intl.formatMessage(causalMessages.enableInterRelation)}:
                  </div>
                  <input
                    type="checkbox"
                    checked={enableInterRelation}
                    onChange={() => {
                      const { enableInterRelation } = this.state;
                      this.setState({ enableInterRelation: !enableInterRelation });
                    }}
                  />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    Enable component intra relation:
                  </div>
                  <input
                    type="checkbox"
                    checked={enableComponentIntra}
                    onChange={() => {
                      const { enableComponentIntra } = this.state;
                      this.setState({ enableComponentIntra: !enableComponentIntra });
                    }}
                  />
                </div>
                <div className="flex-row flex-center-align" style={{ margin: '0 0 16px' }}>
                  <div className="light-label bold" style={{ width: 170 }}>
                    {intl.formatMessage(causalMessages.eventsRelationLookBackTime)}:
                  </div>
                  <Input type="number" valueLink={eventsRelationLookBackWindowLink} style={{ width: 100 }} />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    {intl.formatMessage(causalMessages.enableIncidentOnly)}:
                  </div>
                  <input
                    type="checkbox"
                    checked={enableIncidentOnly}
                    onChange={() => {
                      const { enableIncidentOnly } = this.state;
                      this.setState({ enableIncidentOnly: !enableIncidentOnly });
                    }}
                  />
                  <div
                    className="light-label bold"
                    style={{ width: 200, marginLeft: 50, paddingRight: 10, textAlign: 'right' }}
                  >
                    {intl.formatMessage(causalMessages.enableOrphanIncidentParing)}:
                  </div>
                  <input
                    type="checkbox"
                    checked={includeTargetAnomalyInPossibility}
                    onChange={() => {
                      const { includeTargetAnomalyInPossibility } = this.state;
                      this.setState({ includeTargetAnomalyInPossibility: !includeTargetAnomalyInPossibility });
                    }}
                  />
                </div>
              </form>

              <div className="flex-row flex-center-align">
                <div className="light-label bold" style={{ width: 170 }}>
                  {intl.formatMessage(DashboardMessages.projectList)}:
                </div>
                <div className="flex-grow flex-col" style={{ width: 0 }}>
                  <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
                    <div className="flex-row">
                      <Button size="small" type="primary" onClick={this.handleAddProjectClick}>
                        <FileAddOutlined /> {intl.formatMessage(appButtonsMessages.add)}
                      </Button>
                    </div>
                  </div>

                  <div className="event-list flex-grow flex-col flex-min-height overflow-x-auto">
                    <div className="event-list-header" style={{ height: 'fit-content', width: 'fit-content' }}>
                      <div className="header-column" style={{ width: 200 }}>
                        {intl.formatMessage(eventMessages.projectName)}
                        <span style={{ color: 'red', textDecoration: 'none', marginLeft: 5 }}>*</span>
                      </div>
                      <div className="header-column" style={{ width: 220 }}>
                        {intl.formatMessage(causalMessages.intervalLimit)}
                        <span style={{ color: 'red', textDecoration: 'none', marginLeft: 5 }}>*</span>
                      </div>
                      <div className="header-column" style={{ width: 100 }}>
                        {intl.formatMessage(causalMessages.considerAnomalyAfterIncident)}
                      </div>
                      <div className="header-column" style={{ width: 120 }}>
                        {intl.formatMessage(causalMessages.sourceCandidateAnomalyTypes)}
                      </div>
                      <div className="header-column" style={{ width: 185 }}>
                        {intl.formatMessage(causalMessages.enableAnomalyToIncidentGapCheck)}
                      </div>
                      <div className="header-column" style={{ width: 100 }}>
                        {intl.formatMessage(causalMessages.enableLatestComponentLevelCausalValidations)}
                      </div>
                      <div className="header-column" style={{ width: 220 }}>
                        {intl.formatMessage(causalMessages.pairingDuration)}
                      </div>
                      <div className="header-column" style={{ width: 70 }} />
                    </div>
                    <div
                      className="event-list-grid flex-grow overflow-y-auto"
                      style={{ maxHeight: 160, width: 'fit-content' }}
                      ref={(el) => {
                        this.listNode = el;
                      }}
                    >
                      {R.addIndex(R.map)(
                        (rowData, index) => this.renderListViewProjects(rowData, index),
                        selectProjects || [],
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>

          {Boolean(errorMessage) && (
            <div className="error content">
              <div className="ui error mini message">
                <i className="icon warning sign" />
                <span>{intl.formatMessage(errorMessage.message, errorMessage.params)}</span>
              </div>
            </div>
          )}
        </Modal>

        {showEditPairingDurationModal && (
          <EditPairingDurationModal
            intl={intl}
            credentials={credentials}
            activeEvent={activeEvent}
            defaultCausalMetricPairingThreshold={defaultCausalMetricPairingThreshold}
            onClose={(saveItem) => {
              if (!R.isEmpty(saveItem)) {
                R.forEachObjIndexed((val, key) => {
                  const { selectProjects } = this.state;
                  this.setState({
                    selectProjects: update(selectProjects, {
                      [key]: { $set: { ...selectProjects[key], metricLevelValidPairingSet: val } },
                    }),
                  });
                }, saveItem || {});
              }
              this.setState({ showEditPairingDurationModal: false, activeEvent: null });
            }}
          />
        )}
      </>
    );
  }
}

const UpdateGroupModal = injectIntl(UpdateGroupModalCore);
export default connect((state) => {
  const { currentLoadingComponents } = state.app;
  let { projects, userList } = state.app;
  projects = R.filter((project) => project.ps !== 'Deleting' && project.ps !== 'In trash', projects);
  userList = R.filter((user) => user.role !== 'Admin', userList || []);
  const { credentials, userInfo } = state.auth;
  const { errorMessage } = state.causal;

  return {
    currentLoadingComponents,
    projects,
    credentials,
    errorMessage,
    userInfo,
    userList,
  };
}, {})(UpdateGroupModal);
