import React from 'react';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import * as R from 'ramda';
import { Button, DatePicker, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { CellMeasurer, List } from 'react-virtualized';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { State } from '../../common/types';
import { hideAppLoader, showAppAlert, showAppLoader } from '../../common/app/actions';
import { settingsMessages } from '../../common/settings/messages';
import BaseUrls from '../app/BaseUrls';
import { buildLocation, buildUrl, Defaults, parseLocation, timeScopeControl } from '../../common/utils';
import { AutoSizer, CellMeasurerCache, Column, Container, Table } from '../../lib/fui/react';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../common/app/messages';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import PublicBuildCizizen from '../dashboard/components/PublicBuildCizizen';
import fetchPost from '../../common/apis/fetchPost';
import { eventMessages } from '../../common/metric/messages';
import getInstanceDisplayName from '../../common/utils/getInstanceDisplayName';

type Props = {
  intl: Object,
  location: Object,
  userInfo: Object,
  credentials: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  systemsMap: Object,
  projects: Array<Object>,
  allProjects: Array<Object>,
  push: Function,
  replace: Function,
  hideAppLoader: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  showAppLoader: Function,
  userList: Array<Object>,
  timezoneOffset: Number,
};

class GlobalActionPageCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location, isAdmin, isLocalAdmin } = props;
    const params = parseLocation(location);
    const { systemSearch, projectOwner, customerName } = params;
    let { startTime, endTime } = params;
    const time = this.getUserCurrentTime(props, false);
    if (time) {
      startTime = time;
      endTime = time;
    }

    this.userListOptions = [];
    this.state = {
      startTimeObj: moment.utc(startTime || moment.utc(), Defaults.DateFormat),
      endTimeObj: moment.utc(endTime || moment.utc(), Defaults.DateFormat),
      endTimeOpen: false,
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      isLoadingSystem: false,
      isGroupingLoading: false,

      isKbProject: false,
      rawActionRecords: [],
      actionRecords: [],
      sortBy: 'triggerTimestamp',
      sortDirection: null,
      statusOptions: [],
      deploymentOptions: [],
      containerOptions: [],
      podsOptions: [],
      projectOptions: [],
      instanceOptions: [],
      statusFilter: null,
      deploymentFilter: null,
      containerFilter: null,
      podsFilter: null,
      projectFilter: null,
      instanceFilter: null,
      systemName: 'No system',
      systemList: [],
      systemSearch: systemSearch || '',
      projectOwner: projectOwner || (isAdmin || isLocalAdmin ? customerName : null),

      summaryObj: {},
      instanceDisplayNameMap: {},
    };

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
  }

  async componentDidMount() {
    const { hideAppLoader, allProjects } = this.props;
    hideAppLoader();
    if (allProjects.length === 0) {
      showAppAlert('info', settingsMessages.alertNoProject);
      push(BaseUrls.SettingsProjectWizard);
    } else {
      this.parseData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.systemId !== query.systemId
    ) {
      this.parseData(nextProps);
    }
    if (nextQuery.forceRefreshTime !== query.forceRefreshTime) {
      this.reloadGrouping(nextProps);
    }
  }

  @autobind
  getSystemList(props) {
    const { location, globalInfo, favorites: favoriteList } = props;
    const { environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    let systemList = environment ? environment.systemList : [];
    let favorites = R.filter((item) => favoriteList.includes(item.id), systemList);
    let restSystems = R.filter((item) => !favoriteList.includes(item.id), systemList);
    favorites = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(favorites);
    restSystems = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(restSystems);
    // 把hasData = false 放在后面
    const notHasDataSystems = R.filter((item) => !item.hasData, restSystems);
    restSystems = R.filter((item) => item.hasData, restSystems);

    systemList = [...favorites, ...restSystems, ...notHasDataSystems];
    return systemList;
  }

  @autobind
  getUserCurrentTime(props, nonReplace = true) {
    const { location, replace } = props;
    let { timezoneOffset } = props;
    const query = parseLocation(location);
    const { systemId } = query;

    const systemList = this.getSystemList(props);
    const systemInfo = R.find((system) => system.id === systemId, systemList) || systemList[0];

    if (systemInfo && systemInfo.timezone) {
      const zone = momenttz.tz(systemInfo?.timezone);
      timezoneOffset = zone.utcOffset();
      const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
      const time = moment.utc(nowTimestamp).format(Defaults.DateFormat);
      if (nonReplace) {
        replace(
          buildLocation(
            location.pathname,
            {},
            {
              ...query,
              startTime: time,
              endTime: time,
              systemId: systemInfo.id,
            },
          ),
        );
        return time;
      }
    }
    return undefined;
  }

  @autobind
  reloadGrouping(props) {
    const { credentials, location, userInfo, globalInfo } = props;
    const { systemSearch, startTimeObj, endTimeObj, sortBy } = this.state;
    let query = parseLocation(location);
    const { customerName, environmentId, systemId } = query;
    const startTime = startTimeObj.format(Defaults.DateFormat);
    const endTime = endTimeObj.format(Defaults.DateFormat);

    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    // update start/end time if changed
    if (startTime !== query.startTime || endTime !== query.endTime) {
      query = { ...query, startTime, endTime };
    }

    const url = buildUrl(this.props.location.pathname, {}, { ...query, systemId: systemSearch });
    window.history.replaceState(null, null, url);

    this.setState({ isGroupingLoading: true });

    const requests = [];
    if (systemSearch && startTimeObj && endTimeObj && systemInfo) {
      requests.push(
        fetchGet(getEndpoint('IFActionRecordServlet', 2), {
          ...credentials,
          systemName: systemSearch,
          customerName: systemInfo?.ownerUserName || customerName || userInfo.userName,
          startTime: startTimeObj.startOf('days').valueOf(),
          endTime: endTimeObj.endOf('days').valueOf(),
        }),
        fetchGet(getEndpoint('info/metadataAggregate', 2), {
          ...credentials,
          startTime: startTimeObj.startOf('days').valueOf(),
          endTime: endTimeObj.endOf('days').valueOf(),
          customerName: systemInfo?.ownerUserName || customerName || userInfo.userName,
          systemName: systemSearch,
          anomalyInstanceOnly: false,
        }),
      );
    }

    Promise.all(requests)
      .then((results) => {
        const actionResult = results[0];
        const metaResult = results[1];

        if (actionResult) {
          const { success, message, records = [] } = actionResult;
          if (!success) {
            console.error(message);
            this.setState({ isGroupingLoading: false });
          } else {
            const isKbProject = !!R.find((item) => item.deployment || item.container, records);
            const rawActionRecords = records;
            const statusOptions = {};
            const deploymentOptions = {};
            const containerOptions = {};
            const podsOptions = {};
            const projectOptions = {};
            const instanceOptions = {};

            R.forEach((r) => {
              if (r.status) {
                statusOptions[r.status] = r.status;
              }
              if (isKbProject) {
                if (r.deployment) {
                  deploymentOptions[r.deployment] = r.deployment;
                }
                if (r.container) {
                  containerOptions[r.container] = r.container;
                }
                if (r.pods) {
                  podsOptions[r.pods] = r.pods;
                }
              } else {
                if (r.project) {
                  projectOptions[r.project] = r.project;
                }
                if (r.instance) {
                  instanceOptions[r.instance] = r.instance;
                }
              }
            }, rawActionRecords);

            const instanceDisplayNameMap = {};
            if (metaResult?.success || metaResult?.success === undefined) {
              const { aggregatedResult } = metaResult || {};
              const { globalViewInstanceInfoResult } = aggregatedResult || {};
              const instancesByDay = globalViewInstanceInfoResult?.g || {};
              const projectInfo = globalViewInstanceInfoResult?.p?.projectInfoList || [];

              R.forEachObjIndexed((instance, key) => {
                const { ip, idn } = instance || {};
                instanceDisplayNameMap[key] = idn;
                R.forEach((item) => {
                  const { projectName, userName } = projectInfo[item.i] || {};
                  instanceDisplayNameMap[`${projectName}-${userName}-${key}`] = idn;
                }, ip?.i || []);
              }, instancesByDay);
            }

            this.setState(
              {
                isKbProject,
                rawActionRecords,
                actionRecords: R.sortWith([R.descend(R.prop(sortBy || 'triggerTimestamp'))], rawActionRecords),
                statusOptions: R.sort((a, b) => a.localeCompare(b), R.keys(statusOptions)),
                deploymentOptions: R.keys(deploymentOptions),
                containerOptions: R.keys(containerOptions),
                podsOptions: R.keys(podsOptions),
                projectOptions: R.keys(projectOptions),
                instanceOptions: R.keys(instanceOptions),
                statusFilter: null,
                deploymentFilter: null,
                containerFilter: null,
                podsFilter: null,
                projectFilter: null,
                instanceFilter: null,
                isGroupingLoading: false,
                instanceDisplayNameMap,
              },
              () => {
                setTimeout(() => {
                  this.cellMeasureCache.clearAll();
                  if (this.listNode) this.listNode.forceUpdateGrid();
                }, 50);
              },
            );
          }
        }
      })
      .catch((e) => {
        this.setState({ isGroupingLoading: false });
      });
  }

  @autobind
  async parseData(props) {
    const { location } = props;
    let { systemId } = parseLocation(location);

    await this.getSummaryObj(this.props);
    const systemList = this.getSystemList(props);

    if (!systemId && systemList && systemList.length > 0) {
      systemId = systemList ? systemList[0].id : '';
    }
    const { name, systemDisplayName } = R.find((system) => system.id === systemId, systemList) || {};

    const userList = props?.userList || [];
    this.setState({ isLoadingSystem: true });

    this.userListOptions = R.map(
      (userName) => ({ value: userName, label: userName }),
      R.sort((a, b) => a.localeCompare(b), R.uniq(R.map((item) => item.userName, userList || []))),
    );

    this.setState(
      { systemList, systemSearch: systemId, isLoadingSystem: false, systemName: name || systemDisplayName },
      () => {
        this.reloadGrouping(this.props);
      },
    );
  }

  @autobind
  onChangeFilterOwner(projectOwner) {
    const { showAppLoader, location } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    setTimeout(() => {
      const params = parseLocation(location);
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { ...params, redirect: undefined, projectOwner, customerName: projectOwner, systemId: undefined },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  handleSystemClick(systemId) {
    const { systemList } = this.state;
    const { location } = this.props;
    const query = parseLocation(location);
    const systemInfo = R.find((_item) => _item.id === systemId, systemList) || {};

    const { id: systemSearch, systemDisplayName, name } = systemInfo;
    this.props.replace(buildLocation(location.pathname, {}, { ...query, systemId: systemSearch }));

    this.setState({ systemSearch, systemName: systemDisplayName || name });
  }

  @autobind
  handleRefreshClick() {
    const { location, replace } = this.props;
    const { startTimeObj, endTimeObj } = this.state;
    let query = parseLocation(location);
    let needReload = true;

    if (startTimeObj && endTimeObj) {
      const startTime = startTimeObj.format(Defaults.DateFormat);
      const endTime = endTimeObj.format(Defaults.DateFormat);
      if (startTime !== query.startTime || endTime !== query.endTime) {
        query = { ...query, startTime, endTime };
        needReload = false;
      }
    }

    if (needReload) {
      query = { ...query, forceRefreshTime: moment.utc().valueOf() };
    }

    replace(buildLocation(location.pathname, {}, { ...query }));
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        startTimeObj,
        // endTimeObj: startTimeObj.clone().endOf('day'),
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        endTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleStartOpenChange(open) {
    if (!open) {
      this.setState({ endTimeOpen: true });
    }
  }

  @autobind
  handleEndOpenChange(open) {
    this.setState({ endTimeOpen: open });
  }

  @autobind
  getSummaryObj(props) {
    const { credentials, location, globalInfo } = props;
    const { environmentId, startTime, endTime } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemIdsWithShare = R.map((item) => ({ id: item.id, customerName: item.ownerUserName }), systemList || []);
    if (startTime && endTime) {
      return fetchPost(getEndpoint('dashboard-summary'), {
        ...credentials,
        systemIdsWithShare: JSON.stringify(systemIdsWithShare),
        startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
        endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      })
        .then((data) => {
          this.setState({ summaryObj: data || {} });
        })
        .catch((e) => {
          console.log(String(e));
        });
    }
    return false;
  }

  @autobind
  utcTimeRenderer({ cellData }) {
    const time = cellData ? moment.utc(cellData).format(Defaults.TimeFormat) : '';
    return <div>{time}</div>;
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  jumpToAction(urlString) {
    const url = new URL(urlString);
    window.open(buildUrl(url.pathname + url.search + url.hash), '_blank');
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        const { state } = this;
        const actionRecords = this.applyDataFilter({ ...state, sortBy: name, sortDirection: sortDir });
        this.setState({ sortBy: name, sortDirection: sortDir, actionRecords }, () => {
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  renderListItem(props) {
    const { key, index: rowIndex, style, parent } = props;
    const { actionRecords, isKbProject, instanceDisplayNameMap } = this.state;
    const action = actionRecords[rowIndex];
    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} columnIndex={0} parent={parent} rowIndex={rowIndex}>
        <div
          className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          style={{ ...style, minHeight: 40, paddingTop: 8, paddingBottom: 8 }}
        >
          <div className="row-column" style={{ width: 140 }}>
            <div>{moment.utc(action?.triggerTimestamp).format(Defaults.DateTimeFormat)}</div>
          </div>
          <div className="row-column flex-col" style={{ flex: 2, whiteSpace: 'pre-line', alignItems: 'flex-start' }}>
            {isKbProject ? (
              <>
                {!!action?.deployment && (
                  <>
                    <div style={{ color: 'var(--link-color)' }}>Deployment:</div>
                    <div style={{ paddingLeft: 0, wordBreak: 'break-all' }}>{action.deployment}</div>
                  </>
                )}
                {!!action?.pods && (
                  <>
                    <div style={{ color: 'var(--link-color)' }}>Pods:</div>
                    <div style={{ paddingLeft: 0, wordBreak: 'break-all' }}>
                      {getInstanceDisplayName(instanceDisplayNameMap, action.pods).instanceStr}
                    </div>
                  </>
                )}
                {!!action?.container && (
                  <>
                    <div style={{ color: 'var(--link-color)' }}>Container:</div>
                    <div style={{ paddingLeft: 0, wordBreak: 'break-all' }}>{action.container}</div>
                  </>
                )}
              </>
            ) : (
              <>
                {!!action?.project && (
                  <>
                    <div style={{ color: 'var(--link-color)' }}>Project:</div>
                    <div style={{ paddingLeft: 0, wordBreak: 'break-all' }}>{action.project}</div>
                  </>
                )}
                {!!action?.instance && (
                  <>
                    <div style={{ color: 'var(--link-color)' }}>Instance:</div>
                    <div style={{ paddingLeft: 0, wordBreak: 'break-all' }}>
                      {getInstanceDisplayName(instanceDisplayNameMap, action.instance).instanceStr}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="row-column" style={{ width: 200, paddingLeft: 0 }}>
            {action?.reason || ''}
          </div>
          <div className="row-column" style={{ flex: 3, paddingLeft: 0 }}>
            {action?.actionName || ''}
          </div>
          <div className="row-column" style={{ width: 100, paddingLeft: 0 }}>
            {action?.status || ''}
          </div>
          <div className="row-column" style={{ width: 100, paddingLeft: 0 }}>
            {action?.mode || ''}
          </div>
          <div className="row-column" style={{ width: 100, paddingLeft: 0 }}>
            {action?.detail && (
              <Button size="small" onClick={(e) => this.jumpToAction(action?.detail)}>
                Detail
              </Button>
            )}
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  async onChangeFilterStatus(typeFilter) {
    const { state } = this;
    const actionRecords = this.applyDataFilter({ ...state, statusFilter: typeFilter });
    this.setState({ statusFilter: typeFilter, actionRecords });
    this.cellMeasureCache.clearAll();
    if (this.listNode) {
      await this.listNode.forceUpdateGrid();
      this.listNode.scrollToPosition(0);
    }
  }

  @autobind
  async onChangeFilterDeployment(typeFilter) {
    const { state } = this;
    const actionRecords = this.applyDataFilter({ ...state, deploymentFilter: typeFilter });
    this.setState({ deploymentFilter: typeFilter, actionRecords });
    this.cellMeasureCache.clearAll();
    if (this.listNode) {
      await this.listNode.forceUpdateGrid();
      this.listNode.scrollToPosition(0);
    }
  }

  @autobind
  async onChangeFilterContainer(typeFilter) {
    const { state } = this;
    const actionRecords = this.applyDataFilter({ ...state, containerFilter: typeFilter });
    this.setState({ containerFilter: typeFilter, actionRecords });
    this.cellMeasureCache.clearAll();
    if (this.listNode) {
      await this.listNode.forceUpdateGrid();
      this.listNode.scrollToPosition(0);
    }
  }

  @autobind
  async onChangeFilterPods(typeFilter) {
    const { state } = this;
    const actionRecords = this.applyDataFilter({ ...state, podsFilter: typeFilter });
    this.setState({ podsFilter: typeFilter, actionRecords });
    this.cellMeasureCache.clearAll();
    if (this.listNode) {
      await this.listNode.forceUpdateGrid();
      this.listNode.scrollToPosition(0);
    }
  }

  @autobind
  async onChangeFilterInstance(typeFilter) {
    const { state } = this;
    const actionRecords = this.applyDataFilter({ ...state, instanceFilter: typeFilter });
    this.setState({ instanceFilter: typeFilter, actionRecords });
    this.cellMeasureCache.clearAll();
    if (this.listNode) {
      await this.listNode.forceUpdateGrid();
      this.listNode.scrollToPosition(0);
    }
  }

  @autobind
  async onChangeFilterProject(typeFilter) {
    const { state } = this;
    const actionRecords = this.applyDataFilter({ ...state, projectFilter: typeFilter });
    this.setState({ projectFilter: typeFilter, actionRecords });
    this.cellMeasureCache.clearAll();
    if (this.listNode) {
      await this.listNode.forceUpdateGrid();
      this.listNode.scrollToPosition(0);
    }
  }

  @autobind
  applyDataFilter(state) {
    const { rawActionRecords, isKbProject } = this.state;

    let actionRecords = R.filter((r) => {
      if (state.statusFilter && r.status !== state.statusFilter) {
        return false;
      }

      if (isKbProject) {
        if (state.deploymentFilter && r.deployment !== state.deploymentFilter) {
          return false;
        }
        if (state.containerFilter && r.container !== state.containerFilter) {
          return false;
        }
        if (state.podsFilter && r.pods !== state.podsFilter) {
          return false;
        }
      } else {
        if (state.projectFilter && r.project !== state.projectFilter) {
          return false;
        }
        if (state.instanceFilter && r.instance !== state.instanceFilter) {
          return false;
        }
      }

      return true;
    }, rawActionRecords);

    const sortBy = state.sortBy || 'triggerTimestamp';
    const sortDirection = state.sortDirection || 'DESC';
    const sort = sortDirection === 'DESC' ? R.descend((x) => x?.[sortBy] || '') : R.ascend((x) => x?.[sortBy] || '');

    actionRecords = R.sortWith([sort], actionRecords);
    return actionRecords;
  }

  render() {
    const { intl, isAdmin, isLocalAdmin, userInfo, favorites, location } = this.props;
    const query = parseLocation(location);
    const {
      startTimeObj,
      endTimeObj,
      endTimeOpen,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
      projectOwner,
      isLoadingSystem,
      systemList,
      isGroupingLoading,
      systemSearch,
      summaryObj,
      instanceDisplayNameMap,
    } = this.state;
    const { sortBy, sortDirection } = this.state;

    const isLoading = isLoadingSystem || isGroupingLoading;
    const { isKbProject, actionRecords } = this.state;
    const { statusFilter, statusOptions } = this.state;
    const { deploymentFilter, deploymentOptions } = this.state;
    const { containerFilter, containerOptions } = this.state;
    const { podsFilter, podsOptions } = this.state;
    const { projectFilter, projectOptions } = this.state;
    const { instanceFilter, instanceOptions } = this.state;

    return (
      <Container fullHeight withGutter className="flex-col corner-10" style={{ borderRadius: 10 }}>
        <div className="flex-row flex-space-between" style={{ margin: '8px 16px 16px 16px', alignItems: 'end' }}>
          <div className="flex-row flex-center-align" style={{ alignItems: 'end' }}>
            <PublicBuildCizizen
              summaryObj={summaryObj}
              systemList={systemList}
              systemId={systemSearch}
              handleSystemIdChange={this.handleSystemClick}
              isServiceMap
              userInfo={userInfo}
              favorites={favorites}
            />
            <Button
              size="small"
              onClick={(e) => window.open(buildUrl(BaseUrls.GlobalActionWorkFlow, {}, query), '_blank')}
            >
              Action workflow
            </Button>
          </div>
          <div className="flex-row flex-grow flex-end-justify flex-center-align" style={{ fontSize: 12 }}>
            <div className="flex-row flex-center-align" style={{ paddingRight: 16 }}>
              <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.startDate)}
              </span>
              <DatePicker
                size="small"
                allowClear={false}
                showToday
                value={startTimeObj}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleStartTimeChange}
                onOpenChange={this.handleStartOpenChange}
              />
              <span
                style={{
                  fontWeight: 700,
                  padding: '0 1em',
                }}
              >
                {intl.formatMessage(appFieldsMessages.endDate)}
              </span>
              <DatePicker
                size="small"
                allowClear={false}
                showToday={false}
                value={endTimeObj}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={this.handleEndTimeChange}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>
            {(isAdmin || isLocalAdmin) && (
              <div className="flex-row flex-center-align" style={{ marginLeft: 8, marginRight: 8 }}>
                <div style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.user)}</div>
                <Select
                  allowClear
                  showArrow={false}
                  showSearch
                  size="small"
                  style={{ width: 100, marginLeft: 8 }}
                  optionFilterProp="children"
                  value={projectOwner}
                  onChange={this.onChangeFilterOwner}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  dropdownMatchSelectWidth={false}
                >
                  {this.userListOptions.map((item) => (
                    <Select.Option key={item.value} title={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            <Tooltip
              mouseEnterDelay={0.3}
              placement="bottomRight"
              visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
              title={
                disableRefresh
                  ? 'Range of days <= 31'
                  : timeChange
                  ? intl.formatMessage(appMessages.clickToReload)
                  : null
              }
            >
              <Button size="small" disabled={disableRefresh} onClick={this.handleRefreshClick}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </Tooltip>
          </div>
        </div>
        <Container
          fullHeight
          className="flex-grow flex-col flex-min-height content-bg corner-10"
          style={{ margin: '0 16px 8px 16px', padding: 8 }}
        >
          <Container className="flex-row flex-min-height flex-min-width">
            <div
              className="block flex-grow flex-col flex-min-height flex-min-width content-bg"
              style={{ padding: '0 8px' }}
            >
              <Spin spinning={isLoading} wrapperClassName="spin-full-height">
                <div className="flex-row flex-center-align" style={{ padding: '12px 0' }}>
                  <Select
                    allowClear
                    showArrow={false}
                    showSearch
                    size="small"
                    style={{ width: 180, marginRight: 16 }}
                    placeholder="Status"
                    value={statusFilter}
                    onChange={this.onChangeFilterStatus}
                    filterOption
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item} label={item} title={item} disabled={item.count < 1}>
                          {item}
                        </Select.Option>
                      );
                    }, statusOptions)}
                  </Select>
                  {isKbProject && (
                    <Select
                      allowClear
                      showArrow={false}
                      showSearch
                      size="small"
                      style={{ width: 180, marginRight: 16 }}
                      placeholder="Deployment"
                      value={deploymentFilter}
                      onChange={this.onChangeFilterDeployment}
                      filterOption
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <Select.Option key={item} label={item} title={item} disabled={item.count < 1}>
                            {item}
                          </Select.Option>
                        );
                      }, deploymentOptions)}
                    </Select>
                  )}
                  {isKbProject && (
                    <Select
                      allowClear
                      showArrow={false}
                      showSearch
                      size="small"
                      style={{ width: 180, marginRight: 16 }}
                      placeholder="Container"
                      value={containerFilter}
                      onChange={this.onChangeFilterContainer}
                      filterOption
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <Select.Option key={item} label={item} title={item} disabled={item.count < 1}>
                            {item}
                          </Select.Option>
                        );
                      }, containerOptions)}
                    </Select>
                  )}
                  {isKbProject && (
                    <Select
                      allowClear
                      showArrow={false}
                      showSearch
                      size="small"
                      style={{ width: 180, marginRight: 16 }}
                      placeholder="Pods"
                      value={podsFilter}
                      onChange={this.onChangeFilterPods}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                        (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.addIndex(R.map)((item, index) => {
                        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item);
                        return (
                          <Select.Option key={item} label={instanceStr} title={item} disabled={item.count < 1}>
                            {instanceStr}
                          </Select.Option>
                        );
                      }, podsOptions)}
                    </Select>
                  )}
                  {!isKbProject && (
                    <Select
                      allowClear
                      showArrow={false}
                      showSearch
                      size="small"
                      style={{ width: 180, marginRight: 16 }}
                      placeholder="Project"
                      value={projectFilter}
                      onChange={this.onChangeFilterProject}
                      filterOption
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.addIndex(R.map)((item, index) => {
                        return (
                          <Select.Option key={item} label={item} title={item} disabled={item.count < 1}>
                            {item}
                          </Select.Option>
                        );
                      }, projectOptions)}
                    </Select>
                  )}
                  {!isKbProject && (
                    <Select
                      allowClear
                      showArrow={false}
                      showSearch
                      size="small"
                      style={{ width: 180, marginRight: 16 }}
                      placeholder="Instance"
                      value={instanceFilter}
                      onChange={this.onChangeFilterInstance}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                        (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                    >
                      {R.addIndex(R.map)((item, index) => {
                        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item);
                        return (
                          <Select.Option key={item} label={instanceStr} title={item} disabled={item.count < 1}>
                            {instanceStr}
                          </Select.Option>
                        );
                      }, instanceOptions)}
                    </Select>
                  )}
                </div>
              </Spin>
            </div>
          </Container>
          <Container className="flex-grow block flex-row flex-min-height" style={{ paddingBottom: 8 }}>
            <Spin spinning={isLoading} wrapperClassName="flex-grow flex-min-height flex-row spin-full-width">
              <Container className="flex-col full-height full-width" style={{ zIndex: 0 }}>
                <AutoSizer
                  onResize={() => {
                    this.cellMeasureCache.clearAll();
                    if (this.listNode) this.listNode.forceUpdateGrid();
                  }}
                >
                  {({ width, height }) => (
                    <div className="event-list">
                      <div
                        className="event-list-header"
                        style={{
                          height: this.listHeaderHeight,
                          width,
                          paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                        }}
                      >
                        <div
                          className="header-column"
                          style={{ width: 140 }}
                          onClick={this.headerClick('triggerTimestamp')}
                        >
                          <span>Timestamp</span>
                          {this.sortIcon(sortBy, sortDirection, 'triggerTimestamp')}
                        </div>
                        <div className="header-column" style={{ flex: 2 }}>
                          <span style={{ textDecoration: 'none' }}>Impacted</span>
                        </div>
                        <div className="header-column" style={{ width: 200 }}>
                          <span style={{ textDecoration: 'none' }}>Reason</span>
                        </div>
                        <div className="header-column" style={{ flex: 3 }} onClick={this.headerClick('actionName')}>
                          <span>Action</span>
                          {this.sortIcon(sortBy, sortDirection, 'actionName')}
                        </div>
                        <div className="header-column" style={{ width: 100 }} onClick={this.headerClick('status')}>
                          <span>Status</span>
                          {this.sortIcon(sortBy, sortDirection, 'status')}
                        </div>
                        <div className="header-column" style={{ width: 100 }}>
                          <span style={{ textDecoration: 'none' }}>Mode</span>
                        </div>
                        <div className="header-column" style={{ width: 100 }}>
                          <span style={{ textDecoration: 'none' }}>Detail</span>
                        </div>
                      </div>
                      <List
                        className="event-list-grid"
                        ref={(listNode) => {
                          this.listNode = listNode;
                        }}
                        width={width}
                        height={height - 40}
                        rowCount={actionRecords.length}
                        overscanRowCount={4}
                        deferredMeasurementCache={this.cellMeasureCache}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowRenderer={this.renderListItem}
                        onScrollbarPresenceChange={({ horizontal, vertical }) => {
                          if (vertical) {
                            this.listNodeHeaderScrollbar = true;
                            this.cellMeasureCache.clearAll();
                            if (this.listNode) this.listNode.forceUpdateGrid();
                          } else {
                            this.listNodeHeaderScrollbar = false;
                          }
                          this.forceUpdate();
                        }}
                      />
                    </div>
                  )}
                </AutoSizer>
              </Container>
            </Spin>
          </Container>
        </Container>
      </Container>
    );
  }
}

const GlobalActionPage = injectIntl(GlobalActionPageCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { userInfo, credentials } = state.auth;
    const { isAdmin, isLocalAdmin, isReadUser } = state.auth.userInfo;
    const { loaderStatus, userList, loadStatus, systemsMap, projects, allProjects, globalInfo, favorites } = state.app;
    const { currentTheme, timezoneOffset } = state.app;
    const isDark = currentTheme === 'dark';
    return {
      location,
      loaderStatus,
      loadStatus,
      userInfo,
      credentials,
      userList,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      systemsMap,
      projects,
      allProjects,
      globalInfo,
      favorites,
      isDark,
      timezoneOffset,
    };
  },
  { push, replace, hideAppLoader, showAppLoader },
)(GlobalActionPage);
