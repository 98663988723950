import React from 'react';

export default function CodeTypeHeader({ title, desc }: Object) {
  return (
    <div style={{ marginBottom: 20, userSelect: 'none' }}>
      <div className="font-24 bold hidden-line-with-ellipsis">{title}</div>
      <div className="light-label font-14" style={{ marginTop: 8 }}>
        {desc}
      </div>
    </div>
  );
}
