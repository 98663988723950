import React, { useEffect, useMemo, useReducer } from 'react';
import * as R from 'ramda';
import { debounce, get } from 'lodash';
import { Checkbox, Divider, Input, message, Select, Spin, TreeSelect } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Modal } from '../../../../lib/fui/react';
import getInstanceDisplayName from '../../../../common/utils/getInstanceDisplayName';

import { appFieldsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { eventMessages } from '../../../../common/metric/messages';

const DebounceTreeSelect = ({ debounceTimeout = 800, fetchOptions, compoenntRef, ...props }: Object) => {
  return (
    <TreeSelect
      ref={compoenntRef}
      onSearch={useMemo(() => {
        const loadOptions = (value) => {
          fetchOptions(value);
        };
        return debounce(loadOptions, debounceTimeout);
      }, [fetchOptions, debounceTimeout])}
      {...props}
    />
  );
};

export default function DerivedMetricsModal(props: Object) {
  const { intl, credentials, currentProject, onClose, instanceDisplayNameMap } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,

    metricList: [],
    metricName: '',

    treeData: [],
    filterTreeData: [],
    instanceList: [],
    searchValue: '',
    instanceDropdownOpen: false,

    operation: 1,

    derivedMetricName: '',

    targetInstanceName: '',
  });
  const { metricList, metricName, treeData, loading, instanceList, searchValue, filterTreeData, instanceDropdownOpen } =
    state;
  const { operation, derivedMetricName, targetInstanceName } = state;
  const isContainer = get(currentProject, 'isContainer');

  const getInitData = () => {
    return fetchGet(getEndpoint('metricaggregationsetting'), {
      ...credentials,
      projectName: currentProject?.projectShortName,
      customerName: currentProject?.owner,
    })
      .then((data) => {
        const { success, addOps, avgOps } = data || {};
        if (success || success === undefined) {
          const addOptions = JSON.parse(addOps || '[]');
          const avgOptions = JSON.parse(avgOps || '[]');
          const operation = addOptions.length > 0 ? 1 : 2;
          let metricName;
          let instanceList;
          let targetInstanceName;
          let derivedMetricName;
          if (operation === 1) {
            const addData = addOptions[0]?.metricAggregationColumn?.instanceAggregationColumnModel || {};
            metricName = addData?.metricName;
            instanceList = addData?.instanceNameList;
            targetInstanceName = addData?.targetInstanceName;
            derivedMetricName = addData?.derivedMetricName;
          } else {
            const avgData = avgOptions[0]?.metricAggregationColumn?.instanceAggregationColumnModel || {};
            metricName = avgData?.metricName;
            instanceList = avgData?.instanceNameList;
            targetInstanceName = avgData?.targetInstanceName;
            derivedMetricName = avgData?.derivedMetricName;
          }
          return { metricName, instanceList, operation, targetInstanceName, derivedMetricName };
        } else {
          return {};
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        return {};
      });
  };

  const getMetrics = () => {
    return fetchGet(getEndpoint('metricmetadata'), {
      ...credentials,
      projectName: currentProject?.projectShortName,
      customerName: currentProject?.owner,
    })
      .then((data) => {
        const { success, possibleMetricList } = data || {};
        if (success || success === undefined) {
          return R.map((item) => ({ label: item, value: item }), possibleMetricList || []);
        } else {
          return [];
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
        return [];
      });
  };

  const getInstances = () => {
    let instanceStructureSet = get(currentProject, ['instanceStructureSet'], []);
    instanceStructureSet = R.sortWith([R.ascend(R.prop('i'))])(instanceStructureSet);
    const treeData = [];
    R.forEach((inc) => {
      const { i, c } = inc || {};
      if (i) {
        if (isContainer) {
          const cs = R.sort((a, b) => a - b, c || []);
          const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, i, {
            pn: currentProject?.projectShortName,
            owner: currentProject?.owner,
          });
          const children = R.map((c) => {
            const cins = `${c}_${i}`;
            return {
              title: `${c}_${instanceStr}`,
              value: cins,
              key: cins,
            };
          }, cs);
          treeData.push({
            title: instanceStr,
            value: i,
            key: i,
            children,
          });
        } else {
          treeData.push({ label: i, value: i });
        }
      }
    }, instanceStructureSet || []);
    return treeData;
  };

  const reloadData = async () => {
    setState({ loading: true });
    const { metricName, instanceList, operation, targetInstanceName, derivedMetricName } = await getInitData();
    const metricList = await getMetrics();
    const treeData = getInstances();

    setState({
      metricList,
      treeData,
      loading: false,
      metricName: metricName || '',
      instanceList: instanceList || [],
      operation: operation || 1,
      targetInstanceName: targetInstanceName || '',
      derivedMetricName: derivedMetricName || '',
    });
  };

  useEffect(() => {
    reloadData();
  }, [currentProject]);

  const changeDropdown = (open) => {
    const newState = { instanceDropdownOpen: open };
    if (!open) {
      newState.searchValue = '';
      newState.filterTreeData = [];
    }
    setState(newState);
  };

  const handleInstanceSearch = (searchValue) => {
    const filterTreeData = [];
    if (searchValue) {
      R.forEach((item) => {
        if (item.title.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) {
          filterTreeData.push(item);
        } else {
          let children = item.children || [];
          children = R.filter((c) => c.title.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0, children);
          if (children.length > 0) {
            filterTreeData.push({
              ...item,
              children,
            });
          }
        }
      }, treeData);
    }
    setState({ searchValue, filterTreeData });
  };

  const changeInstace = (instanceList) => {
    setState({ instanceList });
  };

  const handleSave = () => {
    if (derivedMetricName === metricName) {
      message.warning('Derived metric name conflicts with the metric name list,pick another derived metric name.');
      return;
    }
    setState({ loading: true });
    let newInstanceList = [];
    R.forEach((item) => {
      const [c, i] = R.split('_', item || '');
      if (i) newInstanceList.push(i);
      newInstanceList.push(item);
    }, instanceList || []);
    newInstanceList = R.uniq(newInstanceList);
    const list = JSON.stringify([
      { instanceNameList: newInstanceList, metricName, derivedMetricName, targetInstanceName },
    ]);
    fetchGet(getEndpoint('metricaggregationsetting'), {
      ...credentials,
      projectName: currentProject?.projectShortName,
      customerName: currentProject?.owner,
      addOps: operation === 1 ? list : JSON.stringify([]),
      avgOps: operation === 2 ? list : JSON.stringify([]),
    })
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          message.success(msg);
          setState({ loading: false });
          onClose(true);
        } else {
          message.error(msg);
          setState({ loading: false });
        }
      })
      .catch((err) => {
        setState({ loading: false });
        message.error(err.message || String(err));
      });
  };

  const getListOptionValues = (option) => {
    return R.map((item) => item.value, option);
  };

  const getFilterValue = ({ searchValue, options, type = 'label' }) => {
    return R.filter(
      (item) => (item[type] || '').toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1,
      options,
    );
  };

  const isCheckedAll = ({ searchValue, options, valueList }) => {
    const filterValue = getListOptionValues(getFilterValue({ searchValue, options }));
    const diff = R.difference(filterValue, valueList);
    return diff.length === 0;
  };

  const hasError = !metricName || !instanceList.length || !derivedMetricName || !targetInstanceName;
  const displayTreeData = searchValue ? filterTreeData : treeData;
  return (
    <Modal
      title={intl.formatMessage(appFieldsMessages.derivedMetrics)}
      width={650}
      visible
      onCancel={() => onClose()}
      onOk={handleSave}
      okButtonProps={{ disabled: hasError, loading }}
    >
      <Spin spinning={loading} wrapperClassName="full-height spin-full-height">
        <div className="flex-row flex-center-align">
          <div style={{ width: 120, flexShrink: 0 }}>
            {intl.formatMessage(settingsMessages.metricName)}:<span style={{ color: 'red', marginLeft: 4 }}>*</span>
          </div>
          <Select
            showSearch
            options={metricList}
            value={metricName}
            allowClear
            showArrow={false}
            size="small"
            className="flex-grow"
            onChange={(metricName) => setState({ metricName })}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 16 }}>
          <div style={{ width: 120, flexShrink: 0 }}>
            {intl.formatMessage(appFieldsMessages.instanceNameList)}:
            <span style={{ color: 'red', marginLeft: 4 }}>*</span>
          </div>
          {isContainer ? (
            <DebounceTreeSelect
              size="small"
              showSearch
              allowClear
              treeCheckable
              showArrow={false}
              treeDefaultExpandAll
              filterTreeNode={false}
              treeNodeFilterProp="title"
              treeData={displayTreeData}
              open={instanceDropdownOpen}
              autoClearSearchValue={false}
              value={instanceList || []}
              className="flex-grow flex-min-width no-count-num"
              fetchOptions={handleInstanceSearch}
              onDropdownVisibleChange={changeDropdown}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              onChange={changeInstace}
              dropdownRender={(menu) => {
                let allInstances = [];
                let selectAll = [];
                allInstances = R.unnest(
                  R.map((item) => {
                    return isContainer ? R.map((_item) => _item.value, item.children || []) : item;
                  }, displayTreeData || []),
                );
                selectAll = R.difference(allInstances, instanceList).length === 0;
                return (
                  <div>
                    <div
                      className="flex-row"
                      style={{ padding: '5px 24px' }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selectAll}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const filterList = R.difference(instanceList, allInstances);
                          if (checked) {
                            setState({ instanceList: [...filterList, ...allInstances] });
                          } else {
                            setState({ instanceList: filterList });
                          }
                        }}
                      />
                      <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                    </div>
                    <Divider style={{ margin: '4px 0' }} />
                    {menu}
                  </div>
                );
              }}
            />
          ) : (
            <Select
              showSearch
              mode="multiple"
              value={instanceList || []}
              optionFilterProp="children"
              autoClearSearchValue={false}
              className="flex-grow flex-min-width no-count-num"
              onChange={(instanceList) => setState({ instanceList })}
              onDropdownVisibleChange={(open) => {
                if (!open) {
                  setState({ searchValue: '' });
                }
              }}
              onSearch={(searchValue) => setState({ searchValue })}
              dropdownRender={(menu) => {
                return (
                  <div>
                    <div
                      className="flex-row"
                      style={{ padding: '5px 12px' }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={isCheckedAll({ searchValue, options: treeData, valueList: instanceList })}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const filterValue = getListOptionValues(getFilterValue({ searchValue, options: treeData }));
                          const diff = R.difference(instanceList, filterValue);
                          setState({ instanceList: checked ? [...diff, ...filterValue] : diff });
                        }}
                      />
                      <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                    </div>
                    <Divider style={{ margin: '4px 0' }} />
                    {menu}
                  </div>
                );
              }}
            >
              {R.map((item) => {
                return (
                  <Select.Option className="hide-icon" key={item.value} value={item.value}>
                    <Checkbox
                      checked={(instanceList || []).includes(item.value)}
                      onChange={(e) => null}
                      style={{ marginRight: 8 }}
                    />
                    {item.label}
                  </Select.Option>
                );
              }, treeData || [])}
            </Select>
          )}
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 16 }}>
          <div style={{ width: 120, flexShrink: 0 }}>
            {intl.formatMessage(appFieldsMessages.operation)}:<span style={{ color: 'red', marginLeft: 4 }}>*</span>
          </div>
          <Select
            showSearch
            options={[
              { label: 'Sum', value: 1 },
              { label: 'Average', value: 2 },
            ]}
            value={operation}
            allowClear={false}
            showArrow={false}
            size="small"
            className="flex-grow"
            onChange={(operation) => setState({ operation })}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 16 }}>
          <div style={{ width: 120, flexShrink: 0 }}>
            {intl.formatMessage(appFieldsMessages.derivedMetricName)}:
            <span style={{ color: 'red', marginLeft: 4 }}>*</span>
          </div>
          <Input
            value={derivedMetricName}
            allowClear
            size="small"
            className={`${
              derivedMetricName === metricName && metricName && derivedMetricName ? 'inputIsNil' : ''
            } flex-grow`}
            onChange={(e) => setState({ derivedMetricName: e.target.value })}
          />
        </div>
        <div className="flex-row flex-center-align" style={{ marginTop: 16 }}>
          <div style={{ width: 120, flexShrink: 0 }}>
            {intl.formatMessage(eventMessages.targetInstanceName)}:
            <span style={{ color: 'red', marginLeft: 4 }}>*</span>
          </div>
          <Input
            value={targetInstanceName}
            allowClear
            size="small"
            className="flex-grow"
            onChange={(e) => setState({ targetInstanceName: e.target.value })}
          />
        </div>
      </Spin>
    </Modal>
  );
}
