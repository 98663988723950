import './style/index.less';
import React, { createContext, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { parseInt } from 'lodash';
import { Spin, Steps } from 'antd';
import { ApartmentOutlined, ThunderboltOutlined } from '@ant-design/icons';

import { State } from '../../common/types';
import { Container } from '../../lib/fui/react';
import { connectionID, updateEdgeData } from './data/utils';

import FlowContent from './components/FlowContent';
import CodeTypeList from './components/CodeTypeList';

const workFlowContext = createContext(null);
const workFlowDispatchContext = createContext(null);

let startX;
let startWidth;

function ActionWorkFlowCore(props: Object) {
  const { isDark } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    isLoading: false,
    stepCurrent: -1,
    // flow modal
    workFlowInfo: { title: 'Untitled workflow' },
    workNodes: [],
    workEdges: [],
    activeWorkNode: {},
    // code modal
    activeTypeItem: null,
    searchVal: '',
  });
  const workFlowBoxRef = useRef(null);
  const separatorRef = useRef(null);
  const { isLoading, stepCurrent, workNodes, workEdges } = state;

  const onDrag = (e) => {
    const newWidth = e.clientX - startX + startWidth;
    workFlowBoxRef.current.style.width = `${newWidth}px`;
  };

  const stopGrag = () => {
    const { removeEventListener } = document.documentElement;
    removeEventListener('mousemove', onDrag);
    removeEventListener('mouseup', stopGrag);
  };

  const startDrag = (e) => {
    startX = e.clientX;
    const { width } = window.getComputedStyle(workFlowBoxRef.current);
    startWidth = parseInt(width);
    const { addEventListener } = document.documentElement;
    addEventListener('mousemove', onDrag);
    addEventListener('mouseup', stopGrag);
  };

  const stepsChange = (step) => {
    if (stepCurrent === -1) return;
    if (stepCurrent === 0 && workNodes.length === 1) return;
    if (step === 0 && workNodes.length >= 1) {
      let newActiveWorkNode;
      const newWorkNodes = R.map((node) => {
        if (node.data.isRoot) {
          newActiveWorkNode = { ...node, data: { ...node.data, activeNodeFlag: true } };
          return newActiveWorkNode;
        } else {
          return { ...node, data: { ...node.data, activeNodeFlag: false } };
        }
      }, workNodes || []);
      const newWorkEdges = R.map((edge) => updateEdgeData(isDark, edge, false), workEdges);
      setState({ workNodes: newWorkNodes, workEdges: newWorkEdges, activeWorkNode: newActiveWorkNode });
    }
    if (step === 1 && workNodes.length > 1) {
      let newActiveWorkNode;
      const newWorkNodes = R.addIndex(R.map)((node, idx) => {
        if (idx === 1) {
          newActiveWorkNode = { ...node, data: { ...node.data, activeNodeFlag: true } };
          return newActiveWorkNode;
        } else {
          return { ...node, data: { ...node.data, activeNodeFlag: false } };
        }
      }, workNodes || []);
      const newWorkEdges = R.map((edge) => {
        if (edge.id === `${workNodes[0]?.id}${connectionID}${newActiveWorkNode.id}`) {
          return updateEdgeData(isDark, edge, true);
        }
        return updateEdgeData(isDark, edge, false);
      }, workEdges);
      setState({ workNodes: newWorkNodes, workEdges: newWorkEdges, activeWorkNode: newActiveWorkNode });
    }
    setState({ stepCurrent: step });
  };

  useEffect(() => {
    const separatorRefDom = separatorRef.current;
    const { removeEventListener } = document.documentElement;
    separatorRefDom.addEventListener('mousedown', startDrag);
    return () => {
      removeEventListener('mousemove', (e) => onDrag(e));
      removeEventListener('mouseup', stopGrag);
      separatorRefDom.removeEventListener('mousedown', startDrag);
    };
  }, []);

  const nodeDataSave = useCallback((workNodes, activeWorkNode) => {
    const { nodeData } = activeWorkNode?.data || {};
    const newWorkNodes = R.map((node) => {
      if (node.id === activeWorkNode.id) {
        return { ...node, data: { ...node.data, nodeData, localNodeData: nodeData } };
      }
      return node;
    }, workNodes || []);
    const newActiveWorkNode = { ...activeWorkNode, data: { ...activeWorkNode.data, localNodeData: nodeData } };
    setState({ workNodes: newWorkNodes, activeWorkNode: newActiveWorkNode });
  }, []);

  const nodeEditTitle = useCallback((title, workNodes, activeWorkNode) => {
    const { nodeData } = activeWorkNode?.data || {};
    const newNodeData = { ...nodeData, title };
    const newWorkNodes = R.map((node) => {
      if (node.id === activeWorkNode.id) {
        return { ...node, data: { ...node.data, nodeData: newNodeData, localNodeData: newNodeData } };
      }
      return node;
    }, workNodes || []);
    const newActiveWorkNode = {
      ...activeWorkNode,
      data: { ...activeWorkNode.data, nodeData: newNodeData, localNodeData: newNodeData },
    };
    setState({ workNodes: newWorkNodes, activeWorkNode: newActiveWorkNode });
  }, []);

  const integratedFunction = useMemo(() => {
    return {
      contextSetState: setState,
      nodeDataSave,
      nodeEditTitle,
    };
  }, [setState]);

  return (
    <Container fullHeight withGutter className="flex-col" style={{ borderRadius: 10 }}>
      <Container
        className="flex-grow block flex-row flex-min-height corner-10 content-bg"
        style={{ margin: '16px 16px 8px 16px' }}
      >
        <Spin spinning={isLoading} wrapperClassName="flex-grow flex-min-height flex-row spin-full-width">
          <workFlowContext.Provider value={state}>
            <workFlowDispatchContext.Provider value={integratedFunction}>
              <div className="flex-row full-width full-height" style={{ padding: 10 }}>
                <div
                  ref={workFlowBoxRef}
                  className="corner-10"
                  style={{
                    border: '1px solid var(--virtualized-table-border-color)',
                    padding: 16,
                    width: 900,
                    minWidth: 400,
                    maxWidth: 1100,
                  }}
                >
                  <FlowContent />
                </div>
                <div
                  ref={separatorRef}
                  className="full-height flex-row flex-center-align flex-center-justify"
                  style={{ width: 8, cursor: 'col-resize' }}
                >
                  <div style={{ width: 2, height: 36, background: '#a5a6c4' }} />
                </div>

                <div
                  className="flex-grow corner-10 flex-min-width flex-col"
                  style={{ border: '1px solid var(--virtualized-table-border-color)', padding: 16, width: 0 }}
                >
                  <div
                    style={{
                      paddingLeft: 8,
                      paddingBottom: 4,
                      borderBottom: '2px solid var(--virtualized-table-border-color)',
                    }}
                  >
                    <Steps current={stepCurrent} style={{ width: 400 }} onChange={stepsChange}>
                      <Steps.Step title="Step 1: Trigger" icon={<ThunderboltOutlined />} />
                      <Steps.Step title="Step 2: Actions" icon={<ApartmentOutlined />} />
                    </Steps>
                  </div>
                  <CodeTypeList />
                </div>
              </div>
            </workFlowDispatchContext.Provider>
          </workFlowContext.Provider>
        </Spin>
      </Container>
    </Container>
  );
}

export { workFlowContext, workFlowDispatchContext };
const ActionWorkFlow = injectIntl(ActionWorkFlowCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(ActionWorkFlow);
