import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { toInteger } from 'lodash';
import { Button, Checkbox, InputNumber, Select, Spin } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Container,
  Modal,
  SortDirection,
  Table,
} from '../../../lib/fui/react';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';

import { causalMessages } from '../../../common/causal/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';
import { settingsMessages } from '../../../common/settings/messages';

const cellMeasureCache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

const reducer = (oldVal, newVal) => ({ ...oldVal, ...newVal });

const initState = {
  localMetricList: [],
  metricList: [],
  isLoading: false,
  sortBy: null,
  sortDirection: null,
  allChecked: false,
  showGroupSetting: false,
};

export default function EditPairingDurationModal({
  intl,
  credentials,
  onClose,
  activeEvent,
  defaultCausalMetricPairingThreshold,
}: Object) {
  const dataTableNode = useRef(null);
  const [state, setState] = useReducer(reducer, initState);
  const { metricList, isLoading, sortBy, sortDirection, allChecked, showGroupSetting } = state;

  const onToggleCollapse = useCallback(() => {
    cellMeasureCache.clearAll();
    if (dataTableNode.current) {
      dataTableNode.current.forceUpdate();
    }
  }, []);

  const getMetricMetaData = (cancel) => {
    if (cancel || !activeEvent) return;
    setState({ isLoading: true });
    const { projectShortName, owner, metricLevelValidPairingSet } = activeEvent || {};
    fetchGet(getEndpoint('metricmetadata'), {
      ...credentials,
      projectName: projectShortName,
      customerName: owner,
      withoutInstance: true,
    })
      .then((data) => {
        const { success, message: msg, possibleMetricList } = data || {};
        if (success || success === undefined) {
          let metrics = R.map((item) => {
            const findMetric = R.find((_item) => _item.metricName === item, metricLevelValidPairingSet || []);
            const duration = findMetric?.duration || defaultCausalMetricPairingThreshold;
            let handleDuration = duration;
            const hours = toInteger(handleDuration / 3600000);
            handleDuration %= 3600000;
            const minutes = String(toInteger(handleDuration / 60000));
            return { metricName: item, duration, hours, minutes };
          }, possibleMetricList || []);
          metrics = R.sortWith([R.ascend(R.prop('metricName'))])(metrics);
          setState({ metricList: metrics, localMetricList: metrics });
          onToggleCollapse();
        } else {
          console.error(msg);
        }
        setState({ isLoading: false });
      })
      .catch((err) => {
        console.error(err.message || String(err));
        setState({ isLoading: false });
      });
  };

  const handleSumbit = () => {
    const { index } = activeEvent;
    const saveMetricList = R.map(
      (m) => {
        const { hours, minutes, ...rest } = m;
        return rest;
      },
      R.filter((item) => item.duration !== defaultCausalMetricPairingThreshold, metricList || []),
    );
    onClose({ [index]: saveMetricList });
  };

  useEffect(() => {
    let cancel = false;
    getMetricMetaData(cancel);
    return () => {
      cancel = true;
    };
  }, [activeEvent, defaultCausalMetricPairingThreshold]);

  useEffect(() => {
    if (sortBy) {
      if (sortDirection === SortDirection.DESC) {
        setState({ metricList: R.sortWith([R.descend(R.prop(sortBy))])(metricList) });
      } else {
        setState({ metricList: R.sortWith([R.ascend(R.prop(sortBy))])(metricList) });
      }
      onToggleCollapse();
    }
  }, [sortBy, sortDirection]);

  const sort = ({ sortBy, sortDirection }) => {
    setState({ sortBy, sortDirection });
  };

  const headerRenderer = ({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) => {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  };

  const metricNameRenderer = ({ dataKey, parent, rowIndex, rowData, columnIndex }) => {
    const { metricName } = rowData;
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div className="hidden-line-with-ellipsis" style={{ wordWrap: 'break-word' }}>
          {metricName}
        </div>
      </CellMeasurer>
    );
  };

  const checkRender = ({ dataKey, parent, rowIndex, rowData, columnIndex }) => {
    const { checked } = rowData;
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Checkbox
          checked={checked}
          onChange={({ target: { checked } }) => {
            rowData.checked = checked;
            const findChecked = R.find((item) => !item.checked, metricList || []);
            setState({ allChecked: !findChecked });
          }}
        />
      </CellMeasurer>
    );
  };

  const durationRenderer = ({ dataKey, parent, rowIndex, rowData, columnIndex }) => {
    const { hours, minutes } = rowData;
    return (
      <CellMeasurer
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div className="flex-row flex-center-align">
          <InputNumber
            size="small"
            style={{ width: 120, marginLeft: 4 }}
            addonAfter="Hours"
            min={0}
            precision={0}
            value={hours}
            onChange={(hours) => {
              const durationThreshold = (hours || 0) * 3600000 + (minutes ? Number(minutes) : 0) * 60000;
              rowData.duration = durationThreshold;
              rowData.hours = hours || 0;
              onToggleCollapse();
            }}
          />
          <Select
            size="small"
            style={{ width: 100, marginLeft: 4 }}
            placeholder="Minute"
            value={minutes || '0'}
            onChange={(minutes) => {
              const durationThreshold = (hours || 0) * 3600000 + (minutes ? Number(minutes) : 0) * 60000;
              rowData.duration = durationThreshold;
              rowData.minutes = minutes;
              onToggleCollapse();
            }}
          >
            <Select.Option key="0">0 Mins</Select.Option>
            <Select.Option key="5">5 Mins</Select.Option>
            <Select.Option key="15">15 Mins</Select.Option>
            <Select.Option key="30">30 Mins</Select.Option>
            <Select.Option key="45">45 Mins</Select.Option>
          </Select>
        </div>
      </CellMeasurer>
    );
  };

  const handleGroupSettings = (duration, hours, minutes) => {
    const newMetricList = R.map((item) => {
      if (item.checked) {
        return { ...item, duration, hours, minutes };
      } else {
        return item;
      }
    }, metricList || []);
    setState({ metricList: newMetricList, showGroupSetting: false });
  };

  const findCheckedDisabled = R.find((item) => item.checked, metricList || []);

  return (
    <>
      <Modal
        visible
        width={900}
        onCancel={onClose}
        bodyStyle={{ height: 400 }}
        title={intl.formatMessage(causalMessages.editPairingDuration)}
        footer={
          <div>
            <Button
              size="small"
              type="primary"
              disabled={!findCheckedDisabled}
              onClick={() => setState({ showGroupSetting: true })}
            >
              {intl.formatMessage(settingsMessages.groupSettingsUpdate)}
            </Button>
            <Button size="small" onClick={onClose}>
              {intl.formatMessage(appButtonsMessages.cancel)}
            </Button>
            <Button size="small" type="primary" onClick={handleSumbit}>
              {intl.formatMessage(appButtonsMessages.save)}
            </Button>
          </div>
        }
      >
        <Container className="full-height">
          <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="with-border"
                  width={width}
                  height={height}
                  deferredMeasurementCache={cellMeasureCache}
                  headerHeight={40}
                  rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                  rowHeight={cellMeasureCache.rowHeight}
                  rowCount={metricList.length}
                  rowGetter={({ index }) => metricList[index]}
                  sort={sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  headerClassName="flex-row flex-center-align"
                  ref={(c) => {
                    dataTableNode.current = c;
                  }}
                >
                  <Column
                    width={40}
                    disableSort
                    dataKey="checked"
                    cellRenderer={checkRender}
                    label={
                      <Checkbox
                        checked={allChecked}
                        onChange={({ target: { checked } }) => {
                          R.forEach((item) => {
                            item.checked = checked;
                          }, metricList || []);
                          setState({ allChecked: checked });
                        }}
                      />
                    }
                  />
                  <Column
                    width={130}
                    flexGrow={1}
                    dataKey="metricName"
                    headerRenderer={headerRenderer}
                    cellRenderer={metricNameRenderer}
                    label={intl.formatMessage(settingsMessages.metricName)}
                  />
                  <Column
                    width={350}
                    disableSort
                    dataKey="duration"
                    headerRenderer={headerRenderer}
                    cellRenderer={durationRenderer}
                    label={intl.formatMessage(appFieldsMessages.duration)}
                  />
                </Table>
              )}
            </AutoSizer>
          </Spin>
        </Container>
      </Modal>
      {showGroupSetting && (
        <GroupSettings
          intl={intl}
          defaultCausalMetricPairingThreshold={defaultCausalMetricPairingThreshold}
          onClose={() => setState({ showGroupSetting: false })}
          onSubmit={handleGroupSettings}
        />
      )}
    </>
  );
}

const GroupSettings = ({ intl, onClose, defaultCausalMetricPairingThreshold, onSubmit }: Object) => {
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    duration: defaultCausalMetricPairingThreshold,
    hours: toInteger(defaultCausalMetricPairingThreshold / 3600000),
    minutes: String(toInteger(toInteger(defaultCausalMetricPairingThreshold / 3600000) / 60000)),
  });
  const { duration, hours, minutes } = state;

  return (
    <Modal
      visible
      width={700}
      onCancel={onClose}
      onOk={() => onSubmit(duration, hours, minutes)}
      okText={intl.formatMessage(appButtonsMessages.update)}
      title={intl.formatMessage(settingsMessages.groupSettingsUpdate)}
    >
      <div className="flex-row flex-center-align">
        <div style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.duration)}:</div>
        <div className="flex-row flex-center-align">
          <InputNumber
            size="small"
            style={{ width: 140, marginLeft: 4 }}
            addonAfter="Hours"
            min={0}
            precision={0}
            value={hours}
            onChange={(hours) => {
              const durationThreshold = (hours || 0) * 3600000 + (minutes ? Number(minutes) : 0) * 60000;
              setState({ duration: durationThreshold, hours: hours || 0 });
            }}
          />
          <Select
            size="small"
            style={{ width: 140, marginLeft: 4 }}
            placeholder="Minute"
            value={minutes || '0'}
            onChange={(minutes) => {
              const durationThreshold = (hours ? Number(hours) : 0) * 3600000 + (minutes ? Number(minutes) : 0) * 60000;
              setState({ duration: durationThreshold, minutes });
            }}
          >
            <Select.Option key="0">0 Mins</Select.Option>
            <Select.Option key="5">5 Mins</Select.Option>
            <Select.Option key="15">15 Mins</Select.Option>
            <Select.Option key="30">30 Mins</Select.Option>
            <Select.Option key="45">45 Mins</Select.Option>
          </Select>
        </div>
      </div>
    </Modal>
  );
};
