/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';

import * as R from 'ramda';
import moment from 'moment';
// import update from 'immutability-helper';
// import numeral from 'numeral';
import { get } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { Tooltip, Select, Button, notification, DatePicker, Tabs, Spin, Input, message, Switch, Progress } from 'antd';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';

import fetchGet from '../../../common/apis/fetchGet';
import {
  Defaults,
  parseLocation,
  buildUrl,
  buildLocation,
  timeScopeControl,
  Regex,
  downloadPdf,
  sleep,
} from '../../../common/utils';
import { State } from '../../../common/types';
import { Container, Modal, Popover } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo, ActionTypes as AppActionTypes } from '../../../common/app/actions';
import { BaseUrls } from '../../app/Constants';

import { appFieldsMessages, appButtonsMessages, appMessages, appMenusMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';

import RootCauseList from './RootCauseList';
import PublicBuildCizizen from './PublicBuildCizizen';
import LikelyRootCausesRCA from './LikelyRootCausesRCA';
import { IgnoredIncidentsIcon, PDFIcon } from '../../../lib/fui/icons';
import { SyncOutlined } from '@ant-design/icons';

type Props = {
  refresh: Number,
  handleRefresh: Function,
  handleCustomerNameChange: Function,
  // eslint-disable-next-line
  handleEnvironmentChange: Function,
  handleSystemIdChange: Function,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  push: Function,
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  userList: Array<Object>,
  userInfo: Object,
  isAdmin: Boolean,
  // eslint-disable-next-line
  projectDisplayMap: Object,

  globalInfo: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  credentials: Object,
  updateLastActionInfo: Function,
  systemsMap: Object,
};

const totalFun = (field) => R.compose(R.sum, R.pluck(field), R.filter(R.propSatisfies(R.complement(R.isNil), field)));
const result = (arr1, arr2) => R.chain((x) => R.map((y) => `${x}_${y}`, arr2), arr1);

class GlobalSystemRootCauseListCore extends React.PureComponent {
  props: Props;

  childRef = React.createRef();

  constructor(props) {
    super(props);

    const { intl, location, systemsMap } = props;
    const query = parseLocation(location);
    const { startTime, endTime, systemId } = query;
    const {
      eventProjectName,
      eventPatternId,
      eventTimestamp,
      eventCategory,
      jumpComponentName,
      jumpInstanceName,
      jumpStartHour,
    } = query;
    const { jumpContainer } = query;
    const hasKubernetes = !!R.find(
      (item) => R.includes('Kubernetes', item?.cloudType || ''),
      systemsMap[systemId]?.projectDetailsList || [],
    );

    let activeKey = 'incident';
    let activeKeyList = ['incident'];
    if (eventProjectName && eventPatternId && eventTimestamp && eventCategory) {
      activeKeyList = [];
      if (R.toLower(eventCategory) === 'incident') {
        activeKey = 'incident';
      } else if (R.toLower(eventCategory) === 'metric') {
        activeKey = 'metric';
      } else if (R.toLower(eventCategory) === 'deployment') {
        activeKey = 'deployment';
      } else if (R.toLower(eventCategory) === 'trace') {
        activeKey = 'trace';
      } else if (R.toLower(eventCategory) === 'logalert') {
        activeKey = 'logAlert';
      } else {
        activeKey = 'logAnomaly';
      }
    }

    if (eventCategory && (jumpComponentName || jumpInstanceName)) {
      activeKeyList = [];
      if (R.toLower(eventCategory) === 'incident') {
        activeKey = 'incident';
      } else if (R.toLower(eventCategory) === 'metric') {
        activeKey = 'metric';
      } else if (R.toLower(eventCategory) === 'deployment') {
        activeKey = 'deployment';
      } else if (R.toLower(eventCategory) === 'trace') {
        activeKey = 'trace';
      } else if (R.toLower(eventCategory) === 'logalert') {
        activeKey = 'logAlert';
      } else {
        activeKey = 'logAnomaly';
      }
    }
    const isInstanceContainer = R.includes('_', jumpInstanceName || '');
    this.state = {
      startTimeObj: moment.utc(startTime, Defaults.DateFormat),
      endTimeObj: moment.utc(endTime, Defaults.DateFormat),
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      isLoadingSystem: false,

      activeKey,
      tabCountMap: {},

      isAuth: false,
      meetingUrl: '',
      isZoomLoading: false,
      emailsList: [],
      summaryObj: {},
      activeKeyList,
      activeKeyListFlag: true,

      componentFilter: jumpComponentName || undefined,
      instanceFilter: undefined,

      showEventsRCA: false,
      needRC: false,
      needPT: false,
      activeEvent: null,
      rcaFlag: true,
      destroyTabPane: false,

      hasKubernetes,
      logicPodID: undefined,
      filterContainer: jumpContainer || (isInstanceContainer ? R.split('_', jumpInstanceName)[0] : undefined),
      filterStartHour: jumpStartHour || undefined,

      showEnableRCA: false,
    };
    this.tabList = [
      { name: 'incident', title: intl.formatMessage(eventMessages.incidents) },
      { name: 'logAlert', title: intl.formatMessage(eventMessages.alertAnomaly) },
      { name: 'metric', title: intl.formatMessage(DashboardMessages.metricAnomalies) },
      { name: 'logAnomaly', title: intl.formatMessage(DashboardMessages.logAnomalies) },
      { name: 'trace', title: intl.formatMessage(DashboardMessages.traceAnomalies) },
      { name: 'deployment', title: intl.formatMessage(appFieldsMessages.changes) },
    ];
    this.componentListOptions = [];
    this.instanceListOptions = [];
    this.podListOptions = [];
    this.containersListOptions = [];
    this.countTabCountMap = {};
    this.flagClearJumpInstanceName = isInstanceContainer
      ? jumpInstanceName
      : jumpContainer
      ? `${jumpContainer}_${jumpInstanceName}`
      : jumpInstanceName;
  }

  componentDidMount() {
    const { location, globalInfo } = this.props;
    const query = parseLocation(location);
    const { systemId, environmentId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const tabCounterMap = get(environment, ['allTabCounterMap', systemId], {});

    this.getSummaryObj(this.props);
    // this.getUserZoomAuth(this.props);
    if (systemInfo) {
      this.loadSystemData(this.props);
    }
    this.setTabCounterMap(tabCounterMap);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.systemId !== query.systemId
    ) {
      const { globalInfo, systemsMap = {} } = nextProps;
      const { systemId, environmentId } = nextQuery;
      this.countTabCountMap = {};
      this.flagClearJumpInstanceName = undefined;
      this.setState(
        {
          tabCountMap: {},
          componentFilter: undefined,
          instanceFilter: undefined,
          logicPodID: undefined,
          filterContainer: undefined,
          filterStartHour: undefined,
          activeKeyListFlag: false,
          showEnableRCA: false,
          hasKubernetes: !!R.find(
            (item) => R.includes('Kubernetes', item?.cloudType || ''),
            systemsMap[systemId]?.projectDetailsList || [],
          ),
        },
        () => {
          const environment = R.find((e) => e.id === environmentId, globalInfo || []);
          const systemList = get(environment, 'systemList', []);
          const tabCounterMap = get(environment, ['allTabCounterMap', systemId], {});
          const systemInfo = R.find((system) => system.id === systemId, systemList);
          this.getSummaryObj(nextProps);
          // this.getUserZoomAuth(nextProps);
          if (systemInfo && !systemInfo.hasAllInstanceInfo) {
            this.loadSystemData(nextProps);
          } else {
            this.setTabCounterMap(tabCounterMap);
          }
        },
      );
    } else if (nextQuery.forceRefreshTime !== query.forceRefreshTime) {
      this.getSummaryObj(nextProps);
      // this.getUserZoomAuth(nextProps);
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  getUserZoomAuth(props) {
    const { credentials, globalInfo, location } = props;
    const query = parseLocation(location);
    const { systemId, environmentId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList) || {};

    const { ownerUserName, environmentName } = systemInfo;
    const systemKey = { userName: ownerUserName, systemName: systemId, environmentName };
    this.setState({ isZoomLoading: true });
    fetchGet(getEndpoint('zoom-meeting'), {
      ...credentials,
      systemKey: JSON.stringify(systemKey),
    })
      .then((data) => {
        const { isAuth, success, message: msg, meetingUrl, emails } = data;
        if (success || success === undefined) {
          this.setState({ isAuth, meetingUrl, isZoomLoading: false, emailsList: emails });
        } else {
          this.setState({ isZoomLoading: false });
          console.log(msg);
        }
      })
      .catch((e) => {
        this.setState({ isZoomLoading: false });
        console.log(e);
      });
  }

  @autobind
  async clickZoomButton() {
    const { intl } = this.props;
    const { meetingUrl, emailsList } = this.state;

    if (meetingUrl) {
      window.open(meetingUrl);
    } else {
      this.emailAlertModal = Modal.confirm({
        title: intl.formatMessage(appButtonsMessages.confirm),
        content: (
          <div className="flex-row flex-center-align flex-center-justify">
            <Spin spinning />
          </div>
        ),
        okButtonProps: { disabled: true },
        onOk: () => {
          if (this.emailAlertModal) this.emailAlertModal.destroy();
        },
      });

      this.handleMailAlertModal(emailsList);
    }
  }

  @autobind
  handleMailAlertModal(emailAddress) {
    const { intl } = this.props;
    this.emailAddressList = R.filter((item) => item, emailAddress || []);

    // handle content change
    const onChangeMailList = (e) => {
      const value = e.target.value || '';
      const hasErrorEmailAddress = !R.reduce(
        (a, b) => a && b,
        true,
        R.map((email) => Regex.email.test(R.trim(email)), value.split(',')),
      );
      this.emailAddressList = R.map((email) => R.trim(email), R.split(',', value));
      if (this.emailAlertModal) {
        this.emailAlertModal.update({ okButtonProps: { disabled: hasErrorEmailAddress } });
      }
    };

    this.emailAlertModal.update({
      content: (
        <div>
          <div>{`${intl.formatMessage(eventMessages.sendEmailToAddress)}:`}</div>
          <Input.TextArea rows={3} defaultValue={emailAddress} onChange={onChangeMailList} />
        </div>
      ),
      okButtonProps: { disabled: false },
      onOk: this.handleMailAlertSumbit(),
    });

    if (this.emailAddressList.length < 1) {
      if (this.emailAlertModal) {
        this.emailAlertModal.update({ okButtonProps: { disabled: true } });
      }
    }
  }

  @autobind
  handleMailAlertSumbit() {
    return () => {
      const { location, credentials, globalInfo } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList) || {};

      if (this.emailAlertModal) {
        this.emailAlertModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }
      this.props.updateLastActionInfo();
      const { ownerUserName, environmentName } = systemInfo;
      const systemKey = { userName: ownerUserName, systemName: systemId, environmentName };
      this.setState({ isZoomLoading: true });
      fetchPost(getEndpoint('zoom-meeting'), {
        ...credentials,
        emails: JSON.stringify(this.emailAddressList),
        systemKey: JSON.stringify(systemKey),
      })
        .then((data) => {
          const { success, message: msg } = data;
          if (success || success === undefined) {
            message.success(msg);
            this.getUserZoomAuth(this.props);
          } else {
            this.setState({ isZoomLoading: false });
            console.log(msg);
          }
        })
        .catch((e) => {
          this.setState({ isZoomLoading: false });
          console.log(e);
        });
    };
  }

  @autobind
  loadSystemData(props) {
    const { location, isAdmin, userInfo, createLoadAction, isLocalAdmin } = props;
    const query = parseLocation(location);
    const { startTime, endTime, customerName, systemId, environmentId } = query;
    if (((isAdmin && customerName) || !isAdmin) && startTime && endTime && systemId && environmentId) {
      this.setState({ isLoadingSystem: true });
      createLoadAction(
        AppActionTypes.LOAD_INFO_SYSTEM,
        {
          startTime,
          endTime,
          customerName: isAdmin || isLocalAdmin ? customerName : userInfo.userName,
          systemName: systemId,
          environmentId,
          anomalyInstanceOnly: true,
        },
        false,
        true,
        () => this.callbackHandleUpdateReloadSystemTime(query),
      );
    }
  }

  @autobind
  callbackHandleUpdateReloadSystemTime(query) {
    const { location, replace, globalInfo } = this.props;
    const { hasKubernetes } = this.state;
    const { jumpInstanceName, jumpPodId, jumpContainer } = query;
    const environment = R.find((e) => e.id === query.environmentId, globalInfo || []);
    replace(buildLocation(location.pathname, {}, { ...query, reloadSystem: moment.utc().valueOf() }));
    const tabCounterMap = get(environment, ['allTabCounterMap', query.systemId], {});

    let logicPodIDs = [];
    R.forEachObjIndexed((item, key) => {
      const { pods } = item;
      logicPodIDs = [...logicPodIDs, ...R.map((_item) => _item.p, pods || [])];
    }, tabCounterMap || {});
    logicPodIDs = R.uniq(logicPodIDs);

    let instanceFilter;
    let logicPodID;
    const isInstanceContainer = R.includes('_', jumpInstanceName || '');
    const newJumpInstanceName = isInstanceContainer
      ? jumpInstanceName
      : jumpContainer
      ? `${jumpContainer}_${jumpInstanceName}`
      : jumpInstanceName;
    const isContainer = R.includes('_', newJumpInstanceName || '');
    if (isContainer) {
      const instance = R.split('_', newJumpInstanceName)[1];
      const inLogicPodIDs = R.includes(instance, logicPodIDs) && hasKubernetes;
      if (inLogicPodIDs) {
        logicPodID = instance;
      } else {
        instanceFilter = instance;
      }
    } else {
      const inLogicPodIDs = R.includes(newJumpInstanceName, logicPodIDs) && hasKubernetes;
      if (inLogicPodIDs) {
        logicPodID = newJumpInstanceName;
      } else {
        instanceFilter = newJumpInstanceName;
      }
    }
    if (jumpPodId) {
      logicPodID = jumpPodId;
    }

    this.setState({ instanceFilter, logicPodID }, () => {
      this.setTabCounterMap(tabCounterMap);
      this.setState({ isLoadingSystem: false, activeKeyListFlag: true });
    });
  }

  @autobind
  setTabCounterMap(tabCounterMap, flag) {
    const { location } = this.props;
    const query = parseLocation(location);
    const { eventCategory, jumpComponentName, jumpInstanceName, cancelActiveIncident } = query;
    const { componentFilter, instanceFilter, filterContainer, logicPodID, hasKubernetes } = this.state;
    let { activeKey } = this.state;
    const mapKey = {
      INCIDENT: 'incident',
      ALERT: 'logAlert',
      METRIC_ANOMALY: 'metric',
      LOG_ANOMALY: 'logAnomaly',
      TRACE: 'trace',
      CHANGE: 'deployment',
    };
    const tabCountMap = {};
    let componentListOptions = [];
    let instanceListOptions = [];
    let podListOptions = [];
    let containersListOptions = [];
    R.forEachObjIndexed((item, key) => {
      const { systemLevelCount, componentLevelCountSet, instanceLevelCountSet, pods, containers } = item;
      let mergeCount = 0;
      let totalCount = 0;

      // 获取tab num
      mergeCount = systemLevelCount.u;
      totalCount = systemLevelCount.t;
      if (componentFilter) {
        const findCItem = R.filter(
          (_item) => R.includes(_item.c, R.split(',', componentFilter)),
          componentLevelCountSet,
        );
        mergeCount = totalFun('u')(findCItem);
        totalCount = totalFun('t')(findCItem);
      }
      if (instanceFilter) {
        const findIItem = R.filter((_item) => R.includes(_item.i, R.split(',', instanceFilter)), instanceLevelCountSet);
        mergeCount = totalFun('u')(findIItem);
        totalCount = totalFun('t')(findIItem);
      }

      if (logicPodID) {
        const findCTItem = R.filter((_item) => R.includes(_item.p, R.split(',', logicPodID)), pods);
        mergeCount = totalFun('u')(findCTItem);
        totalCount = totalFun('t')(findCTItem);
      }

      if (filterContainer) {
        let findCTItem = R.filter((_item) => R.includes(_item.ct, R.split(',', filterContainer)), containers);
        if (instanceFilter) {
          findCTItem = R.filter(
            (_item) =>
              R.includes(
                _item.containerAndPod,
                result(R.split(',', filterContainer || ''), R.split(',', instanceFilter || '')),
              ),
            containers,
          );
        }
        if (logicPodID) {
          findCTItem = R.filter(
            (_item) =>
              R.includes(
                _item.containerAndPod,
                result(R.split(',', filterContainer || ''), R.split(',', logicPodID || '')),
              ),
            containers,
          );
        }
        mergeCount = totalFun('u')(findCTItem);
        totalCount = totalFun('t')(findCTItem);
      }

      if (mergeCount && totalCount) {
        tabCountMap[mapKey[key]] = { mergeCount, totalCount };
      }

      // 获取componentListOptions
      componentListOptions = R.concat(
        componentListOptions,
        R.map((_item) => ({ value: _item.c, label: _item.c }), componentLevelCountSet || []),
      );
      // 获取instanceListOptions
      instanceListOptions = R.concat(
        instanceListOptions,
        R.map((_item) => ({ value: _item.i, label: _item.i }), instanceLevelCountSet || []),
      );

      podListOptions = R.concat(
        podListOptions,
        R.map((_item) => ({ value: _item.p, label: _item.p }), pods || []),
      );

      containersListOptions = R.concat(
        containersListOptions,
        R.map((_item) => ({ value: _item.ct, label: _item.ct }), containers || []),
      );
    }, tabCounterMap || {});
    componentListOptions = R.uniqBy((item) => item.value, componentListOptions || []);
    componentListOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], componentListOptions);
    instanceListOptions = R.uniqBy((item) => item.value, instanceListOptions || []);
    instanceListOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], instanceListOptions);
    podListOptions = R.uniqBy((item) => item.value, podListOptions || []);
    podListOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], podListOptions);
    containersListOptions = R.uniqBy((item) => item.value, containersListOptions || []);
    containersListOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], containersListOptions);

    if (hasKubernetes) {
      instanceListOptions = R.differenceWith((x, y) => x.value === y.value, instanceListOptions, podListOptions);
    }

    this.componentListOptions = componentListOptions;
    this.instanceListOptions = instanceListOptions;
    this.podListOptions = podListOptions;
    this.containersListOptions = containersListOptions;

    if (!eventCategory && (jumpComponentName || jumpInstanceName || cancelActiveIncident)) {
      let newActive;
      R.forEach((item) => {
        const cancelActiveIncidentFlag =
          !cancelActiveIncident || (cancelActiveIncident && !['incident'].includes(item));
        if (R.has(item, tabCountMap) && !newActive && cancelActiveIncidentFlag) {
          newActive = item;
        }
      }, R.values(mapKey));
      activeKey = newActive || activeKey;
    }

    this.setState({ tabCountMap, activeKey }, () => {
      if (flag && this.flagClearJumpInstanceName) {
        this.flagClearJumpInstanceName = undefined;
        this.setState({ destroyTabPane: true }, () => {
          this.setState({ destroyTabPane: false, activeKey: 'incident' });
        });
      }
    });
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        startTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        endTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'add'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        endTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleTabChange(activeKey) {
    this.setState({ activeKey, showEnableRCA: false });
  }

  @autobind
  handleUpdateStats({ stats, activeKeyItem }) {
    const { activeKeyList } = this.state;
    this.countTabCountMap = { ...this.countTabCountMap, ...stats };
    this.setState({ activeKeyList: R.uniq([...activeKeyList, activeKeyItem]) }, () => {
      let activeKeyListFlag = false;
      if (R.keys(this.countTabCountMap)?.length === this.state.activeKeyList?.length) {
        activeKeyListFlag = true;
      }
      this.setState({ activeKeyListFlag });
    });
  }

  @autobind
  getSummaryObj(props) {
    const { credentials, location, globalInfo } = props;
    const { environmentId, startTime, endTime } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemIdsWithShare = R.map((item) => ({ id: item.id, customerName: item.ownerUserName }), systemList || []);
    if (startTime && endTime) {
      fetchPost(getEndpoint('dashboard-summary'), {
        ...credentials,
        systemIdsWithShare: JSON.stringify(systemIdsWithShare),
        startTime: moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf(),
        endTime: moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf(),
      })
        .then((data) => {
          this.setState({ summaryObj: data || {} });
        })
        .catch((e) => {
          console.log(String(e));
        });
    }
  }

  @autobind
  onChangeFilterComponent(componentFilter) {
    const { location, globalInfo } = this.props;
    this.setState({ componentFilter }, () => {
      const query = parseLocation(location);
      const environment = R.find((e) => e.id === query.environmentId, globalInfo || []);
      const tabCounterMap = get(environment, ['allTabCounterMap', query.systemId], {});
      this.setTabCounterMap(tabCounterMap);
    });
  }

  @autobind
  onChangeFilterInstance(instanceFilter) {
    const { location, globalInfo } = this.props;
    const { hasKubernetes } = this.state;
    let otherState = {};
    if (hasKubernetes) {
      otherState = { logicPodID: undefined, filterContainer: undefined };
    }
    this.setState({ instanceFilter, ...otherState }, () => {
      const query = parseLocation(location);
      const environment = R.find((e) => e.id === query.environmentId, globalInfo || []);
      const tabCounterMap = get(environment, ['allTabCounterMap', query.systemId], {});
      this.setTabCounterMap(tabCounterMap, true);
    });
  }

  @autobind
  onChangeFilterPhysicalPodID(logicPodID) {
    const { location, globalInfo } = this.props;
    const { hasKubernetes } = this.state;
    let otherState = {};
    if (hasKubernetes) {
      otherState = { instanceFilter: undefined };
      if (!logicPodID) {
        otherState = { ...otherState, filterContainer: undefined };
      }
    }
    this.setState({ logicPodID, ...otherState }, () => {
      const query = parseLocation(location);
      const environment = R.find((e) => e.id === query.environmentId, globalInfo || []);
      const tabCounterMap = get(environment, ['allTabCounterMap', query.systemId], {});
      this.setTabCounterMap(tabCounterMap, true);
    });
  }

  @autobind
  onChangeFilterContainer(filterContainer) {
    const { location, globalInfo } = this.props;
    this.setState({ filterContainer }, () => {
      const query = parseLocation(location);
      const environment = R.find((e) => e.id === query.environmentId, globalInfo || []);
      const tabCounterMap = get(environment, ['allTabCounterMap', query.systemId], {});
      this.setTabCounterMap(tabCounterMap, true);
    });
  }

  @autobind
  handleSystemIgnoredIncidentClick() {
    const { location } = this.props;
    const { customerName, startTime, endTime, environmentId, systemId } = parseLocation(location);
    const query = {
      customerName,
      startTime,
      endTime,
      environmentId,
      systemId,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemIgnoredIncident, {}, query), '_blank');
  }

  @autobind
  async exportPDF(systemInfo) {
    try {
      if (this.exportPDFing) {
        return;
      }
      if (this.tableList?.length > 2000) {
        message.info('the number of list is over 2000, please reduce the time range or filter conditions');
        return;
      }
      this.exportPDFing = true;
      this.setState({ exportPDFing: true, exportPDFPersent: 0 });
      const { location } = this.props;
      const { startTime, endTime } = parseLocation(location);
      const pdfFilename = `${systemInfo.name}-${startTime}-${endTime} Incident Investigation.pdf`;
      const pdfContents = document.createElement('div');
      pdfContents.setAttribute('id', 'divToPrint');
      document.body.appendChild(pdfContents);
      const height = this.tableList.length * 60 + 280;
      const searchBarHeight =
        (this.searchBarCountObj?.childCount || 0) * 36 + (this.searchBarCountObj?.headCount || 0) * 64 + 160;
      pdfContents.setAttribute(
        'style',
        `min-width: 210mm; 
        width: 100%; 
        height: 100%; 
        overflow: auto; 
        margin-left: auto; 
        margin-right: auto; 
        background-color: white;
        position: absolute;
        top: -100000px;
      `,
      );

      const clientContent = document.createElement('div');
      clientContent.setAttribute(
        'style',
        `width: 100%; 
        min-height: 100%; 
        height: ${Math.max(height, searchBarHeight) + 84}px; 
        margin-left: auto; 
        margin-right: auto; 
        background-color: white;
      `,
      );
      const exportContainer = document.getElementById('export-container');
      clientContent.appendChild(exportContainer);

      await pdfContents.appendChild(clientContent);
      await sleep(600);
      await downloadPdf(
        pdfFilename,
        [pdfContents],
        () => {
          this.setState({ exportPDFing: false, exportPDFPersent: 0 }, () => {
            document.getElementById('export-container-parent').appendChild(exportContainer);
            document.body.removeChild(pdfContents);
          });
          this.exportPDFing = false;
        },
        (percent) => {
          this.setState({ exportPDFPersent: percent });
        },
      );
    } catch (e) {
      console.log(e);
      this.setState({ exportPDFing: false, exportPDFPersent: 0 });
      this.exportPDFing = false;
    }
  }

  @autobind
  onTableChange(tableList) {
    this.tableList = tableList;
  }

  @autobind
  onSearchBarShowChangeCb(countObj) {
    this.searchBarCountObj = countObj;
  }

  @autobind
  renderProgressBar(percent, successPercent) {
    const { intl } = this.props;
    if (percent >= 100) {
      return (
        <span style={{ fontSize: 14 }}>
          {intl.formatMessage(appFieldsMessages.waiting)}
          <SyncOutlined style={{ marginLeft: 2 }} size="small" spin />
        </span>
      );
    }
    return `${percent.toFixed(2)}%`;
  }

  render() {
    const { refresh, intl, location, userList, globalInfo, isLocalAdmin, isAdmin, favorites, userInfo } = this.props;
    const { customerName, environmentId, systemId } = parseLocation(location);
    const {
      startTimeObj,
      endTimeObj,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
      isAuth,
      meetingUrl,
      isZoomLoading,
      summaryObj,
      activeKeyListFlag,
      componentFilter,
      instanceFilter,
      destroyTabPane,
      hasKubernetes,
      logicPodID,
      filterContainer,
      filterStartHour,
      showEnableRCA,
    } = this.state;
    const { isLoadingSystem, activeKey, tabCountMap, showEventsRCA, activeEvent, needRC, needPT, exportPDFing } =
      this.state;
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    let projectNameRCA;
    if (activeEvent) {
      const { projectOwner } = activeEvent;
      projectNameRCA =
        userInfo.userName !== projectOwner ? `${activeEvent.projectName}@${projectOwner}` : activeEvent.projectName;
    }

    return (
      <Container className="flex-grow flex-col flex-min-height flex-min-width" id="export-container-parent">
        <div className="flex-grow flex-col flex-min-height flex-min-width full-width full-height" id="export-container">
          <Container breadcrumb className={`${showEventsRCA ? 'display-none' : 'flex-row'}`}>
            <div className="flex-grow flex-row flex-center-align" style={{ alignItems: 'self-end' }}>
              <PublicBuildCizizen
                summaryObj={summaryObj}
                systemList={systemList}
                systemId={systemId}
                handleSystemIdChange={this.props.handleSystemIdChange}
                favorites={favorites}
              />

              {false && (
                <div className="flex-col flex-space-between" style={{ height: 50, paddingTop: 5, marginRight: 20 }}>
                  <div style={{ fontSize: 12 }}>{intl.formatMessage(appFieldsMessages.component)}</div>
                  <Select
                    allowClear
                    showArrow={false}
                    showSearch
                    size="small"
                    style={{ width: 150 }}
                    placeholder={intl.formatMessage(appFieldsMessages.component)}
                    value={componentFilter}
                    filterOption
                    optionFilterProp="value"
                    optionLabelProp="value"
                    onChange={this.onChangeFilterComponent}
                    dropdownMatchSelectWidth={500}
                    dropdownStyle={{ maxWidth: 860 }}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} title={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.componentListOptions || [])}
                  </Select>
                </div>
              )}
              {false && (
                <div className="flex-col flex-space-between" style={{ height: 50, paddingTop: 5, marginRight: 20 }}>
                  <div style={{ fontSize: 12 }}>{intl.formatMessage(appFieldsMessages.instance)}</div>
                  <Select
                    allowClear
                    showArrow={false}
                    showSearch
                    size="small"
                    style={{ width: 150 }}
                    placeholder={intl.formatMessage(appFieldsMessages.instance)}
                    value={instanceFilter}
                    filterOption
                    optionFilterProp="value"
                    optionLabelProp="value"
                    onChange={this.onChangeFilterInstance}
                    dropdownMatchSelectWidth={500}
                    dropdownStyle={{ maxWidth: 860 }}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} title={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.instanceListOptions || [])}
                  </Select>
                </div>
              )}
              {hasKubernetes && false && (
                <div className="flex-col flex-space-between" style={{ height: 50, paddingTop: 5, marginRight: 20 }}>
                  <div style={{ fontSize: 12 }}>{intl.formatMessage(appFieldsMessages.logicPodID)}</div>
                  <Select
                    allowClear
                    showArrow={false}
                    showSearch
                    size="small"
                    style={{ width: 150 }}
                    placeholder={intl.formatMessage(appFieldsMessages.logicPodID)}
                    value={logicPodID}
                    filterOption
                    optionFilterProp="value"
                    optionLabelProp="value"
                    onChange={this.onChangeFilterPhysicalPodID}
                    dropdownMatchSelectWidth={500}
                    dropdownStyle={{ maxWidth: 860 }}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} title={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.podListOptions || [])}
                  </Select>
                </div>
              )}
              {false && (
                <div className="flex-col flex-space-between" style={{ height: 50, paddingTop: 5 }}>
                  <div style={{ fontSize: 12 }}>{intl.formatMessage(appFieldsMessages.container)}</div>
                  <Select
                    allowClear
                    showArrow={false}
                    showSearch
                    size="small"
                    style={{ width: 150 }}
                    placeholder={intl.formatMessage(appFieldsMessages.container)}
                    value={filterContainer}
                    filterOption
                    optionFilterProp="value"
                    optionLabelProp="value"
                    onChange={this.onChangeFilterContainer}
                    dropdownMatchSelectWidth={500}
                    dropdownStyle={{ maxWidth: 860 }}
                    disabled={hasKubernetes ? !logicPodID : false}
                  >
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <Select.Option key={item.value} title={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    }, this.containersListOptions || [])}
                  </Select>
                </div>
              )}
            </div>
            <div className="flex-row" style={{ alignItems: 'self-end', height: 50 }}>
              <Container className="flex-row" style={{ alignItems: 'center' }}>
                <span style={{ fontWeight: 700, padding: '0 1em' }}>
                  {intl.formatMessage(appFieldsMessages.startDate)}
                </span>
                <DatePicker
                  size="small"
                  allowClear={false}
                  showToday={false}
                  value={startTimeObj}
                  disabledDate={(current) => {
                    return current && current > moment.utc().add(1, 'days').endOf('day');
                  }}
                  onChange={this.handleStartTimeChange}
                />
                <span style={{ fontWeight: 700, padding: '0 1em' }}>
                  {intl.formatMessage(appFieldsMessages.endDate)}
                </span>
                <DatePicker
                  size="small"
                  allowClear={false}
                  showToday={false}
                  value={endTimeObj}
                  disabledDate={(current) => {
                    return current && current > moment.utc().add(1, 'days').endOf('day');
                  }}
                  onChange={this.handleEndTimeChange}
                />

                {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.environment)}
              </span>
              <Select
                size="small"
                value={environmentId}
                style={{ width: 140 }}
                onChange={this.props.handleEnvironmentChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.name} value={item.name} title={item.name}>
                      {item.id}
                    </Select.Option>
                  ),
                  globalInfo || [],
                )}
              </Select> */}
                {(isAdmin || isLocalAdmin) && (
                  <span style={{ fontWeight: 700, padding: '0 1em' }}>
                    {intl.formatMessage(appFieldsMessages.user)}
                  </span>
                )}
                {(isAdmin || isLocalAdmin) && (
                  <Select
                    showSearch
                    size="small"
                    value={customerName}
                    style={{ width: 100 }}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={this.props.handleCustomerNameChange}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ maxWidth: 650 }}
                  >
                    {R.map(
                      (item) => (
                        <Select.Option key={item.userName} value={item.userName}>
                          {item.userName}
                        </Select.Option>
                      ),
                      userList || [],
                    )}
                  </Select>
                )}
                <div
                  style={{ marginLeft: 8 }}
                  onMouseEnter={() => {
                    if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                      this.setState({ tooltipVisibleReloadMouseOver: true });
                  }}
                  onMouseLeave={() => {
                    if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
                  }}
                >
                  <Tooltip
                    mouseEnterDelay={0.3}
                    placement="bottomRight"
                    visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                    title={
                      disableRefresh
                        ? 'Range of days <= 31'
                        : timeChange
                        ? intl.formatMessage(appMessages.clickToReload)
                        : null
                    }
                  >
                    <Button
                      size="small"
                      disabled={disableRefresh}
                      onClick={() => this.props.handleRefresh({ startTimeObj, endTimeObj })}
                    >
                      {intl.formatMessage(appButtonsMessages.refresh)}
                    </Button>
                  </Tooltip>
                </div>
                <div style={{ marginLeft: 8 }}>
                  <Button.Group style={{ marginRight: 8 }}>
                    <Tooltip title="Export PDF" placement="topRight">
                      <Button
                        size="small"
                        disabled={disableRefresh}
                        icon={<PDFIcon style={{ color: 'white', fontSize: 18 }} />}
                        onClick={() => this.exportPDF(systemInfo)}
                        Tooltip="Export PDF"
                      />
                    </Tooltip>
                  </Button.Group>
                </div>
              </Container>
            </div>
          </Container>

          <Container
            className={`global-view flex-grow flex-min-height content-bg corner-10  ${
              showEventsRCA ? 'display-none' : 'flex-col'
            }`}
            style={{ margin: '0 16px 8px 16px', padding: 8 }}
          >
            <Tabs
              key={destroyTabPane}
              type="card"
              className="full-width full-height ant-tabs-content-full-height"
              activeKey={activeKey}
              onChange={(activeKey) => {
                this.handleTabChange(activeKey);
              }}
              tabBarExtraContent={
                <div className="flex-row flex-center-align">
                  {false && (
                    <Popover
                      placement="left"
                      content={
                        !isAuth
                          ? 'Zoom is not authorized. Please to Zoom for authorization'
                          : meetingUrl
                          ? 'Click to join the Zoom meeting'
                          : 'Create a Zoom Meeting'
                      }
                    >
                      <Button
                        size="small"
                        type="primary"
                        disabled={!isAuth}
                        onClick={this.clickZoomButton}
                        loading={isZoomLoading}
                      >
                        {!isAuth ? 'Zoom Meeting' : meetingUrl ? 'Join Zoom Meeting' : 'Create Zoom Meeting'}
                      </Button>
                    </Popover>
                  )}
                  {activeKey !== 'incident' && (
                    <div className="flex-row flex-center-align" style={{ marginRight: 8 }}>
                      <Switch
                        size="small"
                        checked={showEnableRCA}
                        onChange={(showEnableRCA) => this.setState({ showEnableRCA })}
                      />
                      <span style={{ marginLeft: 8 }}>{intl.formatMessage(logMessages.showEnableRCA)}</span>
                    </div>
                  )}
                  <Button
                    size="small"
                    onClick={this.handleSystemIgnoredIncidentClick}
                    style={{ marginLeft: 8 }}
                    disabled={!systemInfo}
                    icon={<IgnoredIncidentsIcon />}
                  >
                    {intl.formatMessage(appMenusMessages.globalSystemIgnoredIncident)}
                  </Button>
                </div>
              }
            >
              {R.map((item) => {
                const { name: tabName, title } = item;
                return (
                  <Tabs.TabPane
                    tab={
                      <span>
                        <span>{title}</span>
                        {Boolean(tabCountMap[tabName]) && (
                          <span style={{ marginLeft: 6 }}>
                            {`${tabCountMap[tabName].totalCount} ${intl.formatMessage(logMessages.total)}`}
                          </span>
                        )}
                      </span>
                    }
                    key={tabName}
                  >
                    <RootCauseList
                      activeKey={activeKey}
                      tabName={tabName}
                      refresh={refresh}
                      isLoadingSystem={isLoadingSystem}
                      handleUpdateStats={this.handleUpdateStats}
                      activeKeyListFlag={activeKeyListFlag}
                      componentFilter={componentFilter}
                      instanceFilter={instanceFilter}
                      logicPodID={logicPodID}
                      filterContainer={filterContainer}
                      filterStartHour={filterStartHour}
                      showEnableRCA={showEnableRCA}
                      flagClearJumpInstanceName={this.flagClearJumpInstanceName}
                      handleChangeRCAModal={({ activeEvent, needRC, needPT, handleCallBack }) => {
                        this.handleCallBack = handleCallBack;
                        this.setState({ showEventsRCA: true, activeEvent, needRC, needPT });
                      }}
                      onChangeFilterComponent={this.onChangeFilterComponent}
                      onChangeFilterInstance={this.onChangeFilterInstance}
                      onChangeFilterPhysicalPodID={this.onChangeFilterPhysicalPodID}
                      onChangeFilterContainer={this.onChangeFilterContainer}
                      hasKubernetes={hasKubernetes}
                      onTableChange={this.onTableChange}
                      onSearchBarShowChangeCb={this.onSearchBarShowChangeCb}
                    />
                  </Tabs.TabPane>
                );
              }, this.tabList)}
            </Tabs>
          </Container>
        </div>
        {!!exportPDFing && (
          <Container
            fullHeight
            className="flex-grow flex-col flex-center-align flex-center-justify"
            style={{ padding: 0 }}
          >
            <Progress
              type="circle"
              percent={(this.state.exportPDFPersent || 0) * 100}
              format={this.renderProgressBar}
            />
          </Container>
        )}
        {showEventsRCA && (
          <div className="flex-col full-height">
            <Container breadcrumb className="flex-row" style={{ marginBottom: 0 }}>
              <div className="flex-row flex-center-align" style={{ width: 'fit-content' }}>
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => {
                    if (this.handleCallBack) this.handleCallBack(activeEvent);
                    this.setState({
                      showEventsRCA: false,
                      activeEvent: null,
                      needRC: false,
                      needPT: false,
                      rcaFlag: true,
                    });
                    this.handleCallBack = undefined;
                  }}
                >
                  {intl.formatMessage(appMenusMessages.globalSystemRootCause)}
                </Button>
                <div style={{ marginLeft: 4 }}>{`/ ${intl.formatMessage(
                  needPT ? eventMessages.trailingEventsDetails : eventMessages.rootCauseDetails,
                )}`}</div>
              </div>
            </Container>
            <div
              className="flex-grow flex-min-height flex-col content-bg flex-min-width corner-10"
              style={{ margin: '0 16px', padding: 8 }}
            >
              <LikelyRootCausesRCA
                incident={activeEvent}
                environmentId={environmentId}
                systemId={systemId}
                projectName={projectNameRCA}
                functionRC="root cause"
                needRC={needRC}
                needPT={needPT}
                rcaFlag={this.state.rcaFlag}
              />
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const GlobalSystemRootCauseList = injectIntl(GlobalSystemRootCauseListCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, globalInfo, projects, projectDisplayMap, systemsMap, favorites } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isReadUser, isLocalAdmin, userName } = state.auth.userInfo;
    const { globalSystemInfo } = state.dashboard;
    return {
      credentials,
      userList,
      userInfo,
      isAdmin,
      userName,
      projects,
      projectDisplayMap,
      systemsMap,
      isReadUser,
      isLocalAdmin,
      location,
      loadStatus,
      globalInfo,
      globalSystemInfo,
      favorites,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(GlobalSystemRootCauseList);
