/* @flow */
/* eslint-disable no-console */
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { isPlainObject } from 'lodash';
import { REHYDRATE } from 'redux-persist/constants';

import { BaseUrls } from '../../web/app/Constants';
import type { AppState, Action } from '../types';
import { ActionTypes } from './actions';
import { buildUrl, Defaults } from '../utils';

import loadLocaleMessages from '../loadLocaleMessages';

const localeMessages = loadLocaleMessages();
const locales = R.map((l) => l.name, localeMessages);
const messages = R.map((l) => l.messages, localeMessages);

const initialState = {
  appName: '',
  appVersion: '',
  currentTheme: 'light',
  currentLocale: null,
  locales,
  messages,

  rehydrated: false,
  starting: false,
  started: false,
  appLoaderVisible: false,

  inited: false,

  pageLoaderVisible: false,
  pageLoaderCount: 0,
  lastError: null,
  modals: [],
  alerts: [],
  systemsMap: {},
  projects: [],
  allProjects: [],
  projectDisplayMap: {},
  projectInstanceComponentMap: {},
  defaultTimezone: '',
  timezoneOffset: 0,
  currentLoadingComponents: {},

  currentProjectName: null,
  currentProjectGroupList: [],

  userList: [],
  // v2
  loadStatus: {}, // status for load actions

  globalInfoLoaded: false,
  globalInfo: [],

  // user missing info
  needInputUserMissingInfo: false,
  userMissingInfo: {},

  // Awesome Tours
  runAwesomeTours: false,
  toursState: {
    stepIndex: 0,
    url: buildUrl(
      BaseUrls.GlobalHealth,
      {},
      { startTime: Defaults.demoDay(), endTime: Defaults.demoDay(), customerName: 'demoUser' },
    ),
  },

  openedSubMenus: ['analysis'],

  // To be removed
  loaderStatus: {},
  collapsed: true,
  favorites: [],

  showDetailsFrame: false,
};

const reducer = (state: AppState = initialState, action: Action): AppState => {
  if (action.type === REHYDRATE) {
    // When doing rehydrate, if returns different state object, autoRehydrate will be skipped.
    // So we just return the origin state object.
    return state;
  } else if (action.type === ActionTypes.APP_START) {
    return {
      ...state,
      appLoaderVisible: true,
      starting: true,
    };
  } else if (action.type === ActionTypes.APP_REHYDRATED) {
    return {
      ...state,
      rehydrated: true,
    };
  } else if (action.type === ActionTypes.APP_STARTED) {
    return {
      ...state,
      starting: false,
      started: true,
    };
  } else if (action.type === ActionTypes.SET_LOAD_STATUS) {
    // Merge and overwrite the load status with status in store.
    const { loadStatus } = state;
    return {
      ...state,
      loadStatus: {
        ...loadStatus,
        ...action.payload,
      },
    };
  } else if (action.type === ActionTypes.SET_INFO_GLOBAL) {
    const { data } = action.payload;
    return {
      ...state,
      globalInfoLoaded: true,
      ...data,
    };
  } else if (action.type === ActionTypes.RESET_INFO_GLOBAL) {
    return {
      ...state,
      globalInfoLoaded: false,
      globalInfo: [],
    };
  } else if (action.type === ActionTypes.SET_INFO_SYSTEM_CONFIGS) {
    const { data } = action.payload;
    return {
      ...state,
      ...data,
    };
  } else if (action.type === 'SET_CURRENT_THEME') {
    return {
      ...state,
      currentTheme: action.payload.theme,
    };
  } else if (action.type === 'SET_SHOW_DETAILS_FRAME') {
    return {
      ...state,
      showDetailsFrame: action.payload.showDetailsFrame,
    };
  } else if (action.type === 'SET_CURRENT_LOCALE') {
    return {
      ...state,
      currentLocale: action.payload.locale,
    };
  } else if (action.type === 'SET_INIT_DATA') {
    const {
      licenseValid,
      licenseWillExpired,
      expirationDate,
      defaultTimezone,
      systemsMap,
      projects,
      projectDisplayMap,
      userList,
      shareSystemArr,
      ownSystemArr,
    } = action.payload;
    const timezoneOffset =
      defaultTimezone && momenttz.tz.zone(defaultTimezone)
        ? -momenttz.tz.zone(defaultTimezone).utcOffset(moment.utc())
        : 0;
    return {
      ...state,
      inited: true,
      systemsMap: systemsMap || {},
      projects: (projects || []).filter((item) => item.ps !== 'Deleting'),
      allProjects: projects || [],
      projectDisplayMap: projectDisplayMap || {},
      userList: R.filter((item) => item.role !== 'LocalAdmin', userList || []),
      licenseValid,
      licenseWillExpired,
      expirationDate,
      defaultTimezone,
      timezoneOffset,
      shareSystemArr,
      ownSystemArr,
      globalInfoLoaded: true,
    };
  } else if (action.type === 'APP_STOP') {
    return {
      ...state,
      started: false,
    };
  } else if (action.type === ActionTypes.SHOW_APP_LOADER) {
    // Show the app loader if it's not initialized, otherwise show page loader.
    const { inited, appLoaderVisible, pageLoaderCount } = state;
    return {
      ...state,
      appLoaderVisible: appLoaderVisible || !inited,
      pageLoaderVisible: inited && !appLoaderVisible,
      pageLoaderCount: pageLoaderCount + 1,
    };
  } else if (action.type === ActionTypes.HIDE_APP_LOADER) {
    const { inited } = state;
    // Decrease the page load cout.
    let { pageLoaderCount } = state;
    pageLoaderCount -= 1;
    if (pageLoaderCount < 0) pageLoaderCount = 0;
    return {
      ...state,
      appLoaderVisible: false,
      pageLoaderVisible: pageLoaderCount > 0 ? inited : false,
      pageLoaderCount,
    };
  } else if (action.type === 'APP_ERROR') {
    return {
      ...state,
      appLoaderVisible: false,
      pageLoaderVisible: false,
      lastError: action.payload,
    };
  } else if (action.type === 'SHOW_APP_MODAL') {
    const { type, params } = action.payload;
    let modals = state.modals || [];
    modals = [
      ...modals,
      {
        id: Date.now().toString(),
        type,
        params: params || {},
      },
    ];
    return {
      ...state,
      modals,
    };
  } else if (action.type === 'SHOW_APP_ALERT') {
    const { type, message, params, options } = action.payload;

    const alerts = [
      {
        key: Date.now().toString(),
        type,
        message,
        params: params || {},
        options: options || {},
      },
    ];

    return {
      ...state,
      alerts,
    };
  } else if (action.type === 'SET_LOADING_COMPONENTS') {
    // Merge the current loadings components with the new components status
    // And remove the false components to reduce the state size.
    const components = action.payload;
    if (R.isNil(components) || R.isEmpty(components)) {
      return state;
    }

    const currentLoadingComponents = R.filter((x) => Boolean(x), {
      ...state.currentLoadingComponents,
      ...components,
    });
    return {
      ...state,
      currentLoadingComponents,
    };
  } else if (action.type === ActionTypes.SET_PROJECT_INFO) {
    const { data: infoList } = action.payload;
    if (infoList.length === 0) {
      return state;
    }

    // Merge the full info of project into list
    let projects = [];
    const { allProjects: prevInfoList } = state;
    R.forEach((p) => {
      const proj = R.find((np) => np.projectName === p.projectName, infoList);
      // Find the project in the new list and update the info for the project.
      if (proj) {
        projects.push({ ...p, ...proj, ps: proj.status || p.ps });
      } else {
        projects.push(p);
      }
    }, prevInfoList);
    projects = R.filter((p) => R.toLower(p?.ps || '') !== 'invalid', projects || []);

    return {
      ...state,
      projects: projects.filter((item) => item.ps !== 'Deleting'),
      allProjects: projects,
    };
  } else if (action.type === ActionTypes.SET_PROJECT_NOSYSTEM_INFO) {
    const { noSystemProject: infoList } = action.payload;
    if (infoList.length === 0) {
      return state;
    }

    // Merge the full info of project into list
    const { allProjects: prevInfoList } = state;
    const projects = [...prevInfoList];

    R.forEach((p) => {
      const proj = R.find((np) => np.projectName === p.projectName, prevInfoList);
      // Find the project in the new list and update the info for the project.
      if (!proj) {
        projects.push(p);
      }
    }, infoList);

    return {
      ...state,
      projects: projects.filter((item) => item.ps !== 'Deleting'),
      allProjects: projects,
    };
  } else if (action.type === ActionTypes.SET_PROJECT_INSTANCE_COMPONENT_MAP) {
    const { data } = action.payload;
    const { projectName, instanceComponentMap } = data || {};
    if (!projectName || !isPlainObject(instanceComponentMap)) {
      return state;
    }

    // Merge the instance component map info
    const { projectInstanceComponentMap } = state;
    if (!R.has(projectName, projectInstanceComponentMap)) {
      projectInstanceComponentMap[projectName] = {};
    }
    projectInstanceComponentMap[projectName] = R.mergeRight(
      projectInstanceComponentMap[projectName],
      instanceComponentMap,
    );

    return {
      ...state,
      projectInstanceComponentMap,
    };
  } else if (action.type === 'SET_PROJECT_GROUP_LIST') {
    const { projectName, groupList } = action.payload;
    return {
      ...state,
      currentProjectName: projectName,
      currentProjectGroupList: groupList || [],
    };
  } else if (action.type === 'SET_DEFAULT_TIMEZONE') {
    const { defaultTimezone } = action.payload;
    const timezoneOffset =
      defaultTimezone && momenttz.tz.zone(defaultTimezone)
        ? -momenttz.tz.zone(defaultTimezone).utcOffset(moment.utc())
        : 0;

    return {
      ...state,
      defaultTimezone,
      timezoneOffset,
    };
  } else if (action.type === 'SET_ACTIVE_INSTANCE') {
    let { data: activeMap } = action.payload;
    if (!activeMap) activeMap = action.payload.params;
    return {
      ...state,
      activeMap,
    };
  } else if (action.type === 'SET_USER_MISSING_INFO') {
    const { data } = action.payload;
    const { needInputUserMissingInfo, userMissingInfo } = data || {};
    return {
      ...state,
      needInputUserMissingInfo,
      userMissingInfo,
    };
  } else if (action.type === 'SET_AWESOME_TOURS') {
    const { toursState: prevToursState } = state;
    const { data } = action.payload;
    const { toursState, ...rest } = data || {};
    return {
      ...state,
      ...rest,
      toursState: R.merge(prevToursState, toursState),
    };
  } else if (action.type === ActionTypes.SET_OPENED_SUBMENUS) {
    const openedSubMenus = action.payload.openedSubMenus || [];
    return { ...state, openedSubMenus };
  } else if (action.type === ActionTypes.SET_COLLAPSED) {
    const { collapsed } = action.payload;
    return { ...state, collapsed };
  } else if (action.type === ActionTypes.SET_LOADER_STATUS) {
    // Merge the loaders status, and remove the false loaders to reduce the state size.
    // the loader is identify by a string.
    const loader = action.payload;
    if (R.isNil(loader) || R.isEmpty(loader)) {
      return state;
    }

    const loaderStatus = R.filter((x) => Boolean(x), {
      ...state.loaderStatus,
      ...loader,
    });
    return {
      ...state,
      loaderStatus,
    };
  } else if (action.type === ActionTypes.SET_SYSTEM_FAVORITES) {
    const { data } = action.payload;
    const { favorites = [] } = data;
    return {
      ...state,
      favorites,
    };
  }
  return { ...initialState, ...state };
};

export default reducer;
