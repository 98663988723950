/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { CheckOutlined, ClockCircleOutlined, LineChartOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Timeline, Button, Empty, Spin, message } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { TrendIcon, QeuryIcon } from '../../../lib/fui/icons';
import { Popover } from '../../../lib/fui/react';
import {
  Defaults,
  GlobalRenderers,
  CellRenderers,
  LogRenderers,
  EventRenderers,
  GlobalParse,
  buildUrl,
  parseLocation,
} from '../../../common/utils';
import { State } from '../../../common/types';
import { updateLastActionInfo } from '../../../common/app/actions';

import { appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';

import InsightQueryBoxModal from './InsightQueryBoxModal';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import LogToMetricContextRCA from './LogToMetricContextRCA';
import LogToMetricContext from './LogToMetricContext';

type Props = {
  // eslint-disable-next-line
  incident: Object,
  // eslint-disable-next-line
  selectChain: Object,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  systemId: String,
  // eslint-disable-next-line
  onToggleCollapse: Function,

  intl: Object,
  // eslint-disable-next-line
  match: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  projects: Array<Object>,
  projectDisplayMap: Object,
  globalInfo: Object,
  credentials: Object,
  summarySettingsMap: Object,
  currentTheme: String,
  infoRender: Function,
  relevanceRender: Function,
  highlightTimestamp: number,
  highlightEndTimestamp: number,
  useScroll: Boolean,
  isJWT: Boolean,
  userInfo: Object,
};

class RootCauseTimelineRCAListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      timelines: [],
      eventList: [],
      patternNameMap: {},

      activeKey: null,

      showInsightQueryBox: false,
      rootCauseEvent: null,

      activeIncident: null,
      selectProject: null,
      selectInstance: undefined,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
      showContextModal: false,

      showLogToMetricModal: false,
    };
    this.eventList = [];
    this.redirect = true;
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectChain !== this.props.selectChain &&
      (nextProps.highlightTimestamp !== this.props.highlightTimestamp ||
        nextProps.highlightEndTimestamp !== this.props.highlightEndTimestamp)
    ) {
      this.parseData(nextProps);
    }
  }

  componentWillUnmount() {}

  @autobind
  parseData(props) {
    const { incident, selectChain, highlightTimestamp, highlightEndTimestamp, useScroll } = props;
    const { operation } = selectChain || {};

    const incidentKey = incident.id || R.join('-', incident.rootCauseTableKey || []);
    let eventList = [];
    R.forEach((item) => {
      const { sourceKey, displayTimestamp } = item;
      eventList.push({ ...item, key: `${sourceKey}-${displayTimestamp}` });
    }, selectChain.sourceInfoList || []);

    // merge event list in timeline
    const eventsMap = {};
    R.forEach((s) => {
      const { id, patternId, displayTimestamp } = s;
      const key = `${id}__${displayTimestamp}__${patternId}`;
      if (!eventsMap[key]) {
        eventsMap[key] = s;
      }
    }, eventList);
    eventList = R.values(eventsMap);

    // build timelines with sorted
    let timelines = R.map((item) => {
      const { sourceKey, patternId, displayTimestamp } = item;
      return { ...item, timestamp: displayTimestamp, patternIds: [patternId], key: `${sourceKey}-${displayTimestamp}` };
    }, R.sortWith([R.ascend(R.prop('displayTimestamp'))], eventList));

    // add root
    if (operation === 'rootCauseEvents') {
      timelines = [
        ...timelines,
        {
          key: incidentKey,
          timestamp: incident.displayTimestamp,
          isRoot: true,
          event: incident,
        },
      ];
    } else {
      timelines = [
        {
          key: incidentKey,
          timestamp: incident.displayTimestamp,
          isRoot: true,
          event: incident,
        },
        ...timelines,
      ];
    }

    // append incident event to timelineInfos
    if (operation === 'rootCauseEvents') {
      eventList = [
        ...eventList,
        {
          ...incident,
          key: incidentKey,
          displayTimestamp: incident.displayTimestamp,
          isRoot: true,
        },
      ];
    } else {
      eventList = [
        {
          ...incident,
          key: incidentKey,
          displayTimestamp: incident.displayTimestamp,
          isRoot: true,
        },
        ...eventList,
      ];
    }

    let activeKey = null;
    const findActiveItem = R.find(
      (item) => item.timestamp >= highlightTimestamp && item.timestamp <= highlightEndTimestamp,
      timelines,
    );
    if (findActiveItem) {
      activeKey = findActiveItem.key;
    }

    this.eventList = this.filterData(eventList, { ...this.state, activeKey: null });
    this.setState({ timelines, eventList, activeKey }, () => {
      if (this.props.onToggleCollapse) this.props.onToggleCollapse();

      if (findActiveItem && highlightTimestamp && highlightEndTimestamp) {
        if (!this.redirect) {
          const timeEl = document.getElementById(`time-${findActiveItem.key}`);
          const el = document.getElementById(findActiveItem.key);
          if (useScroll && el && timeEl && this.currentTimeRef && this.currentRef) {
            try {
              this.currentTimeRef.scroll({ top: timeEl.offsetTop - 10, left: timeEl.offsetLeft, behavior: 'smooth' });
              this.currentRef.scroll({ top: el.offsetTop, left: el.offsetLeft, behavior: 'smooth' });
            } catch (error) {
              timeEl.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
              el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
          } else {
            timeEl.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
            el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        } else {
          this.redirect = false;
        }
      }
    });
  }

  @autobind
  filterData(events, state) {
    const { activeKey } = state;

    let eventList = events || [];
    if (activeKey) {
      eventList = R.filter((event) => event.key === activeKey, eventList);
    }

    return eventList;
  }

  @autobind
  handleTimelineClick(item) {
    const { useScroll } = this.props;
    const { activeKey } = this.state;
    const { key } = item;
    const clear = key && key === activeKey;
    this.setState({ activeKey: clear ? null : key }, () => {
      const el = document.getElementById(key);
      if (useScroll && this.currentRef) {
        try {
          this.currentRef.scroll({ top: el.offsetTop, left: el.offsetLeft, behavior: 'smooth' });
        } catch (error) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      } else {
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    });
  }

  @autobind
  patternRenderer(rowData) {
    const { patternId, patternName } = rowData;

    return (
      <div className="max-width flex-row flex-center-align">
        <Popover
          content={Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true }).patternNameStr}
          mouseEnterDelay={0.3}
          placement="top"
        >
          <div className="hidden-line-with-ellipsis">
            {Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true }).patternNameStr}
          </div>
        </Popover>
      </div>
    );
  }

  @autobind
  renderIncidentDetails(rowData, summarySettings) {
    const { intl, currentTheme } = this.props;

    return (
      <div
        className="full-width flex-col"
        style={{ padding: '4px 0', wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
      >
        <EventRenderers.RenderShortDescription
          intl={intl}
          event={rowData}
          shouldExpandLimit={80}
          onToggleCollapse={this.props.onToggleCollapse}
          summarySettings={summarySettings}
          currentTheme={currentTheme}
        />
      </div>
    );
  }

  @autobind
  renderRootCauseDetails(rowData, summarySettings) {
    const { intl, currentTheme } = this.props;
    const { sourceDetail } = rowData || {};
    const { isMetric, content } = sourceDetail || {};

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(content);
    } catch (error) {
      // console.debug(error);
    }

    return (
      <div className="full-width flex-col" style={{ padding: '4px 0' }}>
        <div className="flex-row">
          {!isMetric && (
            <LogRenderers.RenderLogContent
              intl={intl}
              rawData={content}
              rawDataJson={rawDataJson}
              onToggleCollapse={this.props.onToggleCollapse}
              owner={rowData}
              summarySettings={summarySettings}
              currentTheme={currentTheme}
            />
          )}
          {isMetric && (
            <div className="flex-grow" style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}>
              {EventRenderers.RenderMetricAnomalySummary({ intl, event: sourceDetail })}
            </div>
          )}
        </div>
      </div>
    );
  }

  @autobind
  handleIncidentChartClick(rowData) {
    const { credentials, environmentId, location } = this.props;
    const { projectOwner, startTimestamp, endTimestamp } = rowData || {};
    const { customerName } = parseLocation(location);
    let { projectName } = rowData || {};
    if (projectOwner !== credentials.userName) {
      projectName = `${projectName}@${projectOwner}`;
    }
    const startTs = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTs = moment.utc(endTimestamp).endOf('day').valueOf();
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    let metricList = [];
    let instanceList = [];
    const metricAnomalyMap = {};

    R.forEach((item) => {
      const metricName = item.rootCauseMetric || item.metricName;
      const instanceName = item.instanceId || item.instanceName;
      metricList.push(metricName);
      instanceList.push(instanceName);
      if (!metricAnomalyMap[metricName]) {
        metricAnomalyMap[metricName] = [];
      }
      metricAnomalyMap[metricName].push(instanceName);
    }, get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []));
    metricList = R.filter((item) => item, R.uniq(metricList));
    instanceList = R.filter((item) => item, R.uniq(instanceList));

    const query = {
      projectName,
      instanceGroup,
      modelType,
      startTimestamp: startTs,
      endTimestamp: endTs,
      justSelectMetric: R.join(',', metricList),
      justInstanceList: R.join(',', instanceList),
      withBaseline: true,
      metricAnomalyMap: JSON.stringify(metricAnomalyMap),
      showRCAFlag: true,
      customerName: customerName || credentials.userName || projectOwner,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleIncidentJumpClick(rowData) {
    const { credentials, projects, location } = this.props;
    const { customerName } = parseLocation(location);
    const {
      projectOwner,
      startTimestamp,
      endTimestamp,
      anomalyLogInstance,
      instanceName,
      patternId,
      type: eventType,
    } = rowData || {};
    let { projectName } = rowData || {};
    if (projectOwner !== credentials.userName) {
      projectName = `${projectName}@${projectOwner}`;
    }
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const startTime = moment.utc(startTimestamp).format(Defaults.DateFormat);
    const endTime = moment.utc(endTimestamp).format(Defaults.DateFormat);

    const query = {
      projectName,
      instanceName: anomalyLogInstance || instanceName,
      startTime,
      endTime,
      activeTab: eventType.toLowerCase() === 'rare' ? 'important' : 'clusters',
      activePatternId: patternId,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: customerName || credentials.userName || project?.owner || projectOwner,
      anomalyType: eventType.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  handleRootCauseChartClick(rowData) {
    const { credentials, incident } = this.props;
    const { sourceDetail, instanceName, owner, displayTimestamp } = rowData || {};
    const { startTimestamp: incidentStart, endTimestamp: incidentEnd, patternId, isIncident, isDeployment } = incident;
    const { content } = sourceDetail;
    let { projectName } = rowData || {};
    projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
    const startTimestamp = moment.utc(displayTimestamp).subtract(1, 'days').startOf('day').valueOf();
    const endTimestamp = moment.utc(displayTimestamp).endOf('day').valueOf();
    const { location } = this.props;
    const { customerName } = parseLocation(location);

    const incidentInfo = {
      startTimestamp: incidentStart,
      endTimestamp: incidentEnd,
      patternId,
      type: isIncident ? 'incident' : isDeployment ? 'deployment' : 'normal',
    };
    const metricAnomalyMap = { [content]: [instanceName] };

    const query = {
      customerName: customerName || credentials.userName || owner,
      projectName,
      instanceGroup: 'All',
      startTimestamp,
      endTimestamp,
      justSelectMetric: R.join(',', [content]),
      justInstanceList: R.join(',', [instanceName]),
      // withBaseline: true,
      incidentInfo: JSON.stringify(incidentInfo),
      metricAnomalyMap: JSON.stringify(metricAnomalyMap),
      showRCAFlag: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleJumpClick(rowData) {
    const { credentials, environmentId, projects, location } = this.props;
    const { customerName } = parseLocation(location);

    const { sourceDetail, instanceName, owner, patternId, displayTimestamp } = rowData || {};
    const { isMetric, eventType } = sourceDetail;
    let { projectName } = rowData || {};
    projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const startTime = moment.utc(displayTimestamp).format(Defaults.DateFormat);
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    if (isMetric) {
      const query = {
        projectName,
        instanceGroup,
        startTime,
        endTime: startTime,
        customerName: customerName || credentials.userName || project?.owner || owner,
        patternSearch: patternId,
      };
      window.open(buildUrl(BaseUrls.MetricEvents, {}, query), '_blank');
    } else {
      const query = {
        projectName,
        instanceName,
        startTime,
        endTime: startTime,
        activeTab: eventType.toLowerCase() === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: customerName || credentials.userName || project?.owner || owner,
        anomalyType: eventType.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleLogContextOneMinClick(rowData) {
    const { intl, credentials } = this.props;
    const { owner, instanceName, displayTimestamp, sourceDetail } = rowData || {};
    const { content } = sourceDetail || {};
    let { projectName } = rowData || {};
    projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
    const selectStartTimestamp = displayTimestamp - 9 * 60 * 1000;
    const selectEndTimestamp = displayTimestamp + 60 * 1000;
    const activeIncident = { ...rowData, timestamp: displayTimestamp, rawData: content };
    return (
      <Button
        size="small"
        style={{ height: 22, marginRight: 8 }}
        onClick={() => {
          this.setState({
            activeIncident,
            selectProject: projectName,
            selectInstance: instanceName,
            selectStartTimestamp,
            selectEndTimestamp,
            showContextModal: true,
          });
        }}
      >
        {intl.formatMessage(logMessages.context)}
      </Button>
    );
  }

  @autobind
  handleTrendPatternsClick(rowData) {
    const { credentials, location } = this.props;
    const { customerName } = parseLocation(location);
    const { instanceName, owner, patternId, displayTimestamp } = rowData || {};
    let { projectName } = rowData || {};
    projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;

    const startTimeObj = moment.utc(displayTimestamp).subtract(7, 'days').startOf('day');
    const endTimeObj = moment.utc(displayTimestamp).endOf('day');

    const query = {
      t: '953de6a33d8a4b96ac9c100bf69ba3fc',
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      pattern: patternId,
      customerName: customerName || credentials.userName || owner,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
  }

  @autobind
  onConfirmInsightQueryProjectSelect(params) {
    const { location, credentials } = this.props;
    const { customerName } = parseLocation(location);
    const {
      templateId,
      projectName,
      instanceName,
      startTimeObj,
      endTimeObj,
      keyword,
      numOfCluster,
      pattern,
      duration,
    } = params;
    const query = {
      t: templateId,
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      keyword,
      numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
      pattern,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      duration,
      customerName: customerName || credentials.userName,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    this.setState({ showInsightQueryBox: false });
  }

  @autobind
  patternsJumpButtonRoot(rowData) {
    const { intl, credentials, projects } = this.props;
    const { projectOwner } = rowData;
    let { projectName } = rowData || {};
    if (projectOwner !== credentials.userName) {
      projectName = `${projectName}@${projectOwner}`;
    }
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    return (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={
          isAlert || isIncident
            ? intl.formatMessage(appMenusMessages.alertAnalysis)
            : intl.formatMessage(appMenusMessages.logAnalysis)
        }
      >
        <Button
          size="small"
          style={{ height: 22, marginRight: 8 }}
          onClick={() => this.handleIncidentJumpClick(rowData)}
        >
          <UnorderedListOutlined />
        </Button>
      </Popover>
    );
  }

  @autobind
  lineChartButtonRoot(rowData) {
    const { intl } = this.props;
    return (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={intl.formatMessage(DashboardMessages.lineChart)}
      >
        <Button
          size="small"
          icon={<LineChartOutlined />}
          style={{ height: 22, marginRight: 8 }}
          onClick={() => this.handleIncidentChartClick(rowData)}
        />
      </Popover>
    );
  }

  @autobind
  patternsJumpButton(rowData) {
    const { intl, credentials, projects } = this.props;
    const { owner } = rowData || {};
    let { projectName } = rowData || {};
    projectName = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    return (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={
          isAlert || isIncident
            ? intl.formatMessage(appMenusMessages.alertAnalysis)
            : intl.formatMessage(appMenusMessages.logAnalysis)
        }
      >
        <Button size="small" style={{ height: 22, marginRight: 8 }} onClick={() => this.handleJumpClick(rowData)}>
          <UnorderedListOutlined />
        </Button>
      </Popover>
    );
  }

  @autobind
  trendPatternsButton(rowData) {
    const { intl } = this.props;
    return (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={intl.formatMessage(eventMessages.patternTrend)}
      >
        <Button
          size="small"
          style={{ height: 22, marginRight: 8 }}
          onClick={() => this.handleTrendPatternsClick(rowData)}
        >
          <TrendIcon />
        </Button>
      </Popover>
    );
  }

  @autobind
  logQueryButton(rowData) {
    const { intl } = this.props;
    return (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={intl.formatMessage(appMenusMessages.logQuery)}
      >
        <Button
          type="primary"
          size="small"
          style={{ height: 22, marginRight: 8 }}
          onClick={() =>
            this.setState({
              showInsightQueryBox: true,
              rootCauseEvent: rowData,
            })
          }
        >
          <QeuryIcon />
        </Button>
      </Popover>
    );
  }

  @autobind
  lineChartButton(rowData) {
    const { intl } = this.props;
    return (
      <Popover
        overlayClassName="small-popover"
        placement="top"
        title={null}
        mouseEnterDelay={0.3}
        content={intl.formatMessage(DashboardMessages.lineChart)}
      >
        <Button
          size="small"
          icon={<LineChartOutlined />}
          style={{ height: 22, marginRight: 8 }}
          onClick={() => this.handleRootCauseChartClick(rowData)}
        />
      </Popover>
    );
  }

  @autobind
  logToMetricButton(rowData) {
    const { intl } = this.props;
    return (
      <Popover content={intl.formatMessage(eventMessages.logToMetricContext)} mouseEnterDelay={0.3} placement="top">
        <Button
          size="small"
          icon={<UnorderedListOutlined />}
          style={{ height: 22, marginRight: 8 }}
          onClick={() => {
            this.setState({ showLogToMetricModal: true, activeIncident: rowData });
          }}
        />
      </Popover>
    );
  }

  render() {
    const {
      intl,
      credentials,
      projectDisplayMap,
      summarySettingsMap = {},
      infoRender,
      relevanceRender,
      highlightTimestamp,
      highlightEndTimestamp,
      location,
      globalInfo,
      isJWT,
      currentTheme,
      projects,
      userInfo,
    } = this.props;
    const { timelines, activeKey, showInsightQueryBox, rootCauseEvent } = this.state;

    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    const logProjects = [];
    if (rootCauseEvent) {
      const { projectName, owner } = rootCauseEvent;
      const projectNameReal = owner !== credentials.userName ? `${projectName}@${owner}` : projectName;
      const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
      logProjects.push({
        projectNameReal,
        projectDisplayName,
      });
    }

    const eventList = this.eventList || [];
    return (
      <div className="flex-row full-height">
        <div
          style={{ width: 200, minWidth: 200, padding: 8, height: '100%', overflow: 'hidden auto' }}
          ref={(ref) => {
            this.currentTimeRef = ref;
          }}
        >
          <Timeline>
            {R.map((item) => {
              const { key, isRoot, timestamp, patternIds, event, timePairs = [], patternName } = item;
              const isActive = activeKey === key;
              const isHighlight = timestamp >= highlightTimestamp && timestamp <= highlightEndTimestamp;

              if (isRoot) {
                const { patternId, patternName } = event || {};
                return (
                  <Timeline.Item key={key} id={`time-${key}`}>
                    <div
                      className="flex-col"
                      style={{ paddingLeft: 4, cursor: 'pointer', color: isActive ? '#ff5142' : 'currentColor' }}
                      onClick={() => this.handleTimelineClick(item)}
                    >
                      <div className="flex-row flex-center-align font-14 bold">
                        <span
                          style={{
                            padding: '0 4px',
                            backgroundColor: isHighlight ? 'var(--item-active-bg3)' : 'initial', // root item also add highlight
                          }}
                        >
                          {moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}
                        </span>
                        {isActive && <CheckOutlined style={{ marginLeft: 2 }} />}
                      </div>
                      <div className="flex-row" style={{ paddingLeft: 4 }}>
                        <div className="light-label" style={{ marginRight: 8, flexShrink: 0 }}>
                          {intl.formatMessage(appFieldsMessages.pattern)}:
                        </div>
                        <div style={{ wordBreak: 'break-word' }}>{patternName || patternId}</div>
                      </div>
                    </div>
                  </Timeline.Item>
                );
              }
              return (
                <Timeline.Item
                  key={key}
                  color="green"
                  dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
                  id={`time-${key}`}
                >
                  {/* <Popover
                    placement="left"
                    title={`Historical causal relations: ${timePairs.length}`}
                    mouseEnterDelay={0.3}
                    content={
                      <div className="flex-col">
                        {(timePairs || []).length === 0 && (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: 0 }} />
                        )}
                        {(timePairs || []).length > 0 && (
                          <GlobalRenderers.TimePairs intl={intl} width={360} height={250} timePairs={timePairs || []} />
                        )}
                      </div>
                    }
                  > */}
                  <div
                    className="flex-col"
                    style={{ paddingLeft: 4, cursor: 'pointer', color: isActive ? '#ff5142' : 'currentColor' }}
                    onClick={() => this.handleTimelineClick(item)}
                  >
                    <div className="flex-row flex-center-align font-14 bold">
                      <span
                        style={{
                          padding: '0 4px',
                          backgroundColor: isHighlight ? 'var(--item-active-bg3)' : 'initial',
                        }}
                      >
                        {moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}
                      </span>
                      {isActive && <CheckOutlined style={{ marginLeft: 2 }} />}
                    </div>
                    <div className="flex-row" style={{ paddingLeft: 4 }}>
                      <div className="light-label" style={{ marginRight: 8, flexShrink: 0 }}>
                        {intl.formatMessage(appFieldsMessages.pattern)}:
                      </div>
                      <div style={{ wordBreak: 'break-word' }}>{patternName || R.join(', ', patternIds || [])}</div>
                    </div>
                  </div>
                  {/* </Popover> */}
                </Timeline.Item>
              );
            }, timelines)}
          </Timeline>
        </div>

        <div
          className="flex-grow overflow-y-auto flex-min-width"
          style={{ paddingLeft: 8 }}
          ref={(ref) => {
            this.currentRef = ref;
          }}
        >
          {R.addIndex(R.map)((rowData, index) => {
            const {
              isRoot,
              owner,
              projectOwner,
              instanceName,
              metricInstanceName,
              projectName,
              type,
              category,
              sourceDetail,
              displayTimestamp,
              key,
              isDeployment,
              componentName,
              logToMetricEvents,
              projectCouldType,
            } = rowData || {};

            const { isMetric, eventType } = sourceDetail || {};
            const projectNameReal =
              (owner || projectOwner) !== credentials.userName
                ? `${projectName}@${owner || projectOwner}`
                : projectName;
            const summarySettings = summarySettingsMap[projectNameReal] || [];
            const rowDataType = isRoot ? type : eventType;

            const {
              instanceDisplayName,
              instanceName: iName,
              containerName,
            } = getInstanceDisplayName(instanceDisplayNameMap, metricInstanceName || instanceName, {
              pn: projectName,
              owner: projectOwner,
            });
            const width = instanceDisplayName ? 140 : 85;

            return (
              <div
                key={index}
                style={{
                  ...(index !== 0
                    ? {
                        paddingTop: 12,
                        marginTop: 12,
                        borderTop: '2px dashed var(--virtualized-table-border-color)',
                      }
                    : {}),
                }}
                id={key}
              >
                {index === 0 && (
                  <div className="flex-row flex-center-align">
                    <div className="flex-grow" />
                    {infoRender()}
                    {relevanceRender()}
                  </div>
                )}
                <div className="flex-row flex-center-align">
                  <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(appFieldsMessages.time)}:
                  </span>
                  <span className="flex-grow hidden-line-with-ellipsis" style={{ marginRight: 8 }}>
                    <div>{moment.utc(displayTimestamp).format(Defaults.ShortDateTimeFormat)}</div>
                  </span>
                </div>
                <div className="flex-row flex-center-align">
                  <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(appFieldsMessages.type)}:
                  </span>
                  <span className="flex-grow hidden-line-with-ellipsis" style={{ marginRight: 8 }}>
                    <div>{CellRenderers.logTypeRenderer({ intl, rowData: { type: rowDataType } })}</div>
                  </span>
                </div>
                <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                  <span className="light-label bold" style={{ width: 120, display: 'inline-block', flexShrink: 0 }}>
                    {intl.formatMessage(appFieldsMessages.patternIdName)}:
                  </span>
                  <div className="flex-row flex-center-align flex-wrap flex-grow overflow-hidden">
                    <span className="hidden-line-with-ellipsis" style={{ marginRight: 8 }}>
                      {this.patternRenderer(rowData)}
                    </span>
                    {isRoot ? (
                      <>
                        {category !== 'metric' && !isJWT && this.patternsJumpButtonRoot(rowData)}
                        {category === 'metric' && !isJWT && this.lineChartButtonRoot(rowData)}
                        {projectCouldType === 'LogToMetric' && !isJWT && this.logToMetricButton(rowData)}
                      </>
                    ) : (
                      <>
                        {isDeployment && R.includes(type, ['Log']) && (
                          <>
                            {!isMetric && false && this.trendPatternsButton(rowData)}
                            {!isMetric && false && this.logQueryButton(rowData)}
                          </>
                        )}
                        {!isDeployment &&
                          R.includes(type, ['Log']) &&
                          !isJWT &&
                          this.handleLogContextOneMinClick(rowData)}
                        {!isMetric && !isJWT && this.patternsJumpButton(rowData)}
                        {isMetric && !isJWT && this.lineChartButton(rowData)}
                        {logToMetricEvents && logToMetricEvents.length > 0 && !isJWT && this.logToMetricButton(rowData)}
                      </>
                    )}
                  </div>
                </div>
                {containerName && (
                  <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                    <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                      {intl.formatMessage(appFieldsMessages.container)}:
                    </span>
                    <span className="flex-grow hidden-line-with-ellipsis">
                      <Popover content={containerName} mouseEnterDelay={0.3} placement="top">
                        <div className="hidden-line-with-ellipsis inline-block max-width">{containerName}</div>
                      </Popover>
                    </span>
                  </div>
                )}
                <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                  <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(logMessages.instance)}:
                  </span>
                  <span className="flex-grow hidden-line-with-ellipsis">
                    <Popover
                      content={
                        <div style={{ maxWidth: 400, maxHeight: 400, overflowY: 'auto' }}>
                          <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                            <div className="bold" style={{ minWidth: width }}>
                              {intl.formatMessage(appFieldsMessages.project)}:
                            </div>
                            {get(projectDisplayMap, projectNameReal, projectNameReal)}
                          </div>
                          <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                            <div className="bold" style={{ minWidth: width }}>
                              {intl.formatMessage(appFieldsMessages.component)}:
                            </div>
                            {componentName}
                          </div>
                          <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                            <div className="bold" style={{ minWidth: width }}>
                              {intl.formatMessage(appFieldsMessages.instance)}:
                            </div>
                            {iName}
                          </div>
                          {instanceDisplayName && (
                            <div className="flex-row" style={{ wordBreak: 'break-all' }}>
                              <div className="bold" style={{ minWidth: width }}>
                                {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                              </div>
                              {instanceDisplayName}
                            </div>
                          )}
                        </div>
                      }
                      mouseEnterDelay={0.3}
                      placement="top"
                    >
                      <div className="hidden-line-with-ellipsis inline-block max-width">
                        {instanceDisplayName || iName}
                      </div>
                    </Popover>
                  </span>
                </div>
                <div className="flex-row" style={{ marginTop: 8 }}>
                  <span className="light-label bold" style={{ width: 120, display: 'inline-block' }}>
                    {intl.formatMessage(eventMessages.fieldDetails)}:
                  </span>
                  <span className="flex-grow hidden-line-with-ellipsis">
                    {isRoot && this.renderIncidentDetails(rowData, summarySettings)}
                    {!isRoot && this.renderRootCauseDetails(rowData, summarySettings)}
                  </span>
                </div>
              </div>
            );
          }, eventList)}
        </div>

        {showInsightQueryBox && (
          <InsightQueryBoxModal
            projects={logProjects}
            onConfirm={this.onConfirmInsightQueryProjectSelect}
            onClose={() => this.setState({ showInsightQueryBox: false })}
          />
        )}

        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeIncident}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            keywordFilter=""
            onClose={() => this.setState({ showContextModal: false, activeIncident: null })}
            useTimeRange
          />
        )}
        {this.state.showLogToMetricModal && !this.state.activeIncident?.isRoot && (
          <LogToMetricContextRCA
            intl={intl}
            activeIncident={this.state.activeIncident}
            credentials={credentials}
            currentTheme={currentTheme}
            summarySettingsMap={summarySettingsMap}
            projects={projects}
            location={location}
            globalInfo={globalInfo}
            onClose={() => this.setState({ showLogToMetricModal: false, activeIncident: null })}
          />
        )}
        {this.state.showLogToMetricModal && this.state.activeIncident?.isRoot && (
          <LogToMetricContext
            intl={intl}
            rowData={this.state.activeIncident}
            credentials={credentials}
            isAdmin={userInfo?.isAdmin}
            userName={userInfo?.userName}
            currentTheme={currentTheme}
            summarySettingsMap={summarySettingsMap}
            projects={projects}
            location={location}
            globalInfo={globalInfo}
            onClose={() => this.setState({ showLogToMetricModal: false, activeIncident: null })}
          />
        )}
      </div>
    );
  }
}

const RootCauseTimelineRCAList = injectIntl(RootCauseTimelineRCAListCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { projects, projectDisplayMap, globalInfo } = state.app;
    const { userInfo } = state.auth;

    const { dark, jwtToken, customerName } = parseLocation(location);
    let credentials = {};
    if (jwtToken) {
      if (dark) {
        state.app.currentTheme = 'dark';
      } else {
        state.app.currentTheme = 'light';
      }
      credentials = { userName: customerName };
    } else {
      credentials = state.auth?.credentials;
    }
    const { currentTheme } = state.app;

    return {
      location,
      projects,
      projectDisplayMap,
      globalInfo,
      credentials,
      userInfo: userInfo || {},
      currentTheme,
    };
  },
  {
    push,
    replace,
    updateLastActionInfo,
  },
)(RootCauseTimelineRCAList);
