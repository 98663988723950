import React from 'react';
import * as R from 'ramda';
import dagre from 'dagre';
import { AlertOutlined, CodeOutlined, MailOutlined, StockOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { MarkerType } from '../../../lib/reactflow/core';
import { Regex } from '../../../common/utils';

export const connectionID = '-';
export const nodeWidth = 200;
export const nodeHeight = 110;
export const ranksep = 140;
export const nodesep = 40;

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};

export const getNodeTitle = (isRoot, title) => {
  if (title) {
    return title;
  } else if (isRoot) {
    return 'Select a trigger';
  } else {
    return 'Select an action';
  }
};

export const returnIcon = (id) => {
  let icon = <ThunderboltOutlined />;
  switch (id) {
    case 'patternTrigger':
      icon = <AlertOutlined />;
      break;
    case 'metricThreshold':
      icon = <StockOutlined />;
      break;
    case 'codeAction':
      icon = <CodeOutlined />;
      break;
    case 'emailAction':
      icon = <MailOutlined />;
      break;
    default:
      break;
  }
  return icon;
};

export const verifyContent = (nodeData) => {
  const { nodeType } = nodeData || {};
  const { pattern, frequency, dampening } = nodeData || {};
  const { durationThreshold, ruleList } = nodeData || {};
  const { email } = nodeData || {};
  const { codeName, codeContent } = nodeData || {};
  const { nameSpace, deployment, min, max } = nodeData || {};

  if (nodeType === 'patternTrigger') {
    const hasPatternError = !pattern;
    const hasFrequencyError = !frequency;
    const hasDampeningError = !dampening && dampening !== 0;
    const hasError = hasPatternError || hasFrequencyError || hasDampeningError;
    return { hasPatternError, hasFrequencyError, hasDampeningError, hasError };
  } else if (nodeType === 'metricThreshold') {
    const durationThresholdError = !durationThreshold;
    const ruleListError =
      ruleList.length > 0 &&
      R.reduce(
        R.and,
        true,
        R.addIndex(R.map)((item, idx) => {
          const nextRule = ruleList[idx + 1] ? item.logic : true;
          return item.project && item.metric && item.condition && (item.threshold || item.threshold === 0) && nextRule;
        }, ruleList),
      );
    const hasError = durationThresholdError || !ruleListError;
    return { durationThresholdError, ruleListError, hasError };
  } else if (nodeType === 'emailAction') {
    const hasError = !email || !Regex.email.test(email);
    return { hasError };
  } else if (nodeType === 'codeAction') {
    const codeNameError = !codeName;
    const codeContentError = !codeContent;
    const hasError = codeNameError || codeContentError;
    return { codeNameError, codeContentError, hasError };
  } else if (nodeType === 'kubernetesHPA') {
    const nameSpaceError = !nameSpace;
    const deploymentError = !deployment;
    const maxError = !max && max !== 0;
    const minError = !min && min !== 0;
    const maxAndMinError = max <= min;
    const hasError = nameSpaceError || deploymentError || maxError || minError || maxAndMinError;
    return { nameSpaceError, deploymentError, maxError, minError, maxAndMinError, hasError };
  } else if (nodeType === 'kubernetesRestart') {
    const nameSpaceError = !nameSpace;
    const deploymentError = !deployment;
    const hasError = nameSpaceError || deploymentError;
    return { nameSpaceError, deploymentError, hasError };
  } else if (nodeType === 'kubernetesJob') {
    const nameSpaceError = !nameSpace;
    const deploymentError = !deployment;
    const codeContentError = !codeContent;
    const hasError = nameSpaceError || deploymentError || codeContentError;
    return { nameSpaceError, deploymentError, codeContentError, hasError };
  }

  return { hasError: true };
};

export const nodeContent = (nodeData) => {
  const { nodeType, pattern, ruleList, email, codeName, nameSpace } = nodeData || {};
  if (nodeType === 'patternTrigger') {
    return pattern;
  } else if (nodeType === 'metricThreshold') {
    let content = '';
    R.addIndex(R.forEach)((rule, idx) => {
      const { metric, condition, threshold, logic } = rule || {};
      const nextRule = (ruleList || [])[idx];
      content += `${metric} ${condition} ${threshold} ${logic}${nextRule ? ' ' : ''}`;
    }, ruleList || []);
    return content;
  } else if (nodeType === 'emailAction') {
    return email;
  } else if (nodeType === 'codeAction') {
    return codeName;
  } else if (nodeType === 'kubernetesHPA') {
    return nameSpace;
  } else if (nodeType === 'kubernetesRestart') {
    return nameSpace;
  } else if (nodeType === 'kubernetesJob') {
    return nameSpace;
  }

  return '';
};

export const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const flowWidth = document.getElementById('react-flow-id').offsetWidth || 0;
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction, ranksep, nodesep });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2 + (flowWidth / 2 - nodeWidth / 2),
      y: nodeWithPosition.y - nodeHeight / 2 + nodesep,
    };

    return node;
  });

  return { nodes, edges };
};

export const updateEdgeData = (isDark, item, selected) => {
  const darkColor = selected ? '#ccc' : '#555';
  const lightColor = selected ? '#f1d9d6' : '#b1b1b7';
  return {
    ...item,
    zIndex: selected ? 99 : 1,
    selected,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: isDark ? darkColor : lightColor,
    },
  };
};
