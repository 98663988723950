/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */
import * as R from 'ramda';
import moment from 'moment';
import { get, isObject } from 'lodash';
import { notification } from 'antd';

import { ifIn, Defaults, parseJSON } from '.';
import { Options } from '../app';
import { metricUnitParser } from '../apis/magicParsers';

const getDirectionRelatedList = (relations, filterNodes, direction, deepStep) => {
  const newDeepStep = deepStep - 1;
  let relationList = [];
  let deepRelationList = [];

  let isRelationNodes = filterNodes;
  R.forEach((relation) => {
    let isFilter = false;
    if (direction === 'elem1') {
      if (ifIn(relation.elem2, filterNodes)) {
        isRelationNodes.push(relation.elem1);
        isFilter = true;
      }
    } else if (direction === 'elem2') {
      if (ifIn(relation.elem1, filterNodes)) {
        isRelationNodes.push(relation.elem2);
        isFilter = true;
      }
    }
    if (isFilter) {
      relationList.push(relation);
    }
  }, relations);
  const otherRelations = R.differenceWith(
    (relationX, relationY) => relationX.elem1 === relationY.elem1 && relationX.elem2 === relationY.elem2,
    relations,
    relationList,
  );
  isRelationNodes = R.uniq(isRelationNodes);
  if (newDeepStep > 0 && otherRelations.length !== 0 && relationList.length !== 0) {
    deepRelationList = getDirectionRelatedList(otherRelations, isRelationNodes, direction, newDeepStep);
  }
  relationList = [...relationList, ...deepRelationList];
  return relationList;
};

const timeSeg = (startT = 0, endT = 0, interval = 1) => {
  const starTime = moment.utc(startT);
  const endTime = moment.utc(endT);
  const diff = endTime.diff(starTime, 'minutes') + interval;
  const num = Math.ceil(diff / interval);
  const segs = [];
  for (let i = 1; i <= num; i++) {
    let timeFrom = starTime.clone().add((i - 1) * interval, 'minutes');
    const isAfter = timeFrom.isAfter(moment.utc(endT));
    if (isAfter) {
      timeFrom = moment.utc(endT);
    }
    segs.push({
      timeFrom: timeFrom.format('HH:mm'),
      sT: timeFrom.valueOf(),
    });
  }
  return segs;
};

const CausalParser = {
  getRelationLogType: (type, content) => {
    const typeString = type || '';

    let isLogType = false;
    let typeOnly = typeString;
    let eventType = typeString;
    let typeShort = '';
    const postFix = content ? content.nid : '';

    if (typeString.indexOf('LogRareEvent') === 0) {
      isLogType = true;
      typeOnly = 'LogRareEvent';
      eventType = 'Rare';
      typeShort = 'R';
    } else if (typeString.indexOf('LogHotEvent') === 0) {
      isLogType = true;
      typeOnly = 'LogHotEvent';
      eventType = 'Hot';
      typeShort = 'H';
    } else if (typeString.indexOf('LogColdEvent') === 0) {
      isLogType = true;
      typeOnly = 'LogColdEvent';
      eventType = 'Cold';
      typeShort = 'C';
    } else if (typeString.indexOf('LogCriticalEvent') === 0) {
      isLogType = true;
      typeOnly = 'LogCriticalEvent';
      eventType = 'Critical';
      typeShort = 'C';
    } else if (typeString.indexOf('LogNewPatternEvent') === 0) {
      isLogType = true;
      typeOnly = 'LogNewPatternEvent';
      eventType = 'NewPattern';
      typeShort = 'N';
    } else if (typeString.indexOf('LogWhiteListEvent') === 0) {
      isLogType = true;
      typeOnly = 'LogWhiteListEvent';
      eventType = 'WhiteList';
      typeShort = 'W';
    } else if (typeString.indexOf('LogNormalEvent') === 0) {
      isLogType = true;
      typeOnly = 'LogNormalEvent';
      eventType = 'Normal';
      typeShort = 'N';
    } else if (typeString.indexOf('Incident') === 0) {
      isLogType = true;
      typeOnly = 'Incident';
      eventType = 'Incident';
      typeShort = 'I';
    } else if (typeString.indexOf('Deployment') === 0) {
      isLogType = true;
      typeOnly = 'Deployment';
      eventType = 'Deployment';
      typeShort = 'C';
    } else if (typeString.indexOf('LogAlertEvent') === 0) {
      isLogType = true;
      typeOnly = 'LogAlertEvent';
      eventType = 'LogAlertEvent';
      typeShort = 'A';
    } else if (typeString.indexOf('Log') === 0) {
      isLogType = true;
      eventType = 'Log';
    }
    const nodeInfo = { typeOnly, isLogType, eventType, typeShort, postFix, contentInfo: content };
    return nodeInfo;
  },
  getKpiPrediction: (data) => {
    let kpiPrediction = {};
    let kpi = {};
    const kpiResultStr = get(data, ['kpiResult']);
    // change kpiPrediction data format
    if (isObject(kpiResultStr)) {
      kpiPrediction = get(data, ['kpiResult'], {});
      kpi = get(data, ['kpiSetting'], {});
    } else {
      let kpiResult = [];
      try {
        kpiResult = JSON.parse(kpiResultStr);
      } catch (err) {
        // console.error(err)
      }
      R.forEach((k) => {
        const { srcMetric, srcName, targetMetric, targetName, thresholdValue, attrribution, timePairList, result } = k;
        const iNames = `${srcName},${targetName}`;
        const mNames = `${srcMetric},${targetMetric}`;
        if (!R.has(iNames, kpiPrediction)) {
          kpiPrediction[iNames] = {};
        }
        kpiPrediction[iNames] = {
          ...kpiPrediction[iNames],
          [mNames]: {
            kpiAttrribution: attrribution,
            kpiData: result,
            thresholdValue,
            timePairList,
          },
        };
        kpi[targetMetric] = thresholdValue;
      }, kpiResult);
    }
    return { kpiPrediction, kpi };
  },
  getAllRelatedList: (relations, filterNodes, deepStep) => {
    const newDeepStep = deepStep - 1;
    let relationList = [];
    const relationSrcNodes = [];
    const relationTargetNodes = [];
    R.forEach((relation) => {
      let isFilter = false;
      if (ifIn(relation.elem1, filterNodes)) {
        relationTargetNodes.push(relation.elem2);
        isFilter = true;
      } else if (ifIn(relation.elem2, filterNodes)) {
        relationSrcNodes.push(relation.elem1);
        isFilter = true;
      }
      if (isFilter) {
        relationList.push(relation);
      }
    }, relations);
    const otherRelations = R.differenceWith(
      (relationX, relationY) => relationX.elem1 === relationY.elem1 && relationX.elem2 === relationY.elem2,
      relations,
      relationList,
    );
    const srcRelationList =
      newDeepStep > 0 ? getDirectionRelatedList(otherRelations, relationSrcNodes, 'elem1', newDeepStep) : [];
    const targetRelationList =
      newDeepStep > 0 ? getDirectionRelatedList(otherRelations, relationTargetNodes, 'elem2', newDeepStep) : [];
    relationList = [...relationList, ...srcRelationList, ...targetRelationList];

    return relationList;
  },
  trimString: (str, limit) => {
    if (str && limit) {
      let realLength = 0;
      const len = str.length;
      let charCode = -1;
      for (let i = 0; i < len; i += 1) {
        charCode = str.charCodeAt(i);
        if (charCode >= 0 && charCode <= 128) realLength += 1;
        else realLength += 2;

        if (realLength >= limit) {
          return `${str.substr(0, i)}...`;
        }
      }
      return str;
    }
    return str;
  },
  parseSvgSelector: (id) => {
    return R.replace(/[.()/[\]]/g, '-', R.replace(/ /g, '', id));
  },
  getColorByProbability: (probability) => {
    let color = '#ccc';
    if (probability >= 0.9) {
      color = Defaults.Colorbrewer[0];
    } else if (probability >= 0.7) {
      color = Defaults.Colorbrewer[1];
    } else if (probability >= 0.5) {
      color = Defaults.Colorbrewer[2];
    } else if (probability >= 0.3) {
      color = Defaults.Colorbrewer[3];
    }
    return color;
  },
  getWidthByCount: (count) => {
    let width = 2;
    if (count >= 500) {
      width = 4;
    } else if (count >= 100) {
      width = 3.5;
    } else if (count >= 50) {
      width = 3;
    } else if (count >= 10) {
      width = 2.5;
    }
    return width;
  },
  parseIncidentRelations: ({ operation, data, mapping, instanceName, withReverseData, ...rest }) => {
    let relations = data || [];
    const relationElemInfoMap = {};
    const maxProbabilityCountMap = {};

    const getTypeCategory = (type, isAlert) => {
      let category;
      if (isAlert) {
        category = 'alert';
      } else if (type === 'Metric') {
        category = 'metric';
      } else if (type === 'Incident') {
        category = 'incident';
      } else {
        category = 'log';
      }
      return category;
    };

    // parse relation:
    relations = R.map((relation) => {
      let { to: toContents, from: fromContents } = relation;
      const { timeDifference, labelObj, count, probability, src, target, elem1, elem2, probabilityCountChoice } =
        relation;
      if (labelObj) {
        fromContents = get(labelObj, 'from', []);
        toContents = get(labelObj, 'to', []);
      }
      const contentTimeDifference = R.map((item) => {
        const { s, t, tp, p: probability, rtsp, c } = item;
        let { d: delay } = item;
        delay *= 60000;

        let rtspTimes = [];
        R.forEach((rtspItem) => {
          const { s, srl, tt } = rtspItem;
          R.forEach((srlTime) => {
            const splitTimes = timeSeg(srlTime.s, srlTime.e, s);
            R.forEach((splitTime) => {
              const { sT } = splitTime;
              if (sT <= tt) {
                rtspTimes.push([sT, tt]);
              }
            }, splitTimes || []);
          }, srl || []);
        }, rtsp || []);
        rtspTimes = R.uniqBy((times) => times[0], rtspTimes);

        let timePair = tp || [];
        timePair = R.map((item) => [Number(item.s) * 1000, Number(item.e) * 1000], timePair);
        timePair = [...rtspTimes, ...timePair];
        timePair = R.sortBy(R.prop(0), timePair);

        const { type: fType, isAlert: fisAlert } = fromContents[s] || {};
        const { type: tType, isAlert: tisAlert } = toContents[t] || {};
        // build modality => alert > incident > log > metric
        const modality = R.join(
          '-',
          R.sort((a, b) => a.localeCompare(b), [getTypeCategory(fType, fisAlert), getTypeCategory(tType, tisAlert)]),
        );

        return {
          sP: s,
          tP: t,
          sc: fromContents[s],
          tc: toContents[t],
          timePair,
          probability,
          delay,
          modality,
          count: c,
        };
      }, timeDifference);

      let fromElem = src || elem1;
      let toElem = target || elem2;
      let elem1Info = {};
      let elem2Info = {};
      if (operation === 'intra') {
        // reset fromElem and toElem format from "LogRareEvent" => "LogRareEvent767"
        const fromContent = fromContents[0] || {};
        const toContent = toContents[0] || {};
        if (fromElem === fromContent.type) fromElem = `${fromContent.type}${fromContent.nid}`;
        if (toElem === toContent.type) toElem = `${toContent.type}${toContent.nid}`;

        elem1Info = CausalParser.getRelationLogType(fromElem, fromContent);
        elem2Info = CausalParser.getRelationLogType(toElem, toContent);
        if (contentTimeDifference.length > 0) {
          elem1Info.contentInfo.timeStamp = (contentTimeDifference[0].timePair[0] || [])[0];
          elem2Info.contentInfo.timeStamp = (contentTimeDifference[0].timePair[0] || [])[1];
        }
      }

      // get max count of probability
      const probabilityCountMap = parseJSON(probabilityCountChoice) || {};
      R.forEachObjIndexed((maxCount, probability) => {
        if (!R.has(probability, maxProbabilityCountMap)) {
          maxProbabilityCountMap[probability] = maxCount;
        } else {
          maxProbabilityCountMap[probability] = R.max(maxCount, maxProbabilityCountMap[probability]);
        }
      }, probabilityCountMap);

      // build all nids
      let nidList = R.map((item) => item.nid, [...fromContents, ...toContents]);
      nidList = R.uniq(nidList);

      return {
        contentTimeDifference,
        toContents,
        fromContents,
        count,
        probability,
        // change incident node content if from/to list have any incident relation
        elem1: fromElem,
        elem2: toElem,
        elem1Info,
        elem2Info,
        probabilityCountMap,
        nidList,
      };
    }, relations);

    // filter relations if elem1 === elem2
    relations = R.filter((relation) => relation.elem1 !== relation.elem2, relations);

    // merge same patternId for log anomaly
    if (operation === 'intra') {
      const mergeRelationMap = {};
      R.forEach((relation) => {
        const {
          contentTimeDifference,
          toContents,
          fromContents,
          count,
          probability,
          elem1,
          elem2,
          elem1Info,
          elem2Info,
          ...rest
        } = relation;
        const fromContent = fromContents[0] || {};
        const toContent = toContents[0] || {};
        const fromType = fromContent.type;
        const toType = toContent.type;
        const fromNid = fromContent.nid;
        const toNid = toContent.nid;
        const fromIsLog = fromType !== 'Metric';
        const toIsLog = toType !== 'Metric';

        // use key like `CPU-642` to merge relations
        const uniqKey = `${fromIsLog ? fromNid : elem1}-${toIsLog ? toNid : elem2}`;
        if (!R.has(uniqKey, mergeRelationMap)) {
          mergeRelationMap[uniqKey] = {
            ...relation,
            elem1: [elem1],
            elem2: [elem2],
          };
        } else {
          const lastRelation = mergeRelationMap[uniqKey];

          // build new relation info
          const newTimeDifference = [];
          const newFromContents = [];
          const newToContents = [];
          let index = 0;
          R.forEach((diff) => {
            const fContent = diff.sc || lastRelation.fromContents[diff.sP];
            const tContent = diff.tc || lastRelation.toContents[diff.tP];
            newFromContents.push(fContent);
            newToContents.push(tContent);
            newTimeDifference.push({
              sP: index,
              tP: index,
              timePair: diff.timePair,
              probability: diff.probability,
              delay: diff.delay,
              modality: diff.modality,
            });
            index += 1;
          }, lastRelation.contentTimeDifference);
          R.forEach((diff) => {
            const fContent = diff.sc || fromContents[diff.sP];
            const tContent = diff.tc || toContents[diff.tP];
            newFromContents.push(fContent);
            newToContents.push(tContent);
            newTimeDifference.push({
              sP: index,
              tP: index,
              timePair: diff.timePair,
              probability: diff.probability,
              delay: diff.delay,
              modality: diff.modality,
            });
            index += 1;
          }, contentTimeDifference);

          const newElem1 = fromIsLog ? [...lastRelation.elem1, elem1] : [elem1];
          const newElem2 = toIsLog ? [...lastRelation.elem2, elem2] : [elem2];
          const newElem1Info =
            fromIsLog && elem1Info.eventType !== lastRelation.elem1Info.eventType
              ? {
                  typeOnly: elem1Info.typeOnly,
                  isLogType: elem1Info.isLogType,
                  eventType: `${elem1Info.eventType}&${lastRelation.elem1Info.eventType}`,
                  typeShort: `${elem1Info.typeShort}&${lastRelation.elem1Info.typeShort}`,
                  postFix: elem1Info.postFix,
                  contentInfo: elem1Info.contentInfo,
                }
              : elem1Info;
          const newElem2Info =
            toIsLog && elem2Info.eventType !== lastRelation.elem2Info.eventType
              ? {
                  typeOnly: elem2Info.typeOnly,
                  isLogType: elem2Info.isLogType,
                  eventType: `${elem2Info.eventType}&${lastRelation.elem2Info.eventType}`,
                  typeShort: `${elem2Info.typeShort}&${lastRelation.elem2Info.typeShort}`,
                  postFix: elem2Info.postFix,
                  contentInfo: elem2Info.contentInfo,
                }
              : elem2Info;
          mergeRelationMap[uniqKey] = {
            ...rest,
            contentTimeDifference: newTimeDifference,
            toContents: newToContents,
            fromContents: newFromContents,
            count: R.max(count, lastRelation.count),
            probability: R.max(probability, lastRelation.probability),
            elem1: newElem1,
            elem2: newElem2,
            elem1Info: newElem1Info,
            elem2Info: newElem2Info,
          };
        }
      }, relations);

      // recreate relations
      relations = R.values(mergeRelationMap);
      relations = R.map((relation) => {
        return {
          ...relation,
          elem1: R.join('&', R.sortWith([R.ascend(R.toLower)], R.uniq(relation.elem1))),
          elem2: R.join('&', R.sortWith([R.ascend(R.toLower)], R.uniq(relation.elem2))),
        };
      }, relations);
    }

    // reduce relations info
    relations = R.map((relation) => {
      const { toContents, fromContents, elem1, elem2 } = relation;

      // get content info
      R.forEach((content) => {
        if (content.type === 'Metric') {
          if (operation === 'intra' && content.content.indexOf(instanceName) === 0) {
            content.content = content.content.substring(instanceName.length + 1);
          } else if (operation !== 'intra' && content.content.indexOf(elem1) === 0) {
            content.content = content.content.substring(elem1.length + 1);
          }
        } else if (content.type === 'Incident') {
          content.content = content.rawData || content.content;
        }
      }, fromContents || []);
      R.forEach((content) => {
        if (content.type === 'Metric') {
          if (operation === 'intra' && content.content.indexOf(instanceName) === 0) {
            content.content = content.content.substring(instanceName.length + 1);
          } else if (operation !== 'intra' && content.content.indexOf(elem2) === 0) {
            content.content = content.content.substring(elem2.length + 1);
          }
        } else if (content.type === 'Incident') {
          content.content = content.rawData || content.content;
        }
      }, toContents || []);

      return {
        ...relation,
      };
    }, relations);

    // build node info map
    relations = R.forEach((relation) => {
      const { elem1, elem2, elem1Info, elem2Info } = relation;
      if (!R.has(elem1, relationElemInfoMap)) relationElemInfoMap[elem1] = elem1Info;
      if (!R.has(elem2, relationElemInfoMap)) relationElemInfoMap[elem2] = elem2Info;
    }, relations);

    // Get the metric name to metric short name and unit mapping.
    const metricUnitMap = {};
    const metricShortNameMap = {};
    R.forEach((m) => {
      const { unit, shortName, metric } = m;
      metricUnitMap[metric] = metricUnitParser(unit);
      metricShortNameMap[metric] = shortName;
    }, mapping || []);

    // all relations
    const allRelations = [...relations];

    // add reversed relations for correlation graph
    if (withReverseData) {
      R.forEach((relation) => {
        const { contentTimeDifference, elem1, elem1Info, elem2, elem2Info, fromContents, toContents, ...rest } =
          relation;
        const newContentTimeDifference = R.map((td) => {
          return { ...td, sP: td.tP, tP: td.sP, timePair: R.map((tr) => [tr[1], tr[0]], td.timePair || []) };
        }, contentTimeDifference || []);
        allRelations.push({
          ...rest,
          isReverseRelation: true,
          elem1: elem2,
          elem1Info: elem2Info,
          elem2: elem1,
          elem2Info: elem1Info,
          fromContents: toContents,
          toContents: fromContents,
          contentTimeDifference: newContentTimeDifference,
        });
      }, relations);
    }

    return {
      relation: allRelations,
      relationElemInfoMap,
      maxProbabilityCountMap,
      metricUnitMap,
      metricShortNameMap,
      ...rest,
    };
  },
  mergeIncidentRelations: (incident, incidentNew) => {
    const relation = R.concat(incident.relation, incidentNew.relation);
    const relationElemInfoMap = R.merge(incident.relationElemInfoMap, incidentNew.relationElemInfoMap);
    const maxProbabilityCountMap = R.mergeWith(
      R.max,
      incident.maxProbabilityCountMap,
      incidentNew.maxProbabilityCountMap,
    );
    return { ...incident, relation, relationElemInfoMap, maxProbabilityCountMap };
  },
  getRootnodeDuration: (node, minDuration, maxDuration, fixedCountAndProb, hasInstance) => {
    // get node duration options and default value
    let relationDurationOptions = [];
    let lastDuration = null;
    R.forEach((item) => {
      const duration = Number(item.value) * 60 * 1000;
      if ((duration >= minDuration && duration <= maxDuration) || fixedCountAndProb) {
        relationDurationOptions.push(item);
      } else if (lastDuration && maxDuration > lastDuration && maxDuration < duration) {
        relationDurationOptions.push(item);
      }
      lastDuration = duration;
    }, Options.RelationTimeThresholdOptions);

    // set 5.0 by default
    if (relationDurationOptions.length === 0) {
      relationDurationOptions = R.slice(0, 1, Options.RelationTimeThresholdOptions);
    }

    // alert for zero maxDuration
    if (maxDuration === 0) {
      notification.info({
        message: 'Notification',
        description: hasInstance
          ? `Maximum intra-instance causal duration of ${node} is 0.`
          : `Maximum inter-instance causal duration of ${node} is 0.`,
        duration: 3,
      });
    }

    return { relationDurationOptions };
  },
};

export default CausalParser;
