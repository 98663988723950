import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AutoComplete } from 'antd';

import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

import { State } from '../../../common/types';
import { CodeMirror } from '../../../lib/fui/react';
import { verifyContent } from '../data/utils';

import ActiveTriggerHeader from './ActiveTriggerHeader';
import DealActiveButton from './DealActiveButton';

import { eventActionMessages } from '../../../common/metric/messages';

function CodeActionCore(props: Object) {
  const { intl, isDark } = props || {};
  const { activeWorkNode } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};
  const { nodeData = {} } = activeWorkNode?.data || {};
  const { codeName, codeContent } = nodeData;

  const { codeNameError, codeContentError, hasError } = verifyContent(nodeData);

  return (
    <>
      <ActiveTriggerHeader editInput />
      <div className="flex-grow" style={{ overflowY: 'scroll', paddingRight: 14 }}>
        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span style={{ fontSize: 12, marginBottom: 4 }}>
            {intl.formatMessage(eventActionMessages.actionName)}
            <span style={{ color: 'red' }}>*</span>
          </span>
          <AutoComplete
            allowClear
            size="small"
            value={codeName}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf((codeName || '').toUpperCase()) !== -1
            }
            onChange={(value) =>
              contextSetState({
                activeWorkNode: {
                  ...activeWorkNode,
                  data: { ...activeWorkNode.data, nodeData: { ...nodeData, codeName: value } },
                },
              })
            }
            options={[
              { value: '1', label: 'codeName 1' },
              { value: '2', label: 'codeName 2' },
              { value: '3', label: 'codeName 3' },
              { value: '4', label: 'codeName 4' },
            ]}
            className={`${codeNameError ? 'jsonKeyNoneError' : ''}`}
          />
        </div>
        <div className="flex-col" style={{ paddingTop: 8 }}>
          <span style={{ fontSize: 12, marginBottom: 4 }}>
            Code<span style={{ color: 'red' }}>*</span>
          </span>
          <div
            className="corner-10 overflow-hidden"
            style={{
              height: 400,
              border: `1px solid ${codeContentError ? '#ff7c6b' : 'var(--virtualized-table-border-color)'}`,
            }}
          >
            <CodeMirror
              className="codeMirror_auto"
              options={{
                lineNumbers: true,
                foldGutter: true,
                gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
                theme: isDark ? 'material' : 'xq-light',
              }}
              value={codeContent}
              onBeforeChange={(editor, data, value) =>
                contextSetState({
                  activeWorkNode: {
                    ...activeWorkNode,
                    data: { ...activeWorkNode.data, nodeData: { ...nodeData, codeContent: value } },
                  },
                })
              }
            />
          </div>
        </div>
      </div>
      <DealActiveButton hasError={hasError} />
    </>
  );
}

const CodeAction = injectIntl(CodeActionCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(CodeAction);
