import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Dropdown, Menu } from 'antd';
import {
  BorderVerticleOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  MoreOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

import { State } from '../../../common/types';
import { verifyContent } from '../data/utils';

import EditInput from './EditInput';
import FlowGraphics from './FlowGraphics';

import { settingsMessages } from '../../../common/settings/messages';
import { appButtonsMessages } from '../../../common/app/messages';

function FlowContentCore(props: Object) {
  const { intl } = props || {};
  const { workFlowInfo, workNodes } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};

  const hasError = useMemo(() => {
    let error = false;
    R.forEach((node) => {
      if (node?.data?.nodeData) {
        error = error || verifyContent(node?.data?.nodeData)?.hasError;
      } else {
        error = error || true;
      }
    }, workNodes || []);
    return error;
  }, [workNodes]);

  const menuList = useMemo(() => {
    return (
      <Menu>
        <Menu.Item key="delete" icon={<DeleteOutlined />}>
          {intl.formatMessage(settingsMessages.deleteWorkflow)}
        </Menu.Item>
      </Menu>
    );
  }, []);

  return (
    <div className="full-height flex-col">
      <div
        className="flex-row flex-center-align flex-space-between"
        style={{
          height: 40,
          borderBottom: '1px solid var(--virtualized-table-border-color)',
          marginBottom: 8,
          paddingBottom: 8,
        }}
      >
        <div className="flex-grow overflow-hidden" style={{ marginRight: 8 }}>
          <EditInput
            workFlowInfo={workFlowInfo}
            textStyle={{ fontSize: 24 }}
            inputStyle={{ height: 38, fontSize: 24 }}
            handleChange={(value) => contextSetState({ workFlowInfo: { ...workFlowInfo, title: value } })}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            size="small"
            type="primary"
            icon={<SaveOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => console.log(111)}
            disabled={hasError}
          >
            {intl.formatMessage(appButtonsMessages.save)}
          </Button>
          <Button size="small" icon={<CaretRightOutlined />} style={{ marginRight: 8 }} disabled={hasError}>
            {intl.formatMessage(settingsMessages.run)}
          </Button>
          <Button size="small" icon={<BorderVerticleOutlined />} style={{ marginRight: 8 }} disabled={hasError}>
            Test
          </Button>

          <Dropdown trigger="click" overlay={menuList}>
            <Button
              size="small"
              style={{ border: 'none' }}
              icon={<MoreOutlined style={{ fontSize: 16, fontWeight: 'bold' }} />}
            />
          </Dropdown>
        </div>
      </div>
      <FlowGraphics />
    </div>
  );
}

const FlowContent = injectIntl(FlowContentCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(FlowContent);
