/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import moment from 'moment';
import * as R from 'ramda';
import { get, isArray, isObject } from 'lodash';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';
import { parseJSON } from '../utils';

const MAX_FATCH_NUM = 5;

const getInfoGlobalV2 = (credentials: Credentials, params: Object) => {
  const {
    startTime,
    endTime,
    customerName,
    userName,
    systemsMap,
    projects,
    needsGlobalInfo,
    ownSystemArr = [],
    shareSystemArr = [],
    allProjects,
    userInfo,
  } = params;

  if (!ownSystemArr.length && !shareSystemArr.length) {
    return Promise.resolve({ globalInfo: [], rawEnvironments: {}, rawSystems: [], favorites: [] });
  }
  const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
  const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
  const requests = [
    Promise.resolve({ ownSystemArr, shareSystemArr }),
    fetchGet(getEndpoint('order'), {
      ...credentials,
      targetUser: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : undefined,
    })
      .then((res) => {
        const { success, message: msg, order } = res;
        if (success || success === undefined) {
          let orederObj = {};
          try {
            orederObj = JSON.parse(order);
          } catch (e) {
            // error
          }
          let { healthChartFavoriteOrder } = orederObj;
          if (healthChartFavoriteOrder) {
            try {
              healthChartFavoriteOrder = JSON.parse(healthChartFavoriteOrder);
            } catch (e) {
              // error
            }
            const { DATA } = healthChartFavoriteOrder;
            return DATA;
          } else {
            return [];
          }
        } else {
          return [];
        }
      })
      .catch((e) => {
        return [];
      }),
  ];

  const systemKeyList = [];
  R.forEach(
    (s) => {
      systemKeyList.push({ id: s?.systemKey?.systemName, customerName: s?.systemKey?.userName });
    },
    [...ownSystemArr, ...shareSystemArr],
  );

  if (needsGlobalInfo) {
    const splitEveryTakes = R.splitEvery(MAX_FATCH_NUM, systemKeyList || []);
    R.forEach((group) => {
      if (group.length > 0) {
        requests.push(
          fetchGet(getEndpoint('system-basic-info', 1), {
            ...credentials,
            startTime: startTimestamp,
            endTime: endTimestamp,
            systemKeyList: JSON.stringify(group),
            customerName,
          }),
        );
      }
    }, splitEveryTakes || []);
  }

  return Promise.all(requests).then((results) => {
    const { ownSystemArr, shareSystemArr } = results[0];
    const favoriteData = results[1];

    let data = {};
    if (needsGlobalInfo) {
      const basicInfos = R.slice(2, Infinity, results);
      R.forEach((item) => {
        data = { ...data, ...item };
      }, basicInfos || []);
    }

    const favoriteList = [];
    R.addIndex(R.forEach)((item, index) => {
      let { l } = item;
      try {
        l = JSON.parse(l);
      } catch (e) {
        l = {};
      }
      favoriteList.push(l.systemName);
    }, favoriteData);

    const allSystems = [];

    const parseSystem = (sys) => {
      allSystems.push(parseJSON(sys || '{}'));
    };
    R.forEach(parseSystem, ownSystemArr);
    R.forEach(parseSystem, shareSystemArr);

    const systemInfoMap = {};
    const allTabInfoDailyMap = {};
    let allTabCounterMap = {};
    R.forEachObjIndexed((val, key) => {
      const systemKey = parseJSON(key) || {};
      const { id } = systemKey || {};
      let hasData = false;
      let hasCPSetting = false;
      const tabCounter = {};
      let hasCost = false;
      const tabDailyMap = {};
      let zoneSet = [];
      R.forEach((b) => {
        const j = parseJSON(b?.basicInfo) || {};
        if (!hasData && j.hasData) {
          hasData = true;
        }
        hasCPSetting = hasCPSetting || j.hasCPSetting;
        hasCost = hasCost || j.hasCost;

        const zoneSetJson = parseJSON(j?.zoneSet);
        if (isArray(zoneSetJson)) {
          zoneSet = zoneSetJson;
        } else if (isObject(zoneSetJson)) {
          zoneSet = R.keys(zoneSetJson);
        }
        tabDailyMap[b.dailyTimestamp] = parseJSON(j?.tabCounterSet || '[]');
        const tabCounterSet = parseJSON(j?.tabCounterSet || '[]');
        R.forEach((item) => {
          const { tabName } = item;
          if (!R.has(tabName, tabCounter)) {
            tabCounter[tabName] = item;
          } else {
            const {
              componentLevelCountSet = [],
              instanceLevelCountSet = [],
              projectLevelCountSet = [],
              systemLevelCount = {},
            } = tabCounter[tabName];

            tabCounter[tabName].componentLevelCountSet = R.concat(
              componentLevelCountSet,
              item.componentLevelCountSet || [],
            );
            tabCounter[tabName].instanceLevelCountSet = R.concat(
              instanceLevelCountSet,
              item.instanceLevelCountSet || [],
            );
            tabCounter[tabName].projectLevelCountSet = R.concat(projectLevelCountSet, item.projectLevelCountSet || []);
            tabCounter[tabName].systemLevelCount = {
              ...tabCounter[tabName].systemLevelCount,
              u: systemLevelCount.u + item.systemLevelCount.u,
              t: systemLevelCount.t + item.systemLevelCount.t,
            };
          }
        }, tabCounterSet);
        hasCost = hasCost || j.hasCost;
      }, val);
      if (id) {
        systemInfoMap[id] = { showInGV: true, hasData, hasCPSetting, hasCost, zoneSet };
        allTabCounterMap[id] = tabCounter;
        allTabInfoDailyMap[id] = tabDailyMap;
      }
    }, data);

    allTabCounterMap = R.mapObjIndexed((val, key) => {
      return R.mapObjIndexed((tabItem, key) => {
        const { componentLevelCountSet, instanceLevelCountSet, projectLevelCountSet, systemLevelCount } = tabItem || {};
        const componentMap = {};
        const instanceMap = {};
        const projectMap = {};
        R.forEach((item) => {
          const { c, u, t } = item;
          if (!R.has(c, componentMap)) {
            componentMap[c] = { u, t };
          } else {
            componentMap[c].u += u;
            componentMap[c].t += t;
          }
        }, componentLevelCountSet || []);

        R.forEach((item) => {
          const { i, c, u, t } = item;
          if (!R.has(i, instanceMap)) {
            instanceMap[i] = { c: c || [], u, t };
          } else {
            instanceMap[i].c = R.uniq([...instanceMap[i].c, ...(c || [])]);
            instanceMap[i].u += u;
            instanceMap[i].t += t;
          }
        }, instanceLevelCountSet || []);

        R.forEach((item) => {
          const { p, u, t } = item;
          if (!R.has(p, projectMap)) {
            projectMap[p] = { u, t };
          } else {
            projectMap[p].u += u;
            projectMap[p].t += t;
          }
        }, projectLevelCountSet || []);

        const newComponentLevelCountSet = [];
        let newInstanceLevelCountSet = [];
        const newProjectLevelCountSet = [];
        const pods = [];
        const containers = [];
        R.forEachObjIndexed((comItem, comKey) => {
          newComponentLevelCountSet.push({ c: comKey, ...comItem });
        }, componentMap);
        R.forEachObjIndexed((insItem, insKey) => {
          const isContainer = R.includes('_', insKey);
          if (isContainer) {
            const [c, i] = R.split('_', insKey);
            const logicPodID = instanceMap[i] || { c: [], u: 0, t: 0 };
            const containerAndPod = instanceMap[insKey] || { c: [], u: 0, t: 0 };
            newInstanceLevelCountSet.push({ i, ...logicPodID });
            pods.push({ p: i, ...logicPodID });
            containers.push({ ct: c, containerAndPod: insKey, ...containerAndPod });
          } else {
            newInstanceLevelCountSet.push({ i: insKey, ...insItem });
          }
        }, instanceMap);
        newInstanceLevelCountSet = R.uniqBy((item) => item.i, newInstanceLevelCountSet);
        R.forEachObjIndexed((proItem, proKey) => {
          const findProject = R.find((item) => item.projectShortName === proKey, projects || []) || {};
          newProjectLevelCountSet.push({
            p: proKey,
            ...proItem,
            customerName: findProject?.owner,
            projectType: findProject?.projectTypeRaw,
          });
        }, projectMap);
        return {
          systemLevelCount,
          tabName: key,
          componentLevelCountSet: newComponentLevelCountSet,
          instanceLevelCountSet: newInstanceLevelCountSet,
          projectLevelCountSet: newProjectLevelCountSet,
          containers,
          pods,
        };
      }, val || {});
    }, allTabCounterMap || {});

    const allEnvironmentMap = {};
    const systems = {};

    R.forEach((s) => {
      const { environmentName, systemName, userName } = s?.systemKey || {};
      if (environmentName && systemName) {
        allEnvironmentMap[environmentName] = {
          id: environmentName,
          name: environmentName,
          ownerUserName: userName,
          systemIds: [...(allEnvironmentMap[environmentName]?.systemIds || []), systemName],
        };

        systems[systemName] = {
          ...s,
          hasCPSetting: systemInfoMap[systemName] ? systemInfoMap[systemName].hasCPSetting : false,
          hasCost: systemInfoMap[systemName] ? systemInfoMap[systemName].hasCost : false,
          id: systemName,
          name: get(systemsMap, [systemName, 'systemName'], systemName),
          environmentName,
          ownerUserName: userName,
          systemInfo: systemInfoMap[systemName]
            ? { owner: userName, ...systemInfoMap[systemName] }
            : { owner: userName, hasData: true },
        };
      }
    }, allSystems);
    const rawSystems = systems;

    let globalInfo = [];
    R.forEachObjIndexed((environment) => {
      const eid = environment.id;
      const { ownerUserName } = environment;
      let systemIds = R.uniq(environment.systemIds || []);
      let systemList = [];

      R.forEach((sid) => {
        const system = systems[sid];
        if (!system) {
          console.warn(`[IF_API] system "${sid}" in "${eid}" not found in systems`);
        } else {
          let projectNameSet = parseJSON(system.projectDetailsList || '[]') || [];
          projectNameSet = R.map((project) => {
            const projectNameReal =
              project.userName !== userName ? `${project.projectName}@${project.userName}` : project.projectName;
            const projectInfo = R.find((item) => item.projectName === projectNameReal, projects || []);
            const allProjectInfo = R.find((item) => item.projectName === projectNameReal, allProjects || []);
            const projectDisplayName = projectInfo ? projectInfo.projectDisplayName : projectNameReal;
            return {
              ...project,
              projectNameReal,
              projectDisplayName,
              ...(allProjectInfo ? { ps: allProjectInfo.ps } : {}),
            };
          }, projectNameSet);
          projectNameSet = R.sortWith([R.ascend(R.prop('projectName'))], projectNameSet);
          const hasMetricProject = Boolean(R.find((item) => item.dataType === 'Metric', projectNameSet));
          const hasIncidentProject = Boolean(R.find((item) => item.dataType === 'Incident', projectNameSet));
          const hasAlertProject = Boolean(R.find((item) => item.dataType === 'Alert', projectNameSet));
          const hasDeploymentProject = Boolean(R.find((item) => item.dataType === 'Deployment', projectNameSet));

          // get has data info anf status
          const { hasData } = system.systemInfo || {};

          systemList.push({
            ...system,
            hasAllInfo: false,
            hasAllInstanceInfo: false,
            level: 'system',
            id: sid,
            parentLocalId: eid,
            localId: `${eid}#${sid}`,
            environmentId: eid,
            projectNameSet,
            hasMetricProject,
            hasAlertProject,
            hasIncidentProject,
            hasDeploymentProject,
            hasData,
            tabCounter: allTabCounterMap[sid] || {},
            allTabInfoDailyMap: allTabInfoDailyMap[sid] || {},
          });
        }
      }, systemIds);

      systemIds = R.sort((systemIdA, systemIdB) => systemIdA.localeCompare(systemIdB), systemIds);
      systemList = R.sort((systemA, systemB) => systemA.name.localeCompare(systemB.name), systemList);
      let favorites = R.filter((item) => favoriteList.includes(item.id), systemList);
      let restSystems = R.filter((item) => !favoriteList.includes(item.id), systemList);
      favorites = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(favorites);
      restSystems = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])(restSystems);
      systemList = [...favorites, ...restSystems];

      const localIdReducer = (acc, elem) => {
        acc[elem.localId] = elem;
        return acc;
      };
      const allSystemLocalMap = R.reduce(localIdReducer, {}, systemList);

      globalInfo.push({
        ...environment,
        level: 'environment',
        id: eid,
        localId: eid,
        ownerUserName,
        name: environment.name || eid,
        systemList,
        systemIds: systemList.length === 0 ? systemIds : R.map((system) => system.id, systemList),
        allSystemLocalMap,
      });
    }, allEnvironmentMap);
    globalInfo = R.sort((a, b) => a.name.localeCompare(b.name), globalInfo);

    return { globalInfo, rawEnvironments: allEnvironmentMap, rawSystems, favorites: favoriteList };
  });
};

export default getInfoGlobalV2;
