/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import { autobind } from 'core-decorators';
import * as R from 'ramda';
import { forEach, get, isArray, isString } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Select, Menu, Input } from 'antd';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { BaseUrls } from '../../app/Constants';
import { createLoadAction } from '../../../common/app/actions';
import { Defaults, getLoadStatus, CausalParser, CausalRenderers, buildUrl, CellRenderers } from '../../../common/utils';
import { State } from '../../../common/types';
import {
  Modal,
  Table,
  Column,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Container,
  SortDirection,
  Dropdown,
  Popover,
} from '../../../lib/fui/react';
import { ActionTypes } from '../../../common/causal/actions';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import { causalMessages } from '../../../common/causal/messages';
import { logMessages } from '../../../common/log/messages';
import { eventMessages } from '../../../common/metric/messages';
import { convertModality, modalityMatching } from './CausalRelationTree2';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  isIntra: Boolean,
  intraInstanceName: String,
  edgeRelation: Object,
  leftLabel: String,
  rightLabel: String,
  incidentParams: Object,
  // eslint-disable-next-line
  relationProbability: String,
  filterModality: String,
  filterPattern: String,
  filterMetric: String,
  instanceCausalInfo: Object,
  instanceIdCausalInfoMap: Object,
  // eslint-disable-next-line
  instanceMapping: Object,
  onClose: Function,
  isCorrelation: Boolean,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  projects: Array<Object>,
  projectDisplayMap: Object,
  credentials: Object,
  isAdmin: Boolean,
  causalLogEvents: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  currentTheme: String,
  metricUnitMap: Object,
  instanceDisplayNameMap: Object,
  getComponentProjectInstanceName: Function,
};

class CausalRelationsModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'causal_relation_modal_loader';
    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 80 });
    this.contentWidth = 200;

    const { intl, filterModality, filterPattern, filterMetric } = props;
    this.eventRelationList = [];
    this.eventMetricList = [];
    this.state = {
      sortBy: null,
      sortDirection: null,

      filterModality: filterModality || 'all',
      filterPattern,
      filterMetric,
      showContextModal: false,
    };

    this.modalityFilterOptions = [
      { label: 'All', value: 'all' },
      { label: intl.formatMessage(causalMessages.metricToMetric, { flag: '<->' }), value: 'metric-metric' },
      { label: intl.formatMessage(causalMessages.logToLog, { flag: '<->' }), value: 'log-log' },
      { label: intl.formatMessage(causalMessages.metricToLog, { flag: '<->' }), value: 'log-metric' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalMetric)} -> ${intl.formatMessage(
          causalMessages.causalLog,
        )}`,
        value: 'metric->log',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalLog)} -> ${intl.formatMessage(
          causalMessages.causalMetric,
        )}`,
        value: 'log->metric',
      },
      { label: intl.formatMessage(causalMessages.metricToIncident, { flag: '<->' }), value: 'incident-metric' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalIncident)} -> ${intl.formatMessage(
          causalMessages.causalMetric,
        )}`,
        value: 'incident->metric',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalMetric)} -> ${intl.formatMessage(
          causalMessages.causalIncident,
        )}`,
        value: 'metric->incident',
      },
      { label: intl.formatMessage(causalMessages.logToIncident, { flag: '<->' }), value: 'incident-log' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalIncident)} -> ${intl.formatMessage(
          causalMessages.causalLog,
        )}`,
        value: 'incident->log',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalLog)} -> ${intl.formatMessage(
          causalMessages.causalIncident,
        )}`,
        value: 'log->incident',
      },
      { label: intl.formatMessage(causalMessages.metricToAlert, { flag: '<->' }), value: 'alert-metric' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalAlert)} -> ${intl.formatMessage(
          causalMessages.causalMetric,
        )}`,
        value: 'alert->metric',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalMetric)} -> ${intl.formatMessage(
          causalMessages.causalAlert,
        )}`,
        value: 'metric->alert',
      },
      { label: intl.formatMessage(causalMessages.logToAlert, { flag: '<->' }), value: 'alert-log' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalAlert)} -> ${intl.formatMessage(causalMessages.causalLog)}`,
        value: 'alert->log',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalLog)} -> ${intl.formatMessage(causalMessages.causalAlert)}`,
        value: 'log->alert',
      },
      { label: intl.formatMessage(causalMessages.alertToIncident, { flag: '<->' }), value: 'alert-incident' },
      {
        label: `  ${intl.formatMessage(causalMessages.causalIncident)} -> ${intl.formatMessage(
          causalMessages.causalAlert,
        )}`,
        value: 'incident->alert',
      },
      {
        label: `  ${intl.formatMessage(causalMessages.causalAlert)} -> ${intl.formatMessage(
          causalMessages.causalIncident,
        )}`,
        value: 'alert->incident',
      },
    ];
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filterModality !== this.props.filterModality) {
      this.reloadData(nextProps);
    }
    if (
      nextProps.edgeRelation !== this.props.edgeRelation ||
      nextProps.causalLogEvents !== this.props.causalLogEvents
    ) {
      this.preprocessData(nextProps, this.state);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection, filterPattern, filterMetric, keyword } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        if (sortDirection === SortDirection.DESC) {
          this.eventRelationList = R.sortWith([R.descend(R.prop(sortBy))])(this.eventRelationList);
        } else {
          this.eventRelationList = R.sortWith([R.ascend(R.prop(sortBy))])(this.eventRelationList);
        }
        this.cellMeasureCache.clearAll();
        if (this.dataTable) {
          this.dataTable.forceUpdate();
        }
      }
    } else if (
      nextState.filterPattern !== filterPattern ||
      nextState.filterMetric !== filterMetric ||
      nextState.keyword !== keyword
    ) {
      this.preprocessData(nextProps, nextState);
    }
  }

  @autobind
  reloadData(props) {
    const { createLoadAction, incidentParams, intraInstanceName } = props;

    const customerName = get(incidentParams, 'customerName');
    const edgeRelation = props.edgeRelation || {};
    const contentTimeDifference = edgeRelation.contentTimeDifference || [];
    const fromContents = edgeRelation.fromContents || [];
    const toContents = edgeRelation.toContents || [];

    let logEventQueryStr = [];
    const maxCount = 20;
    const sourceNidCounts = {};
    const targetNidCounts = {};

    R.forEach((diff) => {
      const fromTimestampList = R.map((item) => item[0], diff.timePair || []);
      const toTimestampList = R.map((item) => item[1], diff.timePair || []);
      const fromContent =
        diff.sc || R.find((item) => item.sP === diff.sP, fromContents || []) || fromContents[diff.srcPosition];
      const toContent =
        diff.tc || R.find((item) => item.tP === diff.tP, toContents || []) || toContents[diff.targetPosition];
      const { nid: snid, type: stype } = fromContent || {};
      const { nid: tnid, type: ttype } = toContent || {};
      const { typeOnly: stypeOnly } = CausalParser.getRelationLogType(stype);
      const { typeOnly: ttypeOnly } = CausalParser.getRelationLogType(ttype);

      if (fromContent && stype && !fromContent.instanceDown && stype !== 'Metric') {
        const logProjectName = fromContent.projectName;
        const logInstanceNameList = [];
        const involvedInstanceSet = fromContent?.involvedInstanceSet || [];
        if (involvedInstanceSet.length > 0) {
          R.forEach((iid) => {
            const logInstanceName = this.props.getComponentProjectInstanceName(iid, logProjectName);
            if (logInstanceName) {
              logInstanceNameList.push(logInstanceName);
            }
          }, involvedInstanceSet);
        } else {
          logInstanceNameList.push(fromContent?.instanceNameInThisProject);
        }
        R.forEach((logInstanceName) => {
          R.forEach((timestamp) => {
            const id = `${logProjectName}-${logInstanceName}-${snid}-${stypeOnly}-${timestamp}`;
            if (!R.find((item) => item.id === id, logEventQueryStr)) {
              const key = `${logProjectName}-${logInstanceName}-${String(snid)}-${stype}`;
              if (!sourceNidCounts[key] || sourceNidCounts[key] < maxCount) {
                logEventQueryStr.push({
                  id,
                  logProjectName,
                  logInstanceName,
                  nid: String(snid),
                  timestamp,
                  type: stype,
                });
                sourceNidCounts[key] = (sourceNidCounts[key] || 0) + 1;
              }
            }
          }, fromTimestampList);
        }, logInstanceNameList);
      }

      if (toContent && ttype && !toContent.instanceDown && ttype !== 'Metric') {
        const logProjectName = toContent?.projectName;
        const logInstanceNameList = [];
        const involvedInstanceSet = toContent?.involvedInstanceSet || [];
        if (involvedInstanceSet.length > 0) {
          R.forEach((iid) => {
            const logInstanceName = this.props.getComponentProjectInstanceName(iid, logProjectName);
            if (logInstanceName) {
              logInstanceNameList.push(logInstanceName);
            }
          }, involvedInstanceSet);
        } else {
          logInstanceNameList.push(toContent?.instanceNameInThisProject);
        }
        R.forEach((logInstanceName) => {
          R.forEach((timestamp) => {
            const id = `${logProjectName}-${logInstanceName}-${tnid}-${ttypeOnly}-${timestamp}`;
            if (!R.find((item) => item.id === id, logEventQueryStr)) {
              const key = `${logProjectName}-${logInstanceName}-${String(tnid)}-${ttype}`;
              if (!targetNidCounts[key] || targetNidCounts[key] < maxCount) {
                logEventQueryStr.push({
                  id,
                  logProjectName,
                  logInstanceName,
                  nid: String(tnid),
                  timestamp,
                  type: ttype,
                });
              }
              targetNidCounts[key] = (targetNidCounts[key] || 0) + 1;
            }
          }, toTimestampList);
        }, logInstanceNameList);
      }
    }, contentTimeDifference);

    logEventQueryStr = R.map((item) => {
      const { logProjectName, logInstanceName, nid, timestamp, type } = item;
      return { logProjectName, logInstanceName, nid, timestamp, type };
    }, logEventQueryStr);

    // Remove duplicated items
    logEventQueryStr = R.uniqBy(
      (item) => `${item.logProjectName}-${item.logInstanceName}-${item.nid}-${item.type}-${item.timestamp}`,
      logEventQueryStr,
    );

    createLoadAction(
      ActionTypes.LOAD_CAUSAL_LOG_EVENTS,
      {
        customerName,
        logEventQueryStr: JSON.stringify(logEventQueryStr),
      },
      this.dataLoader,
    );
  }

  @autobind
  preprocessData(props, state) {
    const { relationProbability, instanceIdCausalInfoMap } = props;
    const { filterModality, filterPattern, filterMetric } = state;
    const causalLogEvents = props.causalLogEvents || {};
    const edgeRelation = props.edgeRelation || {};
    let contentTimeDifference = edgeRelation.contentTimeDifference || [];
    const fromContents = edgeRelation.fromContents || [];
    const toContents = edgeRelation.toContents || [];

    if (filterModality && filterModality !== 'all') {
      const modality = convertModality(filterModality);
      contentTimeDifference = R.filter((c) => c.modality === modality, contentTimeDifference);
    }

    let eventRelationList = [];
    R.forEach((diff) => {
      const fromTimestampList = R.map((item) => item[0], diff.timePair || []);
      const toTimestampList = R.map((item) => item[1], diff.timePair || []);
      const f = R.clone(
        diff.sc || R.find((item) => item.sP === diff.sP, fromContents || []) || fromContents[diff.srcPosition],
      );
      const t = R.clone(
        diff.tc || R.find((item) => item.tP === diff.tP, toContents || []) || toContents[diff.targetPosition],
      );

      const involvedInstanceSet = f?.involvedInstanceSet || [];
      const tInvolvedInstanceSet = t?.involvedInstanceSet || [];
      const fInstanceList = [];
      const tInstanceList = [];
      if (involvedInstanceSet.length > 0) {
        for (let i = 0; i < involvedInstanceSet.length; i++) {
          fInstanceList.push(this.props.getComponentProjectInstanceName(involvedInstanceSet[i], f?.projectName));
          tInstanceList.push(this.props.getComponentProjectInstanceName(tInvolvedInstanceSet[i], t?.projectName));
        }
      } else {
        fInstanceList.push(f?.instanceNameInThisProject);
        tInstanceList.push(t?.instanceNameInThisProject);
      }

      for (let i = 0; i < fInstanceList.length; i++) {
        const f = R.clone(
          diff.sc || R.find((item) => item.sP === diff.sP, fromContents || []) || fromContents[diff.srcPosition],
        );
        const t = R.clone(
          diff.tc || R.find((item) => item.tP === diff.tP, toContents || []) || toContents[diff.targetPosition],
        );
        const fInstanceName = fInstanceList[i];
        const tInstanceName = tInstanceList[i];
        const srcLogEvents = get(causalLogEvents, fInstanceName, []);
        const targetLogEvents = get(causalLogEvents, tInstanceName, []);

        // filter logs
        const fLogs = R.filter(
          (s) =>
            s.patternId === String(f?.nid) &&
            (f?.type || '').indexOf(s.logType) >= 0 &&
            fromTimestampList.indexOf(s.timestamp) !== -1,
          srcLogEvents,
        );
        if (fLogs.length > 0) {
          f.logContentList = R.map((item) => [item.timestamp, item.logContent || ''], fLogs);
        }
        const tLogs = R.filter(
          (s) =>
            s.patternId === String(t?.nid) &&
            (t?.type || '').indexOf(s.logType) >= 0 &&
            toTimestampList.indexOf(s.timestamp) !== -1,
          targetLogEvents,
        );
        if (tLogs.length > 0) {
          t.logContentList = R.map((item) => [item.timestamp, item.logContent || ''], tLogs);
        }

        const timePair = diff.timePair || [];
        const { count } = diff || {};
        if (f && t) {
          eventRelationList.push({
            fromContents: f,
            toContents: t,
            delay: diff.delay,
            count,
            probability: diff.probability,
            modality: diff.modality,
            timePair,
            nidList: [f.nid, t.nid],
          });
        }
      }
    }, contentTimeDifference);

    // if (relationProbability) {
    //   eventRelationList = R.filter(
    //     (relation) => relation.probability >= parseFloat(relationProbability),
    //     eventRelationList,
    //   );
    // }
    if (filterModality && filterModality !== 'all') {
      const modality = convertModality(filterModality);
      eventRelationList = R.filter((relation) => {
        return relation.modality === modality;
      }, eventRelationList);

      const parts = filterModality.split('->') || [];
      const from = parts[0];
      const to = parts[1];

      if (from && to) {
        eventRelationList = R.filter((r) => {
          const { fromContents, toContents } = r;
          const fromMatch = modalityMatching(fromContents, from);
          const toMatch = modalityMatching(toContents, to);
          return fromMatch && toMatch;
        }, eventRelationList);
      }
    }
    if (filterPattern) {
      eventRelationList = R.filter((relation) => relation.nidList.includes(Number(filterPattern)), eventRelationList);
    }

    // Get all metrics in the from and to
    const metrics = {};
    R.forEach((r) => {
      if (r?.fromContents?.type === 'Metric') {
        metrics[r.fromContents.content] = true;
      }
      if (r?.toContents?.type === 'Metric') {
        metrics[r.toContents.content] = true;
      }
    }, eventRelationList);

    // Expand the by instance name

    this.eventMetricList = R.keys(metrics);

    if (filterMetric) {
      const f = isString(filterMetric) ? [filterMetric] : filterMetric;

      if (f.length > 0) {
        eventRelationList = R.filter((r) => {
          return (
            (r?.fromContents?.type === 'Metric' && R.indexOf(r.fromContents.content, f) >= 0) ||
            (r?.toContents?.type === 'Metric' && R.indexOf(r.toContents.content, f) >= 0)
          );
        }, eventRelationList);
      }
    }
    const expandEventRelationList = [];
    R.forEach((item) => {
      const involvedInstanceSet = item?.fromContents?.involvedInstanceSet || [];
      const toContents = item?.toContents || {};
      if (involvedInstanceSet.length > 0) {
        for (let i = 0; i < involvedInstanceSet.length; i++) {
          const inst = involvedInstanceSet[i];
          const hostName = instanceIdCausalInfoMap?.[inst]?.hostName;
          const toHostName = instanceIdCausalInfoMap?.[toContents?.involvedInstanceSet?.[i]]?.hostName;
          if (hostName) {
            const fromContents = { ...item.fromContents, hostName };
            const toContents = { ...item.toContents, hostName: toHostName };
            expandEventRelationList.push({ ...item, fromContents, toContents });
          }
        }
      } else {
        expandEventRelationList.push(item);
      }
    }, eventRelationList);

    this.eventRelationList = expandEventRelationList;

    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdate();
    }
  }

  @autobind
  renderTimePair(props) {
    const { intl, isCorrelation } = this.props;
    return CellRenderers.timePair({ intl, isCorrelation, ...props });
  }

  @autobind
  eventContentAutoHeightRenderer(props) {
    const { intl, incidentParams, credentials, projectDisplayMap, currentTheme, metricUnitMap } = this.props;
    const { dataKey, parent, rowIndex } = props;
    const { keyword } = this.state;
    const customerName = get(incidentParams, 'customerName');
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        {CausalRenderers.EventContentRenderer({
          intl,
          props,
          options: { keyword },
          customerName,
          credentials,
          projectDisplayMap,
          currentTheme,
          metricUnitMap,
        })}
      </CellMeasurer>
    );
  }

  @autobind
  eventContentScrollingRenderer(props) {
    const { intl, incidentParams, credentials, projectDisplayMap, currentTheme, metricUnitMap } = this.props;
    const { keyword } = this.state;
    const customerName = get(incidentParams, 'customerName');
    return (
      <div className="overflow-y-auto" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        {CausalRenderers.EventContentRenderer({
          intl,
          props,
          options: { keyword },
          customerName,
          credentials,
          projectDisplayMap,
          currentTheme,
          metricUnitMap,
        })}
      </div>
    );
  }

  @autobind
  detailsRenderer(flag) {
    return ({ rowData }) => {
      const { intl, instanceCausalInfo, isIntra, intraInstanceName, leftLabel, rightLabel } = this.props;
      const label = isIntra ? intraInstanceName : flag === 'from' ? leftLabel : rightLabel;
      const incident = flag === 'from' ? get(rowData, ['fromContents']) : get(rowData, ['toContents']);
      const { projectName, hostName } = incident;

      let projectInfo = R.find(
        (item) => item.projectName === projectName,
        get(instanceCausalInfo, [label, 'projectInstanceInfoList'], []),
      );

      // Search projectInfo from all instanceCausalInfo
      if (!projectInfo) {
        const values = R.values(instanceCausalInfo);
        for (let i = 0; i < values.length; i++) {
          projectInfo = R.find(
            (item) => item.projectName === projectName,
            get(values[i], ['projectInstanceInfoList'], []),
          );
          if (projectInfo) {
            break;
          }
        }
      }

      if (!projectInfo) {
        console.error(`[IF] cannot found projectInfo for projectName: ${projectName}`);
      }

      const { isMetric, userName, projectName: logProjectName, instanceName: logInstanceName } = projectInfo || {};

      const handleMenuClick = ({ key }) => {
        if (key === 'context') {
          this.handleLogContextClick(rowData, flag, logProjectName, hostName || logInstanceName, userName);
        } else if (key === 'details') {
          this.handleLogDetailsClick(rowData, flag, logProjectName, hostName || logInstanceName, userName);
        } else if (key === 'lineChart') {
          let metric;
          if (flag === 'from') {
            metric = rowData.fromContents.instanceDown ? undefined : rowData.fromContents.content;
          } else {
            metric = rowData.toContents.instanceDown ? undefined : rowData.toContents.content;
          }
          this.handleLineChartClick(rowData, flag, label, metric, userName);
        }
      };
      return (
        <Dropdown name={intl.formatMessage(logMessages.investigate)} itemClick={handleMenuClick}>
          <>
            {!isMetric && <Menu.Item key="context">{intl.formatMessage(eventMessages.context)}</Menu.Item>}
            {!isMetric && <Menu.Item key="details">{intl.formatMessage(eventMessages.details)}</Menu.Item>}
            {isMetric && <Menu.Item key="lineChart">{intl.formatMessage(eventMessages.lineChart)}</Menu.Item>}
          </>
        </Dropdown>
      );
    };
  }

  @autobind
  handleLogContextClick(rowData, flag, logProjectName, logInstanceName, userName) {
    const { isAdmin, credentials } = this.props;
    const incident = flag === 'from' ? get(rowData, ['fromContents']) : get(rowData, ['toContents']);
    const { content: rawData, logContentList, hostName } = incident;
    if (logProjectName && logInstanceName) {
      let projectName = logProjectName.indexOf('@') >= 0 ? logProjectName : `${logProjectName}@${userName}`;
      if (!isAdmin && projectName && projectName.indexOf('@') >= 0) {
        const [p, u] = projectName.split('@');
        projectName = u !== credentials?.userName ? projectName : p;
      }
      this.setState({
        activeIncident: {
          ...incident,
          rawData,
          rawDataList: R.map((item) => item[1], logContentList || []),
          timestamp: logContentList && logContentList.length > 0 ? logContentList[0][0] : null,
          projectName,
          user: userName,
          instanceName: logInstanceName || hostName,
        },
        showContextModal: true,
      });
    }
  }

  @autobind
  handleLogDetailsClick(rowData, flag, logProjectName, logInstanceName, userName) {
    const { isAdmin, projects, credentials } = this.props;
    const incident = flag === 'from' ? get(rowData, ['fromContents']) : get(rowData, ['toContents']);
    const { logContentList, nid, customerName, hostName } = incident;
    if (logProjectName && logInstanceName) {
      let projectName = logProjectName.indexOf('@') >= 0 ? logProjectName : `${logProjectName}@${userName}`;
      if (!isAdmin && projectName && projectName.indexOf('@') >= 0) {
        const [p, u] = projectName.split('@');
        projectName = u !== credentials?.userName ? projectName : p;
      }
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);

      const day = moment
        .utc(logContentList && logContentList.length > 0 ? logContentList[0][0] : null)
        .format(Defaults.DateFormat);
      const query = {
        projectName,
        instanceName: logInstanceName,
        startTime: day,
        endTime: day,
        activeTab: 'clusters',
        activePatternId: nid,
        customerName: customerName || userName,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleLineChartClick(rowData, flag, label, metric, userName) {
    const { incidentParams, credentials } = this.props;
    const incident = flag === 'from' ? get(rowData, ['fromContents']) : get(rowData, ['toContents']);
    const { instanceNameInThisProject, customerName, hostName } = incident;
    let { projectName } = incident;
    projectName = credentials.userName !== userName ? `${projectName}@${userName}` : projectName;
    const { startTimestamp, endTimestamp } = incidentParams;
    const instanceGroup = 'All';
    const startTs = moment.utc(Number(startTimestamp)).startOf('day').valueOf();
    const endTs = moment.utc(Number(endTimestamp)).endOf('day').valueOf();
    const query = {
      projectName,
      instanceGroup,
      modelType: 'Holistic',
      startTimestamp: startTs,
      endTimestamp: endTs,
      justSelectMetric: metric,
      justInstanceList: instanceNameInThisProject || hostName,
      withBaseline: true,
      customerName: customerName || userName,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  sort({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  handleClose() {
    this.props.onClose();
  }

  @autobind
  headerToopTipRenderer(prop, label, title) {
    const { intl, instanceDisplayNameMap } = this.props;
    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, label);
    return (
      <Popover title={null} content={instanceStr} placement="top" mouseEnterDelay={0.3}>
        <div className="hidden-line-with-ellipsis">{`${intl.formatMessage(title)}${
          instanceStr && instanceStr.length > 20 ? ` (${R.take(20, instanceStr)}...)` : ` (${instanceStr})`
        }`}</div>
      </Popover>
    );
  }

  @autobind
  getComponentName(nodeName) {
    const { incidentParams } = this.props;
    const { causalKey } = incidentParams;
    const prefix = `${causalKey}-Component-`;
    return (nodeName || '').indexOf(prefix) >= 0 ? nodeName.replace(prefix, '') : nodeName;
  }

  render() {
    const { intl, loadStatus, isIntra, intraInstanceName, leftLabel, rightLabel, isCorrelation } = this.props;
    const { sortBy, sortDirection, filterModality, filterPattern, filterMetric, activeIncident } = this.state;
    const sourceLabel = this.getComponentName(isIntra ? intraInstanceName : leftLabel);
    const targetLabel = this.getComponentName(isIntra ? intraInstanceName : rightLabel);

    const { eventRelationList } = this;
    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);

    return (
      <Modal width={1200} title="Causal relations" onCancel={this.handleClose} open maskClosable={false} footer={null}>
        <Container className={`content flex-col ${isLoading && !errorMessage ? 'loading' : ''}`}>
          <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
            <div style={{ fontWeight: 500, marginRight: 8 }}>{intl.formatMessage(causalMessages.modality)}:</div>
            <Select
              size="small"
              style={{ width: 200 }}
              value={filterModality}
              onChange={(filterModality) =>
                this.setState({ filterModality, filterMetric: [] }, async () => {
                  // this.props.onClose({ filterModality });
                  this.cellMeasureCache.clearAll();
                  if (this.dataTable) {
                    await this.dataTable.forceUpdateGrid();
                    this.dataTable.scrollToPosition(1);
                  }
                })
              }
            >
              {this.modalityFilterOptions.map((item) => (
                <Select.Option key={item.value} title={item.value}>
                  <span style={{ whiteSpace: 'pre' }}> {item.label} </span>
                </Select.Option>
              ))}
            </Select>

            <div style={{ fontWeight: 500, marginRight: 8, marginLeft: 16 }}>
              {intl.formatMessage(eventMessages.patternId)}:
            </div>
            <Input
              size="small"
              style={{ width: 150 }}
              allowClear
              value={filterPattern}
              onChange={(e) => this.setState({ filterPattern: e.target.value, filterMetric: [] })}
            />

            <div style={{ fontWeight: 500, marginRight: 8, marginLeft: 16 }}>
              {intl.formatMessage(causalMessages.filterMetric)}:
            </div>
            <Select
              size="small"
              style={{ width: 360 }}
              value={filterMetric}
              mode="tags"
              allowClear
              onChange={(filterMetric) => this.setState({ filterMetric })}
            >
              {this.eventMetricList.map((item) => (
                <Select.Option key={item} title={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="flex-col causal-log" style={{ width: '100%' }}>
            {errorMessage && (
              <Container style={{ margin: 8, fontSize: 14 }}>
                <div className="ui mini error message">{errorMessage}</div>
              </Container>
            )}
            {!errorMessage && (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Table
                    className="with-border"
                    width={width}
                    height={500}
                    deferredMeasurementCache={this.cellMeasureCache}
                    headerHeight={40}
                    rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                    rowHeight={this.cellMeasureCache.rowHeight}
                    rowCount={eventRelationList.length}
                    rowGetter={({ index }) => eventRelationList[index]}
                    ref={(dataTable) => {
                      this.dataTable = dataTable;
                    }}
                    sort={this.sort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                  >
                    {/* <Column
                      width={110}
                      label={intl.formatMessage(logMessages.dateTimes)}
                      dataKey="timePair"
                      cellRenderer={this.renderTimePair}
                      disableSort
                    /> */}
                    <Column
                      width={this.contentWidth}
                      flexGrow={1}
                      dataKey="fromContents"
                      cellRenderer={this.eventContentAutoHeightRenderer}
                      disableSort
                      headerRenderer={(prop) => this.headerToopTipRenderer(prop, sourceLabel, causalMessages.source)}
                    />
                    <Column
                      width={116}
                      label={intl.formatMessage(logMessages.investigate)}
                      dataKey="fromContents"
                      cellRenderer={this.detailsRenderer('from')}
                      disableSort
                    />
                    <Column
                      width={this.contentWidth}
                      style={{ height: '100%' }}
                      flexGrow={1}
                      dataKey="toContents"
                      cellRenderer={this.eventContentScrollingRenderer}
                      disableSort
                      headerRenderer={(prop) => this.headerToopTipRenderer(prop, targetLabel, causalMessages.target)}
                    />
                    <Column
                      width={116}
                      label={intl.formatMessage(logMessages.investigate)}
                      dataKey="toContents"
                      cellRenderer={this.detailsRenderer('to')}
                      disableSort
                    />
                    <Column
                      width={70}
                      label={intl.formatMessage(logMessages.count)}
                      dataKey="count"
                      headerRenderer={this.headerRenderer}
                    />
                    {!isCorrelation && (
                      <Column
                        width={100}
                        label={intl.formatMessage(logMessages.estimatedDelay)}
                        dataKey="delay"
                        headerRenderer={this.headerRenderer}
                        cellRenderer={({ cellData }) => CellRenderers.humanizeDuration({ period: cellData, intl })}
                      />
                    )}
                    <Column
                      width={100}
                      label={intl.formatMessage(logMessages.probability)}
                      dataKey="probability"
                      headerRenderer={this.headerRenderer}
                      cellRenderer={CellRenderers.probability}
                    />
                  </Table>
                )}
              </AutoSizer>
            )}
          </div>
        </Container>

        {this.state.showContextModal && (
          <EventContextModal
            incident={activeIncident}
            useTimeRange
            startTimestamp={get(activeIncident, 'timestamp', moment.utc().valueOf()) - 60000}
            endTimestamp={get(activeIncident, 'timestamp', moment.utc().valueOf()) + 60000}
            onClose={() => this.setState({ showContextModal: false })}
          />
        )}
      </Modal>
    );
  }
}

const CausalRelationsModal = injectIntl(CausalRelationsModalCore);

export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, projects, projectDisplayMap, currentTheme } = state.app;
    const { credentials } = state.auth;
    const { isAdmin } = state.auth.userInfo;
    const { causalLogEvents } = state.causal;
    return { location, loadStatus, projects, projectDisplayMap, credentials, isAdmin, causalLogEvents, currentTheme };
  },
  { createLoadAction },
)(CausalRelationsModal);
