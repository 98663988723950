/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React, { useEffect, useReducer, useRef, useState } from 'react';
import * as R from 'ramda';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message, Select, Checkbox } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';
import { Modal, Container, AutoSizer, Table, Column, Popover } from '../../../lib/fui/react';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';
import { eventMessages } from '../../../common/metric/messages';
import ViewCausalSelectTimeModal from './ViewCausalSelectTimeModal';

type Props = {
  intl: Object,
  updateLastActionInfo: Function,
  isLoading: Boolean,
  credentials: Object,
  // eslint-disable-next-line
  causalGroup: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  errorMessage: ?Object,
  causalIncidentList: Object,
  onSelected: Function,
  onReload: Function,
  userInfo: Object,
};

class CausalRelationTaskList extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      showSubTaskModal: false,
      subTasks: [],
      showTimeModal: false,
      activeEvent: null,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleDateSelect({ rowData }) {
    const { onSelected } = this.props;
    onSelected(rowData);
    // this.setState({ showTimeModal: true, activeEvent: rowData });
  }

  @autobind
  handleIncidentClick(rowData, state) {
    const { intl, onSelected } = this.props;
    const { rangeStartDate, rangeEndDate, resultStartDate, resultEndDate } = state || {};

    if (rangeStartDate >= rangeEndDate || resultStartDate >= resultEndDate) {
      message.error(intl.formatMessage(appMessages.apiFaild));
    } else {
      const newData = { ...rowData };
      if (rangeStartDate) {
        newData.startTimestamp = rangeStartDate.valueOf();
      }
      if (rangeEndDate) {
        newData.endTimestamp = rangeEndDate.valueOf();
      }
      if (resultStartDate) {
        newData.resultStartstamp = resultStartDate.valueOf();
      }
      if (resultEndDate) {
        newData.resultEndstamp = resultEndDate.valueOf();
      }
      this.setState({ showTimeModal: false, activeEvent: null }, () => {
        onSelected(newData);
      });
    }
  }

  @autobind
  utcTimeRenderer({ cellData }) {
    const time = cellData ? moment.utc(cellData).format(Defaults.TimeFormat) : '';
    return <div>{time}</div>;
  }

  @autobind
  statusRenderer({ cellData, rowData }) {
    let color = 'currentColor';
    switch (cellData) {
      case 'Success':
        color = Defaults.ColorStatusFont.success;
        break;
      case 'Updating':
        color = Defaults.ColorStatusFont.info;
        break;
      case 'Partial Success':
        color = Defaults.ColorStatusFont.warning;
        break;
      case 'Fail':
        color = Defaults.ColorStatusFont.error;
        break;
      default:
        break;
    }
    return (
      <div className="flex-row flex-center-align" style={{ color }}>
        {cellData}
      </div>
    );
  }

  @autobind
  statusDetailsRenderer({ rowData }) {
    const { intl, userInfo } = this.props;
    const { subTasks } = rowData;
    return (
      <div className="flex-row">
        <Button
          type="primary"
          size="small"
          style={{ marginRight: 8 }}
          onClick={() => {
            this.handleDateSelect({ rowData });
          }}
        >
          {intl.formatMessage(causalMessages.viewCausalGraph)}
        </Button>
        <Popover
          content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
          mouseEnterDelay={0.3}
          placement="right"
        >
          <Popconfirm
            placement="topRight"
            title={
              <div>
                {intl.formatMessage(causalMessages.rerunRelation)}
                <br />
                {intl.formatMessage(appMessages.continueConfirm)}
              </div>
            }
            okText={intl.formatMessage(appButtonsMessages.yes)}
            cancelText={intl.formatMessage(appButtonsMessages.no)}
            onConfirm={this.handleIncidentRerunClick(rowData)}
            onCancel={(event) => event.stopPropagation()}
            disabled={userInfo.isReadUser}
          >
            <Button
              size="small"
              type="primary"
              disabled={userInfo.isReadUser}
              style={{ marginRight: 8 }}
              onClick={(event) => event.stopPropagation()}
            >
              {intl.formatMessage(causalMessages.rerunTheWholeCausalGraph)}
            </Button>
          </Popconfirm>
        </Popover>

        {/* <Popover
          content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
          mouseEnterDelay={0.3}
          placement="right"
        >
          <Button
            type="primary"
            size="small"
            style={{ marginRight: 8 }}
            disabled={(subTasks || []).length === 0 || userInfo.isReadUser}
            onClick={this.onDetailsClick(rowData)}
          >
            {intl.formatMessage(causalMessages.rerunCausalTasks)}
          </Button>
        </Popover> */}
      </div>
    );
  }

  @autobind
  onDetailsClick(rowData) {
    return (event) => {
      event.stopPropagation();

      const { subTasks } = rowData;
      this.setState({ showSubTaskModal: true, subTasks });
    };
  }

  @autobind
  handleIncidentRerunClick(rowData) {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();

      const { intl, credentials, onReload } = this.props;
      const { causalKey, startTimestamp, endTimestamp, customerName } = rowData;
      this.props.updateLastActionInfo();
      Promise.all([
        fetchPost(
          `${window.BASE_URL || ''}/localcron/relationpreprocess`,
          {
            ...credentials,
            customerName,
            causalKey,
            startTime: startTimestamp,
            endTime: endTimestamp,
            rerunCron: false,
          },
          {},
          false,
        ),
      ])
        .then(() => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          onReload();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
        });
    };
  }

  render() {
    const { intl, credentials, onReload, updateLastActionInfo } = this.props;
    const { isLoading, causalIncidentList } = this.props;
    const { showSubTaskModal, subTasks } = this.state;

    return (
      <Container className={`full-height ${isLoading ? ' loading ' : ''}`}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              width={width}
              height={height}
              headerHeight={40}
              rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
              rowHeight={40}
              rowCount={causalIncidentList.length}
              rowGetter={({ index }) => causalIncidentList[index]}
              // onRowClick={this.handleIncidentClick}
            >
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.relationStartTime)}
                dataKey="startTimestamp"
                cellRenderer={this.utcTimeRenderer}
              />
              <Column
                width={160}
                label={intl.formatMessage(causalMessages.relationEndTime)}
                dataKey="endTimestamp"
                cellRenderer={this.utcTimeRenderer}
              />
              <Column
                width={120}
                label={intl.formatMessage(causalMessages.status)}
                dataKey="status"
                cellRenderer={this.statusRenderer}
              />
              <Column
                width={140}
                flexGrow={1}
                label={null}
                dataKey="status"
                cellRenderer={this.statusDetailsRenderer}
              />
            </Table>
          )}
        </AutoSizer>

        {showSubTaskModal && (
          <SubTaskModal
            intl={intl}
            updateLastActionInfo={updateLastActionInfo}
            credentials={credentials}
            onReload={() => {
              this.setState({ showSubTaskModal: false });
              onReload();
            }}
            onCancel={() => this.setState({ showSubTaskModal: false })}
            subTasks={subTasks}
          />
        )}

        {this.state.showTimeModal && (
          <ViewCausalSelectTimeModal
            activeEvent={this.state.activeEvent}
            onClose={() => this.setState({ showTimeModal: false, activeEvent: null })}
            onSumbit={(activeEvent, state) => this.handleIncidentClick(activeEvent, state)}
          />
        )}
      </Container>
    );
  }
}

// eslint-disable-next-line
const SubTaskModal = ({ intl, updateLastActionInfo, credentials, onReload, onCancel, subTasks }: Object) => {
  const statusOptions = [
    { value: 'failure', label: 'Failure' },
    { value: 'timeout', label: 'Timeout' },
    { value: 'initializing', label: 'Initializing' },
    { value: 'creating', label: 'Creating' },
    { value: 'emptyresult', label: 'Empty result' },
    { value: 'finished', label: 'Finished' },
  ];
  const dataTableNode = useRef(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [status, setStatus] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  useEffect(() => {
    const dataList = [
      ...R.filter((task) => task.status !== 'finished', subTasks),
      ...R.filter((task) => task.status === 'finished', subTasks),
    ];
    setEventList(dataList);
  }, [subTasks]);

  const handleStatusChange = (status) => {
    let dataList = [
      ...R.filter((task) => task.status !== 'finished', subTasks),
      ...R.filter((task) => task.status === 'finished', subTasks),
    ];
    if (status) {
      dataList = R.filter((task) => task.status.toLowerCase() === status, dataList);
    }
    const findChecked = R.find((item) => !item.checked, dataList || []);
    if (dataTableNode.current) dataTableNode.current.forceUpdate();
    setStatus(status);
    setAllChecked(!findChecked);
    setEventList(dataList);
  };

  const utcTimeRenderer = ({ cellData }) => {
    const time = cellData ? moment.utc(cellData).format(Defaults.TimeFormat) : '';
    return <div>{time}</div>;
  };

  const checkRender = ({ rowData, dataKey, cellData }) => {
    return (
      <Checkbox
        size="small"
        checked={cellData}
        onChange={({ target: { checked } }) => {
          rowData[dataKey] = checked;
          const findChecked = R.find((item) => !item.checked, eventList || []);
          if (dataTableNode.current) dataTableNode.current.forceUpdate();
          setAllChecked(!findChecked);
          forceUpdate();
        }}
      />
    );
  };

  const handleSubmit = () => {
    const events = R.filter((item) => item.checked, eventList);
    const { causalKey, customerName } = events[0] || {};
    const timeList = R.map((item) => ({ s: item.startTimestamp, e: item.endTimestamp }), events);
    updateLastActionInfo();
    Promise.all([
      fetchPost(getEndpoint('micausalrelationtask', 1), {
        ...credentials,
        causalKey,
        customerName,
        timeList: JSON.stringify(timeList),
        category: 'rerun_cron',
      }),
    ])
      .then(() => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        onReload();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  };

  const disabled = !R.find((item) => item.checked, eventList || []);

  return (
    <Modal
      title="Sub Task Status"
      width={700}
      visible
      onCancel={onCancel}
      okText={intl.formatMessage(eventMessages.rerun)}
      onOk={handleSubmit}
      okButtonProps={{ disabled }}
    >
      <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
        <div className="light-label bold" style={{ width: 80 }}>
          {intl.formatMessage(appFieldsMessages.status)}:
        </div>
        <Select
          allowClear
          size="small"
          style={{ width: 150 }}
          value={status}
          optionFilterProp="value"
          filterOption
          onChange={handleStatusChange}
          dropdownMatchSelectWidth={false}
        >
          {R.addIndex(R.map)((item, index) => {
            return (
              <Select.Option key={item.value} label={item.label}>
                {item.label}
              </Select.Option>
            );
          }, statusOptions || [])}
        </Select>
      </div>

      <Table
        className="with-border"
        width={650}
        height={400}
        headerHeight={40}
        rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
        rowHeight={40}
        rowCount={eventList.length}
        rowGetter={({ index }) => eventList[index]}
        rowStyle={({ index }) => {
          if (index >= 0) {
            const { status } = eventList[index] || {};
            if (status !== 'finished') {
              return { backgroundColor: 'var(--virtualized-table-row-finish-bg)' };
            }
          }
          return {};
        }}
        ref={(c) => {
          dataTableNode.current = c;
        }}
      >
        <Column
          width={40}
          disableSort
          dataKey="checked"
          cellRenderer={checkRender}
          label={
            <Checkbox
              size="small"
              checked={allChecked}
              onChange={({ target: { checked } }) => {
                R.forEach((item) => {
                  item.checked = checked;
                }, eventList || []);
                if (dataTableNode.current) dataTableNode.current.forceUpdate();
                setAllChecked(checked);
              }}
            />
          }
        />
        <Column
          width={160}
          label={intl.formatMessage(causalMessages.relationStartTime)}
          dataKey="startTimestamp"
          cellRenderer={utcTimeRenderer}
        />
        <Column
          width={160}
          label={intl.formatMessage(causalMessages.relationEndTime)}
          dataKey="endTimestamp"
          cellRenderer={utcTimeRenderer}
        />
        <Column width={120} label={intl.formatMessage(causalMessages.status)} dataKey="status" />
        <Column
          width={160}
          label={intl.formatMessage(causalMessages.requestTime)}
          dataKey="requestTime"
          cellRenderer={utcTimeRenderer}
        />
        <Column
          width={160}
          flexGrow={1}
          label={intl.formatMessage(causalMessages.finishTime)}
          dataKey="finishTime"
          cellRenderer={utcTimeRenderer}
        />
      </Table>
    </Modal>
  );
};

export default CausalRelationTaskList;
