import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, InputNumber, Select } from 'antd';

import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

import { State } from '../../../common/types';
import { verifyContent } from '../data/utils';

import ActiveTriggerHeader from './ActiveTriggerHeader';
import DealActiveButton from './DealActiveButton';

import { logMessages } from '../../../common/log/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../common/app/messages';

const newRule = { project: '', metric: '', condition: '', threshold: 0, logic: '' };

function MetricThresholdTriggerCore(props: Object) {
  const { intl } = props || {};
  const { activeWorkNode } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};

  const timeUnitOption = useMemo(() => {
    return [
      { label: intl.formatMessage(appFieldsMessages.minute), value: 60000 },
      { label: intl.formatMessage(appFieldsMessages.hour), value: 3600000 },
      { label: intl.formatMessage(appFieldsMessages.day), value: 86400000 },
    ];
  }, []);

  const { nodeData = {} } = activeWorkNode?.data || {};
  const { ruleList = [], durationThreshold, timeUnit } = nodeData;

  const addRule = (index) => {
    const newRuleList = R.clone(ruleList);
    newRuleList.splice(index + 1, 0, newRule);
    contextSetState({
      activeWorkNode: {
        ...activeWorkNode,
        data: { ...activeWorkNode.data, nodeData: { ...nodeData, ruleList: newRuleList } },
      },
    });
  };

  const deleteRule = (index) => {
    let newRuleList = R.addIndex(R.map)((rule, idx) => {
      if (index - 1 === idx && !ruleList[index + 1]) {
        return { ...rule, logic: '' };
      } else {
        return rule;
      }
    }, ruleList);
    newRuleList = R.addIndex(R.filter)((_, idx) => index !== idx, newRuleList);
    contextSetState({
      activeWorkNode: {
        ...activeWorkNode,
        data: { ...activeWorkNode.data, nodeData: { ...nodeData, ruleList: newRuleList } },
      },
    });
  };

  const editRule = (key, value, index) => {
    const newRuleList = R.addIndex(R.map)((rule, idx) => {
      if (index === idx) {
        return { ...rule, [key]: value };
      } else {
        return rule;
      }
    }, ruleList || []);
    contextSetState({
      activeWorkNode: {
        ...activeWorkNode,
        data: { ...activeWorkNode.data, nodeData: { ...nodeData, ruleList: newRuleList } },
      },
    });
  };

  const { durationThresholdError, hasError } = verifyContent(nodeData);

  return (
    <>
      <ActiveTriggerHeader />
      <div className="flex-grow" style={{ overflowY: 'scroll', paddingRight: 14 }}>
        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span className="font-12" style={{ marginBottom: 4 }}>
            Duration threshold<span style={{ color: 'red' }}>*</span>
          </span>
          <div className="flex-row flex-center-align">
            <InputNumber
              min={0}
              size="small"
              style={{ width: '100%' }}
              value={durationThreshold}
              onChange={(durationThreshold) =>
                contextSetState({
                  activeWorkNode: {
                    ...activeWorkNode,
                    data: {
                      ...activeWorkNode.data,
                      nodeData: { ...nodeData, durationThreshold: durationThreshold || 0 },
                    },
                  },
                })
              }
              className={`${durationThresholdError ? 'inputIsNil' : ''}`}
            />
            <Select
              size="small"
              value={timeUnit}
              style={{ width: 130, marginLeft: 8 }}
              options={timeUnitOption}
              onChange={(timeUnit) => {
                contextSetState({
                  activeWorkNode: {
                    ...activeWorkNode,
                    data: { ...activeWorkNode.data, nodeData: { ...nodeData, timeUnit: timeUnit || 0 } },
                  },
                });
              }}
            />
          </div>
        </div>

        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span style={{ fontSize: 12, marginBottom: 4 }}>
            {intl.formatMessage(logMessages.rule)}
            <span style={{ color: 'red' }}>*</span>
          </span>
          {R.addIndex(R.map)((rule, index) => {
            const { project, metric, condition, threshold, logic } = rule || {};
            const nextRule = ruleList[index + 1];
            return (
              <div
                key={index}
                className="flex-row flex-center-align corner-8"
                style={{
                  position: 'relative',
                  border: '2px solid var(--virtualized-table-border-color)',
                  marginTop: index !== 0 ? 36 : 0,
                  padding: '8px 4px 18px',
                }}
              >
                <Select
                  size="small"
                  value={project}
                  onChange={(project) => editRule('project', project, index)}
                  placeholder={intl.formatMessage(appFieldsMessages.project)}
                  options={[
                    { value: 'project1', label: 'Project1' },
                    { value: 'project2', label: 'Project2' },
                  ]}
                  className={`flex-grow ${!project ? 'jsonKeyNoneError' : ''}`}
                />
                <Select
                  size="small"
                  value={metric}
                  onChange={(metric) => editRule('metric', metric, index)}
                  style={{ marginLeft: 8 }}
                  placeholder={intl.formatMessage(appFieldsMessages.metric)}
                  options={[
                    { value: 'metric1', label: 'Metric1' },
                    { value: 'metric2', label: 'Metric2' },
                  ]}
                  className={`flex-grow ${!metric ? 'jsonKeyNoneError' : ''}`}
                />
                <Select
                  size="small"
                  value={condition}
                  onChange={(condition) => editRule('condition', condition, index)}
                  style={{ width: 80, marginLeft: 8 }}
                  options={[
                    { value: '>', label: '>' },
                    { value: '=', label: '=' },
                    { value: '<', label: '<' },
                  ]}
                  className={`flex-grow ${!condition ? 'jsonKeyNoneError' : ''}`}
                />
                <InputNumber
                  min={0}
                  size="small"
                  value={threshold}
                  onChange={(threshold) => editRule('threshold', threshold || 0, index)}
                  style={{ width: 130, marginLeft: 8 }}
                  className={`flex-grow ${!threshold && threshold !== 0 ? 'inputIsNil' : ''}`}
                />

                <div
                  className="flex-row flex-center-align"
                  style={{
                    position: 'absolute',
                    bottom: -12,
                    left: '50%',
                    transform: index === 0 || !nextRule ? 'translateX(-50%)' : 'translateX(calc(-50% + 32px))',
                    background: 'var(--content-background)',
                    padding: '0 4px',
                  }}
                >
                  {nextRule && (
                    <Select
                      size="small"
                      style={{ width: 130 }}
                      value={logic}
                      onChange={(logic) => editRule('logic', logic, index)}
                      options={[
                        { value: 'AND', label: 'AND' },
                        { value: 'OR', label: 'OR' },
                      ]}
                      className={`flex-grow ${!logic ? 'jsonKeyNoneError' : ''}`}
                    />
                  )}

                  <Button size="small" style={{ marginLeft: 8 }} onClick={() => addRule(index)}>
                    {intl.formatMessage(appButtonsMessages.add)}
                  </Button>
                  {index !== 0 && (
                    <Button size="small" style={{ marginLeft: 8 }} onClick={() => deleteRule(index)}>
                      {intl.formatMessage(appButtonsMessages.delete)}
                    </Button>
                  )}
                </div>
              </div>
            );
          }, ruleList || [])}
        </div>
      </div>
      <DealActiveButton hasError={hasError} />
    </>
  );
}

const MetricThresholdTrigger = injectIntl(MetricThresholdTriggerCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(MetricThresholdTrigger);
