export const patternTriggerInitData = {
  pattern: '',
  dampening: 0,
  frequency: '',
};

export const metricThresholdTriggerInitData = {
  ruleList: [{ project: '', metric: '', condition: '', threshold: 0, logic: '' }],
  durationThreshold: 0,
  timeUnit: 60000,
};

export const codeActionInitData = {
  codeName: undefined,
  codeContent: undefined,
};

export const emailActionInitData = {
  email: undefined,
};

export const kubernetesHPAActionInitData = {
  nameSpace: '',
  deployment: '',
  min: 0,
  max: 0,
};

export const kubernetesRestartActionInitData = {
  nameSpace: '',
  deployment: '',
};

export const kubernetesJobActionInitData = {
  nameSpace: '',
  deployment: '',
  codeContent: undefined,
};
