/* @flow */
import { defineMessages } from 'react-intl';

const appFieldsMessages = defineMessages({
  name: {
    defaultMessage: 'Name',
    id: 'app.fields.name',
  },
  userName: {
    defaultMessage: 'User Name',
    id: 'app.fields.userName',
  },
  idpType: {
    defaultMessage: 'IDP Type',
    id: 'app.fields.idpType',
  },
  idpName: {
    defaultMessage: 'IDP Name',
    id: 'app.fields.idpName',
  },
  clientId: {
    defaultMessage: 'Client ID',
    id: 'app.fields.clientId',
  },
  clientSecret: {
    defaultMessage: 'Client Secret',
    id: 'app.fields.clientSecret',
  },
  tenant: {
    defaultMessage: 'Tenant',
    id: 'app.fields.tenant',
  },
  serverAddress: {
    defaultMessage: 'Server Address',
    id: 'app.fields.serverAddress',
  },
  domainName: {
    defaultMessage: 'Domain Name',
    id: 'app.fields.domainName',
  },
  fullName: {
    defaultMessage: 'Full Name',
    id: 'app.fields.fullName',
  },
  fname: {
    defaultMessage: 'First Name',
    id: 'app.fields.fname',
  },
  lname: {
    defaultMessage: 'Last Name',
    id: 'app.fields.lname',
  },
  contactPhone: {
    defaultMessage: 'Contact Phone',
    id: 'app.fields.contactPhone',
  },
  emailAddress: {
    defaultMessage: 'Email Address',
    id: 'app.fields.emailAddress',
  },
  companyName: {
    defaultMessage: 'Company Name',
    id: 'app.fields.companyName',
  },
  phoneNumber: {
    defaultMessage: 'Phone Number',
    id: 'app.fields.phoneNumber',
  },
  licenseKey: {
    defaultMessage: 'License Key',
    id: 'app.fields.licenseKey',
  },
  encryptedLicenseKey: {
    defaultMessage: 'Encrypted License Key',
    id: 'app.fields.encryptedLicenseKey',
  },
  token: {
    defaultMessage: 'Token',
    id: 'app.fields.token',
  },
  language: {
    defaultMessage: 'Language',
    id: 'app.fields.language',
  },
  defaultTimezone: {
    defaultMessage: 'Timezone',
    id: 'app.fields.defaultTimezone',
  },
  selectTimezone: {
    defaultMessage: 'Please select timezone to update',
    id: 'app.fields.selectTimezone',
  },
  updateTimezone: {
    defaultMessage: 'Update Timezone',
    id: 'app.fields.updateTimezone',
  },
  changePassword: {
    defaultMessage: 'Change Password',
    id: 'app.fields.changePassword',
  },
  email: {
    defaultMessage: 'Email',
    id: 'app.fields.email',
  },
  password: {
    defaultMessage: 'Password',
    id: 'app.fields.password',
  },
  tempPassword: {
    defaultMessage: 'Temp Password',
    id: 'app.fields.tempPassword',
  },
  newPassword: {
    defaultMessage: 'New Password',
    id: 'app.fields.newPassword',
  },
  confirmPassword: {
    defaultMessage: 'Confirm Password',
    id: 'app.fields.confirmPassword',
  },
  signupCode: {
    defaultMessage: 'Signup Code',
    id: 'app.fields.signupCode',
  },
  promotionCode: {
    defaultMessage: 'Promotion Code',
    id: 'app.fields.promotionCode',
  },
  signUp: {
    defaultMessage: 'Sign Up',
    id: 'app.fields.signUp',
  },
  welcomeToInsightFinder: {
    defaultMessage: 'Welcome to InsightFinder!',
    id: 'app.fields.welcomeToInsightFinder',
  },
  inputRequired: {
    defaultMessage: 'This input is required',
    id: 'app.fields.inputRequired',
  },
  instanceNameCheck: {
    defaultMessage: 'Cannot add the :_[] into the instance name',
    id: 'app.fields.instanceNameCheck',
  },
  category: {
    defaultMessage: 'Category',
    id: 'app.fields.category',
  },
  project: {
    defaultMessage: 'Project',
    id: 'app.fields.project',
  },
  container: {
    defaultMessage: 'Container',
    id: 'app.fields.container',
  },
  projects: {
    defaultMessage: 'Projects',
    id: 'app.fields.projects',
  },
  component: {
    defaultMessage: 'Component',
    id: 'app.fields.component',
  },
  mappingKey: {
    defaultMessage: 'mapping Key',
    id: 'app.fields.mappingKey',
  },
  status: {
    defaultMessage: 'Status',
    id: 'app.fields.status',
  },
  actionStatus: {
    defaultMessage: 'Action status',
    id: 'app.fields.actionStatus',
  },
  instance: {
    defaultMessage: 'Instance',
    id: 'app.fields.instance',
  },
  impactedComponents: {
    defaultMessage: 'Impacted components',
    id: 'app.fields.impactedComponents',
  },
  impactedInstances: {
    defaultMessage: 'Impacted instances',
    id: 'app.fields.impactedInstances',
  },
  impactedPods: {
    defaultMessage: 'Impacted pods',
    id: 'app.fields.impactedPods',
  },
  impactedContainers: {
    defaultMessage: 'Impacted containers',
    id: 'app.fields.impactedContainers',
  },
  physicalPodID: {
    defaultMessage: 'Physical pod ID',
    id: 'app.fields.physicalPodID',
  },
  logicPodID: {
    defaultMessage: 'Logic pod ID',
    id: 'app.fields.logicPodID',
  },
  instanceCount: {
    defaultMessage: 'Instance count',
    id: 'app.fields.instanceCount',
  },
  containerCount: {
    defaultMessage: 'Container count',
    id: 'app.fields.containerCount',
  },
  metricCount: {
    defaultMessage: 'Metric count',
    id: 'app.fields.metricCount',
  },
  configPatternNameRegex: {
    defaultMessage: 'Config pattern name regex',
    id: 'app.fields.configPatternNameRegex',
  },
  derivedMetrics: {
    defaultMessage: 'Derived metrics',
    id: 'app.fields.derivedMetrics',
  },
  derivedMetricName: {
    defaultMessage: 'Derived metric name',
    id: 'app.fields.derivedMetricName',
  },
  instanceNameList: {
    defaultMessage: 'Instance name list',
    id: 'app.fields.instanceNameList',
  },
  instanceRegex: {
    defaultMessage: 'Instance regex',
    id: 'app.fields.instanceRegex',
  },
  pattern: {
    defaultMessage: 'Pattern',
    id: 'app.fields.pattern',
  },
  patternName: {
    defaultMessage: 'Pattern name',
    id: 'app.fields.patternName',
  },
  incidentPattern: {
    defaultMessage: 'Incident Pattern',
    id: 'app.fields.incidentPattern',
  },
  patternIdName: {
    defaultMessage: 'Pattern ID/Name',
    id: 'app.fields.patternIdName',
  },
  query: {
    defaultMessage: 'Query',
    id: 'app.fields.query',
  },
  keywords: {
    defaultMessage: 'Keyword(s)',
    id: 'app.fields.keywords',
  },
  excludingKeywords: {
    defaultMessage: 'Excluding Keyword(s)',
    id: 'app.fields.excludingKeywords',
  },
  similarityLevel: {
    defaultMessage: 'Similarity Level',
    id: 'app.fields.similarityLevel',
  },
  topKPatterns: {
    defaultMessage: 'Top K Patterns',
    id: 'app.fields.topKPatterns',
  },
  incidentId: {
    defaultMessage: 'Incident Id',
    id: 'app.fields.incidentId',
  },
  modelType: {
    defaultMessage: 'Model Type',
    id: 'app.fields.modelType',
  },
  group: {
    defaultMessage: 'Group',
    id: 'app.fields.group',
  },
  interval: {
    defaultMessage: 'Interval',
    id: 'app.fields.interval',
  },
  intervalMins: {
    defaultMessage: 'Interval(mins)',
    id: 'app.fields.intervalMins',
  },
  duration: {
    defaultMessage: 'Duration',
    id: 'app.fields.duration',
  },
  trainingWindow: {
    defaultMessage: 'Training Window(days)',
    id: 'app.fields.trainingWindow',
  },
  dateRange: {
    defaultMessage: 'Date Range',
    id: 'app.fields.dateRange',
  },
  start: {
    defaultMessage: 'Start',
    id: 'app.fields.start',
  },
  pause: {
    defaultMessage: 'Pause',
    id: 'app.fields.Pause',
  },
  end: {
    defaultMessage: 'End',
    id: 'app.fields.end',
  },
  startDate: {
    defaultMessage: 'Start date',
    id: 'app.fields.startDate',
  },
  endDate: {
    defaultMessage: 'End date',
    id: 'app.fields.endDate',
  },
  calculationStartDate: {
    defaultMessage: 'Calculation start date',
    id: 'app.fields.calculationStartDate',
  },
  calculationEndDate: {
    defaultMessage: 'Calculation end date',
    id: 'app.fields.calculationEndDate',
  },
  resultStartDate: {
    defaultMessage: 'Result start date',
    id: 'app.fields.resultStartDate',
  },
  resultEndDate: {
    defaultMessage: 'Result end date',
    id: 'app.fields.resultEndDate',
  },
  selectTime: {
    defaultMessage: 'Select time',
    id: 'app.fields.selectTime',
  },
  startMonth: {
    defaultMessage: 'Start Month',
    id: 'app.fields.startMonth',
  },
  endMonth: {
    defaultMessage: 'End Month',
    id: 'app.fields.endMonth',
  },
  Day: {
    defaultMessage: 'Day',
    id: 'app.fields.Day',
  },
  time: {
    defaultMessage: 'Time',
    id: 'app.fields.time',
  },
  type: {
    defaultMessage: 'Type',
    id: 'app.fields.type',
  },
  order: {
    defaultMessage: 'Order',
    id: 'app.fields.order',
  },
  regex: {
    defaultMessage: 'Regex',
    id: 'app.fields.regex',
  },
  detectionRegex: {
    defaultMessage: 'Detection regex',
    id: 'app.fields.detectionRegex',
  },
  incidentRegex: {
    defaultMessage: 'Incident regex',
    id: 'app.fields.incidentRegex',
  },
  patternNameRegex: {
    defaultMessage: 'Pattern name regex',
    id: 'app.fields.patternNameRegex',
  },
  startTime: {
    defaultMessage: 'Start time',
    id: 'app.fields.startTime',
  },
  endTime: {
    defaultMessage: 'End time',
    id: 'app.fields.endTime',
  },
  softwareUpdateContext: {
    defaultMessage: 'Software Update Context',
    id: 'app.fields.softwareUpdateContext',
  },
  lastSoftwareUpdateTime: {
    defaultMessage: 'Last Software Update Time',
    id: 'app.fields.lastSoftwareUpdateTime',
  },
  softwareUpdateInformation: {
    defaultMessage: 'Update Information',
    id: 'app.fields.softwareUpdateInformation',
  },
  timezone: {
    defaultMessage: 'timezone',
    id: 'app.fields.timezone',
  },
  currentScore: {
    defaultMessage: 'Current Health Score',
    id: 'app.fields.currentScore',
  },
  currentTime: {
    defaultMessage: 'Current Time',
    id: 'app.fields.currentTime',
  },
  pastTime: {
    defaultMessage: 'Past Time',
    id: 'app.fields.pastTime',
  },
  clickForDetails: {
    defaultMessage: 'Click for details',
    id: 'app.fields.clickForDetails',
  },
  timezoneCaptical: {
    defaultMessage: 'Timezone',
    id: 'app.fields.timezoneCaptical',
  },
  environment: {
    defaultMessage: 'Environment',
    id: 'app.fields.environment',
  },
  system: {
    defaultMessage: 'System',
    id: 'app.fields.system',
  },
  systemList: {
    defaultMessage: 'System List',
    id: 'app.fields.systemList',
  },
  systemListSaveTitle: {
    defaultMessage: 'Hide unchecked system and save system list order',
    id: 'app.fields.systemListSaveTitle',
  },
  systemId: {
    defaultMessage: 'System Id',
    id: 'app.fields.systemId',
  },
  systemName: {
    defaultMessage: 'System name',
    id: 'app.fields.systemName',
  },
  integrations: {
    defaultMessage: 'Integrations',
    id: 'app.fields.integrations',
  },
  otherIntegrations: {
    defaultMessage: 'Other integrations',
    id: 'app.fields.otherIntegrations',
  },
  projectDisplayName: {
    defaultMessage: 'Project Display Name',
    id: 'app.fields.projectDisplayName',
  },
  user: {
    defaultMessage: 'User',
    id: 'app.fields.user',
  },
  date: {
    defaultMessage: 'Date',
    id: 'app.fields.date',
  },
  today: {
    defaultMessage: 'Today',
    id: 'app.fields.today',
  },
  yesterday: {
    defaultMessage: 'Yesterday',
    id: 'app.fields.yesterday',
  },
  tomorrow: {
    defaultMessage: 'Tomorrow',
    id: 'app.fields.tomorrow',
  },
  default: {
    defaultMessage: 'Default',
    id: 'app.fields.default',
  },
  timelines: {
    defaultMessage: 'Timelines',
    id: 'app.fields.timelines',
  },
  statistics: {
    defaultMessage: 'Statistics',
    id: 'app.fields.statistics',
  },
  config: {
    defaultMessage: 'Config',
    id: 'app.fields.config',
  },
  anomalyDetectionSetting: {
    defaultMessage: 'Anomaly detection setting',
    id: 'app.fields.anomalyDetectionSetting',
  },
  alertThresholds: {
    defaultMessage: 'Alert thresholds',
    id: 'app.fields.alertThresholds',
  },
  alertThresholdsAndDetectionType: {
    defaultMessage: 'Alert thresholds and detection type',
    id: 'app.fields.alertThresholdsAndDetectionType',
  },
  alertThreshold: {
    defaultMessage: 'Alert threshold',
    id: 'app.fields.alertThreshold',
  },
  Warning: {
    defaultMessage: 'Warning',
    id: 'app.fields.Warning',
  },
  positiveThresholdsAlert: {
    defaultMessage: 'Thresholds for positive anomaly detection have conflicts',
    id: 'app.fields.positiveThresholdsAlert',
  },
  negativeThresholdsAlert: {
    defaultMessage: 'Thresholds for negative anomaly detection have conflicts',
    id: 'app.fields.negativeThresholdsAlert',
  },
  bothThresholdsAlert: {
    defaultMessage: 'Thresholds for positive and negative anomaly detection have conflicts',
    id: 'app.fields.bothThresholdsAlert',
  },
  positiveAnomalyDetection: {
    defaultMessage: 'Positive (higher than normal) anomaly detection',
    id: 'app.fields.positiveAnomalyDetection',
  },
  negativeAnomalyDetection: {
    defaultMessage: 'Negative (lower than normal) anomaly detection',
    id: 'app.fields.negativeAnomalyDetection',
  },
  noAlertMin: {
    defaultMessage: 'No alert min',
    id: 'app.fields.noAlertMin',
  },
  noAlertMax: {
    defaultMessage: 'No alert max',
    id: 'app.fields.noAlertMax',
  },
  invalidValueThreshold: {
    defaultMessage: 'Invalid value threshold',
    id: 'app.fields.invalidValueThreshold',
  },
  causal: {
    defaultMessage: 'Causal Summary',
    id: 'app.fields.causal',
  },
  logKeywordsFields: {
    defaultMessage: 'Anomaly Keywords',
    id: 'app.fields.logKeywordsFields',
  },
  info: {
    defaultMessage: 'info',
    id: 'app.fields.info',
  },
  success: {
    defaultMessage: 'success',
    id: 'app.fields.success',
  },
  error: {
    defaultMessage: 'error',
    id: 'app.fields.error',
  },
  warning: {
    defaultMessage: 'warning',
    id: 'app.fields.warning',
  },
  avg: {
    defaultMessage: 'Avg',
    id: 'app.fields.avg',
  },
  max: {
    defaultMessage: 'Max',
    id: 'app.fields.max',
  },
  min: {
    defaultMessage: 'Min',
    id: 'app.fields.min',
  },
  recommendation: {
    defaultMessage: 'Recommendation',
    id: 'app.fields.recommendation',
  },
  processCrash: {
    defaultMessage: 'Process Crash',
    id: 'app.fields.processCrash',
  },
  metric: {
    defaultMessage: 'Metric',
    id: 'app.fields.metric',
  },
  seeOnly: {
    defaultMessage: 'See Only',
    id: 'app.fields.seeOnly',
  },
  log: {
    defaultMessage: 'Log',
    id: 'app.fields.log',
  },
  incident: {
    defaultMessage: 'Incident',
    id: 'app.fields.incident',
  },
  instanceName: {
    defaultMessage: 'Instance name',
    id: 'app.fields.instanceName',
  },
  instanceDisplayName: {
    defaultMessage: 'Instance display name',
    id: 'app.fields.instanceDisplayName',
  },
  investigate: {
    defaultMessage: 'Investigate',
    id: 'app.fields.investigate',
  },
  details: {
    defaultMessage: 'Details',
    id: 'app.fields.details',
  },
  jump: {
    defaultMessage: 'Jump',
    id: 'app.fields.jump',
  },
  jumpToRCA: {
    defaultMessage: 'Jump to RCA',
    id: 'app.fields.jumpToRCA',
  },
  RCA: {
    defaultMessage: 'RCA',
    id: 'app.fields.RCA',
  },
  Notes: {
    defaultMessage: 'Notes',
    id: 'app.fields.Notes',
  },
  jumpToLinkedProjects: {
    defaultMessage: 'Jump to linked metric projects',
    id: 'app.fields.jumpToLinkedProjects',
  },
  jumpToLinkedLogProjects: {
    defaultMessage: 'Jump to linked log projects',
    id: 'app.fields.jumpToLinkedLogProjects',
  },
  jumpToLogAnalysis: {
    defaultMessage: 'Jump to log analysis',
    id: 'app.fields.jumpToLogAnalysis',
  },
  jumpToExternalSystem: {
    defaultMessage: 'Jump to external system',
    id: 'app.fields.jumpToExternalSystem',
  },
  expand: {
    defaultMessage: 'Expand',
    id: 'app.fields.expand',
  },
  ellipsis: {
    defaultMessage: 'Ellipsis',
    id: 'app.fields.ellipsis',
  },
  topK: {
    defaultMessage: 'Top Ranked',
    id: 'app.fields.topK',
  },
  delay: {
    defaultMessage: 'Delay',
    id: 'app.fields.delay',
  },
  count: {
    defaultMessage: 'Count',
    id: 'app.fields.count',
  },
  totalCount: {
    defaultMessage: 'Total count',
    id: 'app.fields.totalCount',
  },
  uniqueCount: {
    defaultMessage: 'Unique count',
    id: 'app.fields.uniqueCount',
  },
  unique: {
    defaultMessage: 'Unique',
    id: 'app.fields.unique',
  },
  prob: {
    defaultMessage: 'Prob',
    id: 'app.fields.prob',
  },
  probability: {
    defaultMessage: 'Probability',
    id: 'app.fields.probability',
  },
  value: {
    defaultMessage: 'Value',
    id: 'app.fields.value',
  },
  maxCount: {
    defaultMessage: 'Max Count',
    id: 'app.fields.maxCount',
  },
  maxProb: {
    defaultMessage: 'Max Prob',
    id: 'app.fields.maxProb',
  },
  all: {
    defaultMessage: 'All',
    id: 'app.fields.all',
  },
  unignore: {
    defaultMessage: 'Unignore',
    id: 'app.fields.unignore',
  },
  ignore: {
    defaultMessage: 'Ignore',
    id: 'app.fields.ignore',
  },
  predicted: {
    defaultMessage: 'Predicted',
    id: 'app.fields.predicted',
  },
  predictedOccurrence: {
    defaultMessage: 'Predicted Occurrence Time',
    id: 'app.fields.predictedOccurrence',
  },
  triggeredActionTime: {
    defaultMessage: 'Triggered Action Time',
    id: 'app.fields.triggeredActionTime',
  },
  detected: {
    defaultMessage: 'Detected',
    id: 'app.fields.detected',
  },
  kpi: {
    defaultMessage: 'KPI',
    id: 'app.fields.kpi',
  },
  second: {
    defaultMessage: 'second',
    id: 'app.fields.second',
  },
  seconds: {
    defaultMessage: 'seconds',
    id: 'app.fields.seconds',
  },
  minute: {
    defaultMessage: 'minute',
    id: 'app.fields.minute',
  },
  minutes: {
    defaultMessage: 'minutes',
    id: 'app.fields.minutes',
  },
  hour: {
    defaultMessage: 'hour',
    id: 'app.fields.hour',
  },
  hours: {
    defaultMessage: 'hours',
    id: 'app.fields.hours',
  },
  day: {
    defaultMessage: 'day',
    id: 'app.fields.day',
  },
  days: {
    defaultMessage: 'days',
    id: 'app.fields.days',
  },
  month: {
    defaultMessage: 'month',
    id: 'app.fields.month',
  },
  months: {
    defaultMessage: 'months',
    id: 'app.fields.months',
  },
  year: {
    defaultMessage: 'year',
    id: 'app.fields.year',
  },
  years: {
    defaultMessage: 'years',
    id: 'app.fields.years',
  },
  Month: {
    defaultMessage: 'Month',
    id: 'app.fields.Month',
  },
  completeYourSignup: {
    defaultMessage: 'Complete Your Signup',
    id: 'app.fields.completeYourSignup',
  },
  relevance: {
    defaultMessage: 'Relevance',
    id: 'app.fields.relevance',
  },
  abnomalMetrics: {
    defaultMessage: 'Abnomal Metrics',
    id: 'app.fields.abnomalMetrics',
  },
  normalMetrics: {
    defaultMessage: 'Normal Metrics',
    id: 'app.fields.normalMetrics',
  },
  abnomalInstances: {
    defaultMessage: 'Abnomal Instances',
    id: 'app.fields.abnomalInstances',
  },
  normalInstances: {
    defaultMessage: 'Normal Instances',
    id: 'app.fields.normalInstances',
  },
  selectionToShowAllAbnormalInstances: {
    defaultMessage: 'Selection to show all abnormal instances',
    id: 'app.fields.selectionToShowAllAbnormalInstances',
  },
  selectAll: {
    defaultMessage: 'Select all',
    id: 'app.fields.selectAll',
  },
  selectMatchedOnly: {
    defaultMessage: 'Select matched only',
    id: 'app.fields.selectMatchedOnly',
  },
  selectAllThisPage: {
    defaultMessage: 'Select all in current page',
    id: 'app.fields.selectAllThisPage',
  },
  selectAllAnomalyThisPage: {
    defaultMessage: 'Select all anomalous in current page',
    id: 'app.fields.selectAllAnomalyThisPage',
  },
  selectAllAnomaly: {
    defaultMessage: 'Select All Anomalous',
    id: 'app.fields.selectAllAnomaly',
  },
  abnomalAndNormal: {
    defaultMessage: 'Abnomal/Normal',
    id: 'app.fields.abnomalAndNormal',
  },
  anomalous: {
    defaultMessage: 'Anomalies',
    id: 'app.fields.anomalous',
  },
  viewResults: {
    defaultMessage: 'View results',
    id: 'app.fields.viewResults',
  },
  viewExistingData: {
    defaultMessage: 'View existing data',
    id: 'app.fields.viewExistingData',
  },
  uploadNewData: {
    defaultMessage: 'Upload new data',
    id: 'app.fields.uploadNewData',
  },
  changes: {
    defaultMessage: 'Changes',
    id: 'app.fields.changes',
  },
  traces: {
    defaultMessage: 'Traces',
    id: 'app.fields.traces',
  },
  alerts: {
    defaultMessage: 'Alerts',
    id: 'app.fields.alerts',
  },
  and: {
    defaultMessage: 'and',
    id: 'app.fields.and',
  },
  waiting: {
    defaultMessage: 'Waiting',
    id: 'app.fields.waiting',
  },
  icon: {
    defaultMessage: 'Icon',
    id: 'app.fields.icon',
  },
  missingData: {
    defaultMessage: 'Missing Data',
    id: 'app.fields.missingData',
  },
  thresholds: {
    defaultMessage: 'thresholds',
    id: 'app.fields.thresholds',
  },
  owner: {
    defaultMessage: 'Owner',
    id: 'app.fields.owner',
  },
  every: {
    defaultMessage: 'Every',
    id: 'app.fields.every',
  },
  at: {
    defaultMessage: 'at',
    id: 'app.fields.at',
  },
  on: {
    defaultMessage: 'on',
    id: 'app.fields.on',
  },
  week: {
    defaultMessage: 'week',
    id: 'app.fields.week',
  },
  mode: {
    defaultMessage: 'Mode',
    id: 'app.fields.mode',
  },
  from: {
    defaultMessage: 'From',
    id: 'app.fields.from',
  },
  to: {
    defaultMessage: 'To',
    id: 'app.fields.to',
  },
  operation: {
    defaultMessage: 'Operation',
    id: 'app.fields.operation',
  },
  parameter: {
    defaultMessage: 'Script parameter',
    id: 'app.fields.parameter',
  },
  command: {
    defaultMessage: 'Command',
    id: 'app.fields.command',
  },
  script: {
    defaultMessage: 'Script file name',
    id: 'app.fields.script',
  },
  Jan: {
    defaultMessage: 'Jan',
    id: 'app.fields.Jan',
  },
  Feb: {
    defaultMessage: 'Feb',
    id: 'app.fields.Feb',
  },
  Mar: {
    defaultMessage: 'Mar',
    id: 'app.fields.Mar',
  },
  Apr: {
    defaultMessage: 'Apr',
    id: 'app.fields.Apr',
  },
  May: {
    defaultMessage: 'May',
    id: 'app.fields.May',
  },
  Jun: {
    defaultMessage: 'Jun',
    id: 'app.fields.Jun',
  },
  Jul: {
    defaultMessage: 'Jul',
    id: 'app.fields.Jul',
  },
  Aug: {
    defaultMessage: 'Aug',
    id: 'app.fields.Aug',
  },
  Sep: {
    defaultMessage: 'Sep',
    id: 'app.fields.Sep',
  },
  Oct: {
    defaultMessage: 'Oct',
    id: 'app.fields.Oct',
  },
  Nov: {
    defaultMessage: 'Nov',
    id: 'app.fields.Nov',
  },
  Dec: {
    defaultMessage: 'Dec',
    id: 'app.fields.Dec',
  },
  checkAll: {
    defaultMessage: 'Check all',
    id: 'app.fields.checkAll',
  },
  configuration: {
    defaultMessage: 'Configuration',
    id: 'app.fields.configuration',
  },
  dragAndDrop: {
    defaultMessage: 'Drag and drop',
    id: 'app.fields.dragAndDrop',
  },
  exportAs: {
    defaultMessage: 'Export as',
    id: 'app.fields.exportAs',
  },
  checkedOn: {
    defaultMessage: 'on',
    id: 'app.fields.checkedOn',
  },
  checkedOff: {
    defaultMessage: 'off',
    id: 'app.fields.checkedOff',
  },
  custom: {
    defaultMessage: 'Custom',
    id: 'app.fields.custom',
  },
  sort: {
    defaultMessage: 'Sort',
    id: 'app.fields.sort',
  },
  sorting: {
    defaultMessage: 'Sorting',
    id: 'app.fields.sorting',
  },
  hideChartsWithoutData: {
    defaultMessage: 'Hide charts without data',
    id: 'app.fields.hideChartsWithoutData',
  },
  useHealthView: {
    defaultMessage:
      'Welcome to the unified health view of your systems. Please click on any card to view the details of detected insights.',
    id: 'app.fields.useHealthView',
  },
  title: {
    defaultMessage: 'Title',
    id: 'app.fields.title',
  },
  channel: {
    defaultMessage: 'Channel',
    id: 'app.fields.channel',
  },
  options: {
    defaultMessage: 'Options',
    id: 'app.fields.options',
  },
});

export default appFieldsMessages;
