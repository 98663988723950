import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { InputNumber, Select } from 'antd';

import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

import { State } from '../../../common/types';

import ActiveTriggerHeader from './ActiveTriggerHeader';
import DealActiveButton from './DealActiveButton';
import { verifyContent } from '../data/utils';

import { appFieldsMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import { eventMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';

function PatternTriggerCore(props: Object) {
  const { intl } = props || {};
  const { activeWorkNode } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};

  const { nodeData = {} } = activeWorkNode?.data || {};
  const { pattern, frequency, dampening } = nodeData;

  const { hasPatternError, hasFrequencyError, hasDampeningError, hasError } = verifyContent(nodeData);
  return (
    <>
      <ActiveTriggerHeader />
      <div className="flex-grow" style={{ overflowY: 'scroll', paddingRight: 14 }}>
        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span style={{ fontSize: 12, marginBottom: 4 }}>
            {intl.formatMessage(appFieldsMessages.pattern)}
            <span style={{ color: 'red' }}>*</span>
          </span>
          <Select
            size="small"
            value={pattern}
            onChange={(value) =>
              contextSetState({
                activeWorkNode: {
                  ...activeWorkNode,
                  data: { ...activeWorkNode.data, nodeData: { ...nodeData, pattern: value } },
                },
              })
            }
            options={[
              { value: 'Pattern 1', label: 'Pattern 1' },
              { value: 'Pattern 2', label: 'Pattern 2' },
              { value: 'Pattern 3', label: 'Pattern 3' },
              { value: 'Pattern 4', label: 'Pattern 4' },
            ]}
            className={`${hasPatternError ? 'jsonKeyNoneError' : ''}`}
          />
        </div>
        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span className="font-12" style={{ marginBottom: 4 }}>
            {intl.formatMessage(logMessages.frequency)}
            <span style={{ color: 'red' }}>*</span>
          </span>
          <Select
            size="small"
            value={frequency}
            onChange={(value) =>
              contextSetState({
                activeWorkNode: {
                  ...activeWorkNode,
                  data: { ...activeWorkNode.data, nodeData: { ...nodeData, frequency: value } },
                },
              })
            }
            options={[
              { value: 'always', label: 'Always' },
              { value: 'once', label: 'Once' },
            ]}
            className={`${hasFrequencyError ? 'jsonKeyNoneError' : ''}`}
          />
        </div>
        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span className="font-12" style={{ marginBottom: 4 }}>
            {intl.formatMessage(settingsMessages.dampeningPeriod)}
            <span style={{ color: 'red' }}>*</span>
          </span>
          <InputNumber
            min={0}
            size="small"
            style={{ width: '100%' }}
            value={dampening}
            onChange={(dampening) =>
              contextSetState({
                activeWorkNode: {
                  ...activeWorkNode,
                  data: { ...activeWorkNode.data, nodeData: { ...nodeData, dampening: dampening || 0 } },
                },
              })
            }
            className={`${hasDampeningError ? 'inputIsNil' : ''}`}
          />
        </div>
        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span style={{ fontSize: 12, marginBottom: 4 }}>{intl.formatMessage(eventMessages.shortDescription)}:</span>
          <div>xxxxxxx</div>
        </div>
      </div>
      <DealActiveButton hasError={hasError} />
    </>
  );
}

const PatternTrigger = injectIntl(PatternTriggerCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(PatternTrigger);
