import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { State } from '../../../common/types';
import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

function EditInputCore({ workFlowInfo = {}, inputStyle = {}, textStyle = {}, handleChange }: Object) {
  const { workNodes, activeWorkNode } = useContext(workFlowContext) || {};
  const { nodeEditTitle } = useContext(workFlowDispatchContext) || {};

  const [editFlag, setEditFlag] = useState(false);
  const [title, setTitle] = useState('');
  const { title: nodeTitle } = workFlowInfo || {};

  useEffect(() => {
    setTitle(nodeTitle);
  }, [nodeTitle]);

  const editNodeTitle = () => {
    setEditFlag(false);
    let newTitle = title;
    if (!title) {
      newTitle = `${handleChange ? 'Untitled workflow' : workFlowInfo.localTitle}-${+new Date()}`;
    }
    setTitle(newTitle);
    if (handleChange) {
      handleChange(newTitle);
      return;
    }
    if (!R.equals(nodeTitle, newTitle)) nodeEditTitle(newTitle, workNodes, activeWorkNode);
  };

  return (
    <>
      {editFlag ? (
        <Input
          autoFocus
          size="small"
          value={title}
          onBlur={editNodeTitle}
          onPressEnter={editNodeTitle}
          onChange={({ target: { value } }) => setTitle(value)}
          className="flex-row flex-center-align bold"
          style={{ ...inputStyle }}
        />
      ) : (
        <div className="flex-row flex-grow full-width" style={{ alignItems: 'baseline' }}>
          <div
            className="clickable bold hidden-line-with-ellipsis"
            style={{ ...textStyle }}
            onClick={() => setEditFlag(true)}
          >
            {title}
          </div>
          <EditOutlined
            className="clickable bold font-18"
            style={{ marginLeft: 8 }}
            onClick={() => setEditFlag(true)}
          />
        </div>
      )}
    </>
  );
}

const EditInput = injectIntl(EditInputCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(EditInput);
