import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { CloseCircleOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { State } from '../../../common/types';
import { Handle, Position } from '../../../lib/reactflow/core';
import { Popover } from '../../../lib/fui/react';
import { getNodeTitle, nodeContent, nodeHeight, nodeWidth, returnIcon, verifyContent } from '../data/utils';

import OptionHtml from './OptionHtml';

const CustomNodesCore = memo((props: Object) => {
  const { data: nodeBaseData, intl, isDark } = props || {};
  const { isRoot, id, addNodes, deleteNodes, activeNodeFlag, nodeData } = nodeBaseData || {};
  const { title } = nodeData || {};
  const isHasData = !!nodeData;
  const { hasError } = verifyContent(nodeData);

  return (
    <>
      <div className="visibility-display" style={{ position: 'relative' }}>
        <Handle type="target" position={Position.Top} id="a" style={{ visibility: 'hidden' }} />
        <div
          className="nodrag flex-col"
          style={{
            maxWidth: nodeWidth,
            minWidth: nodeWidth,
            maxHeight: nodeHeight,
            minHeight: nodeHeight,
            fontSize: 14,
            border: `2px solid ${
              !isHasData || hasError
                ? 'var(--primary-color)'
                : activeNodeFlag
                ? 'var(--react-flow-edge-selected-color)'
                : 'var(--type-gray)'
            }`,
            background: 'var(--content-background)',
            ...(activeNodeFlag ? { boxShadow: '0 2px 12px 0 var(--react-flow-edge-selected-color)' } : {}),
            ...(isRoot ? { borderRadius: '50%', padding: '15px 0' } : { borderRadius: 8 }),
          }}
        >
          <div
            className="flex-row flex-center-align"
            style={{ padding: `${isRoot ? '4px 20px' : '6px 8px'}`, borderBottom: '1px solid var(--type-gray)' }}
          >
            <div className="flex-row flex-center-align flex-grow overflow-hidden">
              {isRoot && <div style={{ marginRight: 4 }}>{returnIcon()}</div>}
              <span className="hidden-line-with-ellipsis flex-grow">{getNodeTitle(isRoot, title)}</span>
              {isHasData && !hasError && (
                <Popover
                  content={<OptionHtml nodeData={nodeData} intl={intl} isDark={isDark} />}
                  mouseEnterDelay={0.3}
                  placement="right"
                  destroyTooltipOnHide
                >
                  <InfoCircleOutlined className="clickable light-label" />
                </Popover>
              )}
            </div>
            {!isRoot && (
              <div className="font-16 clickable" style={{ position: 'absolute', top: -8, right: -4 }}>
                <CloseCircleOutlined
                  style={{
                    color: 'var(--type-gray)',
                    borderRadius: '50%',
                    background: 'var(--content-background)',
                  }}
                />
                <div
                  id="delete-node-id"
                  className="full-width full-height"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  onClick={() => deleteNodes(id)}
                />
              </div>
            )}
          </div>
          <div
            className="hidden-line-with-ellipsis-multiline break-word light-label font-12"
            style={{ padding: `${isRoot ? '4px 20px 0px 20px' : '8px 8px 0px 8px'}`, marginBottom: isRoot ? 6 : 1 }}
          >
            {nodeContent(nodeData)}
          </div>
          <div
            className="corner-4 flex-row flex-center-align flex-center-justify"
            style={{
              width: 24,
              height: 24,
              background: isHasData && !hasError ? 'var(--primary-color)' : 'var(--type-gray)',
              position: 'absolute',
              left: '50%',
              bottom: -4,
              transform: 'translateX(-50%)',
            }}
          >
            <PlusOutlined style={{ color: '#fff' }} />
            <div
              id="add-node-id"
              className="full-width full-height"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                padding: 0,
                cursor: isHasData && !hasError ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                if (!isHasData || hasError) return;
                addNodes(id, isRoot);
              }}
            />
          </div>
        </div>
        <Handle type="source" position={Position.Bottom} id="c" style={{ visibility: 'hidden' }} />
      </div>
    </>
  );
});

const CustomNodes = injectIntl(CustomNodesCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(CustomNodes);
