import React from 'react';
import { AlertOutlined, CodeOutlined, MailOutlined, StockOutlined } from '@ant-design/icons';

import {
  patternTriggerInitData,
  metricThresholdTriggerInitData,
  emailActionInitData,
  codeActionInitData,
  kubernetesHPAActionInitData,
  kubernetesRestartActionInitData,
  kubernetesJobActionInitData,
} from './initData';
import { KubernetesIcon } from '../../../lib/fui/icons';

import PatternTrigger from '../components/PatternTrigger';
import MetricThresholdTrigger from '../components/MetricThresholdTrigger';
import EmailAction from '../components/EmailAction';
import CodeAction from '../components/CodeAction';
import KubernetesHPAAction from '../components/KubernetesHPAAction';
import KubernetesRestartAction from '../components/KubernetesRestartAction';
import KubernetesJobAction from '../components/KubernetesJobAction';

export const rootSelectInfos = () => {
  return {
    pattern: {
      title: 'Pattern',
      dataList: [
        {
          isRoot: true,
          id: 'patternTrigger',
          title: 'Pattern trigger',
          icon: <AlertOutlined />,
          initData: patternTriggerInitData,
          desc: 'Pattern trigger.',
          component: () => <PatternTrigger />,
        },
      ],
    },
    metricThreshold: {
      title: 'Metric threshold',
      dataList: [
        {
          isRoot: true,
          id: 'metricThreshold',
          title: 'Metric threshold trigger',
          icon: <StockOutlined />,
          initData: metricThresholdTriggerInitData,
          desc: 'Metric threshold trigger.',
          component: () => <MetricThresholdTrigger />,
        },
      ],
    },
  };
};

export const ordinarySelectInfos = () => {
  return {
    notification: {
      title: 'Notification action',
      dataList: [
        {
          isRoot: false,
          id: 'emailAction',
          title: 'Email action',
          icon: <MailOutlined />,
          initData: emailActionInitData,
          desc: 'Email action',
          component: () => <EmailAction />,
        },
      ],
    },
    codeAction: {
      title: 'Code action',
      dataList: [
        {
          isRoot: false,
          id: 'codeAction',
          title: 'Code action',
          icon: <CodeOutlined />,
          initData: codeActionInitData,
          desc: 'Code action',
          component: () => <CodeAction />,
        },
      ],
    },
    kubernetes: {
      title: 'Kubernetes action',
      dataList: [
        {
          isRoot: false,
          id: 'kubernetesHPA',
          title: 'Kubernetes HPA action',
          icon: <KubernetesIcon />,
          initData: kubernetesHPAActionInitData,
          desc: 'Kubernetes HPA action',
          component: () => <KubernetesHPAAction />,
        },
        {
          isRoot: false,
          id: 'kubernetesRestart',
          title: 'Kubernetes restart action',
          icon: <KubernetesIcon />,
          initData: kubernetesRestartActionInitData,
          desc: 'Kubernetes restart action',
          component: () => <KubernetesRestartAction />,
        },
        {
          isRoot: false,
          id: 'kubernetesJob',
          title: 'Kubernetes job action',
          icon: <KubernetesIcon />,
          initData: kubernetesJobActionInitData,
          desc: 'Kubernetes job action',
          component: () => <KubernetesJobAction />,
        },
      ],
    },
  };
};
