import React from 'react';
import * as R from 'ramda';

import { CodeMirror } from '../../../lib/fui/react';

import { appFieldsMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';

const PatternTriggerHtml = ({ intl, nodeData }: Object) => {
  const { pattern, frequency, dampening } = nodeData || {};
  return (
    <div className="formatter-wrap">
      <div>
        <span className="formatter-lable">{intl.formatMessage(appFieldsMessages.pattern)}:</span>
        <span className="formatter-content">{pattern}</span>
      </div>
      <div>
        <span className="formatter-lable">{intl.formatMessage(logMessages.frequency)}:</span>
        <span className="formatter-content">{frequency}</span>
      </div>
      <div>
        <span className="formatter-lable">Dampening period:</span>
        <span className="formatter-content">{dampening} Minutes</span>
      </div>
      <div>
        <span className="formatter-lable">{intl.formatMessage(eventMessages.shortDescription)}:</span>
        <span className="formatter-content">xxx</span>
      </div>
    </div>
  );
};

const MetricThresholdHtml = ({ intl, nodeData }: Object) => {
  const { durationThreshold, timeUnit, ruleList } = nodeData || {};
  const timeUnitOption = {
    60000: intl.formatMessage(appFieldsMessages.minute),
    3600000: intl.formatMessage(appFieldsMessages.hour),
    86400000: intl.formatMessage(appFieldsMessages.day),
  };

  let content = '';
  R.addIndex(R.forEach)((rule, idx) => {
    const { metric, condition, threshold, logic } = rule || {};
    const nextRule = (ruleList || [])[idx];
    content += `${metric} ${condition} ${threshold} ${logic}${nextRule ? ' ' : ''}`;
  }, ruleList || []);

  return (
    <div className="formatter-wrap">
      <div>
        <span className="formatter-lable">Duration threshold:</span>
        <span className="formatter-content">
          {durationThreshold} {timeUnitOption[R.toString(timeUnit || 3600000)]}
        </span>
      </div>
      <div>
        <span className="formatter-lable">{intl.formatMessage(logMessages.rule)}:</span>
        <span className="formatter-content">{content}</span>
      </div>
    </div>
  );
};

const EmailActionHtml = ({ intl, nodeData }: Object) => {
  const { email } = nodeData || {};
  return (
    <div className="formatter-wrap">
      <div>
        <span className="formatter-lable" style={{ width: 40 }}>
          {intl.formatMessage(appFieldsMessages.email)}:
        </span>
        <span className="formatter-content">{email}</span>
      </div>
    </div>
  );
};

const CodeActionHtml = ({ intl, nodeData, isDark }: Object) => {
  const { codeName, codeContent } = nodeData || {};
  return (
    <div className="formatter-wrap">
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          {intl.formatMessage(eventActionMessages.actionName)}:
        </span>
        <span className="formatter-content">{codeName}</span>
      </div>
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          Code:
        </span>
        <span className="formatter-content" style={{ width: 400 }}>
          <CodeMirror
            className="codeMirror_auto"
            options={{
              readOnly: 'nocursor',
              lineNumbers: false,
              theme: isDark ? 'material' : 'xq-light',
            }}
            value={codeContent}
          />
        </span>
      </div>
    </div>
  );
};

const KubernetesHPAHtml = ({ intl, nodeData }: Object) => {
  const { nameSpace, deployment, min, max } = nodeData || {};
  return (
    <div className="formatter-wrap">
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          {intl.formatMessage(settingsMessages.namespace)}:
        </span>
        <span className="formatter-content">{nameSpace}</span>
      </div>
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          Deployment:
        </span>
        <span className="formatter-content">{deployment}</span>
      </div>
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          Constraint:
        </span>
        <span className="formatter-content">
          <div>
            {intl.formatMessage(appFieldsMessages.min)}: {min}
          </div>
          <div>
            {intl.formatMessage(appFieldsMessages.max)}: {max}
          </div>
        </span>
      </div>
    </div>
  );
};

const KubernetesRestartHtml = ({ intl, nodeData }: Object) => {
  const { nameSpace, deployment } = nodeData || {};
  return (
    <div className="formatter-wrap">
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          {intl.formatMessage(settingsMessages.namespace)}:
        </span>
        <span className="formatter-content">{nameSpace}</span>
      </div>
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          Deployment:
        </span>
        <span className="formatter-content">{deployment}</span>
      </div>
    </div>
  );
};

const KubernetesJobHtml = ({ intl, nodeData, isDark }: Object) => {
  const { nameSpace, deployment, codeContent } = nodeData || {};
  return (
    <div className="formatter-wrap">
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          {intl.formatMessage(settingsMessages.namespace)}:
        </span>
        <span className="formatter-content">{nameSpace}</span>
      </div>
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          Deployment:
        </span>
        <span className="formatter-content">{deployment}</span>
      </div>
      <div>
        <span className="formatter-lable" style={{ width: 80 }}>
          Code:
        </span>
        <span className="formatter-content" style={{ width: 400 }}>
          <CodeMirror
            className="codeMirror_auto"
            options={{
              readOnly: 'nocursor',
              lineNumbers: false,
              theme: isDark ? 'material' : 'xq-light',
            }}
            value={codeContent}
          />
        </span>
      </div>
    </div>
  );
};

const OptionHtml = (props: Object) => {
  const { nodeData, intl, isDark } = props || {};
  const { nodeType } = nodeData || {};

  if (nodeType === 'metricThreshold') {
    return <MetricThresholdHtml nodeData={nodeData} intl={intl} />;
  } else if (nodeType === 'emailAction') {
    return <EmailActionHtml nodeData={nodeData} intl={intl} />;
  } else if (nodeType === 'codeAction') {
    return <CodeActionHtml nodeData={nodeData} intl={intl} isDark={isDark} />;
  } else if (nodeType === 'kubernetesHPA') {
    return <KubernetesHPAHtml nodeData={nodeData} intl={intl} />;
  } else if (nodeType === 'kubernetesRestart') {
    return <KubernetesRestartHtml nodeData={nodeData} intl={intl} />;
  } else if (nodeType === 'kubernetesJob') {
    return <KubernetesJobHtml nodeData={nodeData} intl={intl} isDark={isDark} />;
  }

  return <PatternTriggerHtml nodeData={nodeData} intl={intl} />;
};

export default OptionHtml;
