/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */
/*  @flow */
/* eslint-disable no-console */

import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { get, isBoolean, isFinite } from 'lodash';

import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import fetchPost from './fetchPost';
import { parseJSON, parseLocation } from '../utils';
import NoAuthError from '../errors/NoAuthError';

const MAX_FATCH_NUM = 30;

const getInitData = async (credentials, userInfo, customParams) => {
  // If it's admin, get the full user list which will be used in global view.
  const { role } = userInfo;
  const isAdmin = ['Admin', 'LocalAdmin'].indexOf(role) >= 0;
  // If it's admin, get the user list first
  let userList = [];
  let customerName = credentials.userName;
  if (isAdmin) {
    try {
      const adminAssignRole = await fetchGet(`${window.BASE_URL || ''}/adminAssignRole`, {
        ...credentials,
      });
      // get user list info
      userList = get(adminAssignRole, 'message', []);
      userList = R.sort((a, b) => (a.userName || '').localeCompare(b.userName), userList);
      userList = R.map((user) => {
        const { zone } = user;
        let userZoneOffset = 0;
        if (zone) {
          try {
            userZoneOffset = -momenttz.tz.zone(zone).utcOffset(moment.utc());
          } catch (e) {
            console.warn(`Timezone: ${zone}  is incorrect!`);
            console.debug(e);
          }
        }
        return { ...user, userZoneOffset };
      }, userList);
      userList = R.filter((item) => item.role !== 'Admin', userList || []);

      // Get the customerName
      const cname = parseLocation(window.location)?.customerName;
      if (cname) {
        customerName = cname;
      }

      if (isAdmin && !cname && userList && userList.length > 0) {
        const user = userList[0] || R.find((u) => u.userName === 'user' || u.userName === 'guest', userList);
        if (user) {
          customerName = user.userName;
        }
      }
    } catch (ex) {
      console.warn('[IF] adminAssignRole failed', ex);
    }
  }

  const requests = [
    fetchGet(getEndpoint('systemframework', 2), {
      ...credentials,
      customerName: customParams?.customerName || customerName,
      needDetail: false,
    }).catch((e) => {
      console.warn('[IF] systemframework exception', e);
      return {};
    }),

    fetchGet(getEndpoint('loadinitdata'), {
      ...credentials,
      customerName: customParams?.customerName || customerName,
      skipProjectList: true,
    }),
    fetchGet(getEndpoint('projectWithNoSystem', 2), {
      ...credentials,
      customerName: customParams?.customerName || customerName,
    }),
  ];

  return Promise.all(requests).then(async (results) => {
    // get system info map
    const systemData = results[0];

    let { ownSystemArr, shareSystemArr } = systemData;
    ownSystemArr = R.addIndex(R.map)((item, index) => {
      const system = parseJSON(item) || {};
      return { ...system, isShared: false };
    }, ownSystemArr || []);
    shareSystemArr = R.addIndex(R.map)((item, index) => {
      const system = parseJSON(item) || {};
      return { ...system, isShared: true };
    }, shareSystemArr || []);

    const systemsMap = {};
    const projectSystemKeyMap = {};
    let systemProjects = [];

    R.forEach(
      (system) => {
        const { systemDisplayName, systemKey, projectDetailsList, isShared, ...rest } = system;
        const { environmentName, systemName: systemId, userName } = systemKey || {};
        let newProjects = parseJSON(projectDetailsList) || [];
        newProjects = R.map((item) => {
          const { userName, projectClassType, ...restItem } = item;
          return {
            ...restItem,
            isShared,
            customerName: userName,
            projectType: projectClassType,
          };
        }, newProjects);
        systemProjects = systemProjects.concat(newProjects);
        if (!systemsMap[systemId]) {
          systemsMap[systemId] = {
            ...rest,
            isShared,
            environmentName,
            systemId,
            systemName: systemDisplayName || systemId,
            owner: userName,
            projectDetailsList: newProjects,
          };
        }

        R.forEach((item) => {
          const { projectName, customerName } = item;
          const projectFullName = `${projectName}@${customerName}`;
          projectSystemKeyMap[projectFullName] = systemId;
        }, newProjects);
      },
      [...ownSystemArr, ...shareSystemArr],
    );

    // get project info list
    const projectData = results[1];
    // Check the login issue
    const { success, message, errorCode } = projectData || {};
    if (isBoolean(success) && success === false) {
      if (errorCode === 5) {
        throw new NoAuthError(message);
      }
      throw projectData;
    }

    const { licenseValid, expirationDate, defaultTimezone } = projectData;
    let licenseWillExpired = false;
    if (isFinite(expirationDate) && expirationDate <= moment.utc().valueOf() + 7 * 24 * 60 * 60 * 1000) {
      licenseWillExpired = true;
    }

    // The initial projet list only contains basic information of the project, the hasAllInfo
    // is the flag to check whether load full information is needed.
    let noSystemProjects = get(results, [2, 'noSystemProjectList'], '[]');
    noSystemProjects = JSON.parse(noSystemProjects);
    noSystemProjects = R.map((item) => parseJSON(item), noSystemProjects);

    let projects = systemProjects.concat(noSystemProjects);
    projects = R.uniqBy((n) => `${n.projectName}-${n.projectDisplayName}-${n.customerName}-${n.dataType}`, projects);
    projects = R.filter((item) => item.projectName, projects);

    let projectStats = {};
    const statusSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, projects || []);
    const statusRequests = [];
    R.forEach((group) => {
      if (group.length > 0) {
        statusRequests.push(
          fetchPost(getEndpoint('getprojectstatus'), {
            ...credentials,
            projectList: JSON.stringify(group),
          }),
        );
      }
    }, statusSplitEveryTakes || []);
    await Promise.all(statusRequests)
      .then((res) => {
        let groupProjectStats = {};
        R.forEach((data) => {
          groupProjectStats = { ...groupProjectStats, ...get(data, 'data', {}) };
        }, res || []);
        projectStats = R.mapObjIndexed((item) => {
          return { ...item, projectStatus: parseJSON(item.projectStatus) };
        }, groupProjectStats);
      })
      .catch((err) => {
        console.error('[IF] Failed to get project stats', err);
        return {};
      });

    R.forEach((item) => {
      R.forEachObjIndexed((_item) => {
        if (`${item.projectName}-${item.customerName}` === `${_item.projectName}-${_item.customerName}`) {
          const { projectStatus } = _item || {};
          const {
            e: earliestProcessTimestamp,
            lp: latestProcessTimestamp,
            lc: latestCollectTimestamp,
            lm: lastMissingDate,
            pr: processRate,
            ps: status,
            projectKey,
          } = projectStatus || {};
          item.ps = status;
          item.status = status;
          item.earliestProcessTimestamp = earliestProcessTimestamp;
          item.latestProcessTimestamp = latestProcessTimestamp;
          item.latestCollectTimestamp = latestCollectTimestamp;
          item.lastMissingDate = lastMissingDate;
          item.processRate = processRate;
          item.projectKey = projectKey;
        }
      }, projectStats);
    }, projects || []);

    const projectList = R.map((item) => {
      const { customerName, projectName, projectType } = item;
      return { customerName, projectName, projectType };
    }, projects);

    const metaSplitEveryTakes = R.splitEvery(MAX_FATCH_NUM, projectList || []);
    const metaRequests = [];
    R.forEach((group) => {
      if (group.length > 0) {
        metaRequests.push(
          fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
            ...credentials,
            projectList: JSON.stringify(group),
          }),
        );
      }
    }, metaSplitEveryTakes || []);
    await Promise.all(metaRequests)
      .then((res) => {
        let groupMetaProject = [];
        R.forEach((data) => {
          groupMetaProject = [...groupMetaProject, ...get(data, 'data', [])];
        }, res || []);
        R.forEach((item) => {
          const findInfo = R.find((_item) => _item.projectKey === item.projectKey, groupMetaProject);
          if (findInfo) {
            const { projectKey, cloudType, insightAgentType, projectCreationTime: creationTime } = findInfo || {};
            const { samplingInterval, isHolistic, isContainer, projectDisplayName } = findInfo || {};
            const isStationary = [
              'Historical',
              'MetricFile',
              'containerReplay',
              'LogFile',
              'ContainerHistorical',
              'SplunkReplay',
            ].includes(insightAgentType);
            const groupList = R.filter(
              (group) => Boolean(group),
              R.sort((a, b) => (a || '').localeCompare(b), get(findInfo, 'grouping', [])),
            );
            if (groupList.length === 0) groupList.push('All');

            item.projectKey = projectKey;
            item.cloudType = cloudType;
            item.insightAgentType = insightAgentType;
            item.isStationary = isStationary;
            item.creationTime = creationTime;
            item.samplingInterval = samplingInterval;
            item.samplingIntervalInSecond = samplingInterval * 60;
            item.predictionWindow = samplingInterval * 60 === 10 ? 5 / 60 : 4;
            item.isHolistic = isHolistic;
            item.isContainer = isContainer;
            item.projectDisplayName = projectDisplayName;
            item.groupList = groupList;
          }
        }, projects);
      })
      .catch((err) => {
        console.error('[IF] Failed to get project stats', err);
        return {};
      });

    // add projects from shared system
    // const ownProjectNames = R.map((item) => item.projectName, projects);
    // R.forEach((system) => {
    //   R.forEach((item) => {
    //     if (!ownProjectNames.includes(item.projectName)) {
    //       projects.push(item);
    //     }
    //   }, system.projectDetailsList);
    // }, R.filter((system) => system.isShared, R.values(systemsMap)));

    // parse projects
    projects = R.map((project) => {
      const { projectDisplayName, projectName, projectType, customerName, dataType } = project;
      const { status, ps, earliestProcessTimestamp, latestProcessTimestamp, latestCollectTimestamp } = project;
      const { lastMissingDate, processRate, insightAgentType, isStationary, creationTime, samplingInterval } = project;
      const { samplingIntervalInSecond, predictionWindow, isHolistic, isContainer, groupList } = project;

      // The project full name is used for the API calls. It includes the customerName if the current
      // user is not the owner of the project
      let newProjectName = projectName;
      if (customerName !== credentials.userName) {
        newProjectName = `${projectName}@${customerName}`;
      }

      const isMetric = dataType && dataType.toLowerCase() === 'metric';
      const isLog = dataType && ['log', 'trace'].includes(dataType.toLowerCase());
      const isTrace = dataType && dataType.toLowerCase() === 'trace';
      const isDeployment = dataType && dataType.toLowerCase() === 'deployment';
      const isAlert = dataType && dataType.toLowerCase() === 'alert';
      const isIncident = dataType && dataType.toLowerCase() === 'incident';

      // systemInfo
      const projectFullName = `${projectName}@${customerName}`;
      const systemKey = projectSystemKeyMap[projectFullName];
      const { environmentName, systemId, systemName, isShared, timezone } = get(systemsMap, [systemKey], {});

      return {
        // system info
        systemKey,
        environmentName,
        systemId,
        systemName,
        isShared,
        timezone,

        // project meta info
        hasAllInfo: false,
        hasAllInstanceInfo: false,
        projectName: newProjectName,
        projectShortName: projectName,
        projectType: projectType.toLowerCase() === 'custom' ? 'Agent' : projectType,
        projectTypeRaw: projectType,
        dataType,
        owner: customerName,
        // project status
        status,
        ps,
        earliestProcessTimestamp,
        latestProcessTimestamp,
        latestCollectTimestamp,
        lastMissingDate,
        processRate,

        // project meta info
        projectKey: project.projectKey,
        cloudType: project.cloudType,
        insightAgentType,
        isStationary,
        creationTime,
        samplingInterval,
        samplingIntervalInSecond,
        predictionWindow,
        isHolistic,
        isContainer,
        projectDisplayName: projectDisplayName || projectName,
        groupList,

        // project type
        isMetric,
        isLog,
        isTrace,
        isDeployment,
        isAlert,
        isIncident,
      };
    }, projects);
    projects = R.sort((a, b) => (a.projectName || '').localeCompare(b.projectName), projects);
    projects = R.filter((p) => R.toLower(p?.ps || '') !== 'invalid', projects || []);

    const projectDisplayMap = {};
    R.forEach((item) => {
      projectDisplayMap[item.projectName] = item.projectDisplayName;
    }, projects);

    return {
      licenseValid,
      licenseWillExpired,
      expirationDate,
      defaultTimezone,

      systemsMap,
      projects,
      projectDisplayMap,
      userList,
      shareSystemArr,
      ownSystemArr,
    };
  });
};

export default getInitData;
