/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { parseJSON } from '../utils';
// import sampleData from './mock/loadRelationStatus_sample.json';

const getCausalIncidentMetaData = (credentials: Credentials, params: Object) => {
  const { causalKey, customerName, startTime, endTime, projectList } = params;
  const request = [
    fetchGet(getEndpoint('micausalrelationstatus', 1), {
      ...credentials,
      causalKey,
      customerName,
      startTime,
      endTime,
    }),
    fetchGet(getEndpoint('instance-display-name'), {
      ...credentials,
      instanceDisplayNameRequestList: JSON.stringify(
        R.map((p) => ({ projectName: p.projectName, customerName: p.customerName }), projectList || []),
      ),
    })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return null;
      }),
  ];

  return Promise.all(request)
    .then((res) => {
      const [data, d1] = res || [];
      const { dependencyStatus, indexStatus } = data || {};
      let instanceCausalInfo = parseJSON(indexStatus) || {};
      // let instanceCausalInfo = sampleData;

      const instanceMapping = {};
      let interAllNodes = [];
      let interCausalNodes = [];
      let interCausalNodesAsTarget = [];
      let interCorrelationNodes = [];
      const intraCausalInstanceList = [];
      const intraCorrelationInstanceList = [];
      let minInterDuration = Infinity;
      let maxInterDuration = 0;
      let minInterDurationAsTarget = Infinity;
      let maxInterDurationAsTarget = 0;
      let minIntraDuration = Infinity;
      let maxIntraDuration = 0;
      // todo
      const instanceIdCausalInfoMap = {};
      instanceCausalInfo = R.mapObjIndexed((instanceCausal, instance) => {
        const {
          id,
          parentName,
          childInfo,

          hostName,
          rootNode,
          componentName,
          projectInstanceInfoList,

          haveInterCausal,
          haveInterCausalAsTarget,
          haveInterCorrelation,
          haveIntraCausal,
          haveIntraCorrelation,

          minInterCausalDuration,
          maxInterCausalDuration,
          minInterCausalDurationAsTarget,
          maxInterCausalDurationAsTarget,
          minIntraCausalDuration,
          maxIntraCausalDuration,

          haveInterMetricToMetric,
          haveInterLogToLog,
          haveInterMetricToLog,
          haveInterMetricToIncident,
          haveInterLogToIncident,
          haveInterMetricToAlert,
          haveInterLogToAlert,
          haveInterMetricToMetricAsTarget,
          haveInterLogToLogAsTarget,
          haveInterMetricToLogAsTarget,
          haveInterMetricToIncidentAsTarget,
          haveInterLogToIncidentAsTarget,
          haveInterMetricToAlertAsTarget,
          haveInterLogToAlertAsTarget,
          haveInterAlertToIncident,
          haveInterAlertToIncidentAsTarget,
        } = instanceCausal;
        instanceIdCausalInfoMap[id] = instanceCausal;
        // build new node info
        const interDuration = {
          minInterCausalDuration,
          maxInterCausalDuration,
        };
        const interDurationAsTarget = {
          minInterCausalDuration: minInterCausalDurationAsTarget,
          maxInterCausalDuration: maxInterCausalDurationAsTarget,
        };
        const filterModality = {
          haveInterMetricToMetric,
          haveInterLogToLog,
          haveInterMetricToLog,
          haveInterMetricToIncident,
          haveInterLogToIncident,
          haveInterMetricToAlert,
          haveInterLogToAlert,
          haveInterAlertToIncident,
        };
        const filterModalityAsTarget = {
          haveInterMetricToMetric: haveInterMetricToMetricAsTarget,
          haveInterLogToLog: haveInterLogToLogAsTarget,
          haveInterMetricToLog: haveInterMetricToLogAsTarget,
          haveInterMetricToIncident: haveInterMetricToIncidentAsTarget,
          haveInterLogToIncident: haveInterLogToIncidentAsTarget,
          haveInterMetricToAlert: haveInterMetricToAlertAsTarget,
          haveInterLogToAlert: haveInterLogToAlertAsTarget,
          haveInterAlertToIncident: haveInterAlertToIncidentAsTarget,
        };

        // set componentName for all container under this instance
        const isInstance = !parentName;
        const containerList = R.keys(childInfo || {});
        if (containerList.length > 0) {
          R.forEach((item) => {
            instanceMapping[item] = componentName;
          }, containerList);
        }

        const newProjectInstanceInfoList = R.map(
          (item) => ({
            instanceName: item.in || instance,
            componentName: item.cn || item.in || instance,
            content: item.c,
            isMetric: item.im,
            logInstanceName: item.li,
            needFetchAnomaly: item.nfa,
            instanceDisplayName: item.idn,
            grouping: item.g,
            parentName: item.pn,
            licenseKey: item.lk,
            isContainer: item.ic,
            zone: item.z || '',
            i: item.i,
            ini: item.ini,
            dataType: item.d,
            projectName: item.p,
            userName: item.u,
          }),
          projectInstanceInfoList || [],
        );

        const newNodeInfo = {
          id,
          isInstance,
          hasContainers: containerList.length > 0,
          parentName,
          // use for sort
          ownInstanceName: parentName || hostName,
          ownContainerName: parentName ? R.split('_', hostName)[0] : '',

          hostName,
          rootNode,
          componentName,
          projectInstanceInfoList: newProjectInstanceInfoList,

          haveInterCausal: {
            source: haveInterCausal,
            target: haveInterCausalAsTarget,
          },
          haveInterCorrelation,
          haveIntraCausal,
          haveIntraCorrelation,

          interDuration: {
            source: interDuration,
            target: interDurationAsTarget,
          },
          intraDuration: {
            minIntraCausalDuration,
            maxIntraCausalDuration,
          },

          filterModality: {
            source: filterModality,
            target: filterModalityAsTarget,
          },
        };

        // parse data
        // set component name for instance, not container
        if (isInstance) {
          instanceMapping[instance] = componentName;
        }
        interAllNodes.push(newNodeInfo);
        if (haveInterCausal) interCausalNodes.push(newNodeInfo);
        if (haveInterCausalAsTarget) interCausalNodesAsTarget.push(newNodeInfo);
        // not used for now
        if (haveInterCorrelation) interCorrelationNodes.push(newNodeInfo);
        if (haveIntraCausal) intraCausalInstanceList.push(instance);
        if (haveIntraCorrelation) intraCorrelationInstanceList.push(instance);

        minInterDuration = R.min(minInterDuration, minInterCausalDuration);
        maxInterDuration = R.max(maxInterDuration, maxInterCausalDuration);
        minInterDurationAsTarget = R.min(minInterDurationAsTarget, minInterCausalDurationAsTarget);
        maxInterDurationAsTarget = R.max(maxInterDurationAsTarget, maxInterCausalDurationAsTarget);
        minIntraDuration = R.min(minIntraDuration, minIntraCausalDuration);
        maxIntraDuration = R.max(maxIntraDuration, maxIntraCausalDuration);

        return newNodeInfo;
      }, instanceCausalInfo);

      // sort, default by ownInstanceName, second by hostName
      interAllNodes = R.sortWith(
        [
          R.ascend(R.compose(R.toLower, R.prop('ownInstanceName'))),
          R.ascend(R.compose(R.toLower, R.prop('ownContainerName'))),
        ],
        interAllNodes,
      );
      interCausalNodes = R.sortWith(
        [
          R.ascend(R.compose(R.toLower, R.prop('ownInstanceName'))),
          R.ascend(R.compose(R.toLower, R.prop('ownContainerName'))),
        ],
        interCausalNodes,
      );
      interCausalNodesAsTarget = R.sortWith(
        [
          R.ascend(R.compose(R.toLower, R.prop('ownInstanceName'))),
          R.ascend(R.compose(R.toLower, R.prop('ownContainerName'))),
        ],
        interCausalNodesAsTarget,
      );
      interCorrelationNodes = R.sortWith(
        [
          R.ascend(R.compose(R.toLower, R.prop('ownInstanceName'))),
          R.ascend(R.compose(R.toLower, R.prop('ownContainerName'))),
        ],
        interCorrelationNodes,
      );

      const instanceDisplayNameMap = {};
      R.forEach((item) => {
        const [pInfo, iList] = item || [];
        const { projectName, customerName } = pInfo || {};
        R.forEach((instanceInfo) => {
          const { instanceSet, instanceDisplayName } = instanceInfo || {};
          R.forEach((instance) => {
            instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
            instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
          }, instanceSet || []);
        }, iList || []);
      }, d1 || []);

      return {
        incidentMetaData: {
          dependencyStatus,

          instanceCausalInfo,
          instanceIdCausalInfoMap,
          instanceMapping,
          interAllNodes,
          hasContainerInter: R.reduce(
            R.or,
            false,
            R.map((node) => node.hasContainers, interAllNodes),
          ),
          interCausalNodes: {
            source: interCausalNodes,
            target: interCausalNodesAsTarget,
          },
          minInterDuration,
          maxInterDuration,
          minInterDurationAsTarget,
          maxInterDurationAsTarget,
          minIntraDuration,
          maxIntraDuration,

          // not used for now
          interCorrelationNodes,
          intraCausalInstanceList,
          intraCorrelationInstanceList,

          instanceDisplayNameMap,
        },
      };
    })
    .catch((err) => {
      console.log(err);
    });
};

export default getCausalIncidentMetaData;
