/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, round, isEmpty, isNumber, debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { LoadingOutlined, SearchOutlined, CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Tooltip, Radio, Spin, Empty, Input, Button, Alert } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Container, AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';
import { Defaults, CausalParser, parseJSON, LogRenderers } from '../../../common/utils';
import { D3Tree } from '../../share';
import { appFieldsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

import CausalRelationsModal from './CausalRelationsModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  view: String,
  refresh: Number,
  dynamicChange: Number,
  causalIncidentInfo: Object,
  incidentMetaData: Object,
  instanceName: String,
  joinDependency: Boolean,
  relationProbability: String,
  relationCount: Number,
  filterModality: String,
  filterPattern: String,
  incidentParams: Object,
  fixedCountAndProb: Boolean,
  onInstanceChange: Function,
  onFilterChange: Function,
  updateState: Function,

  intl: Object,
  match: Object,
  location: Object,
  push: Function,
  replace: Function,
  updateLastActionInfo: Function,
  credentials: Object,
  projectDisplayMap: Object,
  currentTheme: String,
};

class CausalCorrelationTreeCore extends D3Tree {
  props: Props;

  constructor(props) {
    super(props);

    this.needArrow = false;

    // local data
    this.instanceCausalInfo = {};
    this.incidentInter = {};
    this.incidentIntra = {};
    this.allInterNodes = [];
    this.allIntraNodes = [];
    this.filterNodeList = [];
    this.allInterNodesInfo = {};
    this.hasContainerInter = false;
    this.nodeExpendMap = {};

    this.relationElemInfoMap = {};
    this.relationInfoMap = {};
    this.instanceMapping = {};
    this.intraInstanceList = [];
    this.nodeRelationMap = {};
    this.nodeLogContent = {};

    this.metricUnit = [];

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 30,
    });

    // set default infos
    if (props.incidentMetaData) {
      this.resetMetaData(props);
      const { instanceName } = props;
      this.handleNodeFilter(instanceName, null);
    }

    this.state = {
      isLoading: false,
      isGraphLoading: false,

      hasRelation: false,
      relationList: [],

      rootnodeInter: this.filterNodeList[0],
      rootnode: null,
      nodeSearchVal: null,

      showLogModal: false,
      edgeRelation: null,
      leftLabel: null,
      rightLabel: null,
    };
    this.incidentMetaData = props.incidentMetaData;
  }

  async componentDidMount() {
    await this.getMetricUnit();
    this.reloadData(this.props, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextIncidentParams = nextProps.incidentParams || {};
    const incidentParams = this.props.incidentParams || {};
    if (
      nextIncidentParams.causalKey !== incidentParams.causalKey ||
      nextIncidentParams.customerName !== incidentParams.customerName ||
      nextIncidentParams.startTimestamp !== incidentParams.startTimestamp ||
      nextIncidentParams.endTimestamp !== incidentParams.endTimestamp ||
      nextIncidentParams.fileName !== incidentParams.fileName ||
      nextProps.refresh !== this.props.refresh ||
      nextProps.causalIncidentInfo !== this.props.causalIncidentInfo ||
      nextProps.joinDependency !== this.props.joinDependency ||
      nextProps.instanceName !== this.props.instanceName
    ) {
      // if joinDependency is changed, then reset leaf Node info
      if (nextProps.joinDependency !== this.props.joinDependency) this.leafNodeMap = {};

      // reload data or rerender chart
      let reload = true;
      if (nextProps.instanceName !== this.props.instanceName && !nextProps.instanceName) reload = false;
      if (reload) {
        this.reloadData(nextProps, {});
      } else {
        this.renderChart(nextProps);
      }
    } else if (nextProps.incidentMetaData !== this.props.incidentMetaData && nextProps.incidentMetaData) {
      // set default infos
      this.resetMetaData(nextProps);

      this.reloadData(nextProps, {});
    } else if (
      nextProps.relationCount !== this.props.relationCount ||
      nextProps.relationProbability !== this.props.relationProbability ||
      nextProps.filterModality !== this.props.filterModality ||
      nextProps.filterPattern !== this.props.filterPattern
    ) {
      // if the dynamicChange is changed. then no need to rerender the graph
      if (nextProps.dynamicChange === this.props.dynamicChange) {
        this.renderChart(nextProps);
      }
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  getMetricUnit() {
    return fetchGet(getEndpoint('metric-unit', 2), {})
      .then((data) => {
        const { success, message: msg, mapping } = data;
        if (success || success === undefined) {
          this.metricUnit = mapping || [];
        } else {
          console.error(msg);
        }
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  }

  @autobind
  resetMetaData(props) {
    this.instanceCausalInfo = props.incidentMetaData.instanceCausalInfo || {};
    this.intraInstanceList = props.incidentMetaData.intraCorrelationInstanceList;
    this.allInterNodes = R.map((node) => node.hostName, props.incidentMetaData.interAllNodes || []);
    this.allInterNodesInfo = R.fromPairs(
      R.map((node) => [node.hostName, node], props.incidentMetaData.interAllNodes || []),
    );
    this.hasContainerInter = props.incidentMetaData.hasContainerInter;
    this.instanceMapping = props.incidentMetaData.instanceMapping;
  }

  @autobind
  mappingData(item) {
    const mappingKey = {
      av: 'avgAnomalyValue',
      as: 'avgAnomalyScore',
      af: 'avgAnomalyFrequency',
      anv: 'avgNormalValue',
      c: 'content',
      t: 'type',
      pan: 'patternName',
      n: 'nid',
      idx: 'index',
      pb: 'probability',
      idn: 'instanceDown',
      ov: 'overAllAvgValue',
      m: 'metricDirection',
      cid: 'containerId',
      p: 'percentage',
      ia: 'isAlert',
      r: 'rawData',
      pn: 'projectName',
      cn: 'customerName',
      iip: 'instanceNameInThisProject',
    };
    const newItem = {};
    R.forEachObjIndexed((val, key) => {
      newItem[mappingKey[key] || key] = val;
    }, item || {});
    return newItem;
  }

  @autobind
  getComponentName(nodeName) {
    const { incidentParams } = this.props;
    const { causalKey } = incidentParams;
    const prefix = `${causalKey}-Component-`;
    return (nodeName || '').indexOf(prefix) >= 0 ? nodeName.replace(prefix, '') : nodeName;
  }

  @autobind
  reloadData(props, params) {
    const { credentials, onFilterChange, fixedCountAndProb } = props;
    const { view, incidentMetaData, incidentParams, instanceName, joinDependency, relationProbability, relationCount } =
      props;
    let { rootnodeInter } = this.state;
    const hasInstance = Boolean(instanceName);
    const { causalKey, customerName, startTimestamp, endTimestamp, resultStartstamp, resultEndstamp } = incidentParams;

    if (view === 'correlation' && incidentMetaData && causalKey && customerName && startTimestamp && endTimestamp) {
      // get query params
      const causalType = 'correlation';
      let operation = 'inter';
      if (hasInstance) {
        operation = 'intra';
      }
      let queryParams = {};
      if (hasInstance) {
        queryParams = { intraInstance: this.getComponentName(instanceName) };
      } else {
        rootnodeInter = rootnodeInter || this.filterNodeList[0];
        queryParams = { interInstances: JSON.stringify([rootnodeInter]) };
      }

      const loadingField = hasInstance ? 'isLoading' : 'isGraphLoading';
      this.setState({ [loadingField]: true, rootnodeInter });
      this.props.updateState({ isLoadingGraph: true });
      props.updateLastActionInfo();
      fetchPost(getEndpoint('micausalrelation', 1), {
        ...credentials,
        ...queryParams,
        causalKey,
        customerName,
        startTime: startTimestamp,
        endTime: endTimestamp,
        operation,
        // causalType,
        joinDependency,
        ...(resultStartstamp && resultEndstamp
          ? { resultStartTime: resultStartstamp, resultEndTime: resultEndstamp }
          : {}),
      })
        .then(async (result) => {
          // get causal data
          const { data, startPoint } = result || {};
          R.forEach((item) => {
            item.from = R.map((_item) => this.mappingData(_item), item.from || []);
            item.to = R.map((_item) => this.mappingData(_item), item.to || []);
          }, data || []);
          const mapping = this.metricUnit;
          const incident = CausalParser.parseIncidentRelations({
            operation,
            data,
            mapping,
            instanceName,
            withReverseData: true,
          });
          this.metricUnitMap = incident.metricUnitMap;
          this.causalNodeDegreeMap = result?.causalNodeDegreeMap || {};
          if (hasInstance) {
            this.incidentIntra = incident;
          } else {
            this.incidentInter = incident;
          }

          // if next nodes is leaf, then make node.hasChildren = true
          if (incidentMetaData.dependencyStatus && !hasInstance && joinDependency) {
            const treeNodes = R.uniq(
              R.map(
                (relation) => relation.elem2,
                R.filter((relation) => relation.elem1 === rootnodeInter, incident.relation || []),
              ),
            );
            const needPrefetchNodes = R.filter((item) => !R.has(item, this.leafNodeMap), treeNodes);
            if (treeNodes.length > 0) {
              await this.buildLeafNodeMap({
                needPrefetchNodes,
                credentials,
                causalKey,
                customerName,
                startTimestamp,
                endTimestamp,
                operation,
                // causalType,
                joinDependency,
                resultStartstamp,
                resultEndstamp,
              });
            }
          }

          // has relations
          if (incident.relation.length > 0) {
            // reset probability and count
            let { minimumProbability, minimumCount } = startPoint || {};
            minimumProbability = minimumProbability > 1 ? 1 : minimumProbability;
            minimumCount = R.max(1, minimumCount);
            const newRelationProbability =
              relationProbability !== '0.0'
                ? numeral(R.min(Number(relationProbability), minimumProbability)).format('0.0')
                : numeral(minimumProbability).format('0.0');
            const newRelationCount = relationCount > 0 ? R.min(relationCount, minimumCount) : minimumCount;

            if (
              !fixedCountAndProb &&
              (newRelationProbability !== relationProbability || newRelationCount !== relationCount)
            ) {
              onFilterChange({ relationProbability: newRelationProbability, relationCount: newRelationCount });
            } else {
              this.renderChart(this.props);
            }
          } else {
            this.renderChart(this.props);
          }
          this.setState({ [loadingField]: false });
          this.props.updateState({ isLoadingGraph: false });
        })
        .catch((err) => {
          this.setState({ [loadingField]: false });
          this.props.updateState({ isLoadingGraph: false });
        });
    }
  }

  @autobind
  onChangeRootnode(event) {
    const { instanceName } = this.props;
    const hasInstance = Boolean(instanceName);
    const fieldName = hasInstance ? 'rootnode' : 'rootnodeInter';
    const rootnodeVal = event.target.value;
    this.setState({ [fieldName]: rootnodeVal }, () => {
      if (hasInstance) {
        this.renderChart(this.props);
      } else {
        this.reloadData(this.props);
      }
    });
  }

  @autobind
  getChartData(props) {
    const { hasRelation, relationList } = this.buildGraphData(props);

    const { instanceName } = props;
    const { rootnodeInter, rootnode, nodeSearchVal } = this.state;
    const hasInstance = Boolean(instanceName);

    // build root node for intra data
    if (hasInstance) {
      // reduce relation infos
      let fromNodeNames = [];
      let toNodeNames = [];
      R.forEach((relation) => {
        const { elem1, elem2 } = relation;
        fromNodeNames = [...fromNodeNames, elem1];
        toNodeNames = [...toNodeNames, elem2];
      }, relationList);
      fromNodeNames = R.sort((a, b) => a.localeCompare(b), R.uniq(fromNodeNames));
      toNodeNames = R.sort((a, b) => a.localeCompare(b), R.uniq(toNodeNames));
      this.allIntraNodes = fromNodeNames;

      if (relationList.length >= 100) {
        this.autoDisplayLevel = 2;
      } else {
        this.autoDisplayLevel = this.defaultMaxLevel;
      }
    }

    // traverse node to build tree
    const rootnodeField = hasInstance ? 'rootnode' : 'rootnodeInter';
    let rootnodeVal = hasInstance ? rootnode : rootnodeInter;

    // filter node list
    this.handleNodeFilter(instanceName, nodeSearchVal);
    if (!rootnodeVal || (rootnodeVal && this.filterNodeList.indexOf(rootnodeVal) === -1)) {
      if (this.filterNodeList.length > 0) rootnodeVal = this.filterNodeList[0];
    }

    const startTs = moment.utc().valueOf();
    const treeData = this.createTreeData({
      nodeRelationMap: this.nodeRelationMap,
      parentAllNodeNameMap: {},
      nodeNames: rootnodeVal ? [rootnodeVal] : [],
      parentNode: null,
      parentPath: null,
      level: 1,
      hasInstance,
    });
    console.debug(`Create tree duration: ${(moment.utc().valueOf() - startTs) / 1000} sec`);

    this.setState({
      hasRelation,
      relationList,
      [rootnodeField]: rootnodeVal,
    });
    return {
      treeData: treeData.length > 0 ? treeData[0] : {},
    };
  }

  @autobind
  buildGraphData(props) {
    const { instanceName, relationProbability, relationCount, filterModality, filterPattern, incidentMetaData } = props;
    const hasInstance = Boolean(instanceName);

    const incident = hasInstance ? this.incidentIntra : this.incidentInter;
    let relationList = get(incident, ['relation'], []);
    const hasRelation = !isEmpty(relationList);

    // set all log node info
    this.relationElemInfoMap = get(incident, ['relationElemInfoMap'], {});

    relationList = R.map((item) => {
      R.forEach((_item) => {
        item.fromContents[_item.sP].sP = _item.sP;
        item.toContents[_item.tP].tP = _item.tP;
      }, item.contentTimeDifference || []);
      return item;
    }, relationList);

    // filter
    if (relationProbability) {
      // relationList = R.filter((relation) => relation.probability >= parseFloat(relationProbability), relationList);
      relationList = R.map((relation) => {
        const { probability, count } = relation;
        const newCount = get(relation, ['probabilityCountMap', relationProbability], count);
        return {
          ...relation,
          count: newCount,
          realtionColor: CausalParser.getColorByProbability(probability),
          relationWidth: CausalParser.getWidthByCount(newCount),
        };
      }, relationList);
    }
    // if (relationCount) {
    //   relationList = R.filter((relation) => relation.count >= Number(relationCount), relationList);
    // }
    if (filterModality) {
      if (filterModality !== 'all') {
        relationList = R.filter((relation) => {
          let timePairs = relation.contentTimeDifference || [];
          // if (relationProbability) {
          //   timePairs = R.filter((pair) => pair.probability >= parseFloat(relationProbability), timePairs);
          // }
          timePairs = R.filter((pair) => pair.modality === filterModality, timePairs);
          return timePairs.length > 0;
        }, relationList);
      }

      let interAllNodes = incidentMetaData.interAllNodes || [];
      switch (filterModality) {
        case 'log-metric':
          interAllNodes = R.filter((node) => node.filterModality.source.haveInterMetricToLog, interAllNodes);
          break;
        case 'metric-metric':
          interAllNodes = R.filter((node) => node.filterModality.source.haveInterMetricToMetric, interAllNodes);
          break;
        case 'log-log':
          interAllNodes = R.filter((node) => node.filterModality.source.haveInterLogToLog, interAllNodes);
          break;
        case 'incident-metric':
          interAllNodes = R.filter((node) => node.filterModality.source.haveInterMetricToIncident, interAllNodes);
          break;
        case 'incident-log':
          interAllNodes = R.filter((node) => node.filterModality.source.haveInterLogToIncident, interAllNodes);
          break;
        case 'alert-metric':
          interAllNodes = R.filter((node) => node.filterModality.source.haveInterMetricToAlert, interAllNodes);
          break;
        case 'alert-log':
          interAllNodes = R.filter((node) => node.filterModality.source.haveInterLogToAlert, interAllNodes);
          break;
        case 'alert-incident':
          interAllNodes = R.filter((node) => node.filterModality.source.haveInterAlertToIncident, interAllNodes);
          break;
        default:
          break;
      }
      this.allInterNodes = R.map((node) => node.hostName, interAllNodes);
      this.allInterNodesInfo = R.fromPairs(R.map((node) => [node.hostName, node], interAllNodes));
    }

    if (filterPattern) {
      relationList = R.filter((relation) => relation.nidList.includes(Number(filterPattern)), relationList);
    }

    // create tree data
    const relationInfoMap = {};
    let nodeRelationMap = {};

    // create node map
    R.forEach((relation) => {
      const { elem1, elem2 } = relation;

      // set relationInfoMap
      const relationKey = `${elem1}-${elem2}`;
      if (!R.has(relationKey, relationInfoMap)) {
        relationInfoMap[relationKey] = relation;

        if (!R.has(elem1, nodeRelationMap))
          nodeRelationMap[elem1] = {
            nextNodes: [],
            previousNodes: [],
            nextNodeRelations: [],
            previousNodeRelations: [],
          };
        if (!R.has(elem2, nodeRelationMap))
          nodeRelationMap[elem2] = {
            nextNodes: [],
            previousNodes: [],
            nextNodeRelations: [],
            previousNodeRelations: [],
          };

        nodeRelationMap[elem1].nextNodes.push(elem2);
        nodeRelationMap[elem2].previousNodes.push(elem1);
        nodeRelationMap[elem1].nextNodeRelations.push(relation);
        nodeRelationMap[elem2].previousNodeRelations.push(relation);
      }
    }, relationList);
    nodeRelationMap = R.mapObjIndexed((val, node) => {
      const nextNodeRelations = R.sortWith([R.descend(R.prop('probability'))], val.nextNodeRelations);
      const previousNodeRelations = R.sortWith([R.descend(R.prop('probability'))], val.previousNodeRelations);

      // set hasChildren for current inter graph
      let hasChildren = false;
      if (!hasInstance && val.previousNodes.length > 0) {
        hasChildren = get(this.instanceCausalInfo, [node, 'haveInterCorrelation']) && this.leafNodeMap[node] !== true;
      }
      return { ...val, hasChildren, nextNodeRelations, previousNodeRelations };
    }, nodeRelationMap);
    this.relationInfoMap = relationInfoMap;
    this.nodeRelationMap = nodeRelationMap;

    if (this.relationKey) {
      const relation = this.relationInfoMap[this.relationKey];
      if (relation) {
        const leftLabel = relation.elem1;
        const rightLabel = relation.elem2;
        this.setState({ edgeRelation: relation, leftLabel, rightLabel });
      }
    }

    return { hasRelation, relationList };
  }

  @autobind
  renderTip({ operation, target, d }) {
    const { intl, credentials, projectDisplayMap, currentTheme, incidentMetaData } = this.props;
    if (operation === 'node') {
      const { node, hasInstance, nodeInfo, parentNode, children, childrenBackup } = d;
      const { isLogType, contentInfo } = nodeInfo || {};

      let isLoading = false;
      let nextNodeTotal = 0;
      let nextNodeRelations = [];
      let parentRelation;
      // sepical for instanceDown/metric incident
      const { instanceDown, metricDirection, avgNormalValue, avgAnomalyValue } = contentInfo || {};
      let { content, patternName, avgValue } = contentInfo || {};
      if (R.isNil(avgValue) && isNumber(avgNormalValue)) avgValue = avgNormalValue;
      if (R.isNil(avgValue) && isNumber(avgAnomalyValue)) avgValue = avgAnomalyValue;
      const isLog = isLogType && !metricDirection && !instanceDown;
      content = content || node;
      patternName = patternName || node;

      let appName;
      let renderMetricContent = null;
      if (!hasInstance) {
        const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, node);
        appName =
          this.instanceMapping[node] && this.instanceMapping[node] !== node
            ? `${instanceStr} (${this.instanceMapping[node]})`
            : instanceStr;
        const nodeRelation = this.nodeRelationMap[node] || {};
        nextNodeRelations = children || childrenBackup ? nodeRelation.nextNodeRelations : [];
        nextNodeTotal = nextNodeRelations.length;
        nextNodeRelations = R.take(3, nextNodeRelations);
        const { previousNodeRelations } = nodeRelation;
        parentRelation = parentNode
          ? R.find((relation) => relation.elem1 === parentNode && relation.elem2 === node, previousNodeRelations)
          : null;
      } else if (isLog && !R.has(node, this.nodeLogContent)) {
        isLoading = true;
        // call api to get log content
        this.getNodeLogContent(target, d);
      } else if (!isLog) {
        if (instanceDown) {
          renderMetricContent = (
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <span>{intl.formatMessage(appFieldsMessages.missingData)}</span>
              <i className="icon down arrow" style={{ color: 'red' }} />
            </div>
          );
        } else {
          const isHigher = ['positive', 'higher'].indexOf((metricDirection || '').toLowerCase()) >= 0;
          const isLower = ['negative'].indexOf((metricDirection || '').toLowerCase()) >= 0;
          renderMetricContent = (
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                {intl.formatMessage(appFieldsMessages.value)}:
              </div>
              <div>{round(avgValue, 2)}</div>
              {isHigher && <i className="icon up arrow" />}
              {isLower && <i className="icon down arrow" />}
            </div>
          );
        }
      }

      if (isLoading) {
        return ReactDOMServer.renderToStaticMarkup(
          <div className="flex-row flex-center-justify flex-center-align" style={{ minHeight: 60, minWidth: 160 }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>,
        );
      }
      let rawDataJson;
      try {
        rawDataJson = JSON.parse(this.nodeLogContent[node] || content);
      } catch (error) {
        // console.error(error);
      }
      return ReactDOMServer.renderToStaticMarkup(
        <div
          className="flex-col overflow-y-auto"
          style={{
            fontSize: 12,
            paddingRight: 8,
            minHeight: 60,
            minWidth: 160,
            maxHeight: 300,
            maxWidth: 400,
            background: 'var(--popover-bg)',
            color: 'var(--popover-color)',
          }}
        >
          <div className="flex-row" style={{ borderBottom: '1px solid #ccc', paddingBottom: 4 }}>
            <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 8 }}>
              {!hasInstance
                ? intl.formatMessage(appFieldsMessages.instance)
                : isLog
                ? intl.formatMessage(appFieldsMessages.pattern)
                : intl.formatMessage(appFieldsMessages.metric)}
              :
            </div>
            <div className="flex-col">
              <div>{!hasInstance ? appName : isLog ? patternName : content}</div>
              {!hasInstance && (
                <div className="flex-row">
                  {`${intl.formatMessage(appFieldsMessages.project)}: `}
                  {R.join(
                    ', ',
                    R.uniq(
                      R.map((item) => {
                        const projectNameReal =
                          item.userName !== credentials.userName
                            ? `${item.projectName}@${item.userName}`
                            : item.projectName;
                        const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
                        return projectDisplayName;
                      }, get(this.instanceCausalInfo, [node, 'projectInstanceInfoList'], [])),
                    ),
                  )}
                </div>
              )}
            </div>
          </div>

          {hasInstance && isLog && !rawDataJson && (
            <div className="flex-row" style={{ wordBreak: 'break-word' }}>
              {this.nodeLogContent[node] || content}
            </div>
          )}
          {hasInstance && isLog && rawDataJson && (
            <div className="flex-row" style={{ overflowX: 'auto' }}>
              <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />
            </div>
          )}
          {hasInstance && !isLog && renderMetricContent}

          {!hasInstance && parentRelation && (
            <div className="flex-row" style={{ marginTop: 4 }}>
              <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                {intl.formatMessage(causalMessages.inboundNodeTop, { top: '' })}:
              </div>
              <div className="flex-col">
                <div
                  style={{
                    fontWeight: 500,
                    display: 'inline-block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >{`${
                  getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, parentNode)?.instanceStr ||
                  parentNode
                }`}</div>
                <div>{`${intl.formatMessage(appFieldsMessages.probability)}: ${round(
                  parentRelation.probability * 100,
                  1,
                )}%`}</div>
                <div className="flex-row">
                  {`${intl.formatMessage(appFieldsMessages.project)}: `}
                  {R.join(
                    ', ',
                    R.uniq(
                      R.map((item) => {
                        const projectNameReal =
                          item.userName !== credentials.userName
                            ? `${item.projectName}@${item.userName}`
                            : item.projectName;
                        const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
                        return projectDisplayName;
                      }, get(this.instanceCausalInfo, [node, 'projectInstanceInfoList'], [])),
                    ),
                  )}
                </div>
              </div>
            </div>
          )}
          {!hasInstance && nextNodeRelations.length > 0 && (
            <div className="flex-row" style={{ marginTop: 4 }}>
              <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                {intl.formatMessage(causalMessages.outboundNodeTop, { top: '(Top3)' })}:
              </div>
              <div className="flex-col">
                {R.addIndex(R.map)((relation, idx) => {
                  const { instanceStr } = getInstanceDisplayName(
                    incidentMetaData?.instanceDisplayNameMap,
                    relation.elem2,
                  );
                  return (
                    <div key={idx} style={{ marginBottom: 8 }}>
                      <div
                        style={{
                          fontWeight: 500,
                          display: 'inline-block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >{`${instanceStr}`}</div>
                      <div>{`${intl.formatMessage(appFieldsMessages.probability)}: ${round(
                        relation.probability * 100,
                        1,
                      )}%`}</div>
                      <div className="flex-row">
                        {`${intl.formatMessage(appFieldsMessages.project)}: `}
                        {R.join(
                          ', ',
                          R.uniq(
                            R.map((item) => {
                              const projectNameReal =
                                item.userName !== credentials.userName
                                  ? `${item.projectName}@${item.userName}`
                                  : item.projectName;
                              const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
                              return projectDisplayName;
                            }, get(this.instanceCausalInfo, [node, 'projectInstanceInfoList'], [])),
                          ),
                        )}
                      </div>
                    </div>
                  );
                }, nextNodeRelations)}
                {nextNodeTotal > 3 && <div>......</div>}
              </div>
            </div>
          )}
        </div>,
      );
    }
    return '';
  }

  @autobind
  getNodeLogContent(target, d) {
    const { node, nodeInfo } = d;
    const { typeOnly, contentInfo } = nodeInfo || {};
    const { projectName, nid, timeStamp, type, involvedInstanceSet = [] } = contentInfo || {};

    const { credentials, incidentParams, instanceName } = this.props;

    const isComponent = this.isComponentNode(instanceName);
    let { projectInstanceInfoList } = get(this.instanceCausalInfo, instanceName, {});

    if (isComponent && involvedInstanceSet.length > 0) {
      const id = involvedInstanceSet[0];
      projectInstanceInfoList = this.getComponentProjectInstanceInfoList(id);
    }

    let logProjectName;
    let logInstanceName;
    const logInfo = R.find(
      (item) => item.dataType !== 'Metric' && item.projectName === projectName,
      projectInstanceInfoList || [],
    );
    if (logInfo) {
      logProjectName = logInfo.projectName;
      logInstanceName = logInfo.instanceName;
    }

    // if (logProjectName && logInstanceName && isNumber(nid) && timeStamp && type) {
    if (logProjectName && logInstanceName && isNumber(nid) && type) {
      const logEventQueryStr = [
        {
          logProjectName,
          logInstanceName,
          nid: String(nid),
          timestamp: timeStamp || this.causalNodeDegreeMap[node]?.startTimestamp,
          type,
        },
      ];
      const customerName = get(incidentParams, 'customerName');
      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('loadlogeventsforcausal'), {
        ...credentials,
        customerName,
        logEventQueryStr: JSON.stringify(logEventQueryStr),
      }).then((data) => {
        const logContents = get(data, [logInstanceName, typeOnly], []);
        const logContent = logContents.length > 0 ? logContents[0].logContent || '' : '';
        this.nodeLogContent[node] = logContent;

        setTimeout(() => {
          // if displayed tip node is not change, then display the tip
          const { tipNode } = this;
          if (tipNode === d) {
            this.tip.show({ operation: 'node', target, d }, target);
          }
        }, 600);
      });
    }
  }

  @autobind
  nodeCircleClick(props) {
    return async (d) => {
      const { isExpandNode, node, path, children, childrenBackup, hasChildren, newParentAllNodeNameMap, level } =
        d || {};

      if (isExpandNode) {
        this.nodeClick(props)(d);
      } else {
        // Toggle children on click.
        if (children) {
          d.childrenBackup = children;
          d.children = null;
        } else if (childrenBackup) {
          d.children = childrenBackup;
          d.childrenBackup = null;
        } else if (!childrenBackup && hasChildren) {
          // Dynamically create new node children
          // update tree root. Use current node parentAllNodeNameMap to build
          const { onFilterChange, instanceName, joinDependency, relationProbability, relationCount } = this.props;
          const hasInstance = Boolean(instanceName);

          const { credentials, incidentParams, incidentMetaData } = this.props;
          const { causalKey, customerName, startTimestamp, endTimestamp } = incidentParams;
          const { resultStartstamp, resultEndstamp } = incidentParams;
          const operation = 'inter';
          const causalType = 'correlation';

          if (!hasInstance) {
            this.setState({ isGraphLoading: true });
            this.props.updateLastActionInfo();
            const result = await fetchPost(getEndpoint('micausalrelation', 1), {
              ...credentials,
              interInstances: JSON.stringify([node]),
              causalKey,
              customerName,
              startTime: startTimestamp,
              endTime: endTimestamp,
              operation,
              // causalType,
              joinDependency,
              ...(resultStartstamp && resultEndstamp
                ? { resultStartTime: resultStartstamp, resultEndTime: resultEndstamp }
                : {}),
            });

            // get causal data
            const { data, startPoint } = result || {};
            R.forEach((item) => {
              item.from = R.map((_item) => this.mappingData(_item), item.from || []);
              item.to = R.map((_item) => this.mappingData(_item), item.to || []);
            }, data || []);
            const mapping = this.metricUnit;
            const incident = CausalParser.parseIncidentRelations({
              operation: 'inter',
              data,
              mapping,
              withReverseData: true,
            });
            this.metricUnitMap = incident.metricUnitMap;
            this.causalNodeDegreeMap = result?.causalNodeDegreeMap || {};

            // merge incident
            this.incidentInter = CausalParser.mergeIncidentRelations(this.incidentInter, incident);

            // reset probability and count
            let { minimumProbability, minimumCount } = startPoint || {};
            minimumProbability = minimumProbability > 1 ? 1 : minimumProbability;
            minimumCount = R.max(1, minimumCount);
            const newRelationProbability = numeral(R.min(Number(relationProbability), minimumProbability)).format(
              '0.0',
            );
            const newRelationCount = R.min(relationCount, minimumCount);

            if (newRelationProbability !== relationProbability || newRelationCount !== relationCount) {
              onFilterChange({
                // no need to rerender the graph
                dynamicChange: moment.utc().valueOf(),
                relationProbability: newRelationProbability,
                relationCount: newRelationCount,
              });
            }

            // rebuild causal data
            this.buildGraphData(this.props);
          }

          // get next nodes
          const nodeRelation = get(this.nodeRelationMap, node);
          const childrenNodeNames = nodeRelation.nextNodes || [];

          // get the node path expand info
          if (!R.has(path, this.nodePathExpand)) {
            this.nodePathExpand[path] = { total: childrenNodeNames.length, display: 0 };
          } else {
            this.nodePathExpand[path].total = childrenNodeNames.length;
          }
          const nodeNames = R.slice(
            this.nodePathExpand[path].display,
            this.nodePathExpand[path].display + this.autoDisplayNode,
            childrenNodeNames,
          );
          const needNextNode =
            this.nodePathExpand[path].total > this.nodePathExpand[path].display + this.autoDisplayNode;

          // if next nodes is leaf, then make node.hasChildren = true
          if (incidentMetaData.dependencyStatus && !hasInstance && joinDependency) {
            const needPrefetchNodes = R.filter((item) => !R.has(item, this.leafNodeMap), nodeNames);
            if (needPrefetchNodes.length > 0) {
              await this.buildLeafNodeMap({
                needPrefetchNodes,
                credentials,
                causalKey,
                customerName,
                startTimestamp,
                endTimestamp,
                operation,
                // causalType,
                joinDependency,
                resultStartstamp,
                resultEndstamp,
              });
            }
          }

          // create new children
          const newChildren = this.createTreeData({
            nodeRelationMap: this.nodeRelationMap,
            parentAllNodeNameMap: newParentAllNodeNameMap,
            nodeNames,
            parentNode: node,
            parentPath: path,
            level: level + 1,
            hasInstance,
          });
          // update node status
          if (newChildren.length > 0) {
            d.children = newChildren;
          }
          d.hasChildren = false;

          // build expand control node
          if (needNextNode && d.children) {
            d.children.push(this.getExpandNode({ path, direction: 'next' }));
          }

          this.setState({ isGraphLoading: false });
        }
        this.updateTree(d, props);
      }
    };
  }

  @autobind
  nodeClick(props) {
    return async (d) => {
      const { root } = props;
      const { node, hasIntra, isExpandNode, path, direction } = d || {};

      if (isExpandNode) {
        this.setState({ isGraphLoading: true });

        const nodeData = this.findNodeByPath(root, path);

        // get next nodes
        const nodeRelation = get(this.nodeRelationMap, nodeData.node);
        const childrenNodeNames = nodeRelation.nextNodes || [];

        let nodeNames = [];
        let startIndex = null;
        if (direction === 'next') {
          startIndex = this.nodePathExpand[path].display + this.autoDisplayNode;
        } else {
          startIndex = R.max(0, this.nodePathExpand[path].display - this.autoDisplayNode);
        }
        this.nodePathExpand[path].display = startIndex;
        nodeNames = R.slice(startIndex, startIndex + this.autoDisplayNode, childrenNodeNames);
        const needNextNode = this.nodePathExpand[path].total > startIndex + this.autoDisplayNode;
        const needPreviousNode = startIndex > 0;

        // if next nodes is leaf, then make node.hasChildren = true
        const { credentials, instanceName, joinDependency, incidentParams } = this.props;
        const { causalKey, customerName, startTimestamp, endTimestamp } = incidentParams;
        const { resultStartstamp, resultEndstamp } = incidentParams;
        const hasInstance = Boolean(instanceName);
        const operation = 'inter';
        const causalType = 'correlation';
        if (!hasInstance && joinDependency) {
          const needPrefetchNodes = R.filter((item) => !R.has(item, this.leafNodeMap), nodeNames);
          if (needPrefetchNodes.length > 0) {
            await this.buildLeafNodeMap({
              needPrefetchNodes,
              credentials,
              causalKey,
              customerName,
              startTimestamp,
              endTimestamp,
              operation,
              // causalType,
              joinDependency,
              resultStartstamp,
              resultEndstamp,
            });
          }
        }

        // create new children
        const newChildren = this.createTreeData({
          nodeRelationMap: this.nodeRelationMap,
          parentAllNodeNameMap: nodeData.newParentAllNodeNameMap,
          nodeNames,
          parentNode: nodeData.node,
          parentPath: nodeData.path,
          level: nodeData.level + 1,
          hasInstance: nodeData.hasInstance,
        });

        // build new children
        nodeData.children = newChildren;
        if (needNextNode) {
          nodeData.children.push(this.getExpandNode({ path, direction: 'next' }));
        }
        if (needPreviousNode) {
          nodeData.children.push(this.getExpandNode({ path, direction: 'previous' }));
        }

        this.setState({ isGraphLoading: false });

        // update tree
        this.updateTree(nodeData, props);
      } else if (hasIntra) {
        this.handleInstanceClick(node);
      }
    };
  }

  @autobind
  pathClick(d) {
    const relationKey = `${d.source.node}-${d.target.node}`;
    const relation = this.relationInfoMap[relationKey];
    this.relationKey = relationKey;
    if (relation) {
      const leftLabel = relation.elem1;
      const rightLabel = relation.elem2;
      this.setState({ showLogModal: true, edgeRelation: relation, leftLabel, rightLabel });
    }
  }

  @autobind
  handleLogModalClose(data) {
    if (!data) {
      this.relationKey = undefined;
      this.setState({ showLogModal: false, edgeRelation: null });
    } else {
      const { filterModality } = data;
      this.props.onFilterChange({ filterModality });
    }
  }

  @autobind
  handleInstanceClick(instanceName) {
    this.setState(
      {
        filterNodes: null,
        nodeSearchVal: null,
      },
      () => {
        this.props.onInstanceChange(instanceName);
      },
    );
  }

  @autobind
  handleBackToInter() {
    this.setState(
      {
        filterNodes: null,
        nodeSearchVal: null,
      },
      () => {
        this.props.onInstanceChange(null);
      },
    );
  }

  @autobind
  isComponentNode(nodeName) {
    const { incidentParams } = this.props;
    const { causalKey } = incidentParams;
    const prefix = `${causalKey}-Component-`;
    return (nodeName || '').indexOf(prefix) >= 0;
  }

  @autobind
  getComponentProjectInstanceName(involvedInstanceSetId, projectName) {
    const projects = this.getComponentProjectInstanceInfoList(involvedInstanceSetId);
    const project = R.find((item) => item.projectName === projectName, projects) || {};
    return project?.instanceName;
  }

  @autobind
  getComponentProjectInstanceInfoList(involvedInstanceSetId) {
    const instanceIdCausalInfoMap = this.props?.incidentMetaData?.instanceIdCausalInfoMap || {};
    const instanceIdCausalInfo = instanceIdCausalInfoMap[involvedInstanceSetId] || {};
    return R.map(
      (x) => ({ dataType: x.d, projectName: x.p, instanceName: x.in, userName: x.u }),
      instanceIdCausalInfo.projectInstanceInfoList || [],
    );
  }

  @autobind
  renderListItemInter(rootnodeVal, filterNodeList) {
    return ({ key, index: rowIndex, style, parent }) => {
      const { intl, instanceName, credentials, projectDisplayMap, incidentMetaData } = this.props;
      const hasInstance = Boolean(instanceName);

      const node = filterNodeList[rowIndex];
      if (!node) return null;
      const isComponent = this.isComponentNode(instanceName);
      const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, node);

      let projectInstanceInfoList = get(this.instanceCausalInfo, [node, 'projectInstanceInfoList'], []);
      let label = instanceStr;
      let title = instanceStr;
      const nodeInfo = this.relationElemInfoMap[node];
      const involvedInstanceSet = nodeInfo?.contentInfo?.involvedInstanceSet || [];

      if (isComponent && involvedInstanceSet.length > 0) {
        projectInstanceInfoList = this.getComponentProjectInstanceInfoList(involvedInstanceSet[0]);
      }

      if (hasInstance) {
        const nodeInfo = this.relationElemInfoMap[node];
        if (nodeInfo) {
          label = `${nodeInfo.postFix ? `[${nodeInfo.postFix}] ` : ''}${nodeInfo.eventType}`;
          title = (
            <div>
              <div>{label}</div>
              <div style={{ marginTop: 4 }}>{intl.formatMessage(appFieldsMessages.project)}:</div>
              <div>{get(nodeInfo, ['contentInfo', 'projectName'])}</div>
            </div>
          );
        }
      } else {
        label =
          this.instanceMapping[node] && this.instanceMapping[node] !== node
            ? `${instanceStr} (${this.instanceMapping[node]})`
            : instanceStr;
        label = this.getComponentName(label);
        title = (
          <div>
            <div>{label}</div>
            {projectInstanceInfoList.length > 0 && (
              <>
                <div style={{ marginTop: 4 }}>{intl.formatMessage(appFieldsMessages.project)}:</div>
                {R.addIndex(R.map)(
                  (projectDisplayName, idx) => {
                    return <div key={idx}>{projectDisplayName}</div>;
                  },
                  R.uniq(
                    R.map((item) => {
                      const projectNameReal =
                        item.userName !== credentials.userName
                          ? `${item.projectName}@${item.userName}`
                          : item.projectName;
                      const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
                      return projectDisplayName;
                    }, projectInstanceInfoList),
                  ),
                )}
              </>
            )}
          </div>
        );
      }

      // get inter node info
      const expend = !hasInstance ? this.nodeExpendMap[node] : null;
      const { isInstance, hasContainers, parentName, haveIntraCorrelation } = !hasInstance
        ? this.allInterNodesInfo[node] || {}
        : {};

      const content = (
        <div className="flex-row flex-center-align" style={{ ...style, width: 'auto', maxWidth: '100%' }}>
          {this.hasContainerInter && (
            <div className="flex-row" style={{ minWidth: 22 }}>
              {isInstance &&
                hasContainers &&
                (expend ? (
                  <CaretDownOutlined style={{ fontSize: 18 }} onClick={() => this.handleExpendNode(node, expend)} />
                ) : (
                  <CaretRightOutlined style={{ fontSize: 18 }} onClick={() => this.handleExpendNode(node, expend)} />
                ))}
            </div>
          )}

          <Tooltip title={title} placement="topLeft" mouseEnterDelay={0.3}>
            <Radio
              className="hidden-line-with-ellipsis inline-block max-width"
              style={!hasInstance && parentName ? {} : { fontSize: 13, fontWeight: 'bold' }}
              value={node}
              checked={rootnodeVal === node}
            >
              {!hasInstance && haveIntraCorrelation && <span style={{ color: '#1890ff', marginRight: 4 }}>[*]</span>}
              {label}
            </Radio>
          </Tooltip>
        </div>
      );

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          {content}
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleExpendNode(node, expend) {
    const { instanceName } = this.props;
    const { nodeSearchVal } = this.state;

    this.nodeExpendMap[node] = !expend;
    this.handleNodeFilter(instanceName, nodeSearchVal);
    this.forceUpdate();
  }

  @autobind
  handleNodeFilter(instanceName, nodeSearchVal) {
    const { incidentMetaData } = this.props || {};
    const hasInstance = Boolean(instanceName);
    this.filterNodeList = hasInstance ? this.allIntraNodes : this.allInterNodes;

    this.filterNodeList = nodeSearchVal
      ? R.filter((node) => {
          const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, node);
          let label = node;
          if (!hasInstance) {
            label =
              this.instanceMapping[node] && this.instanceMapping[node] !== node
                ? `${node} (${this.instanceMapping[node]})`
                : node;
          }
          return (
            R.toLower(label).indexOf(R.toLower(nodeSearchVal)) !== -1 ||
            R.toLower(instanceStr).indexOf(R.toLower(nodeSearchVal)) !== -1
          );
        }, this.filterNodeList)
      : this.filterNodeList;

    // filter by inter node expend
    if (!hasInstance) {
      this.filterNodeList = R.filter((node) => {
        const { isInstance, parentName } = this.allInterNodesInfo[node] || {};
        if (isInstance) {
          return true;
        }
        return parentName && this.nodeExpendMap[parentName];
      }, this.filterNodeList);
    }
  }

  render() {
    const { intl, incidentMetaData } = this.props;
    const { instanceName, incidentParams, relationProbability, filterModality, filterPattern } = this.props;
    const { isLoading, isGraphLoading, hasRelation, relationList, rootnodeInter, rootnode, nodeSearchVal } = this.state;
    const { showLogModal, edgeRelation, leftLabel, rightLabel } = this.state;
    const instanceIdCausalInfoMap = this.props?.incidentMetaData?.instanceIdCausalInfoMap || {};

    const hasInstance = Boolean(instanceName);
    const rootnodeVal = hasInstance ? rootnode : rootnodeInter;
    const { haveIntraCorrelation } = hasInstance ? {} : this.allInterNodesInfo[rootnodeInter] || {};
    const isEmptyGraph = isEmpty(relationList);
    const { filterNodeList } = this;

    const { instanceStr: rootnodeValStr } = getInstanceDisplayName(
      incidentMetaData?.instanceDisplayNameMap,
      rootnodeVal,
    );
    const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, instanceName);

    return (
      <Container
        className={`full-height flex-col flex-min-height ${isLoading ? 'loading' : ''}`}
        style={{ paddingTop: 8 }}
      >
        {false && !hasInstance && (
          <Container
            className="chart"
            style={{
              width: '100%',
              border: 'none',
            }}
          >
            <Alert
              type="info"
              message={
                <div style={{ maxHeight: 80, overflowY: 'auto', wordBreak: 'break-all', fontSize: 13 }}>
                  <span style={{ fontWeight: 'bold', marginRight: 8 }}>
                    {intl.formatMessage(causalMessages.viewRelationForInstance)}:
                  </span>
                  {this.intraInstanceList.length > 0 &&
                    R.map(
                      (instanceName) => (
                        <Button
                          key={instanceName}
                          size="small"
                          type="link"
                          style={{ padding: '0 2px', fontSize: 13 }}
                          onClick={() => {
                            this.handleInstanceClick(instanceName);
                          }}
                        >
                          {this.instanceMapping[instanceName] && this.instanceMapping[instanceName] !== instanceName
                            ? `${instanceName} (${this.instanceMapping[instanceName]})`
                            : instanceName}
                        </Button>
                      ),
                      this.intraInstanceList,
                    )}
                </div>
              }
            />
          </Container>
        )}

        <div
          className="flex-col flex-grow flex-min-height corner-8"
          style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <div className="flex-row flex-center-justify flex-center-align" style={{ height: 40 }}>
            {!hasInstance && (
              <div
                className={`font-14 bold ${haveIntraCorrelation ? 'link' : ''}`}
                onClick={haveIntraCorrelation ? () => this.handleInstanceClick(rootnodeVal) : () => {}}
              >
                {haveIntraCorrelation && <span style={{ marginRight: 4 }}>[*]</span>}
                {this.getComponentName(rootnodeValStr)}
              </div>
            )}
            {hasInstance && (
              <div className="title">
                <Tooltip title={intl.formatMessage(causalMessages.backToInstances)}>
                  <i className="arrow left icon" style={{ cursor: 'pointer' }} onClick={this.handleBackToInter} />
                </Tooltip>
                {intl.formatMessage(causalMessages.eventsRelationsForInstance)}:
                <span className="instance">
                  <i className="circle icon" />
                  {this.instanceMapping[instanceName] && this.instanceMapping[instanceName] !== instanceName
                    ? `${this.getComponentName(instanceStr)} (${this.instanceMapping[instanceName]})`
                    : this.getComponentName(instanceStr)}
                </span>
              </div>
            )}
          </div>

          <div className="flex-grow flex-row flex-min-height">
            <div
              className="flex-col flex-min-height"
              style={{ width: 300, padding: '0 8px', borderRight: '1px solid var(--border-color-base)' }}
            >
              <Radio.Group
                className="flex-grow flex-col flex-min-height"
                onChange={this.onChangeRootnode}
                value={rootnodeVal}
              >
                <div className="flex-row" style={{ fontWeight: 'bold', fontSize: 16 }}>
                  {intl.formatMessage(causalMessages.nodeList)}
                </div>

                <div className="flex-row" style={{ margin: '8px 0' }}>
                  <Input
                    allowClear
                    size="small"
                    placeholder="input search node"
                    value={nodeSearchVal}
                    onChange={({ target: { value } }) => {
                      this.setState(
                        { nodeSearchVal: value },
                        debounce(() => {
                          this.handleNodeFilter(instanceName, value);
                          this.cellMeasureCache.clearAll();
                          this.forceUpdate();
                        }, 600),
                      );
                    }}
                    style={{ width: '100%' }}
                    prefix={<SearchOutlined style={{ color: 'var(--text-color-secondary)' }} />}
                  />
                </div>

                <div className="flex-grow">
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        ref={(listNode) => (this.listNode = listNode)}
                        width={width}
                        height={height}
                        rowCount={filterNodeList.length}
                        deferredMeasurementCache={this.cellMeasureCache}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowRenderer={this.renderListItemInter(rootnodeVal, filterNodeList)}
                      />
                    )}
                  </AutoSizer>
                </div>
              </Radio.Group>
            </div>
            <div className="flex-grow">
              <Spin spinning={isGraphLoading} wrapperClassName="full-height spin-full-height">
                <AutoSizer>
                  {({ width, height }) => (
                    <div style={{ width, height }}>
                      <div
                        className="d3-tree-container"
                        style={hasRelation ? {} : { display: 'none' }}
                        ref={(container) => {
                          this.container = container;
                        }}
                      />
                    </div>
                  )}
                </AutoSizer>

                {!hasRelation && (
                  <div className="full-height flex-row flex-center-align flex-center-justify">
                    <Empty description={intl.formatMessage(causalMessages.noConcurrentCorrelationFuond)} />
                  </div>
                )}

                <div className="flex-col" style={{ position: 'absolute', top: 8, right: 12 }}>
                  <div className="flex-row">
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[0] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=90%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[1] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=70%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[2] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=50%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[3] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=30%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: '#2cb37d' }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'<30%'}
                      </span>
                    </div>
                  </div>
                  <div className="flex-row" style={{ marginTop: 4 }}>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{ width: 14, border: 'none', borderTop: '4px solid gray', margin: 'auto 4px auto 2px' }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=500'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{
                          width: 14,
                          border: 'none',
                          borderTop: '3.5px solid gray',
                          margin: 'auto 4px auto 2px',
                        }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=100'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{ width: 14, border: 'none', borderTop: '3px solid gray', margin: 'auto 4px auto 2px' }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=50'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{
                          width: 14,
                          border: 'none',
                          borderTop: '2.5px solid gray',
                          margin: 'auto 4px auto 2px',
                        }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=10'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{ width: 14, border: 'none', borderTop: '2px solid gray', margin: 'auto 4px auto 2px' }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'<10'}
                      </span>
                    </div>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>

        {showLogModal && (
          <CausalRelationsModal
            isIntra={hasInstance}
            intraInstanceName={instanceName}
            instanceIdCausalInfoMap={instanceIdCausalInfoMap}
            edgeRelation={edgeRelation}
            leftLabel={leftLabel}
            rightLabel={rightLabel}
            incidentParams={incidentParams}
            relationProbability={relationProbability}
            filterModality={filterModality}
            filterPattern={filterPattern}
            instanceCausalInfo={this.instanceCausalInfo}
            instanceMapping={this.instanceMapping}
            onClose={this.handleLogModalClose}
            isCorrelation
            metricUnitMap={this.metricUnitMap}
            instanceDisplayNameMap={incidentMetaData?.instanceDisplayNameMap}
            getComponentProjectInstanceName={this.getComponentProjectInstanceName}
          />
        )}
      </Container>
    );
  }
}

const CausalCorrelationTree = injectIntl(CausalCorrelationTreeCore);
export default connect(
  (state) => {
    const { credentials } = state.auth;
    const { location } = state.router;
    const { userName } = state.auth.userInfo;
    const { projects, projectDisplayMap, currentTheme } = state.app;
    return {
      location,
      userName,
      credentials,
      projects,
      projectDisplayMap,
      currentTheme,
    };
  },
  {
    push,
    replace,
    updateLastActionInfo,
  },
)(CausalCorrelationTree);
