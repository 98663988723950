/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, round, isNumber, isEmpty, toInteger, debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import Icon, {
  LoadingOutlined,
  SearchOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  ExpandAltOutlined,
} from '@ant-design/icons';
import { Tooltip, Radio, Spin, Empty, Input, Button, Alert, Checkbox } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Options } from '../../../common/app';
import { Container, AutoSizer, List, CellMeasurerCache, CellMeasurer } from '../../../lib/fui/react';
import { Tree } from '../../../lib/fui/icons';
import { Defaults, CausalParser, parseJSON, LogRenderers } from '../../../common/utils';
import { D3Tree } from '../../share';
import { appFieldsMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

import CausalRelationsModal from './CausalRelationsModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  view: String,
  refresh: Number,
  dynamicChange: Number,
  causalIncidentInfo: Object,
  incidentMetaData: Object,
  instanceName: String,
  joinDependency: Boolean,
  graphView: String,
  relationTimeThreshold: String,
  relationProbability: String,
  relationCount: Number,
  filterModality: String,
  filterPattern: String,
  incidentParams: Object,
  fixedCountAndProb: Boolean,
  onChangeGraphView: Function,
  onInstanceChange: Function,
  onFilterChange: Function,
  updateState: Function,

  intl: Object,
  match: Object,
  push: Function,
  replace: Function,
  updateLastActionInfo: Function,
  credentials: Object,
  projectDisplayMap: Object,
  currentTheme: String,
};

export const convertModality = (modality) => {
  switch (modality) {
    case 'log->metric':
      modality = 'log-metric';
      break;
    case 'metric->log':
      modality = 'log-metric';
      break;
    case 'incident->metric':
      modality = 'incident-metric';
      break;
    case 'metric->incident':
      modality = 'incident-metric';
      break;
    case 'incident->log':
      modality = 'incident-log';
      break;
    case 'log->incident':
      modality = 'incident-log';
      break;
    case 'alert->metric':
      modality = 'alert-metric';
      break;
    case 'metric->alert':
      modality = 'alert-metric';
      break;
    case 'alert->log':
      modality = 'alert-log';
      break;
    case 'log->alert':
      modality = 'alert-log';
      break;
    case 'alert->incident':
      modality = 'alert-incident';
      break;
    case 'incident->alert':
      modality = 'alert-incident';
      break;
    default:
      break;
  }

  return modality;
};

export const modalityMatching = (n, m) => {
  const match = (m || '').toLowerCase();
  const type = (n?.type || '').toLowerCase();
  const isAlert = !!n?.isAlert;
  const isLog = type.startsWith('log');
  const isMetric = type === 'metric';
  const isIncident = type === 'incident';
  switch (match) {
    case 'alert':
      return isAlert;
    case 'metric':
      return isMetric;
    case 'log':
      return isLog;
    case 'incident':
      return isIncident;
    default:
      break;
  }
  return true;
};

class CausalRelationTreeCore extends D3Tree {
  props: Props;

  constructor(props) {
    super(props);

    // update tree orientation
    this.orientation = props.graphView === 'target' ? 'right-to-left' : 'left-to-right';

    // local data
    this.instanceCausalInfo = {};
    this.incidentInter = {};
    this.interInstanceMap = {};
    this.incidentIntra = {};
    this.allInterNodes = [];
    this.allIntraNodes = [];
    this.filterNodeList = [];
    this.allInterNodesInfo = {};
    this.hasContainerInter = false;
    this.nodeCollapseMap = {};

    this.relationElemInfoMap = {};
    this.relationInfoMap = {};
    this.instanceMapping = {};
    this.intraInstanceList = [];
    this.nodeRelationMap = {};
    this.nodeLogContent = {};

    this.metricUnit = [];

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 30,
    });

    // set default infos
    if (props.incidentMetaData) {
      this.resetMetaData(props);
      const { instanceName } = props;
      this.handleNodeFilter(instanceName, null);
    }

    this.state = {
      isLoading: false,
      isGraphLoading: false,

      hasRelation: false,
      hasCausalResult: true,
      relationList: [],

      rootnodeInter: this.filterNodeList[0],
      rootnode: null,
      nodeSearchVal: null,

      showLogModal: false,
      edgeRelation: null,
      leftLabel: null,
      rightLabel: null,
    };
    this.incidentMetaData = props.incidentMetaData;
  }

  async componentDidMount() {
    const { rootnodeInter } = this.state;
    const { graphView, fixedCountAndProb } = this.props;
    await this.getMetricUnit();
    const instanceInfo = get(this.instanceCausalInfo, rootnodeInter);
    if (instanceInfo) {
      const { minInterCausalDuration, maxInterCausalDuration } = instanceInfo.interDuration[graphView];

      // get node duration options and default value
      const { relationDurationOptions } = CausalParser.getRootnodeDuration(
        rootnodeInter,
        minInterCausalDuration,
        maxInterCausalDuration,
        fixedCountAndProb,
      );
      let { relationTimeThreshold: relationTimeThresholdFromProps } = this.props;
      if (Number(relationTimeThresholdFromProps) * 60 * 1000 < minInterCausalDuration && !fixedCountAndProb) {
        relationTimeThresholdFromProps = relationDurationOptions[0].value;
      }
      if (
        Number(relationTimeThresholdFromProps) >
        Number(relationDurationOptions[relationDurationOptions.length - 1].value)
      ) {
        relationTimeThresholdFromProps = relationDurationOptions[relationDurationOptions.length - 1].value;
      }

      this.props.onFilterChange({
        refresh: moment.utc().valueOf(),
        relationTimeThreshold: relationTimeThresholdFromProps,
        relationDurationOptions:
          relationDurationOptions.length > 0 ? relationDurationOptions : Options.RelationTimeThresholdOptions,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextIncidentParams = nextProps.incidentParams || {};
    const incidentParams = this.props.incidentParams || {};
    if (
      nextIncidentParams.causalKey !== incidentParams.causalKey ||
      nextIncidentParams.customerName !== incidentParams.customerName ||
      nextIncidentParams.startTimestamp !== incidentParams.startTimestamp ||
      nextIncidentParams.endTimestamp !== incidentParams.endTimestamp ||
      nextIncidentParams.fileName !== incidentParams.fileName ||
      nextProps.refresh !== this.props.refresh ||
      nextProps.causalIncidentInfo !== this.props.causalIncidentInfo ||
      nextProps.joinDependency !== this.props.joinDependency ||
      nextProps.relationTimeThreshold !== this.props.relationTimeThreshold ||
      nextProps.instanceName !== this.props.instanceName
    ) {
      // if joinDependency is changed, then reset leaf Node info
      if (nextProps.joinDependency !== this.props.joinDependency) this.leafNodeMap = {};

      // reload data or rerender chart
      let reload = true;
      if (nextProps.instanceName !== this.props.instanceName && !nextProps.instanceName) reload = false;
      if (reload) {
        this.reloadData(nextProps, {});
      } else {
        // update tree orientation
        this.orientation = nextProps.graphView === 'target' ? 'right-to-left' : 'left-to-right';

        this.renderChart(nextProps);
      }
    } else if (nextProps.incidentMetaData !== this.props.incidentMetaData && nextProps.incidentMetaData) {
      // set default infos
      this.resetMetaData(nextProps);

      const { rootnodeInter } = this.state;
      if (!rootnodeInter || (rootnodeInter && this.allInterNodes.indexOf(rootnodeInter) === -1)) {
        // reset root node
        this.resetRootNode(nextProps);
      } else {
        // reload data
        this.reloadData(nextProps, {});
      }
    } else if (nextProps.graphView !== this.props.graphView) {
      // update tree orientation
      this.orientation = nextProps.graphView === 'target' ? 'right-to-left' : 'left-to-right';

      const hasInstance = Boolean(nextProps.instanceName);
      if (hasInstance) {
        this.renderChart(nextProps);
      } else {
        // set default infos
        this.resetMetaData(nextProps);
        // reset root node
        const { rootnodeInter } = this.state;
        this.resetRootNode(nextProps, rootnodeInter);
      }
    } else if (
      nextProps.relationCount !== this.props.relationCount ||
      nextProps.relationProbability !== this.props.relationProbability ||
      nextProps.filterModality !== this.props.filterModality ||
      nextProps.filterPattern !== this.props.filterPattern
    ) {
      // if the dynamicChange is changed. then no need to rerender the graph
      if (nextProps.dynamicChange === this.props.dynamicChange) {
        this.renderChart(nextProps);
      }
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  getMetricUnit() {
    return fetchGet(getEndpoint('metric-unit', 2), {})
      .then((data) => {
        const { success, message: msg, mapping } = data;
        if (success || success === undefined) {
          this.metricUnit = mapping || [];
        } else {
          console.error(msg);
        }
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  }

  @autobind
  resetMetaData(props) {
    this.instanceCausalInfo = props.incidentMetaData.instanceCausalInfo || {};
    this.intraInstanceList = props.incidentMetaData.intraCausalInstanceList;
    this.allInterNodes = R.map((node) => node.hostName, props.incidentMetaData.interAllNodes || []);
    this.allInterNodesInfo = R.fromPairs(
      R.map((node) => [node.hostName, node], props.incidentMetaData.interAllNodes || []),
    );
    this.hasContainerInter = props.incidentMetaData.hasContainerInter;
    this.instanceMapping = props.incidentMetaData.instanceMapping;
  }

  @autobind
  resetRootNode(props, node) {
    const { graphView } = props;

    const { instanceName } = props;
    const { nodeSearchVal } = this.state;
    this.handleNodeFilter(instanceName, nodeSearchVal);

    const rootnodeInter = node || this.filterNodeList[0];
    if (rootnodeInter) {
      this.setState({ rootnodeInter }, () => {
        const instanceInfo = get(this.instanceCausalInfo, rootnodeInter, {});
        const { minInterCausalDuration, maxInterCausalDuration } = instanceInfo.interDuration[graphView];
        const { fixedCountAndProb } = this.props;

        // get node duration options and default value
        const { relationDurationOptions } = CausalParser.getRootnodeDuration(
          rootnodeInter,
          minInterCausalDuration,
          maxInterCausalDuration,
          fixedCountAndProb,
        );
        let { relationTimeThreshold: relationTimeThresholdFromProps } = this.props;
        if (Number(relationTimeThresholdFromProps) * 60 * 1000 < minInterCausalDuration && !fixedCountAndProb) {
          relationTimeThresholdFromProps = relationDurationOptions[0].value;
        }
        if (
          Number(relationTimeThresholdFromProps) >
          Number(relationDurationOptions[relationDurationOptions.length - 1].value)
        ) {
          relationTimeThresholdFromProps = relationDurationOptions[relationDurationOptions.length - 1].value;
        }

        props.onFilterChange({
          refresh: moment.utc().valueOf(),
          relationTimeThreshold: relationTimeThresholdFromProps,
          relationDurationOptions:
            relationDurationOptions.length > 0 ? relationDurationOptions : Options.RelationTimeThresholdOptions,
        });
      });
    }
  }

  @autobind
  mappingData(item) {
    const mappingKey = {
      av: 'avgAnomalyValue',
      as: 'avgAnomalyScore',
      af: 'avgAnomalyFrequency',
      anv: 'avgNormalValue',
      c: 'content',
      t: 'type',
      pan: 'patternName',
      n: 'nid',
      idx: 'index',
      pb: 'probability',
      idn: 'instanceDown',
      ov: 'overAllAvgValue',
      m: 'metricDirection',
      cid: 'containerId',
      p: 'percentage',
      ia: 'isAlert',
      r: 'rawData',
      pn: 'projectName',
      cn: 'customerName',
      iip: 'instanceNameInThisProject',
    };
    const newItem = {};
    R.forEachObjIndexed((val, key) => {
      newItem[mappingKey[key] || key] = val;
    }, item || {});
    return newItem;
  }

  @autobind
  reloadData(props, params) {
    const { credentials, onFilterChange, fixedCountAndProb } = props;
    const {
      view,
      incidentMetaData,
      incidentParams,
      instanceName,
      joinDependency,
      graphView,
      relationTimeThreshold,
      relationProbability,
      relationCount,
    } = props;
    let { rootnodeInter } = this.state;
    const hasInstance = Boolean(instanceName);
    const { causalKey, customerName, startTimestamp, endTimestamp, resultStartstamp, resultEndstamp } = incidentParams;
    if (
      view === 'relation' &&
      incidentMetaData &&
      causalKey &&
      customerName &&
      startTimestamp &&
      endTimestamp &&
      relationTimeThreshold
    ) {
      // get query params
      const causalType = 'causal';
      let operation = 'inter';
      if (hasInstance) {
        operation = 'intra';
      }
      const timeThreshold = toInteger(Number(relationTimeThreshold)) * 60000;
      let queryParams = {};
      if (hasInstance) {
        queryParams = { intraInstance: this.getComponentName(instanceName) };
      } else {
        rootnodeInter = rootnodeInter || this.filterNodeList[0];
        queryParams = { interInstances: JSON.stringify([rootnodeInter]) };
      }

      const loadingField = hasInstance ? 'isLoading' : 'isGraphLoading';
      this.setState({ [loadingField]: true, rootnodeInter });
      this.props.updateState({ isLoadingGraph: true });
      props.updateLastActionInfo();
      fetchPost(getEndpoint('micausalrelation', 1), {
        ...credentials,
        ...queryParams,
        causalKey,
        customerName,
        startTime: startTimestamp,
        endTime: endTimestamp,
        operation,
        causalType,
        // timeThreshold,
        joinDependency,
        bySrc: graphView === 'source',
        ...(resultStartstamp && resultEndstamp
          ? { resultStartTime: resultStartstamp, resultEndTime: resultEndstamp }
          : {}),
      })
        .then(async (result) => {
          // get causal data
          const { data, startPoint } = result || {};
          R.forEach((item) => {
            item.from = R.map((_item) => this.mappingData(_item), item.from || []);
            item.to = R.map((_item) => this.mappingData(_item), item.to || []);
          }, data || []);
          this.causalNodeDegreeMap = result?.causalNodeDegreeMap || {};

          const mapping = this.metricUnit;
          const incident = CausalParser.parseIncidentRelations({ operation, data, mapping, instanceName });
          this.metricUnitMap = incident.metricUnitMap;
          if (hasInstance) {
            this.incidentIntra = incident;
          } else {
            R.forEach((r) => {
              const { elem1, elem2 } = r;
              if (elem1) {
                this.interInstanceMap[elem1] = true;
              }
              if (elem2) {
                this.interInstanceMap[elem2] = true;
              }
            }, incident.relation || []);
            this.incidentInter = incident;
          }

          // if next nodes is leaf, then make node.hasChildren = true
          if (incidentMetaData.dependencyStatus && !hasInstance && joinDependency) {
            let treeNodes = [];
            if (graphView === 'target') {
              treeNodes = R.uniq(
                R.map(
                  (relation) => relation.elem2,
                  R.filter((relation) => relation.elem1 === rootnodeInter, incident.relation || []),
                ),
              );
            } else {
              treeNodes = R.uniq(
                R.map(
                  (relation) => relation.elem1,
                  R.filter((relation) => relation.elem2 === rootnodeInter, incident.relation || []),
                ),
              );
            }
            const needPrefetchNodes = R.filter((item) => !R.has(item, this.leafNodeMap), treeNodes);
            if (treeNodes.length > 0) {
              await this.buildLeafNodeMap({
                needPrefetchNodes,
                credentials,
                causalKey,
                customerName,
                startTimestamp,
                endTimestamp,
                operation,
                causalType,
                // timeThreshold,
                joinDependency,
                graphView,
                resultStartstamp,
                resultEndstamp,
              });
            }
          }

          // has relations
          if (incident.relation.length > 0) {
            // reset probability and count
            let { minimumProbability, minimumCount } = startPoint || {};
            minimumProbability = minimumProbability > 1 ? 1 : minimumProbability;
            minimumCount = R.max(1, minimumCount);
            const newRelationProbability =
              relationProbability !== '0.0'
                ? numeral(R.min(Number(relationProbability), minimumProbability)).format('0.0')
                : numeral(minimumProbability).format('0.0');
            const newRelationCount = relationCount > 0 ? R.min(relationCount, minimumCount) : minimumCount;

            if (
              !fixedCountAndProb &&
              (newRelationProbability !== relationProbability || newRelationCount !== relationCount)
            ) {
              onFilterChange({
                relationProbability: newRelationProbability,
                relationCount: newRelationCount,
              });
            } else {
              this.renderChart(this.props);
            }
          } else {
            this.renderChart(this.props);
          }
          this.setState({ [loadingField]: false });
          this.props.updateState({ isLoadingGraph: false });
        })
        .catch((err) => {
          this.setState({ [loadingField]: false });
          this.props.updateState({ isLoadingGraph: false });
        });
    }
  }

  @autobind
  onChangeRootnode(event) {
    const { onFilterChange, instanceName, fixedCountAndProb, graphView } = this.props;
    const hasInstance = Boolean(instanceName);
    const fieldName = hasInstance ? 'rootnode' : 'rootnodeInter';
    const rootnodeVal = event.target.value;
    this.setState({ [fieldName]: rootnodeVal }, () => {
      if (hasInstance) {
        this.renderChart(this.props);
      } else {
        const instanceInfo = get(this.instanceCausalInfo, rootnodeVal, {});
        const { minInterCausalDuration, maxInterCausalDuration } = instanceInfo.interDuration[graphView];
        // get node duration options and default value
        const { relationDurationOptions } = CausalParser.getRootnodeDuration(
          rootnodeVal,
          minInterCausalDuration,
          maxInterCausalDuration,
          fixedCountAndProb,
        );
        let { relationTimeThreshold: relationTimeThresholdFromProps } = this.props;
        if (Number(relationTimeThresholdFromProps) * 60 * 1000 < minInterCausalDuration && !fixedCountAndProb) {
          relationTimeThresholdFromProps = relationDurationOptions[0].value;
        }
        if (
          Number(relationTimeThresholdFromProps) >
          Number(relationDurationOptions[relationDurationOptions.length - 1].value)
        ) {
          relationTimeThresholdFromProps = relationDurationOptions[relationDurationOptions.length - 1].value;
        }

        onFilterChange({
          refresh: moment.utc().valueOf(),
          relationTimeThreshold: relationTimeThresholdFromProps,
          relationDurationOptions,
        });
      }
    });
  }

  @autobind
  getChartData(props) {
    const { hasRelation, relationList } = this.buildGraphData(props);

    const { instanceName, graphView } = props;
    const { rootnodeInter, rootnode, nodeSearchVal } = this.state;
    const hasInstance = Boolean(instanceName);

    // build root node for intra data
    if (hasInstance) {
      // reduce relation infos
      let fromNodeNames = [];
      let toNodeNames = [];
      R.forEach((relation) => {
        const { elem1, elem2 } = relation;
        fromNodeNames = [...fromNodeNames, elem1];
        toNodeNames = [...toNodeNames, elem2];
      }, relationList);
      fromNodeNames = R.sort((a, b) => a.localeCompare(b), R.uniq(fromNodeNames));
      toNodeNames = R.sort((a, b) => a.localeCompare(b), R.uniq(toNodeNames));
      this.allIntraNodes = graphView === 'target' ? toNodeNames : fromNodeNames;

      if (relationList.length >= 100) {
        this.autoDisplayLevel = 2;
      } else {
        this.autoDisplayLevel = this.defaultMaxLevel;
      }
    }

    // traverse node to build tree
    const rootnodeField = hasInstance ? 'rootnode' : 'rootnodeInter';
    let rootnodeVal = hasInstance ? rootnode : rootnodeInter;

    // filter node list
    this.handleNodeFilter(instanceName, nodeSearchVal);
    if (!rootnodeVal || (rootnodeVal && this.filterNodeList.indexOf(rootnodeVal) === -1)) {
      if (this.filterNodeList.length > 0) rootnodeVal = this.filterNodeList[0];
    }

    const startTs = moment.utc().valueOf();
    const treeData = this.createTreeData({
      nodeRelationMap: this.nodeRelationMap,
      parentAllNodeNameMap: {},
      nodeNames: rootnodeVal ? [rootnodeVal] : [],
      parentNode: null,
      parentPath: null,
      level: 1,
      hasInstance,
    });
    console.debug(`Create tree duration: ${(moment.utc().valueOf() - startTs) / 1000} sec`);

    this.setState({
      hasRelation,
      relationList,
      [rootnodeField]: rootnodeVal,
    });

    /*
          The tree data generated by the filter will not be displayed
            when there is no data (that is, there is no data in the table after clicking the line)
        */
    // filter 生成完的tree数据，当没有数据(也就是点击线条后里面表格也没有数据)的时候不展示线条
    // if (treeData.length > 0) {
    //   const { children } = treeData[0];
    //   const lastChildren = R.last(children);
    //   let otherChildren = R.init(children);
    //   R.forEach((item) => {
    //     const relationKey = `${item.node}-${item.parentNode}`;
    //     item.newContentTimeDifference = this.relationInfoMap[relationKey]
    //       ? this.relationInfoMap[relationKey].newContentTimeDifference
    //       : [];
    //   }, otherChildren);
    //   otherChildren = R.filter((item) => item.newContentTimeDifference.length > 0, otherChildren);
    //   treeData[0].children = [...otherChildren, lastChildren];
    // }

    return {
      treeData: treeData.length > 0 ? treeData[0] : {},
    };
  }

  @autobind
  buildGraphData(props) {
    const {
      instanceName,
      graphView,
      relationProbability,
      relationCount,
      filterModality,
      filterPattern,
      incidentMetaData,
    } = props;
    const hasInstance = Boolean(instanceName);

    const incident = hasInstance ? this.incidentIntra : this.incidentInter;
    let relationList = get(incident, ['relation'], []);
    const hasRelation = !isEmpty(relationList);

    // set all log node info
    this.relationElemInfoMap = get(incident, ['relationElemInfoMap'], {});

    relationList = R.map((item) => {
      R.forEach((_item) => {
        item.fromContents[_item.sP].sP = _item.sP;
        item.toContents[_item.tP].tP = _item.tP;
      }, item.contentTimeDifference || []);
      return item;
    }, relationList);

    // filter
    if (relationProbability) {
      // relationList = R.filter((relation) => relation.probability >= parseFloat(relationProbability), relationList);
      relationList = R.map((relation) => {
        const { probability, count } = relation;
        const newCount = get(relation, ['probabilityCountMap', relationProbability], count);
        return {
          ...relation,
          count: newCount,
          realtionColor: CausalParser.getColorByProbability(probability),
          relationWidth: CausalParser.getWidthByCount(newCount),
        };
      }, relationList);
    }
    // if (relationCount) {
    //   relationList = R.filter((relation) => relation.count >= Number(relationCount), relationList);
    // }
    if (filterModality) {
      const modality = convertModality(filterModality);
      if (filterModality !== 'all') {
        relationList = R.filter((relation) => {
          let timePairs = relation.contentTimeDifference || [];
          // if (relationProbability) {
          //   timePairs = R.filter((pair) => pair.probability >= parseFloat(relationProbability), timePairs);
          // }
          timePairs = R.filter((pair) => pair.modality === modality, timePairs);
          return timePairs.length > 0;
        }, relationList);

        const parts = filterModality.split('->') || [];
        const from = parts[0];
        const to = parts[1];

        if (from && to) {
          relationList = R.map((r) => {
            let { fromContents, toContents } = r;
            fromContents = R.filter((c) => modalityMatching(c, from), fromContents);
            toContents = R.filter((c) => modalityMatching(c, to), toContents);
            return { ...r, fromContents, toContents };
          }, relationList);
        }
      }

      let interAllNodes = incidentMetaData.interAllNodes || [];
      switch (modality) {
        case 'log-metric':
          interAllNodes = R.filter((node) => node.filterModality[graphView].haveInterMetricToLog, interAllNodes);
          break;
        case 'metric-metric':
          interAllNodes = R.filter((node) => node.filterModality[graphView].haveInterMetricToMetric, interAllNodes);
          break;
        case 'log-log':
          interAllNodes = R.filter((node) => node.filterModality[graphView].haveInterLogToLog, interAllNodes);
          break;
        case 'incident-metric':
          interAllNodes = R.filter((node) => node.filterModality[graphView].haveInterMetricToIncident, interAllNodes);
          break;
        case 'incident-log':
          interAllNodes = R.filter((node) => node.filterModality[graphView].haveInterLogToIncident, interAllNodes);
          break;
        case 'alert-metric':
          interAllNodes = R.filter((node) => node.filterModality[graphView].haveInterMetricToAlert, interAllNodes);
          break;
        case 'alert-log':
          interAllNodes = R.filter((node) => node.filterModality[graphView].haveInterLogToAlert, interAllNodes);
          break;
        case 'alert-incident':
          interAllNodes = R.filter((node) => node.filterModality[graphView].haveInterAlertToIncident, interAllNodes);
          break;
        default:
          break;
      }
      this.allInterNodes = R.map((node) => node.hostName, interAllNodes);
      this.allInterNodesInfo = R.fromPairs(R.map((node) => [node.hostName, node], interAllNodes));

      if (!hasInstance) {
        const fieldName = hasInstance ? 'rootnode' : 'rootnodeInter';
        const rootnodeVal = this.state[fieldName];
        if (this.allInterNodes.length > 0 && this.allInterNodes.indexOf(rootnodeVal) < 0) {
          this.onChangeRootnode({ target: { value: this.allInterNodes[0] } });
        }
      }
    }

    if (filterPattern) {
      relationList = R.filter((relation) => relation.nidList.includes(Number(filterPattern)), relationList);
    }

    relationList = this.preprocessData(relationList);
    relationList = this.reinstallLineColor(relationList);

    // create tree data
    const relationInfoMap = {};
    let nodeRelationMap = {};

    // create node map
    R.forEach((relation) => {
      const { elem1, elem2 } = relation;

      // set relationInfoMap
      const relationKey = `${elem1}-${elem2}`;
      if (!R.has(relationKey, relationInfoMap)) {
        relationInfoMap[relationKey] = relation;

        if (!R.has(elem1, nodeRelationMap))
          nodeRelationMap[elem1] = {
            nextNodes: [],
            previousNodes: [],
            nextNodeRelations: [],
            previousNodeRelations: [],
          };
        if (!R.has(elem2, nodeRelationMap))
          nodeRelationMap[elem2] = {
            nextNodes: [],
            previousNodes: [],
            nextNodeRelations: [],
            previousNodeRelations: [],
          };

        nodeRelationMap[elem1].nextNodes.push(elem2);
        nodeRelationMap[elem2].previousNodes.push(elem1);
        nodeRelationMap[elem1].nextNodeRelations.push(relation);
        nodeRelationMap[elem2].previousNodeRelations.push(relation);
      }
    }, relationList);
    nodeRelationMap = R.mapObjIndexed((val, node) => {
      const nextNodeRelations = R.sortWith([R.descend(R.prop('probability'))], val.nextNodeRelations);
      const previousNodeRelations = R.sortWith([R.descend(R.prop('probability'))], val.previousNodeRelations);

      // set hasChildren for current inter graph
      let hasChildren = false;
      const treeNodes = graphView === 'target' ? val.nextNodes : val.previousNodes;
      if (!hasInstance && treeNodes.length > 0) {
        hasChildren =
          get(this.instanceCausalInfo, [node, 'haveInterCausal', graphView]) && this.leafNodeMap[node] !== true;
      }
      return { ...val, hasChildren, nextNodeRelations, previousNodeRelations };
    }, nodeRelationMap);
    this.relationInfoMap = relationInfoMap;
    this.nodeRelationMap = nodeRelationMap;

    if (this.relationKey) {
      const relation = this.relationInfoMap[this.relationKey];
      if (relation) {
        const leftLabel = relation.elem1;
        const rightLabel = relation.elem2;
        this.setState({ edgeRelation: relation, leftLabel, rightLabel });
      }
    }

    return { hasRelation, relationList };
  }

  @autobind
  reinstallLineColor(relationList) {
    /*
          After filtering through the preprocessdata function,
            use the maximum probability in newcontenttimedifference to reset the line color
          通过preprocessData函数过滤完之后,使用newContentTimeDifference里面的最大probability重新设置线条颜色
        */
    R.forEach((item) => {
      const { newContentTimeDifference } = item;
      const probabilityList = [];
      R.forEach((_item) => {
        probabilityList.push(_item.probability);
      }, newContentTimeDifference || []);
      if (probabilityList.length > 0) {
        item.probability = Math.max(...probabilityList);
        item.realtionColor = CausalParser.getColorByProbability(item.probability);
      }
    }, relationList || []);
    return relationList;
  }

  @autobind
  preprocessData(relationList) {
    const { relationProbability, filterModality, filterPattern } = this.props;
    const getTypeCategory = (type, isAlert) => {
      let category;
      if (isAlert) {
        category = 'alert';
      } else if (type === 'Metric') {
        category = 'metric';
      } else if (type === 'Incident') {
        category = 'incident';
      } else {
        category = 'log';
      }
      return category;
    };

    R.forEach((item) => {
      const { contentTimeDifference, fromContents, toContents } = item;
      item.newContentTimeDifference = [];
      R.forEach((_item) => {
        const f = R.clone(
          _item.sc || R.find((item) => item.sP === _item.sP, fromContents || []) || fromContents[_item.srcPosition],
        );
        const t = R.clone(
          _item.tc || R.find((item) => item.tP === _item.tP, toContents || []) || toContents[_item.targetPosition],
        );
        if (f && t) {
          const { type: fType, isAlert: fisAlert } = f;
          const { type: tType, isAlert: tisAlert } = t;
          item.newContentTimeDifference.push({
            probability: _item.probability,
            modality: _item.modality,
            directModality: `${getTypeCategory(fType, fisAlert)}->${getTypeCategory(tType, tisAlert)}`,
            nidList: [f.nid, t.nid],
          });
        }
      }, contentTimeDifference);

      // filter
      // if (relationProbability) {
      //   item.newContentTimeDifference = R.filter(
      //     (relation) => relation.probability >= parseFloat(relationProbability),
      //     item.newContentTimeDifference,
      //   );
      // }
      const bidirect = filterModality.indexOf('>') < 0;
      if (filterModality && filterModality !== 'all') {
        const modality = convertModality(filterModality);
        item.newContentTimeDifference = R.filter(
          (relation) => (bidirect ? relation.modality === modality : relation.directModality === filterModality),
          item.newContentTimeDifference,
        );
      }
      if (filterPattern) {
        item.newContentTimeDifference = R.filter(
          (relation) => relation.nidList.includes(Number(filterPattern)),
          item.newContentTimeDifference,
        );
      }
    }, relationList);

    /*
          Newcontenttimedifference represents is no data in the line (contenttimedifference)
            because it is filtered Therefore, the same filtering is required outside.
            The filtering results are placed in the new contenttimedifference.
            If there is no data, the lines will not be displayed
          newContentTimeDifference代表的是:点击线条后表格没有数据(contentTimeDifference)是因为过滤了
            所以外面也要相同的过滤,过滤结果放在newContentTimeDifference,没有数据就不展示线条
        */
    relationList = R.filter(
      (item) => item.newContentTimeDifference.length > 0 && item.fromContents.length > 0 && item.toContents.length > 0,
      relationList,
    );

    return relationList;
  }

  @autobind
  renderTip({ operation, target, d }) {
    const { intl, graphView, credentials, projectDisplayMap, currentTheme, incidentMetaData } = this.props;
    if (operation === 'node') {
      const { node, hasInstance, nodeInfo, parentNode, children, childrenBackup } = d;
      const { isLogType, contentInfo } = nodeInfo || {};

      let isLoading = false;
      let nextNodeTotal = 0;
      let previousNodeTotal = 0;
      let nextNodeRelations = [];
      let previousNodeRelations = [];
      // sepical for instanceDown/metric incident
      const { instanceDown, metricDirection, avgAnomalyValue, avgNormalValue } = contentInfo || {};
      let { content, patternName, avgValue } = contentInfo || {};
      if (R.isNil(avgValue) && isNumber(avgNormalValue)) avgValue = avgNormalValue;
      if (R.isNil(avgValue) && isNumber(avgAnomalyValue)) avgValue = avgAnomalyValue;
      const isLog = isLogType && !metricDirection && !instanceDown;
      content = content || node;
      patternName = patternName || node;

      let appName;
      let renderMetricContent = null;
      if (!hasInstance) {
        const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, node);
        appName =
          this.instanceMapping[node] && this.instanceMapping[node] !== node
            ? `${instanceStr} (${this.instanceMapping[node]})`
            : instanceStr;
        const nodeRelation = this.nodeRelationMap[node] || {};
        if (graphView === 'target') {
          nextNodeRelations = parentNode
            ? R.filter(
                (relation) => relation.elem2 === parentNode && relation.elem1 === node,
                nodeRelation.nextNodeRelations,
              )
            : [];
          previousNodeRelations = children || childrenBackup ? nodeRelation.previousNodeRelations : [];
        } else {
          nextNodeRelations = children || childrenBackup ? nodeRelation.nextNodeRelations : [];
          previousNodeRelations = parentNode
            ? R.filter(
                (relation) => relation.elem1 === parentNode && relation.elem2 === node,
                nodeRelation.previousNodeRelations,
              )
            : [];
        }
        nextNodeTotal = nextNodeRelations.length;
        previousNodeTotal = previousNodeRelations.length;
        nextNodeRelations = R.take(3, nextNodeRelations);
        previousNodeRelations = R.take(3, previousNodeRelations);
      } else if (isLog && !R.has(node, this.nodeLogContent)) {
        isLoading = true;
        // call api to get log content
        this.getNodeLogContent(target, d);
      } else if (!isLog) {
        if (instanceDown) {
          renderMetricContent = (
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <span>{intl.formatMessage(appFieldsMessages.missingData)}</span>
              <i className="icon down arrow" style={{ color: 'red' }} />
            </div>
          );
        } else {
          const isHigher = ['positive', 'higher'].indexOf((metricDirection || '').toLowerCase()) >= 0;
          const isLower = ['negative'].indexOf((metricDirection || '').toLowerCase()) >= 0;
          renderMetricContent = (
            <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
              <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                {intl.formatMessage(appFieldsMessages.value)}:
              </div>
              <div>{round(avgValue, 2)}</div>
              {isHigher && <i className="icon up arrow" />}
              {isLower && <i className="icon down arrow" />}
            </div>
          );
        }
      }

      if (isLoading) {
        return ReactDOMServer.renderToStaticMarkup(
          <div
            className="flex-row flex-center-justify flex-center-align"
            style={{
              minHeight: 60,
              minWidth: 160,
              background: 'var(--popover-bg)',
              color: 'var(--popover-color)',
            }}
          >
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>,
        );
      }

      let rawDataJson;
      try {
        rawDataJson = JSON.parse(this.nodeLogContent[node] || content);
      } catch (error) {
        // console.error(error);
      }
      return ReactDOMServer.renderToStaticMarkup(
        <div
          className="flex-col overflow-y-auto"
          style={{
            fontSize: 12,
            paddingRight: 8,
            minHeight: 60,
            minWidth: 160,
            maxHeight: 300,
            maxWidth: 400,
            background: 'var(--popover-bg)',
            color: 'var(--popover-color)',
          }}
        >
          <div className="flex-row" style={{ borderBottom: '1px solid #ccc', paddingBottom: 4 }}>
            <div style={{ fontSize: 13, fontWeight: 500, paddingRight: 8 }}>
              {!hasInstance
                ? intl.formatMessage(appFieldsMessages.instance)
                : isLog
                ? intl.formatMessage(appFieldsMessages.pattern)
                : intl.formatMessage(appFieldsMessages.metric)}
              :
            </div>
            <div className="flex-col">
              <div>{!hasInstance ? appName : isLog ? patternName : content}</div>
              {!hasInstance && (
                <div className="flex-row">
                  {`${intl.formatMessage(appFieldsMessages.project)}: `}
                  {R.join(
                    ', ',
                    R.uniq(
                      R.map((item) => {
                        const projectNameReal =
                          item.userName !== credentials.userName
                            ? `${item.projectName}@${item.userName}`
                            : item.projectName;
                        const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
                        return projectDisplayName;
                      }, get(this.instanceCausalInfo, [node, 'projectInstanceInfoList'], [])),
                    ),
                  )}
                </div>
              )}
            </div>
          </div>

          {hasInstance && isLog && !rawDataJson && (
            <div className="flex-row" style={{ wordBreak: 'break-word' }}>
              {this.nodeLogContent[node] || content}
            </div>
          )}
          {hasInstance && isLog && rawDataJson && (
            <div className="flex-row" style={{ overflowX: 'auto' }}>
              <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />
            </div>
          )}
          {hasInstance && !isLog && renderMetricContent}

          {!hasInstance && previousNodeRelations.length > 0 && (
            <div className="flex-row" style={{ marginTop: 4 }}>
              <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                {intl.formatMessage(causalMessages.inboundNodeTop, { top: graphView === 'target' ? '(Top3)' : '' })}:
              </div>
              <div className="flex-col">
                {R.addIndex(R.map)((relation, idx) => {
                  const { instanceStr } = getInstanceDisplayName(
                    incidentMetaData?.instanceDisplayNameMap,
                    relation?.elem1,
                  );
                  return (
                    <div key={idx} style={{ marginBottom: 8 }}>
                      <div
                        style={{
                          fontWeight: 500,
                          display: 'inline-block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >{`${instanceStr}`}</div>
                      <div>{`${intl.formatMessage(appFieldsMessages.probability)}: ${round(
                        relation.probability * 100,
                        1,
                      )}%`}</div>
                      <div className="flex-row">
                        {`${intl.formatMessage(appFieldsMessages.project)}: `}
                        {R.join(
                          ', ',
                          R.uniq(
                            R.map((item) => {
                              const projectNameReal =
                                item.userName !== credentials.userName
                                  ? `${item.projectName}@${item.userName}`
                                  : item.projectName;
                              const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
                              return projectDisplayName;
                            }, get(this.instanceCausalInfo, [node, 'projectInstanceInfoList'], [])),
                          ),
                        )}
                      </div>
                    </div>
                  );
                }, previousNodeRelations)}
                {previousNodeTotal > 3 && <div>......</div>}
              </div>
            </div>
          )}
          {!hasInstance && nextNodeRelations.length > 0 && (
            <div className="flex-row" style={{ marginTop: 4 }}>
              <div style={{ fontSize: 13, fontWeight: 500, width: 100 }}>
                {intl.formatMessage(causalMessages.outboundNodeTop, { top: graphView === 'target' ? '' : '(Top3)' })}:
              </div>
              <div className="flex-col">
                {R.addIndex(R.map)((relation, idx) => {
                  const { instanceStr } = getInstanceDisplayName(
                    incidentMetaData?.instanceDisplayNameMap,
                    relation.elem2,
                  );
                  return (
                    <div key={idx} style={{ marginBottom: 8 }}>
                      <div
                        style={{
                          fontWeight: 500,
                          display: 'inline-block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >{`${instanceStr}`}</div>
                      <div>{`${intl.formatMessage(appFieldsMessages.probability)}: ${round(
                        relation.probability * 100,
                        1,
                      )}%`}</div>
                      <div className="flex-row">
                        {`${intl.formatMessage(appFieldsMessages.project)}: `}
                        {R.join(
                          ', ',
                          R.uniq(
                            R.map((item) => {
                              const projectNameReal =
                                item.userName !== credentials.userName
                                  ? `${item.projectName}@${item.userName}`
                                  : item.projectName;
                              const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
                              return projectDisplayName;
                            }, get(this.instanceCausalInfo, [node, 'projectInstanceInfoList'], [])),
                          ),
                        )}
                      </div>
                    </div>
                  );
                }, nextNodeRelations)}
                {nextNodeTotal > 3 && <div>......</div>}
              </div>
            </div>
          )}
        </div>,
      );
    }
    return '';
  }

  @autobind
  getNodeLogContent(target, d) {
    const { node, nodeInfo } = d;
    const { typeOnly, contentInfo } = nodeInfo || {};
    const { projectName, nid, timeStamp, type, involvedInstanceSet = [] } = contentInfo || {};

    const { credentials, incidentParams, instanceName } = this.props;

    const isComponent = this.isComponentNode(instanceName);
    let { projectInstanceInfoList } = get(this.instanceCausalInfo, instanceName, {});

    if (isComponent && involvedInstanceSet.length > 0) {
      const id = involvedInstanceSet[0];
      projectInstanceInfoList = this.getComponentProjectInstanceInfoList(id);
    }

    let logProjectName;
    let logInstanceName;
    const logInfo = R.find(
      (item) => item.dataType !== 'Metric' && item.projectName === projectName,
      projectInstanceInfoList || [],
    );
    if (logInfo) {
      logProjectName = logInfo.projectName;
      logInstanceName = logInfo.instanceName;
    }

    // if (logProjectName && logInstanceName && isNumber(nid) && timeStamp && type) {
    if (logProjectName && logInstanceName && isNumber(nid) && type) {
      const logEventQueryStr = [
        {
          logProjectName,
          logInstanceName,
          nid: String(nid),
          timestamp: timeStamp || this.causalNodeDegreeMap[node]?.startTimestamp,
          type,
        },
      ];
      const customerName = get(incidentParams, 'customerName');
      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('loadlogeventsforcausal'), {
        ...credentials,
        customerName,
        logEventQueryStr: JSON.stringify(logEventQueryStr),
      }).then((data) => {
        const logContents = get(data, [logInstanceName, typeOnly], []);
        const logContent = logContents.length > 0 ? logContents[0].logContent || '' : '';
        this.nodeLogContent[node] = logContent;

        setTimeout(() => {
          // if displayed tip node is not change, then display the tip
          const { tipNode } = this;
          if (tipNode === d) {
            this.tip.show({ operation: 'node', target, d }, target);
          }
        }, 600);
      });
    }
  }

  @autobind
  nodeCircleClick(props) {
    return async (d) => {
      const { isExpandNode, node, path, children, childrenBackup, hasChildren, newParentAllNodeNameMap, level } =
        d || {};

      if (isExpandNode) {
        this.nodeClick(props)(d);
      } else {
        // Toggle children on click.
        if (children) {
          d.childrenBackup = children;
          d.children = null;
        } else if (childrenBackup) {
          d.children = childrenBackup;
          d.childrenBackup = null;
        } else if (!childrenBackup && hasChildren) {
          // Dynamically create new node children
          // update tree root. Use current node parentAllNodeNameMap to build
          const { onFilterChange, instanceName, joinDependency, graphView, relationProbability, relationCount } =
            this.props;
          const hasInstance = Boolean(instanceName);

          const { credentials, incidentParams, relationTimeThreshold, incidentMetaData } = this.props;
          const { causalKey, customerName, startTimestamp, endTimestamp } = incidentParams;
          const { resultStartstamp, resultEndstamp } = incidentParams;
          const timeThreshold = toInteger(Number(relationTimeThreshold)) * 60000;
          const operation = 'inter';
          const causalType = 'causal';

          if (!hasInstance) {
            this.setState({ isGraphLoading: true });
            this.props.updateLastActionInfo();
            const result = await fetchPost(getEndpoint('micausalrelation', 1), {
              ...credentials,
              interInstances: JSON.stringify([node]),
              causalKey,
              customerName,
              startTime: startTimestamp,
              endTime: endTimestamp,
              operation,
              causalType,
              // timeThreshold,
              joinDependency,
              bySrc: graphView === 'source',
              ...(resultStartstamp && resultEndstamp
                ? { resultStartTime: resultStartstamp, resultEndTime: resultEndstamp }
                : {}),
            });

            // get causal data
            const { data, startPoint } = result || {};
            R.forEach((item) => {
              item.from = R.map((_item) => this.mappingData(_item), item.from || []);
              item.to = R.map((_item) => this.mappingData(_item), item.to || []);
            }, data || []);
            const mapping = this.metricUnit;
            const incident = CausalParser.parseIncidentRelations({ operation: 'inter', data, mapping });
            this.metricUnitMap = incident.metricUnitMap;
            this.causalNodeDegreeMap = result?.causalNodeDegreeMap || {};

            // merge incident
            this.incidentInter = CausalParser.mergeIncidentRelations(this.incidentInter, incident);

            // reset probability and count
            let { minimumProbability, minimumCount } = startPoint || {};
            minimumProbability = minimumProbability > 1 ? 1 : minimumProbability;
            minimumCount = R.max(1, minimumCount);
            const newRelationProbability = numeral(R.min(Number(relationProbability), minimumProbability)).format(
              '0.0',
            );
            const newRelationCount = R.min(relationCount, minimumCount);

            if (newRelationProbability !== relationProbability || newRelationCount !== relationCount) {
              onFilterChange({
                // no need to rerender the graph
                dynamicChange: moment.utc().valueOf(),
                relationProbability: newRelationProbability,
                relationCount: newRelationCount,
              });
            }

            // rebuild causal data
            this.buildGraphData(this.props);
          }

          // get next nodes
          const nodeRelation = get(this.nodeRelationMap, node);
          const childrenNodeNames =
            (graphView === 'target' ? nodeRelation.previousNodes : nodeRelation.nextNodes) || [];

          // get the node path expand info
          if (!R.has(path, this.nodePathExpand)) {
            this.nodePathExpand[path] = { total: childrenNodeNames.length, display: 0 };
          } else {
            this.nodePathExpand[path].total = childrenNodeNames.length;
          }
          const nodeNames = R.slice(
            this.nodePathExpand[path].display,
            this.nodePathExpand[path].display + this.autoDisplayNode,
            childrenNodeNames,
          );
          const needNextNode =
            this.nodePathExpand[path].total > this.nodePathExpand[path].display + this.autoDisplayNode;

          // if next nodes is leaf, then make node.hasChildren = true
          if (incidentMetaData.dependencyStatus && !hasInstance && joinDependency) {
            const needPrefetchNodes = R.filter((item) => !R.has(item, this.leafNodeMap), nodeNames);
            if (needPrefetchNodes.length > 0) {
              await this.buildLeafNodeMap({
                needPrefetchNodes,
                credentials,
                causalKey,
                customerName,
                startTimestamp,
                endTimestamp,
                operation,
                causalType,
                // timeThreshold,
                joinDependency,
                graphView,
                resultStartstamp,
                resultEndstamp,
              });
            }
          }

          // create new children
          const newChildren = this.createTreeData({
            nodeRelationMap: this.nodeRelationMap,
            parentAllNodeNameMap: newParentAllNodeNameMap,
            nodeNames,
            parentNode: node,
            parentPath: path,
            level: level + 1,
            hasInstance,
          });
          // update node status
          if (newChildren.length > 0) {
            d.children = newChildren;
          }
          d.hasChildren = false;

          // build expand control node
          if (needNextNode && d.children) {
            d.children.push(this.getExpandNode({ path, direction: 'next' }));
          }

          this.setState({ isGraphLoading: false });
        }
        this.updateTree(d, props);
      }
    };
  }

  @autobind
  nodeClick(props) {
    return async (d) => {
      const { root } = props;
      const { node, hasIntra, isExpandNode, path, direction } = d || {};

      if (isExpandNode) {
        const { credentials, instanceName, joinDependency, graphView, incidentParams, relationTimeThreshold } =
          this.props;
        this.setState({ isGraphLoading: true });

        const nodeData = this.findNodeByPath(root, path);

        // get next nodes
        const nodeRelation = get(this.nodeRelationMap, nodeData.node);
        const childrenNodeNames = (graphView === 'target' ? nodeRelation.previousNodes : nodeRelation.nextNodes) || [];

        let nodeNames = [];
        let startIndex = null;
        if (direction === 'next') {
          startIndex = this.nodePathExpand[path].display + this.autoDisplayNode;
        } else {
          startIndex = R.max(0, this.nodePathExpand[path].display - this.autoDisplayNode);
        }
        this.nodePathExpand[path].display = startIndex;
        nodeNames = R.slice(startIndex, startIndex + this.autoDisplayNode, childrenNodeNames);
        const needNextNode = this.nodePathExpand[path].total > startIndex + this.autoDisplayNode;
        const needPreviousNode = startIndex > 0;

        // if next nodes is leaf, then make node.hasChildren = true
        const { causalKey, customerName, startTimestamp, endTimestamp } = incidentParams;
        const { resultStartstamp, resultEndstamp } = incidentParams;
        const hasInstance = Boolean(instanceName);
        const timeThreshold = toInteger(Number(relationTimeThreshold)) * 60000;
        const operation = 'inter';
        const causalType = 'causal';
        if (!hasInstance && joinDependency) {
          const needPrefetchNodes = R.filter((item) => !R.has(item, this.leafNodeMap), nodeNames);
          if (needPrefetchNodes.length > 0) {
            await this.buildLeafNodeMap({
              needPrefetchNodes,
              credentials,
              causalKey,
              customerName,
              startTimestamp,
              endTimestamp,
              operation,
              causalType,
              // timeThreshold,
              joinDependency,
              graphView,
              resultStartstamp,
              resultEndstamp,
            });
          }
        }

        // create new children
        const newChildren = this.createTreeData({
          nodeRelationMap: this.nodeRelationMap,
          parentAllNodeNameMap: nodeData.newParentAllNodeNameMap,
          nodeNames,
          parentNode: nodeData.node,
          parentPath: nodeData.path,
          level: nodeData.level + 1,
          hasInstance: nodeData.hasInstance,
        });

        // build new children
        nodeData.children = newChildren;
        if (needNextNode) {
          nodeData.children.push(this.getExpandNode({ path, direction: 'next' }));
        }
        if (needPreviousNode) {
          nodeData.children.push(this.getExpandNode({ path, direction: 'previous' }));
        }

        this.setState({ isGraphLoading: false });

        // update tree
        this.updateTree(nodeData, props);
      } else if (hasIntra) {
        this.handleInstanceClick(node);
      }
    };
  }

  @autobind
  pathClick(d) {
    const { graphView } = this.props;
    const relationKey =
      graphView === 'target' ? `${d.target.node}-${d.source.node}` : `${d.source.node}-${d.target.node}`;
    const relation = this.relationInfoMap[relationKey];
    this.relationKey = relationKey;
    if (relation) {
      const leftLabel = relation.elem1;
      const rightLabel = relation.elem2;
      this.setState({ showLogModal: true, edgeRelation: relation, leftLabel, rightLabel });
    }
  }

  @autobind
  handleLogModalClose(data) {
    if (!data) {
      this.relationKey = undefined;
      this.setState({ showLogModal: false, edgeRelation: null });
    } else {
      const { filterModality } = data;
      this.props.onFilterChange({ filterModality });
    }
  }

  @autobind
  handleInstanceClick(instanceName) {
    this.setState(
      {
        filterNodes: null,
        nodeSearchVal: null,
      },
      () => {
        this.props.onInstanceChange(instanceName);
      },
    );
  }

  @autobind
  handleBackToInter() {
    this.setState(
      {
        filterNodes: null,
        nodeSearchVal: null,
      },
      () => {
        this.props.onInstanceChange(null);
      },
    );
  }

  @autobind
  getComponentName(nodeName) {
    const { incidentParams } = this.props;
    const { causalKey } = incidentParams;
    const prefix = `${causalKey}-Component-`;
    return (nodeName || '').indexOf(prefix) >= 0 ? nodeName.replace(prefix, '') : nodeName;
  }

  @autobind
  isComponentNode(nodeName) {
    const { incidentParams } = this.props;
    const { causalKey } = incidentParams;
    const prefix = `${causalKey}-Component-`;
    return (nodeName || '').indexOf(prefix) >= 0;
  }

  @autobind
  getComponentProjectInstanceName(involvedInstanceSetId, projectName) {
    const projects = this.getComponentProjectInstanceInfoList(involvedInstanceSetId);
    const project = R.find((item) => item.projectName === projectName, projects) || {};
    return project?.instanceName;
  }

  @autobind
  getComponentProjectInstanceInfoList(involvedInstanceSetId) {
    const instanceIdCausalInfoMap = this.props?.incidentMetaData?.instanceIdCausalInfoMap || {};
    const instanceIdCausalInfo = instanceIdCausalInfoMap[involvedInstanceSetId] || {};
    return R.map(
      (x) => ({ dataType: x.d, projectName: x.p, instanceName: x.in, userName: x.u }),
      instanceIdCausalInfo.projectInstanceInfoList || [],
    );
  }

  @autobind
  renderListItemInter(rootnodeVal, filterNodeList) {
    return ({ key, index: rowIndex, style, parent }) => {
      const { intl, instanceName, credentials, projectDisplayMap, incidentMetaData } = this.props;
      const hasInstance = Boolean(instanceName);

      const node = filterNodeList[rowIndex];
      if (!node) return null;
      const isComponent = this.isComponentNode(instanceName);
      const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, node);

      let projectInstanceInfoList = get(this.instanceCausalInfo, [node, 'projectInstanceInfoList'], []);
      let label = instanceStr;
      let title = instanceStr;
      const nodeInfo = this.relationElemInfoMap[node];
      const involvedInstanceSet = nodeInfo?.contentInfo?.involvedInstanceSet || [];

      if (isComponent && involvedInstanceSet.length > 0) {
        projectInstanceInfoList = this.getComponentProjectInstanceInfoList(involvedInstanceSet[0]);
      }

      if (hasInstance) {
        if (nodeInfo) {
          label = `${nodeInfo.postFix ? `[${nodeInfo.postFix}] ` : ''}${nodeInfo.eventType}`;
          title = (
            <div>
              <div>{label}</div>
              <div style={{ marginTop: 4 }}>{intl.formatMessage(appFieldsMessages.project)}:</div>
              <div>{get(nodeInfo, ['contentInfo', 'projectName'])}</div>
            </div>
          );
        }
      } else {
        label =
          this.instanceMapping[node] && this.instanceMapping[node] !== node
            ? `${instanceStr} (${this.instanceMapping[node]})`
            : instanceStr;
        label = this.getComponentName(label);
        title = (
          <div>
            <div>{label}</div>
            {projectInstanceInfoList.length > 0 && (
              <>
                <div style={{ marginTop: 4 }}>{intl.formatMessage(appFieldsMessages.project)}:</div>
                {R.addIndex(R.map)(
                  (projectDisplayName, idx) => {
                    return <div key={idx}>{projectDisplayName}</div>;
                  },
                  R.uniq(
                    R.map((item) => {
                      const projectNameReal =
                        item.userName !== credentials.userName
                          ? `${item.projectName}@${item.userName}`
                          : item.projectName;
                      const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
                      return projectDisplayName;
                    }, projectInstanceInfoList),
                  ),
                )}
              </>
            )}
          </div>
        );
      }

      // get inter node info
      const isCollapse = !hasInstance ? !!this.nodeCollapseMap[node] : null;
      const { isInstance, hasContainers, parentName, haveIntraCausal } = !hasInstance
        ? this.allInterNodesInfo[node] || {}
        : {};

      const content = (
        <div className="flex-row flex-center-align hover-display" style={{ ...style, width: '100%', maxWidth: '100%' }}>
          {this.hasContainerInter && (
            <div className="flex-row" style={{ minWidth: 22 }}>
              {isInstance &&
                hasContainers &&
                (!isCollapse ? (
                  <CaretDownOutlined style={{ fontSize: 18 }} onClick={() => this.handleExpendNode(node, isCollapse)} />
                ) : (
                  <CaretRightOutlined
                    style={{ fontSize: 18 }}
                    onClick={() => this.handleExpendNode(node, isCollapse)}
                  />
                ))}
            </div>
          )}

          <Tooltip title={title} placement="topLeft" mouseEnterDelay={0.3}>
            <Radio
              className="hidden-line-with-ellipsis inline-block max-width"
              style={!hasInstance && parentName ? {} : { fontSize: 13, fontWeight: 'bold' }}
              value={node}
              checked={rootnodeVal === node}
            >
              {!hasInstance && haveIntraCausal && <span style={{ color: '#1890ff', marginRight: 4 }}>[*]</span>}
              {label}
            </Radio>
          </Tooltip>
          {haveIntraCausal && (
            <div
              style={{
                position: 'absolute',
                right: 8,
                fontSize: 16,
                lineHeight: '24px',
                width: 24,
                height: 24,
                textAlign: 'center',
                background: 'var(--body-background)',
                cursor: 'pointer',
              }}
              className="hover-display-item"
              onClick={() => this.handleInstanceClick(node)}
            >
              <ExpandAltOutlined />
            </div>
          )}
        </div>
      );

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          {content}
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleExpendNode(node, collapse) {
    const { instanceName } = this.props;
    const { nodeSearchVal } = this.state;

    this.nodeCollapseMap[node] = !collapse;
    this.handleNodeFilter(instanceName, nodeSearchVal);
    this.forceUpdate();
  }

  @autobind
  handleNodeFilter(instanceName, nodeSearchVal) {
    const { incidentMetaData } = this.props || {};
    const { hasCausalResult } = this.state;
    const hasInstance = Boolean(instanceName);
    this.filterNodeList = hasInstance ? this.allIntraNodes : this.allInterNodes;

    // filter by search
    this.filterNodeList = nodeSearchVal
      ? R.filter((node) => {
          const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, node);
          let label = node;
          if (!hasInstance) {
            label =
              this.instanceMapping[node] && this.instanceMapping[node] !== node
                ? `${node} (${this.instanceMapping[node]})`
                : node;
          }
          return (
            R.toLower(label).indexOf(R.toLower(nodeSearchVal)) !== -1 ||
            R.toLower(instanceStr).indexOf(R.toLower(nodeSearchVal)) !== -1
          );
        }, this.filterNodeList)
      : this.filterNodeList;

    if (!hasInstance && hasCausalResult) {
      const filterNodeList = [];
      const nodeChildMap = {};

      R.forEach((node) => {
        const { isInstance, parentName, haveIntraCausal, haveInterCausal } = this.allInterNodesInfo[node] || {};
        const haveCausal = this.interInstanceMap[node] || haveIntraCausal || haveInterCausal;
        if (isInstance) {
          if (haveCausal || nodeChildMap[node]) {
            filterNodeList.push(node);
          }
        } else if (haveCausal) {
          filterNodeList.push(node);
          if (parentName) {
            nodeChildMap[parentName] = true;
          }
        }
      }, R.reverse(this.filterNodeList));
      this.filterNodeList = R.reverse(filterNodeList);
    }

    // filter by inter node expend
    if (!hasInstance) {
      this.filterNodeList = R.filter((node) => {
        const { isInstance, parentName } = this.allInterNodesInfo[node] || {};
        if (isInstance) {
          return true;
        }
        return parentName && !this.nodeCollapseMap[parentName];
      }, this.filterNodeList);
    }
  }

  render() {
    const { intl, onChangeGraphView, graphView, incidentMetaData } = this.props;
    const { instanceName, incidentParams, relationProbability, filterModality, filterPattern } = this.props;
    const { isLoading, isGraphLoading, hasRelation, relationList, rootnodeInter, rootnode, nodeSearchVal } = this.state;
    const { showLogModal, edgeRelation, leftLabel, rightLabel, hasCausalResult } = this.state;
    const instanceIdCausalInfoMap = this.props?.incidentMetaData?.instanceIdCausalInfoMap || {};

    const hasInstance = Boolean(instanceName);
    const rootnodeVal = hasInstance ? rootnode : rootnodeInter;
    const { haveIntraCausal } = hasInstance ? {} : this.allInterNodesInfo[rootnodeInter] || {};
    const { filterNodeList } = this;

    const { instanceStr: rootnodeValStr } = getInstanceDisplayName(
      incidentMetaData?.instanceDisplayNameMap,
      rootnodeVal,
    );
    const { instanceStr } = getInstanceDisplayName(incidentMetaData?.instanceDisplayNameMap, instanceName);

    return (
      <Container
        className={`full-height flex-col flex-min-height ${isLoading ? 'loading' : ''}`}
        style={{ paddingTop: 8 }}
      >
        <div
          className="flex-col flex-grow flex-min-height corner-8"
          style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <div className="flex-row flex-center-justify flex-center-align" style={{ height: 40 }}>
            {!hasInstance && (
              <div
                className={`font-14 bold ${haveIntraCausal ? 'link' : ''}`}
                onClick={haveIntraCausal ? () => this.handleInstanceClick(rootnodeVal) : () => {}}
              >
                {haveIntraCausal && <span style={{ marginRight: 4 }}>[*]</span>}
                {this.getComponentName(rootnodeValStr)}
              </div>
            )}
            {hasInstance && (
              <div className="title">
                <Tooltip title={intl.formatMessage(causalMessages.backToInstances)}>
                  <i className="arrow left icon" style={{ cursor: 'pointer' }} onClick={this.handleBackToInter} />
                </Tooltip>
                {intl.formatMessage(causalMessages.eventsRelationsForInstance)}:
                <span className="instance">
                  <i className="circle icon" />
                  {this.instanceMapping[instanceName] && this.instanceMapping[instanceName] !== instanceName
                    ? `${this.getComponentName(instanceStr)} (${this.instanceMapping[instanceName]})`
                    : this.getComponentName(instanceStr)}
                </span>
              </div>
            )}
          </div>

          <div className="flex-grow flex-row flex-min-height">
            <div
              className="flex-col flex-min-height"
              style={{ width: 300, padding: '0 8px', borderRight: '1px solid var(--border-color-base)' }}
            >
              <Radio.Group
                className="flex-grow flex-col flex-min-height"
                onChange={this.onChangeRootnode}
                value={rootnodeVal}
              >
                <div className="flex-row">
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {intl.formatMessage(causalMessages.nodeList)}
                  </div>
                  <div className="flex-grow flex-row flex-end-justify">
                    <Radio.Group value={graphView} size="small" onChange={onChangeGraphView}>
                      <Tooltip title="Prediction Graph" placement="top">
                        <Radio.Button value="source">
                          <Icon size="small" component={() => <Tree color="currentColor" size="12px" rotate={-90} />} />
                        </Radio.Button>
                      </Tooltip>
                      <Tooltip title="Root Cause Graph" placement="top">
                        <Radio.Button value="target">
                          <Icon size="small" component={() => <Tree color="currentColor" size="12px" rotate={90} />} />
                        </Radio.Button>
                      </Tooltip>
                    </Radio.Group>
                  </div>
                </div>
                {!hasInstance && (
                  <div className="flex-row" style={{ marginTop: 8 }}>
                    <Checkbox
                      checked={hasCausalResult}
                      onChange={(e) => {
                        this.setState({ hasCausalResult: e.target.checked }, () => {
                          this.handleNodeFilter(instanceName, nodeSearchVal);
                          this.forceUpdate();
                        });
                      }}
                    >
                      Has casual result
                    </Checkbox>
                  </div>
                )}
                <div className="flex-row" style={{ marginTop: 8 }}>
                  <Input
                    allowClear
                    size="small"
                    placeholder="input search node"
                    value={nodeSearchVal}
                    onChange={({ target: { value } }) => {
                      this.setState(
                        { nodeSearchVal: value },
                        debounce(() => {
                          this.handleNodeFilter(instanceName, value);
                          this.cellMeasureCache.clearAll();
                          this.forceUpdate();
                        }, 600),
                      );
                    }}
                    style={{ width: '100%' }}
                    prefix={<SearchOutlined />}
                  />
                </div>

                <div className="flex-grow">
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        ref={(listNode) => (this.listNode = listNode)}
                        width={width}
                        height={height}
                        rowCount={filterNodeList.length}
                        deferredMeasurementCache={this.cellMeasureCache}
                        rowHeight={this.cellMeasureCache.rowHeight}
                        rowRenderer={this.renderListItemInter(rootnodeVal, filterNodeList)}
                      />
                    )}
                  </AutoSizer>
                </div>
              </Radio.Group>
            </div>
            <div className="flex-grow">
              <Spin spinning={isGraphLoading} wrapperClassName="full-height spin-full-height">
                <AutoSizer>
                  {({ width, height }) => (
                    <div style={{ width, height }}>
                      <div
                        className="d3-tree-container"
                        style={hasRelation ? {} : { display: 'none' }}
                        ref={(container) => {
                          this.container = container;
                        }}
                      />
                    </div>
                  )}
                </AutoSizer>

                {!hasRelation && (
                  <div className="full-height flex-row flex-center-align flex-center-justify">
                    <Empty description={intl.formatMessage(causalMessages.noCasualRelationFuond)} />
                  </div>
                )}

                <div className="flex-col" style={{ position: 'absolute', top: 8, right: 12 }}>
                  <div className="flex-row">
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[0] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=90%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[1] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=70%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[2] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=50%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: Defaults.Colorbrewer[3] }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'>=30%'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <i className="square icon" style={{ color: '#2cb37d' }} />
                      <span>
                        {intl.formatMessage(appFieldsMessages.prob)}
                        {'<30%'}
                      </span>
                    </div>
                  </div>
                  <div className="flex-row" style={{ marginTop: 4 }}>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{
                          width: 14,
                          border: 'none',
                          borderTop: '4px solid gray',
                          margin: 'auto 4px auto 2px',
                        }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=500'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{
                          width: 14,
                          border: 'none',
                          borderTop: '3.5px solid gray',
                          margin: 'auto 4px auto 2px',
                        }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=100'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{
                          width: 14,
                          border: 'none',
                          borderTop: '3px solid gray',
                          margin: 'auto 4px auto 2px',
                        }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=50'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{
                          width: 14,
                          border: 'none',
                          borderTop: '2.5px solid gray',
                          margin: 'auto 4px auto 2px',
                        }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'>=10'}
                      </span>
                    </div>
                    <div className="flex-row flex-center-align" style={{ width: 100, marginLeft: 8 }}>
                      <hr
                        style={{
                          width: 14,
                          border: 'none',
                          borderTop: '2px solid gray',
                          margin: 'auto 4px auto 2px',
                        }}
                      />
                      <span>
                        {intl.formatMessage(appFieldsMessages.count)}
                        {'<10'}
                      </span>
                    </div>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>

        {showLogModal && (
          <CausalRelationsModal
            isIntra={hasInstance}
            intraInstanceName={instanceName}
            instanceIdCausalInfoMap={instanceIdCausalInfoMap}
            edgeRelation={edgeRelation}
            leftLabel={leftLabel}
            rightLabel={rightLabel}
            incidentParams={incidentParams}
            relationProbability={relationProbability}
            filterModality={filterModality}
            filterPattern={filterPattern}
            instanceCausalInfo={this.instanceCausalInfo}
            instanceMapping={this.instanceMapping}
            onClose={this.handleLogModalClose}
            metricUnitMap={this.metricUnitMap}
            instanceDisplayNameMap={incidentMetaData?.instanceDisplayNameMap}
            getComponentProjectInstanceName={this.getComponentProjectInstanceName}
          />
        )}
      </Container>
    );
  }
}

const CausalRelationTree = injectIntl(CausalRelationTreeCore);
export default connect(
  (state) => {
    const { credentials } = state.auth;
    const { userName } = state.auth.userInfo;
    const { projects, projectDisplayMap, currentTheme } = state.app;
    return {
      userName,
      credentials,
      projects,
      projectDisplayMap,
      currentTheme,
    };
  },
  {
    push,
    replace,
    updateLastActionInfo,
  },
)(CausalRelationTree);
