import React, { useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ExpandOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import { State } from '../../../common/types';
import { Controls } from '../../../lib/reactflow/controls';
import { Background } from '../../../lib/reactflow/background';
import {
  ConnectionLineType,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  MarkerType,
  ReactFlow,
  updateEdge,
  useEdgesState,
  useNodesState,
} from '../../../lib/reactflow/core';
import { Tooltip } from '../../../lib/fui/react';
import { connectionID, getLayoutedElements, updateEdgeData, uuidv4 } from '../data/utils';

import CustomNodes from './CustomNodes';

import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

const nodeTypes = {
  customNodes: CustomNodes,
};

function FlowGraphicsCore(props: Object) {
  const { isDark } = props || {};
  const { workNodes, workEdges, stepCurrent, activeWorkNode } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};

  const flowRef = useRef(null);
  const edgeUpdateSuccessful = useRef(true);
  const [forceUpdateData, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    zoomNum: 1,
  });
  const { zoomNum } = state;
  const [nodes, setNodes, onNodesChange] = useNodesState(workNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(workEdges);

  const onEdgeUpdateStart = useCallback(
    (_, edge) => {
      edgeUpdateSuccessful.current = false;
    },
    [isDark],
  );

  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      let oldNodes = flowRef?.current ? flowRef?.current.getNodes() : [];
      const oldEdges = flowRef?.current ? flowRef?.current.getEdges() : [];
      oldNodes = R.map((node) => ({ ...node, data: { ...node.data, activeNodeFlag: false } }), oldNodes);

      const oldId = `${oldEdge.source}${connectionID}${oldEdge.target}`;
      const newId = `${newConnection.source}${connectionID}${newConnection.target}`;
      edgeUpdateSuccessful.current = true;

      const findEdge = R.find((item) => item.id === newId, oldEdges || []);
      const flagId = findEdge ? oldId : newId;
      const flagList = findEdge ? oldEdges : updateEdge(oldEdge, newConnection, oldEdges);
      let newActiveWorkNode;
      const newEdges = R.map((edge) => {
        if (flagId === `${edge.source}${connectionID}${edge.target}`) {
          oldNodes = R.map((item) => {
            if (item.id === edge.target) {
              newActiveWorkNode = { ...item, data: { ...item.data, activeNodeFlag: true } };
              return newActiveWorkNode;
            } else {
              return { ...item, data: { ...item.data, activeNodeFlag: false } };
            }
          }, oldNodes || []);
          return updateEdgeData(isDark, edge, true);
        } else {
          return updateEdgeData(isDark, edge, false);
        }
      }, flagList || []);
      let newState = { activeWorkNode: newActiveWorkNode, stepCurrent: 1 };
      if (newActiveWorkNode.id === activeWorkNode.id) {
        newState = { stepCurrent: 1 };
      }

      const { nodes: n, edges: e } = getLayoutedElements(oldNodes, newEdges);
      contextSetState({ workNodes: n, workEdges: e, ...newState });
      setNodes(n);
      setEdges(e);
    },
    [isDark, activeWorkNode],
  );

  const onEdgeUpdateEnd = useCallback(
    (_, edge) => {
      if (!edgeUpdateSuccessful.current) {
        const id = `${edge.source}${connectionID}${edge.target}`;
        const oldNodes = flowRef?.current ? flowRef?.current.getNodes() : [];
        const oldEdges = flowRef?.current ? flowRef?.current.getEdges() : [];

        const delectNode = R.find((node) => !node?.data?.nodeData, oldNodes);
        if (delectNode && false) {
          let newNodes = R.filter((node) => node?.id !== delectNode?.id, oldNodes);
          let newEdges = [delectNode].reduce((acc, node) => {
            const incomers = getIncomers(node, oldNodes, oldEdges);
            const outgoers = getOutgoers(node, oldNodes, oldEdges);
            const connectedEdges = getConnectedEdges([node], oldEdges);
            const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
            const createdEdges = incomers.flatMap(({ id: source }) =>
              outgoers.map(({ id: target }) => ({ id: `${source}${connectionID}${target}`, source, target })),
            );
            return [...remainingEdges, ...createdEdges];
          }, oldEdges);

          newEdges = R.map((item) => {
            if (`${item.source}${connectionID}${item.target}` === id) {
              return updateEdgeData(isDark, item, true);
            } else {
              return updateEdgeData(isDark, item, false);
            }
          }, newEdges || []);

          let newState = {};
          if (newNodes.length === 1) {
            newState = { stepCurrent: 0 };
          }

          let newActiveWorkNode;
          newNodes = R.map((node) => {
            if (node?.id === edge.target) {
              newActiveWorkNode = { ...node, data: { ...node.data, activeNodeFlag: true } };
              return newActiveWorkNode;
            }
            return { ...node, data: { ...node.data, activeNodeFlag: false } };
          }, newNodes || []);
          if (delectNode?.data?.activeNodeFlag && delectNode?.id === edge.target) {
            newNodes = R.map((node) => {
              if (node?.data?.isRoot) {
                newActiveWorkNode = { ...node, data: { ...node.data, activeNodeFlag: true } };
                return newActiveWorkNode;
              }
              return { ...node, data: { ...node.data, activeNodeFlag: false } };
            }, newNodes || []);
            newState = { ...newState, stepCurrent: 0, activeWorkNode: newActiveWorkNode };
          } else {
            newState = { ...newState, activeWorkNode: newActiveWorkNode };
          }

          contextSetState({ workNodes: newNodes, workEdges: newEdges, ...newState });
          setNodes(newNodes);
          setEdges(newEdges);
        } else if (edge.target !== activeWorkNode.id) {
          const newEdges = R.map((item) => {
            if (`${item.source}${connectionID}${item.target}` === id) {
              return updateEdgeData(isDark, item, true);
            } else {
              return updateEdgeData(isDark, item, false);
            }
          }, oldEdges || []);

          let newActiveWorkNode;
          const newNodes = R.map((node) => {
            if (node?.id === edge.target) {
              newActiveWorkNode = { ...node, data: { ...node.data, activeNodeFlag: true } };
              return newActiveWorkNode;
            }
            return { ...node, data: { ...node.data, activeNodeFlag: false } };
          }, oldNodes || []);

          const newState = { activeWorkNode: newActiveWorkNode };

          contextSetState({ workNodes: newNodes, workEdges: newEdges, ...newState });
          setNodes(newNodes);
          setEdges(newEdges);
        }
      }
      edgeUpdateSuccessful.current = true;
    },
    [isDark, activeWorkNode],
  );

  const onEdgeClick = useCallback(
    (_, edge) => {
      if (edge.target === activeWorkNode.id) return;
      let oldNodes = flowRef?.current ? flowRef?.current.getNodes() : [];
      let oldEdges = flowRef?.current ? flowRef?.current.getEdges() : [];

      let newActiveWorkNode;
      oldNodes = R.map((item) => {
        if (item.id === edge.target) {
          newActiveWorkNode = { ...item, data: { ...item.data, activeNodeFlag: true } };
          return newActiveWorkNode;
        } else {
          return { ...item, data: { ...item.data, activeNodeFlag: false } };
        }
      }, oldNodes || []);

      oldEdges = R.map((item) => {
        if (item.id === edge.id) {
          return updateEdgeData(isDark, item, true);
        } else {
          return updateEdgeData(isDark, item, false);
        }
      }, oldEdges || []);

      contextSetState({ workNodes: oldNodes, workEdges: oldEdges, activeWorkNode: newActiveWorkNode, stepCurrent: 1 });
      setEdges(oldEdges);
      setNodes(oldNodes);
    },
    [isDark, activeWorkNode],
  );

  const onNodeClick = useCallback(
    (_, node) => {
      const clickNodeDocument = _.target.id;
      if (R.includes(clickNodeDocument, ['add-node-id', 'delete-node-id'])) return;
      if (node.id === activeWorkNode.id) return;
      const oldNodes = flowRef?.current ? flowRef?.current.getNodes() : [];
      const oldEdges = flowRef?.current ? flowRef?.current.getEdges() : [];
      const incomers = getIncomers(node, oldNodes, oldEdges);

      let newEdges = [];
      if (incomers.length > 0) {
        const nextEgde = `${incomers[0].id}${connectionID}${node.id}`;
        newEdges = R.map((edge) => {
          if (nextEgde === `${edge.source}${connectionID}${edge.target}`) {
            return updateEdgeData(isDark, edge, true);
          } else {
            return updateEdgeData(isDark, edge, false);
          }
        }, oldEdges || []);
      } else {
        newEdges = R.map((item) => updateEdgeData(isDark, item, false), oldEdges || []);
      }

      let newActiveWorkNode;
      const newNodes = R.map((item) => {
        if (item.id === node.id) {
          newActiveWorkNode = { ...item, data: { ...item.data, activeNodeFlag: true } };
          return newActiveWorkNode;
        } else {
          return { ...item, data: { ...item.data, activeNodeFlag: false } };
        }
      }, oldNodes || []);
      contextSetState({
        workNodes: newNodes,
        workEdges: newEdges,
        activeWorkNode: newActiveWorkNode,
        stepCurrent: newActiveWorkNode.data.isRoot ? 0 : 1,
      });
      setEdges(newEdges);
      setNodes(newNodes);
    },
    [isDark, activeWorkNode],
  );

  const onPaneClick = useCallback(() => {
    const oldNodes = flowRef?.current ? flowRef?.current.getNodes() : [];
    const oldEdges = flowRef?.current ? flowRef?.current.getEdges() : [];

    const delectNode = R.find((node) => !node?.data?.nodeData, oldNodes);
    if (delectNode?.data?.isRoot) return;

    if (delectNode && false) {
      let newNodes = R.filter((node) => node?.id !== delectNode.id, oldNodes);
      let newEdges = [delectNode].reduce((acc, node) => {
        const incomers = getIncomers(node, oldNodes, oldEdges);
        const outgoers = getOutgoers(node, oldNodes, oldEdges);
        const connectedEdges = getConnectedEdges([node], oldEdges);
        const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
        const createdEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }) => ({ id: `${source}${connectionID}${target}`, source, target })),
        );
        return [...remainingEdges, ...createdEdges];
      }, oldEdges);

      let newState = {};
      if (newNodes.length === 1) {
        newState = { stepCurrent: 0 };
      }

      if (delectNode?.data?.activeNodeFlag) {
        let newActiveWorkNode;
        newNodes = R.map((node) => {
          if (node?.data?.isRoot) {
            newActiveWorkNode = { ...node, data: { ...node.data, activeNodeFlag: true } };
            return newActiveWorkNode;
          }
          return node;
        }, newNodes || []);
        newEdges = R.map((edge) => updateEdgeData(isDark, edge, false), newEdges);
        newState = { activeWorkNode: newActiveWorkNode, stepCurrent: 0 };
      }

      const { nodes: n, edges: e } = getLayoutedElements(newNodes, newEdges);
      contextSetState({ workNodes: n, workEdges: e, ...newState });
      setNodes(n);
      setEdges(e);
    } else {
      const findActiveNode = R.find((node) => node?.data?.activeNodeFlag, oldNodes || []);
      if (findActiveNode) {
        const incomers = getIncomers(findActiveNode, oldNodes, oldEdges);
        if (incomers.length > 0) {
          const nextEgde = `${incomers[0].id}${connectionID}${findActiveNode.id}`;
          const newEdges = R.map((edge) => {
            if (nextEgde === `${edge.source}${connectionID}${edge.target}`) {
              return updateEdgeData(isDark, edge, true);
            } else {
              return updateEdgeData(isDark, edge, false);
            }
          }, oldEdges || []);

          const { nodes: n, edges: e } = getLayoutedElements(oldNodes, newEdges);
          contextSetState({ workNodes: n, workEdges: e });
          setNodes(n);
          setEdges(e);
        }
      }
    }
  }, [isDark]);

  const onMoveEnd = useCallback((_, data) => {
    setState({ zoomNum: data?.zoom || 1 });
  }, []);

  const deleteNodes = (id) => {
    const oldNodes = flowRef?.current ? flowRef?.current.getNodes() : [];
    const oldEdges = flowRef?.current ? flowRef?.current.getEdges() : [];
    const delectNode = R.find((node) => node.id === id, oldNodes);
    if (delectNode) {
      let newNodes = R.filter((node) => node.id !== id, oldNodes);
      let newEdges = [delectNode].reduce((acc, node) => {
        const incomers = getIncomers(node, oldNodes, oldEdges);
        const outgoers = getOutgoers(node, oldNodes, oldEdges);
        const connectedEdges = getConnectedEdges([node], oldEdges);
        const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
        const createdEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }) => ({ id: `${source}${connectionID}${target}`, source, target })),
        );
        return [...remainingEdges, ...createdEdges];
      }, oldEdges);

      let newState = {};
      if (newNodes.length === 1) {
        newState = { stepCurrent: 0 };
      }

      if (delectNode?.data?.activeNodeFlag) {
        let newActiveWorkNode;
        newNodes = R.map((node) => {
          if (node?.data?.isRoot) {
            newActiveWorkNode = { ...node, data: { ...node.data, activeNodeFlag: true } };
            return newActiveWorkNode;
          }
          return node;
        }, newNodes || []);
        newEdges = R.map((edge) => updateEdgeData(isDark, edge, false), newEdges);
        newState = { activeWorkNode: newActiveWorkNode, stepCurrent: 0 };
      }

      const { nodes: n, edges: e } = getLayoutedElements(newNodes, newEdges);
      contextSetState({ workNodes: n, workEdges: e, ...newState });
      setNodes(n);
      setEdges(e);
    }
  };

  const addNodes = (id, isRoot) => {
    let oldNodes = flowRef?.current ? flowRef?.current.getNodes() : [];
    let oldEdges = flowRef?.current ? flowRef?.current.getEdges() : [];

    const delectNode = R.find((node) => !node?.data?.nodeData, oldNodes);
    if (delectNode) {
      const newNodes = R.filter((node) => node?.data?.nodeData, oldNodes);
      const newEdges = [delectNode].reduce((acc, node) => {
        const incomers = getIncomers(node, oldNodes, oldEdges);
        const outgoers = getOutgoers(node, oldNodes, oldEdges);
        const connectedEdges = getConnectedEdges([node], oldEdges);
        const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
        const createdEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }) => ({ id: `${source}${connectionID}${target}`, source, target })),
        );
        return [...remainingEdges, ...createdEdges];
      }, oldEdges);
      oldNodes = newNodes;
      oldEdges = newEdges;
    }

    oldNodes = R.map((node) => ({ ...node, data: { ...node.data, activeNodeFlag: false } }), oldNodes);
    oldEdges = R.map((edge) => updateEdgeData(isDark, edge, false), oldEdges);
    const newNodeId = uuidv4();
    const newNode = {
      id: newNodeId,
      data: { isRoot: false, id: newNodeId, addNodes, deleteNodes, activeNodeFlag: true },
      type: 'customNodes',
    };
    const newEdge = updateEdgeData(
      isDark,
      { id: `${id}${connectionID}${newNodeId}`, source: id, target: newNodeId },
      true,
    );
    const lastFindNodeIdx = R.findLastIndex((node) => node.id === id, oldNodes);
    const addLeftFlag = R.find((edge) => R.includes(`${id}${connectionID}`, edge.id), oldEdges);
    const lastFindEdgeIdx = R.findLastIndex((edge) => R.includes(`${connectionID}${id}`, edge.id), oldEdges);
    let newNodes = [];
    let newEdges = [];
    if (isRoot || addLeftFlag) {
      newNodes = oldNodes.concat(newNode);
      newEdges = oldEdges.concat(newEdge);
    } else {
      newNodes = R.clone(oldNodes);
      newNodes.splice(lastFindNodeIdx + 1, 0, newNode);
      newEdges = R.clone(oldEdges);
      newEdges.splice(lastFindEdgeIdx + 1, 0, newEdge);
    }
    const { nodes: n, edges: e } = getLayoutedElements(newNodes, newEdges);
    contextSetState({ workNodes: n, workEdges: e, activeWorkNode: newNode, stepCurrent: 1 });
    setNodes(n);
    setEdges(e);
  };

  useEffect(() => {
    const nodes = flowRef?.current ? flowRef?.current.getNodes() : [];
    if (stepCurrent === 0 && nodes.length === 0) {
      const isRootId = uuidv4();
      const initialNodes = [
        {
          id: isRootId,
          data: { isRoot: true, id: isRootId, addNodes, deleteNodes, activeNodeFlag: true },
          type: 'customNodes',
        },
      ];
      const { nodes: n, edges: e } = getLayoutedElements(initialNodes, edges);
      contextSetState({ workNodes: n, workEdges: e, activeWorkNode: { ...(n[0] || {}) } });
      setNodes(n);
      setEdges(e);
    }
  }, [stepCurrent]);

  useEffect(() => {
    if (workNodes.length > 0) {
      const { nodes: n, edges: e } = getLayoutedElements(workNodes, workEdges);
      contextSetState({ workNodes: n, workEdges: e });
      setNodes(n);
      setEdges(e);
    }
  }, [workNodes, workEdges]);

  useEffect(() => {
    if (flowRef.current) {
      setTimeout(() => {
        flowRef.current.fitView();
      }, 200);
    }
  }, [forceUpdateData]);

  const onInit = (reactFlowInstance) => {
    flowRef.current = reactFlowInstance;
  };

  return (
    <div className="flex-grow rca-flow-container" id="react-flow-id">
      <ReactFlow
        maxZoom={1}
        nodes={nodes}
        edges={edges}
        minZoom={0.2}
        onInit={onInit}
        nodeTypes={nodeTypes}
        selectionKeyCode={false}
        edgeUpdaterRadius={20}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        proOptions={{ hideAttribution: true }}
        defaultEdgeOptions={{
          style: { strokeWidth: 2.4 },
          type: 'smoothstep',
          markerEnd: { type: MarkerType.ArrowClosed, color: isDark ? '#555' : '#b1b1b7' },
        }}
        connectionLineType={ConnectionLineType.SmoothStep}
        onEdgeClick={onEdgeClick}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onEdgeUpdate={onEdgeUpdate}
        onPaneClick={onPaneClick}
        onNodeClick={onNodeClick}
        onMoveEnd={onMoveEnd}
      >
        <Background color={isDark ? '#ccccdc26' : '#ccc'} size={2} variant="dots" />
        <Controls showFitView={false} showInteractive={false} showZoom={false} position="top-right">
          <div className="flex-col">
            <Tooltip title="Zoom in 0.2x" placement="left">
              <Button
                onClick={() => {
                  flowRef.current.zoomIn();
                  setTimeout(() => setState({ zoomNum: flowRef.current.getZoom() }), 200);
                }}
                style={{ width: 22, height: 26, padding: 0, borderRadius: 0 }}
                disabled={zoomNum === 1}
              >
                <ZoomInOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Zoom out 0.2x" placement="left">
              <Button
                onClick={() => {
                  flowRef.current.zoomOut();
                  setTimeout(() => setState({ zoomNum: flowRef.current.getZoom() }), 200);
                }}
                style={{ width: 22, height: 26, padding: 0, borderRadius: 0 }}
                disabled={zoomNum === 0.2}
              >
                <ZoomOutOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Adjust to appropriate view" placement="left">
              <Button
                onClick={() => {
                  flowRef.current.fitView();
                  setTimeout(() => setState({ zoomNum: flowRef.current.getZoom() }), 200);
                }}
                style={{ width: 22, height: 26, padding: 0, borderRadius: 0 }}
              >
                <ExpandOutlined />
              </Button>
            </Tooltip>
          </div>
        </Controls>
      </ReactFlow>
    </div>
  );
}

const FlowGraphics = injectIntl(FlowGraphicsCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(FlowGraphics);
