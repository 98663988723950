import React, { useContext, useEffect, useReducer } from 'react';
import * as R from 'ramda';
import { Button, Radio } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { Modal } from '../../../lib/fui/react';
import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

import { appButtonsMessages } from '../../../common/app/messages';

export default function TypeList({ intl, typeList, isDark }: Object) {
  const { activeWorkNode } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    nodeTypeRadio: undefined,
  });
  const { nodeTypeRadio } = state;

  const okNext = () => {
    const { initData, isRoot, id, title } = nodeTypeRadio || {};
    contextSetState({
      activeTypeItem: nodeTypeRadio,
      activeWorkNode: {
        ...activeWorkNode,
        data: {
          ...activeWorkNode.data,
          nodeData: { ...initData, isRoot, nodeType: id, title, localTitle: title },
          localNodeData: { ...initData, isRoot, nodeType: id, title, localTitle: title },
        },
      },
    });
  };

  const cancelNext = (nodeData) => {
    let findNodeTypeData;
    R.forEach((item) => {
      R.forEach((_item) => {
        if (_item.id === nodeData.nodeType) {
          findNodeTypeData = _item;
        }
      }, item.dataList || []);
    }, typeList || []);
    setState({ nodeTypeRadio: findNodeTypeData });
  };

  const nodeTypeNext = () => {
    const { nodeData } = activeWorkNode?.data || {};
    const { initData, isRoot, id, title } = nodeTypeRadio || {};
    if (R.isNil(nodeData)) {
      contextSetState({
        activeTypeItem: nodeTypeRadio,
        activeWorkNode: {
          ...activeWorkNode,
          data: {
            ...activeWorkNode.data,
            nodeData: { ...initData, isRoot, nodeType: id, title, localTitle: title },
            localNodeData: { ...initData, isRoot, nodeType: id, title, localTitle: title },
          },
        },
      });
    } else if (nodeData.nodeType === id) {
      contextSetState({ activeTypeItem: nodeTypeRadio });
    } else {
      Modal.confirm({
        icon: <ExclamationCircleFilled />,
        content: 'The previous data will be overwritten after switching. Are you sure you want to continue?',
        okText: `${intl.formatMessage(appButtonsMessages.yes)}`,
        cancelText: `${intl.formatMessage(appButtonsMessages.no)}`,
        onOk: okNext,
        onCancel: () => cancelNext(nodeData),
      });
    }
  };

  useEffect(() => {
    const { nodeData } = activeWorkNode?.data || {};
    let findNodeTypeData;
    R.forEach((item) => {
      R.forEach((_item) => {
        if (_item.id === nodeData?.nodeType) {
          findNodeTypeData = _item;
        }
      }, item.dataList || []);
    }, typeList || []);
    setState({ nodeTypeRadio: findNodeTypeData });
  }, [activeWorkNode]);

  return (
    <>
      <div style={{ userSelect: 'none', overflowY: 'scroll' }}>
        {R.addIndex(R.map)((typeInfo, typeIdx) => {
          const { title, dataList } = typeInfo;
          return (
            <div key={title}>
              <div className="font-16 bold" style={{ ...(typeIdx !== 0 ? { marginTop: 16 } : {}) }}>
                {title}
              </div>
              <Radio.Group value={nodeTypeRadio?.id} onChange={(e) => e.stopPropagation()}>
                <div className="flex-row flex-wrap">
                  {R.addIndex(R.map)((item, idx) => {
                    const { id, title, icon } = item;
                    return (
                      <div
                        key={id}
                        className="flex-row clickable flex-center-align overflow-hidden"
                        style={{ width: 300, padding: 8, flexShrink: 0, marginRight: 26 }}
                        onClick={() => setState({ nodeTypeRadio: item })}
                      >
                        <Radio value={id} />
                        <div
                          className="text-center font-24 corner-8"
                          style={{
                            width: 50,
                            height: 50,
                            lineHeight: '50px',
                            marginRight: 8,
                            background: isDark ? '#3a3a3a' : '#efefef',
                            flexShrink: 0,
                          }}
                        >
                          {icon}
                        </div>
                        <div className="flex-grow overflow-hidden">
                          <div className="font-14 bold hidden-line-with-ellipsis">{title}</div>
                        </div>
                      </div>
                    );
                  }, dataList || [])}
                </div>
              </Radio.Group>
            </div>
          );
        }, typeList || [])}
      </div>
      <div className="flex-row flex-end-justify" style={{ marginTop: 16 }}>
        <Button
          size="small"
          type="primary"
          disabled={!nodeTypeRadio}
          style={{ width: 70 }}
          onClick={() => nodeTypeNext()}
        >
          {intl.formatMessage(appButtonsMessages.next)}
        </Button>
      </div>
    </>
  );
}
