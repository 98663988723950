/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import { ConnectedRouter } from 'react-router-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PrivateRoute } from '../../common/app/components';
import { BaseUrls } from './Constants';

import { StatusComponay } from '../public';
import {
  AwsLandingPage,
  IDPOptions,
  IDPRegister,
  Login,
  PassportLogin,
  PromotionSignup,
  ResetPassword,
  Signup,
  SignupStepConfirm,
} from '../auth';
import { ForgotPassword, ForgotUsername } from '../../../components/auth';
import { AppActionPage, AppCentricPage, AppCentricPageSingle } from './components';
import {
  GlobalCapacityPlanningView,
  GlobalHealthView,
  GlobalSystemHealthViewNew,
  GlobalSystemIgnoredIncidentView,
  GlobalSystemIncidentPredictionMatchedView,
  GlobalSystemIncidentPredictionView,
  GlobalSystemKnowledgeBaseView,
  GlobalSystemKnowledgeBaseViewNew,
  GlobalSystemPlatformViewSingle,
  GlobalSystemRootCauseList,
} from '../dashboard';
import { InsightQuery } from '../query';
import { CapacityPlanningCalendar, Events, EventsDetails, EventsHistory, MetricLineCharts, EmbedMetricLineCharts } from '../metric';
import { LogAnalysisNew, LogCalendarChart } from '../log';
import { JobAnalysis } from '../job';
import { CausalAnalysis, CausalGroupAnalysis } from '../causal';
import {
  CopyProject,
  ExternalServiceSetting,
  ExternalZoomAuthSetting,
  IdentityProvidersSetting,
  InsightfinderSettings,
  ProjectIntegrations,
  ProjectIntegrationsDataSource,
  ProjectSettings,
  ProjectWizard,
  StatusPageSetting,
  SystemBatch,
  SystemSetting,
} from '../settings';
import { Help } from '../help';
import AccountInfo from '../../../components/AccountInfo';
import { LogImportantEventReport, LogRareEventReport, MetricReport } from '../report';

import { FileAnalysis } from '../../../components/filetabs';
import FileDetails from '../../../components/cloud/monitoring/files';
import FileDetectionDetails from '../../../components/cloud/monitoring/filedetection';
import { BugDetails, BugRepository } from '../usecase';

import GlobalServiceMap from '../serviceMap/ServiceMap';
import SystemInsights from '../global/systeminsights';
import StartPage from '../start/StartPage';
import WebSocketMiddleware from './WebSocketMiddleware';
import Dashboard from '../global/dashboard';
import GlobalActionPage from '../action/ActionPage';
import SandBoxSignup from '../auth/SandBoxSignup';
import EmbedServiceMap from '../serviceMap/EmbedServiceMap';
import ActionWorkFlow from '../action/ActionWorkFlow';
import EmbedSystemInsights from '../global/EmbedSystemInsights';
import EmbedSystemRootCause from '../dashboard/EmbedSystemRootCause';
import EmbedSystemPrediction from '../dashboard/EmbedSystemPrediction';

// const isLimitedVersion = process.env.LIMITED_VERSION;

const GlobalRoutings = () => {
  return (
    <Switch>
      <Route
        path={BaseUrls.GlobalHealth}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalHealthView {...props} />
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalHealthSystem}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalSystemPlatformViewSingle view="health">
              <GlobalSystemHealthViewNew {...props} />
            </GlobalSystemPlatformViewSingle>
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalSystemInsights}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalSystemPlatformViewSingle view="insights">
              <SystemInsights />
            </GlobalSystemPlatformViewSingle>
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalDashboard}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalSystemPlatformViewSingle view="dashboard">
              <Dashboard />
            </GlobalSystemPlatformViewSingle>
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalSystemPrediction}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalSystemPlatformViewSingle view="incidentPrediction">
              <GlobalSystemIncidentPredictionView {...props} />
            </GlobalSystemPlatformViewSingle>
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalSystemPredictionMatched}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalSystemPlatformViewSingle view="matchedIncidentPrediction">
              <GlobalSystemIncidentPredictionMatchedView {...props} />
            </GlobalSystemPlatformViewSingle>
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalSystemRootCause}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalSystemPlatformViewSingle view="rootCause">
              <GlobalSystemRootCauseList {...props} />
            </GlobalSystemPlatformViewSingle>
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalSystemKnowledgeBase}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalSystemPlatformViewSingle view="knowledgebase">
              <GlobalSystemKnowledgeBaseViewNew {...props} />
            </GlobalSystemPlatformViewSingle>
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalSystemKnowledgeBaseNew}
        exact
        render={(props) => (
          <AppCentricPage>
            <GlobalSystemPlatformViewSingle view="knowledgebase">
              <GlobalSystemKnowledgeBaseView {...props} />
            </GlobalSystemPlatformViewSingle>
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalSystemIgnoredIncident}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalSystemPlatformViewSingle view="knowledgebase">
              <GlobalSystemIgnoredIncidentView {...props} />
            </GlobalSystemPlatformViewSingle>
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalCapacityPlanning}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalCapacityPlanningView {...props} />
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.CapacityPlanningCalendar}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <CapacityPlanningCalendar {...props} />
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalAction}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalActionPage {...props} />
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalServiceMap}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <GlobalServiceMap {...props} />
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalActionWorkFlow}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <ActionWorkFlow {...props} />
          </AppCentricPage>
        )}
      />
      <Route
        path={BaseUrls.GlobalStart}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <StartPage {...props} />
          </AppCentricPage>
        )}
      />
    </Switch>
  );
};

const QueryRoutings = () => {
  return (
    <AppCentricPageSingle>
      <Switch>
        <Route path={BaseUrls.Query} component={InsightQuery} />
      </Switch>
    </AppCentricPageSingle>
  );
};

const MetricRoutings = () => {
  return (
    <AppCentricPageSingle>
      <Switch>
        <Route path={BaseUrls.MetricEvents} component={Events} />
        <Route path={BaseUrls.MetricLineCharts} component={MetricLineCharts} />
        <Route path={BaseUrls.EventsDetails} component={EventsDetails} />
        <Route path={BaseUrls.EventsHistory} component={EventsHistory} />
        <Redirect from="*" to={BaseUrls.MetricEvents} />
      </Switch>
    </AppCentricPageSingle>
  );
};

const LogRoutings = () => {
  return (
    <AppCentricPage needsGlobalInfo>
      <Switch>
        <Route path={BaseUrls.LogCalendar} component={LogCalendarChart} />
        <Route path={BaseUrls.LogAnalysis} component={LogAnalysisNew} />
        <Redirect from="*" to={BaseUrls.LogCalendar} />
      </Switch>
    </AppCentricPage>
  );
};

const JobRoutings = () => {
  return (
    <AppCentricPageSingle>
      <Switch>
        <Route path={BaseUrls.JobAnalysis} component={JobAnalysis} />
        <Redirect from="*" to={BaseUrls.JobAnalysis} />
      </Switch>
    </AppCentricPageSingle>
  );
};

const CausalRoutings = () => {
  return (
    <AppCentricPageSingle>
      <Switch>
        <Route path={BaseUrls.CausalAnalysis} component={CausalAnalysis} />
        <Route path={BaseUrls.CausalGroupAnalysis} component={CausalGroupAnalysis} />
        <Redirect from="*" to={BaseUrls.CausalAnalysis} />
      </Switch>
    </AppCentricPageSingle>
  );
};

const SettingsRoutings = () => {
  return (
    <AppCentricPageSingle>
      <Switch>
        <Route path={BaseUrls.SystemSetting} exact component={SystemSetting} />
        <Route path={BaseUrls.SystemBatch} exact component={SystemBatch} />
        <Route path={BaseUrls.SettingsProject} exact component={ProjectSettings} />
        <Route path={BaseUrls.SettingsIntegrations} exact component={ProjectIntegrations} />
        <Route path={BaseUrls.SettingsIntegrationsDataSource} exact component={ProjectIntegrationsDataSource} />
        <Route path={BaseUrls.SettingsProjectWizard} exact component={ProjectWizard} />
        <Route path={BaseUrls.SettingsCopyProject} exact component={CopyProject} />

        <Route path={BaseUrls.InsightfinderSetting} exact component={InsightfinderSettings} />
        <Route path={BaseUrls.ExternalServiceSetting} exact component={ExternalServiceSetting} />
        <Route path={BaseUrls.IdentityProvidersSetting} exact component={IdentityProvidersSetting} />
        <Route path={BaseUrls.StatusPageSetting} exact component={StatusPageSetting} />
        <Redirect from="*" to={BaseUrls.SystemSetting} />
      </Switch>
    </AppCentricPageSingle>
  );
};

const ReportRoutings = () => {
  return (
    <Switch>
      <Route path={BaseUrls.ReportMetric} exact component={MetricReport} />
      <Route path={BaseUrls.ReportLogRareEvent} exact component={LogRareEventReport} />
      <Route path={BaseUrls.ReportLogImportantEvent} exact component={LogImportantEventReport} />
    </Switch>
  );
};
const FileTabsRoutings = () => {
  return (
    <AppCentricPageSingle>
      <Switch>
        <Route path={BaseUrls.FileAnalysis} exact component={FileAnalysis} />
        <Route path={BaseUrls.FilesMonitoring} exact component={FileDetails} />
        <Route path={BaseUrls.FilesDetectionMonitoring} exact component={FileDetectionDetails} />
        <Redirect from="*" to={BaseUrls.FileAnalysis} />
      </Switch>
    </AppCentricPageSingle>
  );
};

const UseCaseRoutings = () => {
  return (
    <AppCentricPageSingle>
      <Switch>
        <Route path={BaseUrls.Usecase} component={BugRepository} />
      </Switch>
    </AppCentricPageSingle>
  );
};

/**
 * The private routing defines the routings needs authentication. When access these pages
 * without login, it will be redirected to login page and be redirected back after login.
 */
const PrivateRoutings = ({ isFirstLogin }) => {
  return (
    <Switch>
      <Route path={BaseUrls.Global} component={GlobalRoutings} />
      <Route path={BaseUrls.Query} component={QueryRoutings} />
      <Route path={BaseUrls.Metric} component={MetricRoutings} />
      <Route path={BaseUrls.Log} component={LogRoutings} />
      <Route path={BaseUrls.Job} component={JobRoutings} />
      <Route path={BaseUrls.Causal} component={CausalRoutings} />
      <Route path={BaseUrls.Settings} component={SettingsRoutings} />
      <Route
        path={BaseUrls.Help}
        render={(props) => (
          <AppCentricPageSingle>
            <Help {...props} />
          </AppCentricPageSingle>
        )}
      />
      <Route
        path={BaseUrls.AccountInfo}
        render={(props) => (
          <AppCentricPageSingle>
            <AccountInfo {...props} />
          </AppCentricPageSingle>
        )}
      />
      <Route path={BaseUrls.Report} component={ReportRoutings} />

      {/* <Route path={BaseUrls.FileAnalysis} component={FileTabsRoutings} /> */}
      <Route
        path={BaseUrls.FileAnalysis}
        exact
        render={(props) => (
          <AppCentricPage needsGlobalInfo>
            <FileAnalysis {...props} />
          </AppCentricPage>
        )}
      />
      <Route path={BaseUrls.UsecaseBase} component={UseCaseRoutings} />
      <Route path={BaseUrls.UsecaseDetails} component={BugDetails} />

      <Redirect from="*" to={isFirstLogin ? BaseUrls.GlobalStart : BaseUrls.GlobalSystemRootCause} />
    </Switch>
  );
};

type Props = {
  history: Object,
  isFirstLogin: Boolean,
};

export const EmbedRouting = ({ history }: Props) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path="/global/embed/servicemap" component={EmbedServiceMap} />
      <Route exact path="/global/embed/systeminsights" component={EmbedSystemInsights} />
      <Route path="/global/embed/metriclinecharts" component={EmbedMetricLineCharts} />
      <Route exact path="/global/embed/systeminvestigation" component={EmbedSystemRootCause} />
      <Route exact path="/global/embed/systemprediction" component={EmbedSystemPrediction} />
    </Switch>
  </ConnectedRouter>
);

const Routing = ({ history, isFirstLogin }: Props) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/auth/login2" component={Login} />
      <Route exact path="/auth/virtual_login" component={Login} />
      <Route exact path="/auth/idpoptions" component={IDPOptions} />
      <Route exact path="/auth/idpregister" component={IDPRegister} />
      <Route exact path="/auth/forgotUsername" component={ForgotUsername} />
      <Route exact path="/auth/forgotPassword" component={ForgotPassword} />
      <Route exact path="/auth/resetPassword" component={ResetPassword} />
      <Route exact path="/auth/signup" component={Signup} />
      <Route exact path="/auth/sandbox-signup" component={SandBoxSignup} />
      <Route exact path="/auth/signup2" component={SignupStepConfirm} />
      <Route exact path="/auth/promotion_signup" component={PromotionSignup} />
      <Route path="/auth/zoom" component={ExternalZoomAuthSetting} />
      <Route exact path="/auth/awsLandingPage" component={AwsLandingPage} />

      <Route exact path="/auth/customeraction" component={AppActionPage} />
      <Route exact path="/auth/passtosystem" component={PassportLogin} />

      <Route exact path="/report/companystatus" component={StatusComponay} />

      <WebSocketMiddleware>
        <PrivateRoute component={PrivateRoutings} />
      </WebSocketMiddleware>
    </Switch>
  </ConnectedRouter>
);

export default Routing;
