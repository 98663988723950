import React from 'react';
import * as R from 'ramda';
// import numeral from 'numeral';
import moment from 'moment';
import { get } from 'lodash';

import { eventMessages } from '../metric/messages';
import { Defaults, CellRenderers, EventRenderers } from '.';
import getInstanceDisplayName from './getInstanceDisplayName';

// Log component renderers
const MetricRenderers = {
  LineChartAnnotation: (timestamp, annos, intl, project, k8CoverageMap = {}, instanceDisplayNameMap = {}) => {
    let metricDuration = 0;
    let patternList = [];
    const samplingInterval = (project?.samplingIntervalInSecond || 0) * 1000;
    R.forEach((item) => {
      patternList.push(item.patternName || String(item.patternId));
      let itemDuration = 0;
      R.forEach((timeRange) => {
        const duration = timeRange.duration || timeRange.endTimestamp - timeRange.startTimestamp || 0;
        itemDuration += duration;
      }, get(item, ['timeRangeList'], []));
      metricDuration = R.max(R.max(metricDuration, itemDuration), 0);
    }, annos);
    patternList = R.uniq(R.filter((item) => Boolean(item), patternList));

    metricDuration = metricDuration < samplingInterval ? samplingInterval : metricDuration;

    return (
      <div className="linechart-anno-content tooltip-dark flex-col flex-min-height">
        <div>
          <span className="bold inline-block">{`${moment.utc(timestamp).format(Defaults.TimeFormat)}`}</span>
        </div>
        <div>
          <span className="bold inline-block tooltip-label" style={{ marginRight: 8, width: 110 }}>
            {intl.formatMessage(eventMessages.patternName)}:{' '}
          </span>
          <span>{R.join(', ', patternList)}</span>
        </div>
        <div style={{ marginBottom: 8 }}>
          <span className="bold inline-block tooltip-label" style={{ marginRight: 8, width: 110 }}>
            {intl.formatMessage(eventMessages.fieldDuration)}:{' '}
          </span>
          <span>{CellRenderers.humanizeDuration({ period: metricDuration, intl })}</span>
        </div>

        <div className="flex-grow overflow-y-auto overflow-x-hidden" style={{ paddingRight: 8 }}>
          {R.addIndex(R.map)((rc, idx) => {
            const { instanceId, appName, isInstanceDown, instanceDown, timePairArr } = rc;
            const displayAppName = appName && appName !== instanceId;

            const k8Coverage = k8CoverageMap[instanceId] || [];
            const x = timePairArr && timePairArr.length > 0 ? timePairArr[0].startTimestamp : null;
            const findCoverage = R.find((item) => item[1]?.s <= x && x <= item[1]?.e, k8Coverage);
            let hostID = null;
            let podID = null;
            if (findCoverage) {
              hostID = findCoverage[0]?.h;
              podID = findCoverage[0]?.p;
            }

            const { instanceStr: viewTitle } = getInstanceDisplayName(instanceDisplayNameMap, instanceId);

            return (
              <div className="flex-col" key={idx} style={{ marginBottom: 8 }}>
                <div className="break-all">
                  {hostID && podID ? (
                    <>
                      <span className="inline-block tooltip-label" style={{ marginRight: 4 }}>
                        Host ID:{' '}
                      </span>
                      <span style={{ color: '#5ED66E' }}>{hostID}</span>
                      <span className="inline-block tooltip-label" style={{ marginRight: 4, marginLeft: 8 }}>
                        Pod ID:{' '}
                      </span>
                      <span style={{ color: '#5ED66E' }}>{podID}</span>
                    </>
                  ) : (
                    <span style={{ color: '#5ED66E' }}>{viewTitle}</span>
                  )}
                  {displayAppName && <span>{` (${appName})`}</span>}
                  {(isInstanceDown || instanceDown) && <span style={{}}>{' is '}</span>}
                  {(isInstanceDown || instanceDown) && (
                    <span style={{ borderRadius: 4, background: '#FF5142', padding: '0 2px' }}>Down</span>
                  )}
                </div>
                {!isInstanceDown &&
                  !instanceDown &&
                  EventRenderers.RenderMetricAnomalySummary({
                    intl,
                    event: rc,
                    index: idx,
                    hideTime: false,
                    highlightColor: 'white',
                  })}
              </div>
            );
          }, annos)}
        </div>
      </div>
    );
  },
};

export { MetricRenderers };
