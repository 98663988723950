import React, { useContext, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

import { State } from '../../../common/types';
import { ordinarySelectInfos, rootSelectInfos } from '../data';

import TypeList from './TypeList';
import CodeTypeHeader from './CodeTypeHeader';

import { settingsMessages } from '../../../common/settings/messages';

function CodeTypeListCore(props: Object) {
  const { intl, isDark } = props || {};
  const { activeTypeItem, searchVal, stepCurrent, activeWorkNode } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};
  const { data, id } = activeWorkNode;
  const { isRoot } = data || {};

  const typeList = useMemo(() => {
    const dataMap = isRoot ? rootSelectInfos() : ordinarySelectInfos();
    let typeList = R.values(dataMap || {});

    typeList = R.filter((item) => {
      item.dataList = R.filter(
        (_item) => _item.title.toUpperCase().indexOf(searchVal.toUpperCase()) !== -1,
        item.dataList || [],
      );
      return item.dataList.length > 0;
    }, typeList || []);

    return typeList;
  }, [isRoot, searchVal]);

  useEffect(() => {
    contextSetState({ activeTypeItem: null });
  }, [id]);

  return (
    <div className="full-height overflow-hidden flex-col flex-grow" style={{ padding: '14px 0px 8px 8px' }}>
      {stepCurrent === -1 ? (
        <div className="flex-row flex-center-align flex-center-justify full-height">
          <Button
            size="small"
            type="primary"
            className="font-18"
            style={{ height: 34 }}
            onClick={() => {
              contextSetState({ stepCurrent: 0 });
            }}
          >
            Add a trigger
          </Button>
        </div>
      ) : (
        <>
          {!activeTypeItem && (
            <div className="full-height flex-col">
              {isRoot && (
                <>
                  <CodeTypeHeader
                    title="Select a trigger"
                    desc="Triggers start the execution of a workflow. They can listen for events."
                  />
                  {false && (
                    <Input
                      size="small"
                      placeholder={intl.formatMessage(settingsMessages.searchTriggers)}
                      prefix={<SearchOutlined />}
                      style={{ maxWidth: 400, marginBottom: 16 }}
                      onChange={({ target: { value } }) => contextSetState({ searchVal: value })}
                    />
                  )}
                  <TypeList intl={intl} typeList={typeList} isDark={isDark} />
                </>
              )}
              {!isRoot && (
                <>
                  <CodeTypeHeader
                    title="Choose action"
                    desc="Add a task to the workflow and select the action it performs."
                  />
                  {false && (
                    <Input
                      size="small"
                      placeholder={intl.formatMessage(settingsMessages.searchActions)}
                      prefix={<SearchOutlined />}
                      style={{ maxWidth: 400, marginBottom: 16 }}
                      onChange={({ target: { value } }) => contextSetState({ searchVal: value })}
                    />
                  )}
                  <TypeList intl={intl} typeList={typeList} isDark={isDark} />
                </>
              )}
            </div>
          )}
          {activeTypeItem && activeTypeItem.component()}
        </>
      )}
    </div>
  );
}

const CodeTypeList = injectIntl(CodeTypeListCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(CodeTypeList);
