import React, { useContext } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'antd';

import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

import { appButtonsMessages } from '../../../common/app/messages';

function DealActiveButtonCore(props: Object) {
  const { intl, hasError } = props || {};
  const { workNodes, activeWorkNode } = useContext(workFlowContext) || {};
  const { nodeDataSave } = useContext(workFlowDispatchContext) || {};

  const { nodeData = {}, localNodeData = {} } = activeWorkNode?.data || {};

  const isDisabled = R.equals(localNodeData, nodeData) || hasError;
  return (
    <div className="flex-row flex-end-justify" style={{ marginTop: 16 }}>
      <Button
        style={{ width: 70 }}
        size="small"
        type="primary"
        disabled={isDisabled}
        onClick={() => nodeDataSave(workNodes, activeWorkNode)}
      >
        {intl.formatMessage(appButtonsMessages.update)}
      </Button>
    </div>
  );
}

const DealActiveButton = injectIntl(DealActiveButtonCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(DealActiveButton);
