import { Button, Form, InputNumber, message, Select, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import * as R from 'ramda';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';
import { eventMessages } from '../../../../common/metric/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { Popover, List, Modal } from '../../../../lib/fui/react';
import SelectMultiple from '../../../../../components/custome/SelectMultiple';

type Props = {
  intl: Object,
  location: Object,
  replace: Function,
  system: Object,
  isReadUser: Boolean,
};

const SatelliteSystemModal = ({ system, systemIds = [], systemsMap, onClose, onOk }) => {
  const [selectedSystemIds, setSelectedSystemIds] = useState(systemIds);

  const systemOptions = R.map(
    (s) => ({ label: s.systemName, value: s.systemId }),
    R.filter((x) => x.systemId !== system?.systemId, R.values(systemsMap)),
  );

  const handleApply = () => {
    onOk(selectedSystemIds);
  };

  return (
    <Modal title="Satellite systems" visible maskClosable={false} onCancel={() => onClose()} footer={null}>
      <Spin spinning={false} wrapperClassName="spin-full-height full-width full-height">
        <Form layout="vertical" className="full-width">
          <Form.Item label="Systems">
            <SelectMultiple
              options={systemOptions}
              value={selectedSystemIds}
              onChange={(ids) => setSelectedSystemIds(ids)}
            />
          </Form.Item>
        </Form>
        <div className="flex-row flex-end-justify">
          <Button size="small" disabled={false} type="primary" onClick={handleApply}>
            Apply
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

const knowledgeBaseSetting = ({ intl, credentials, system, systemsMap, isReadUser }: Props) => {
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubloading] = useState(false);
  const [knowledgeBaseData, setKnowledgebaseData] = useState({
    isLoadingGlobalKnowledgeBase: false,
    compositeValidThreshold: 0,
    enableGlobalKnowledgeBase: false,
    enableIgnoreInstancePrediction: false,
    predictionSource: 0,
    timelineTopK: 50,
    filterSelfToSelf: false,
  });
  const [formRef] = Form.useForm();
  const [showSatelliteSystemModal, setShowSatelliteSystemModal] = useState(false);
  const [satelliteSystemList, setSatelliteSystemList] = useState([]);
  const [mainSystem, setMainSystem] = useState(null);
  const [shareSystemType, setShareSystemType] = useState(0);

  const predictionRuleActiveConditionOptions = [
    { label: intl.formatMessage(settingsMessages.inUseConfirmed), value: 0 },
    { label: intl.formatMessage(settingsMessages.confirmed), value: 1 },
  ];
  const predictionSourceOption = [
    { label: intl.formatMessage(settingsMessages.anomalyString), value: 0 },
    { label: intl.formatMessage(settingsMessages.healthView), value: 1 },
  ];
  useEffect(() => {
    if (system) {
      const { systemId, owner } = system;
      setLoading(true);
      fetchGet(getEndpoint('IncidentPredictionSetting', 2), {
        systemIds: JSON.stringify([systemId]),
        customerName: owner,
      })
        .then((res) => {
          const {
            ruleActiveThreshold = '',
            ruleInactiveThreshold = '',
            ruleActiveCondition = '',
            falsePositiveTolerance = '',
            kbTrainingLength = 1,
            tolerance = 0,
            enableInsensitiveRuleMatching = false,
          } = res[0] || {};
          formRef.setFieldsValue({
            ruleActiveThreshold: !ruleActiveThreshold ? 0 : ruleActiveThreshold * 100,
            ruleInactiveThreshold: !ruleInactiveThreshold ? 0 : ruleInactiveThreshold * 100,
            ruleActiveCondition: !ruleActiveCondition ? 0 : ruleActiveCondition,
            falsePositiveTolerance: !falsePositiveTolerance ? 1 : falsePositiveTolerance,
            kbTrainingLength: kbTrainingLength / 1000 / 60 / 60 / 24,
            tolerance: !tolerance ? 0 : tolerance * 100,
            enableInsensitiveRuleMatching,
          });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          message.error(intl.formatMessage(appMessages.apiFaild));
        });

      fetchGet(getEndpoint('globalknowledgebasesetting'), {
        ...credentials,
        customerName: owner,
        systemIds: JSON.stringify([systemId]),
      })
        .then((data) => {
          const {
            compositeValidThreshold,
            enableGlobalKnowledgeBase,
            enableIgnoreInstancePrediction,
            predictionSource,
            timelineTopK,
            shareSystemType = 0,
            filterSelfToSelf,
          } = data[0] || {};
          setKnowledgebaseData({
            isLoadingGlobalKnowledgeBase: false,
            compositeValidThreshold: compositeValidThreshold ? Number(compositeValidThreshold) / 60 / 1000 : 0,
            enableGlobalKnowledgeBase,
            enableIgnoreInstancePrediction,
            predictionSource: predictionSource || 0,
            timelineTopK: timelineTopK || 50,
            filterSelfToSelf,
          });
          setShareSystemType(shareSystemType);
          if (shareSystemType === 0 || shareSystemType === 1) {
            // main system, able to edit satellite systems
            const satelliteSystemSet = data[0]?.satelliteSystemSet || [];
            const satelliteSystemList = R.filter(
              (x) => !!x,
              R.map((s) => s?.systemPartitionKey?.systemName, satelliteSystemSet),
            );
            setMainSystem(null);
            setSatelliteSystemList(satelliteSystemList);
          } else {
            const mainSystem = data[0]?.mainSystemKey?.systemPartitionKey?.systemName || null;
            setMainSystem(mainSystem);
            setSatelliteSystemList([]);
          }
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          setKnowledgebaseData({
            isLoadingGlobalKnowledgeBase: false,
            compositeValidThreshold: 0,
            enableGlobalKnowledgeBase: false,
            enableIgnoreInstancePrediction: false,
            predictionSource: 0,
            timelineTopK: 50,
            filterSelfToSelf: false,
          });
        });
    }
  }, [system.systemId]);

  const handleFinish = (values) => {
    const { systemId, owner } = system;
    const requestData = {
      ...values,
      ruleActiveThreshold: values.ruleActiveThreshold / 100,
      ruleInactiveThreshold: values.ruleInactiveThreshold / 100,
      kbTrainingLength: values.kbTrainingLength * 1000 * 60 * 60 * 24,
      tolerance: values.tolerance / 100,
    };
    setSubloading(true);
    fetchPost(getEndpoint('IncidentPredictionSetting', 2), {
      ...credentials,
      customerName: owner,
      systemIds: JSON.stringify([systemId]),
      settingModels: JSON.stringify([{ systemId, ...requestData }]),
    })
      .then((res) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        setSubloading(false);
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        setSubloading(false);
      });

    // knowledgebase
    setSubloading(true);
    const {
      compositeValidThreshold,
      enableGlobalKnowledgeBase,
      enableIgnoreInstancePrediction,
      predictionSource,
      timelineTopK,
      filterSelfToSelf,
    } = knowledgeBaseData;
    const satelliteSystemListFull = R.map((s) => ({ systemId: s, userName: systemsMap[s].owner }), satelliteSystemList);

    fetchPost(getEndpoint('globalknowledgebasesetting'), {
      ...credentials,
      customerName: owner,
      settingModels: JSON.stringify([
        {
          systemId,
          timelineTopK,
          enableGlobalKnowledgeBase,
          enableIgnoreInstancePrediction,
          predictionSource,
          compositeValidThreshold: Number(compositeValidThreshold) * 1000 * 60,
          satelliteSystemList: satelliteSystemListFull,
          filterSelfToSelf,
        },
      ]),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          setSubloading(false);
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
          setSubloading(false);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isSubmittingGlobalKnowledgeBase: false });
      });
  };

  const {
    isLoadingGlobalKnowledgeBase,
    compositeValidThreshold,
    enableGlobalKnowledgeBase,
    enableIgnoreInstancePrediction,
    predictionSource,
    filterSelfToSelf,
  } = knowledgeBaseData;
  return (
    <Spin
      spinning={loading || isLoadingGlobalKnowledgeBase}
      wrapperClassName="full-height spin-full-height spin-overflow-y"
    >
      <Form form={formRef} onFinish={handleFinish} size="small" layout="vertical">
        <Form.Item
          label={intl.formatMessage(settingsMessages.predictionRuleActiveThreshold)}
          name="ruleActiveThreshold"
          required
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!!value || (parseInt(value, 10) > 0 && parseInt(value, 10) <= 100)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please input a positive float number'));
              },
            }),
          ]}
        >
          <InputNumber max={100} min={0} style={{ width: 180 }} />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage(settingsMessages.predictionRuleInactiveThreshold)}
          required
          name="ruleInactiveThreshold"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!!value || (parseInt(value, 10) > 0 && parseInt(value, 10) <= 100)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please input a positive float number'));
              },
            }),
          ]}
        >
          <InputNumber max={100} min={0} style={{ width: 180 }} />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage(settingsMessages.predictionRuleEnablingStatus)}
          name="ruleActiveCondition"
          required
          rules={[{ required: true, message: intl.formatMessage(settingsMessages.errorEmptySelection) }]}
        >
          <Select options={predictionRuleActiveConditionOptions} style={{ width: 180 }} />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage(settingsMessages.predictionRuleFalsePositiveThreshold)}
          required
          name="falsePositiveTolerance"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!!value || parseInt(value, 10) > 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please input a positive float number'));
              },
            }),
          ]}
        >
          <InputNumber min={0} style={{ width: 180 }} />
        </Form.Item>

        <Form.Item label={intl.formatMessage(settingsMessages.enableGlobalKnowledgeBase)}>
          <Switch
            checked={enableGlobalKnowledgeBase}
            onChange={(enableGlobalKnowledgeBase) =>
              setKnowledgebaseData((val) => ({ ...val, enableGlobalKnowledgeBase }))
            }
          />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage(settingsMessages.timeInsensitiveRuleMatching)}
          name="enableInsensitiveRuleMatching"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item label={intl.formatMessage(settingsMessages.filterSelfToSelfRule)}>
          <Switch
            checked={filterSelfToSelf}
            onChange={(filterSelfToSelf) => setKnowledgebaseData((val) => ({ ...val, filterSelfToSelf }))}
          />
        </Form.Item>

        <Form.Item label={intl.formatMessage(settingsMessages.enableIgnoreInstancePrediction)}>
          <Switch
            checked={enableIgnoreInstancePrediction}
            onChange={(enableIgnoreInstancePrediction) =>
              setKnowledgebaseData((val) => ({ ...val, enableIgnoreInstancePrediction }))
            }
          />
        </Form.Item>

        <Form.Item label={intl.formatMessage(settingsMessages.predictionSource)}>
          <Select
            value={predictionSource}
            onChange={(predictionSource) => setKnowledgebaseData((val) => ({ ...val, predictionSource }))}
            options={predictionSourceOption}
            style={{ width: 180 }}
          />
        </Form.Item>

        <Form.Item label={intl.formatMessage(settingsMessages.compositeValidThreshold)}>
          <InputNumber
            min={0}
            style={{ width: 60 }}
            value={compositeValidThreshold}
            onChange={(compositeValidThreshold) => setKnowledgebaseData((val) => ({ ...val, compositeValidThreshold }))}
          />
          <span style={{ margin: '0 4px' }}>:</span>min
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage(settingsMessages.kbTrainingLength)}(${intl.formatMessage(
            appFieldsMessages.day,
          )})`}
          required
          rules={[{ message: intl.formatMessage(settingsMessages.errorEmptyInput), required: true }]}
          name="kbTrainingLength"
        >
          <InputNumber min={1} style={{ width: 180 }} />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage(settingsMessages.confidenceLevel)}
          name="tolerance"
          required
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!!value || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Please input a positive float number'));
              },
            }),
          ]}
        >
          <InputNumber min={0} max={100} style={{ width: 100 }} />
        </Form.Item>

        {shareSystemType === 2 && (
          <Form.Item
            label={
              <div>
                <span style={{ marginRight: 8 }}>Main system: {systemsMap[mainSystem]?.systemName || ''}</span>
              </div>
            }
            name="mainSystem"
          />
        )}
        {shareSystemType !== 2 && (
          <Form.Item
            label={
              <div>
                <span style={{ marginRight: 8 }}>Satellite systems</span>
                <Button size="small" onClick={() => setShowSatelliteSystemModal(true)}>
                  <EditOutlined /> Edit
                </Button>
              </div>
            }
            name="satelliteSystemList"
          >
            {satelliteSystemList?.length > 0 && (
              <div className="event-list">
                <List
                  className="event-list-grid"
                  width={260}
                  height={satelliteSystemList?.length > 3 ? 120 : satelliteSystemList?.length * 40}
                  rowCount={satelliteSystemList.length}
                  rowHeight={40}
                  style={{ borderTopWidth: 1 }}
                  rowRenderer={({ index: rowIndex }) => {
                    const systemId = satelliteSystemList[rowIndex];
                    const system = systemsMap[systemId];
                    if (system) {
                      return (
                        <div
                          key={system.systemId}
                          className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
                          style={{ minHeight: 40 }}
                        >
                          <div className="row-column" style={{ width: 220, flex: 1 }}>
                            {system.systemName}
                          </div>
                          <div className="row-column flex-end-justify" style={{ width: 40 }}>
                            <DeleteOutlined
                              style={{ fontSize: 16, margin: '0 4px' }}
                              onClick={() => {
                                setSatelliteSystemList(R.without([systemId], satelliteSystemList));
                              }}
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </div>
            )}
          </Form.Item>
        )}

        <Form.Item>
          <Popover
            content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
            mouseEnterDelay={0.3}
            placement="left"
          >
            <Button type="primary" disabled={isReadUser} htmlType="submit" loading={subLoading}>
              {intl.formatMessage(appButtonsMessages.submit)}
            </Button>
          </Popover>
        </Form.Item>
      </Form>
      {showSatelliteSystemModal && (
        <SatelliteSystemModal
          intl={intl}
          systemsMap={systemsMap}
          system={system}
          systemIds={satelliteSystemList}
          onClose={() => setShowSatelliteSystemModal(false)}
          onOk={(systemIds) => {
            setShowSatelliteSystemModal(false);
            setSatelliteSystemList(systemIds);
          }}
        />
      )}
    </Spin>
  );
};
export default knowledgeBaseSetting;
