import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Popconfirm } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';

import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

import { State } from '../../../common/types';

import EditInput from './EditInput';
import { appButtonsMessages, appMessages } from '../../../common/app/messages';

function ActiveTriggerHeaderCore(props: Object) {
  const { intl, editInput } = props || {};
  const { workNodes, activeTypeItem, activeWorkNode } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};
  const { nodeData = {}, localNodeData = {} } = activeWorkNode?.data || {};
  const { title } = nodeData;

  const findActiveNode = useMemo(
    () => R.find((node) => node.id === activeWorkNode.id, workNodes || []),
    [workNodes, activeWorkNode],
  );

  const isDisabled = R.equals(localNodeData, nodeData);

  return (
    <div style={{ marginBottom: 20 }}>
      <div className="flex-row flex-center-align">
        {editInput && (
          <EditInput workFlowInfo={nodeData} textStyle={{ fontSize: 24 }} inputStyle={{ height: 38, fontSize: 24 }} />
        )}
        {!editInput && (
          <div className="font-24 bold flex-grow hidden-line-with-ellipsis " style={{ marginRight: 8, width: 0 }}>
            {title}
          </div>
        )}
        {isDisabled ? (
          <div
            className="font-14 bold clickable"
            style={{ padding: '4px 8px', flexShrink: 0 }}
            onClick={() => contextSetState({ activeTypeItem: null, activeWorkNode: findActiveNode })}
          >
            <RollbackOutlined />
            <span style={{ marginLeft: 8 }}>Back</span>
          </div>
        ) : (
          <Popconfirm
            title={<div>{intl.formatMessage(appMessages.continueConfirm)}</div>}
            okText={intl.formatMessage(appButtonsMessages.yes)}
            cancelText={intl.formatMessage(appButtonsMessages.no)}
            onConfirm={() => contextSetState({ activeTypeItem: null, activeWorkNode: findActiveNode })}
            onCancel={(event) => event.stopPropagation()}
            placement="left"
          >
            <div className="font-14 bold clickable" style={{ padding: '4px 8px', flexShrink: 0 }}>
              <RollbackOutlined />
              <span style={{ marginLeft: 8 }}>Back</span>
            </div>
          </Popconfirm>
        )}
      </div>
      <div className="font-14 break-word">{activeTypeItem.desc}</div>
    </div>
  );
}

const ActiveTriggerHeader = injectIntl(ActiveTriggerHeaderCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(ActiveTriggerHeader);
