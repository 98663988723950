import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AutoComplete, Select } from 'antd';

import { State } from '../../../common/types';
import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';
import { verifyContent } from '../data/utils';

import ActiveTriggerHeader from './ActiveTriggerHeader';
import DealActiveButton from './DealActiveButton';

import { settingsMessages } from '../../../common/settings/messages';

function KubernetesRestartActionCore(props: Object) {
  const { intl } = props || {};
  const { activeWorkNode } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};

  const { nodeData = {} } = activeWorkNode?.data || {};
  const { nameSpace, deployment } = nodeData;

  const { nameSpaceError, deploymentError, hasError } = verifyContent(nodeData);

  return (
    <>
      <ActiveTriggerHeader editInput />
      <div className="flex-grow" style={{ overflowY: 'scroll', paddingRight: 14 }}>
        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span style={{ fontSize: 12, marginBottom: 4 }}>
            {intl.formatMessage(settingsMessages.namespace)}
            <span style={{ color: 'red' }}>*</span>
          </span>
          <AutoComplete
            allowClear
            size="small"
            value={nameSpace}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf((nameSpace || '').toUpperCase()) !== -1
            }
            onChange={(value) =>
              contextSetState({
                activeWorkNode: {
                  ...activeWorkNode,
                  data: { ...activeWorkNode.data, nodeData: { ...nodeData, nameSpace: value } },
                },
              })
            }
            options={[
              { value: '1', label: 'namespace 1' },
              { value: '2', label: 'namespace 2' },
              { value: '3', label: 'namespace 3' },
              { value: '4', label: 'namespace 4' },
            ]}
            className={`${nameSpaceError ? 'jsonKeyNoneError' : ''}`}
          />
        </div>
        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span style={{ fontSize: 12, marginBottom: 4 }}>
            Deployment<span style={{ color: 'red' }}>*</span>
          </span>
          <Select
            size="small"
            value={deployment}
            onChange={(value) =>
              contextSetState({
                activeWorkNode: {
                  ...activeWorkNode,
                  data: { ...activeWorkNode.data, nodeData: { ...nodeData, deployment: value } },
                },
              })
            }
            options={[
              { value: 'deployment1', label: 'deployment1' },
              { value: 'deployment2', label: 'deployment2' },
            ]}
            className={`${deploymentError ? 'jsonKeyNoneError' : ''}`}
          />
        </div>
      </div>
      <DealActiveButton hasError={hasError} />
    </>
  );
}

const KubernetesRestartAction = injectIntl(KubernetesRestartActionCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(KubernetesRestartAction);
