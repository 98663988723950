import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Input } from 'antd';

import { workFlowContext, workFlowDispatchContext } from '../ActionWorkFlow';

import { State } from '../../../common/types';
import { verifyContent } from '../data/utils';

import ActiveTriggerHeader from './ActiveTriggerHeader';
import DealActiveButton from './DealActiveButton';

import { appFieldsMessages } from '../../../common/app/messages';

function EmailActionCore(props: Object) {
  const { intl } = props || {};
  const { activeWorkNode } = useContext(workFlowContext) || {};
  const { contextSetState } = useContext(workFlowDispatchContext) || {};
  const { nodeData = {} } = activeWorkNode?.data || {};
  const { email } = nodeData;

  const { hasError } = verifyContent(nodeData);

  return (
    <>
      <ActiveTriggerHeader editInput />
      <div className="flex-grow" style={{ overflowY: 'scroll', paddingRight: 14 }}>
        <div className="flex-col" style={{ paddingTop: 8, marginBottom: 16 }}>
          <span style={{ fontSize: 12, marginBottom: 4 }}>
            {intl.formatMessage(appFieldsMessages.email)}
            <span style={{ color: 'red' }}>*</span>
          </span>
          <Input
            size="small"
            value={email}
            onChange={(e) =>
              contextSetState({
                activeWorkNode: {
                  ...activeWorkNode,
                  data: { ...activeWorkNode.data, nodeData: { ...nodeData, email: e.target.value } },
                },
              })
            }
            className={`${hasError ? 'inputIsNil' : ''}`}
          />
        </div>
      </div>
      <DealActiveButton hasError={hasError} />
    </>
  );
}

const EmailAction = injectIntl(EmailActionCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { userInfo, credentials } = state.auth;
  const { currentTheme } = state.app;
  const isDark = currentTheme === 'dark';
  return {
    location,
    userInfo,
    credentials,
    isDark,
  };
}, {})(EmailAction);
