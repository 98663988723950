/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React, { useState, useEffect, forwardRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import update from 'immutability-helper';
import { get, isArray } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import Icon, {
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  EditOutlined,
  DownOutlined,
  LineChartOutlined,
  UnorderedListOutlined,
  MailOutlined,
  ApartmentOutlined,
  TeamOutlined,
  HistoryOutlined,
  ReadOutlined,
  UpOutlined,
  ExclamationCircleFilled,
  FormOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  RightSquareOutlined,
  BellOutlined,
  CloseCircleOutlined,
  AimOutlined,
  AreaChartOutlined,
} from '@ant-design/icons';
import { Tooltip, Select, Spin, Button, notification, Input, message, Switch, Menu, Divider, Card, Empty } from 'antd';

import {
  Defaults,
  buildLocation,
  parseLocation,
  EventRenderers,
  LogRenderers,
  GlobalParse,
  GlobalRenderers,
  buildUrl,
  Regex,
  parseJSON,
  sleep,
  downloadFile,
  CellRenderers,
  EventActionHandle,
  getPatternNameIcon,
} from '../../../common/utils';
import { State } from '../../../common/types';
import {
  ChangeEventIcon,
  FlagNewIcon,
  GoodIcon,
  GoodOutlinedIcon,
  RootCauseIcon,
  IncidentPredictIcon,
  TrendIcon,
  SavedActionIcon,
  JiraIcon,
  ServiceNowIcon,
  SlackIcon,
  FixedIcon,
  BarChartIcon,
  ActionIcon,
  CompositeIcon,
  InvalidPredictionsIcon,
  RobotIcon,
  RecurrentIncidentsIcon,
} from '../../../lib/fui/icons';
import {
  Modal,
  Container,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer,
  Dropdown,
  Popover,
} from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/dashboard/actions';
import { BaseUrls } from '../../app/Constants';
import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';

import { appFieldsMessages, appButtonsMessages, appMessages, appMenusMessages } from '../../../common/app/messages';
import { eventMessages, eventActionMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import { queryFieldMessages } from '../../../common/query/messages';

import ProjectSelectorModal from './ProjectSelectorModal';
import TimeSelectModal from '../../metric/components/TimeSelectModal';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import EventActionModal from '../../metric/components/EventActionModal';
import ReportJiraModal from '../../metric/components/ReportJiraModal';
import ReportServiceNowModal from '../../metric/components/ReportServiceNowModal';

import LikelyRootCausesModal from './LikelyRootCausesModal';
import RecommendationsModal from './RecommendationsModal';
import TriageReportModal from './TriageReportModal';
import TriggeredActionsModal from './TriggeredActionsModal';
import PastIncidentRCModal from './PastIncidentRCModal';
import CompareIncidentsModal from './CompareIncidentsModal';
import CompareCommonLogsModal from './CompareCommonLogsModal';
import PatternConfigModal from './PatternConfigModal';
import ProjectLogSelectorModal from './ProjectLogSelectorModal';
import fetchDelete from '../../../common/apis/fetchDelete';
import LogToMetricContext from './LogToMetricContext';
import { DashboardMessages } from '../../../common/dashboard/messages';
import ConfigPatterName from '../../../../components/incidents/ConfigPatterName';
import RootCausesPopover from './RootCausesPopover';
import ModalRenderContent from './ModalRenderContent';
import { EChart } from '../../share';
import RootCauseRenderSearchBar from './RootCauseRenderSearchBar';
import IndividualIncidentsModal from './IndividualIncidentsModal';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';
import RecommendationsGPT from './RecommendationsGPT';
import RecommendationIncidendModal from './RecommendationIncidendModal';
import CDFModal from './CDFModal';
import CompareIncidentsNewModal from './CompareIncidentsNewModal';
import {
  buildMergeData,
  customizeRestore,
  dateHeight,
  filterEventListTechnology,
  getChartOption,
  getChartTimeMap,
  getEventInAnomalyTimelines,
  getJumpInfo,
  getTypeColor,
  handleCategoryClick,
  handleChartFinish,
  handleChartRestore,
  handleChartZoom,
  handleClickDateRow,
  handleComposite,
  handleIgnoreClick,
  impactedRender,
  listHeaderHeight,
  LLMTitleMap,
  onSystemChartClick,
  positionEvent,
  recurrentIncidentsViewList,
  renderCategory,
  rendererChecked,
  rendererExpand,
  rendererIncidentStatus,
  rowMinHeight,
  setChartRef,
  sortFunction,
  statusOptions,
  statusRenderer,
  tableViewList,
  tidyEventList,
  timeRenderer,
  traceRenderer,
} from '../utils/rootCauseTimeLine';
import ProjectSelectorModalV2 from './ProjectSelectorModalV2';

const mapKey = {
  Incident: 'INCIDENT',
  logAlert: 'ALERT',
  Metric: 'METRIC_ANOMALY',
  logAnomaly: 'LOG_ANOMALY',
  Trace: 'TRACE',
  Deployment: 'CHANGE',
};

type Props = {
  // eslint-disable-next-line
  activeKey: String,
  tabName: String,
  refresh: Number,
  isLoadingSystem: Boolean,
  // eslint-disable-next-line
  handleUpdateStats: Function,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  push: Function,
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  userInfo: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  userName: String,
  credentials: Object,
  timezoneOffset: Number,
  projects: Array<Object>,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  systemsMap: Object,

  globalInfo: Object,
  globalSystemTimelineInfo: Object,

  currentTheme: String,
  activeKeyListFlag: Boolean,
  componentFilter: String,
  instanceFilter: String,
  logicPodID: String,
  filterContainer: String,
  filterStartHour: Number,
  handleChangeRCAModal: Function,
  // eslint-disable-next-line
  flagClearJumpInstanceName: String | Boolean,
  showEnableRCA: Boolean,
  onChangeFilterComponent: Function,
  onChangeFilterInstance: Function,
  onChangeFilterPhysicalPodID: Function,
  onChangeFilterContainer: Function,
  hasKubernetes: Boolean,
};

class RootCauseListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { tabName, location, componentFilter, instanceFilter, logicPodID, filterContainer, filterStartHour } = props;
    const query = parseLocation(location);
    const { hideIgnore } = query;
    const startHour = filterStartHour && false ? Number(filterStartHour) : undefined;
    this.listHeaderHeight = listHeaderHeight;
    this.rowMinHeight = rowMinHeight;
    this.dateHeight = dateHeight;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      fixedHeight: false,
      minHeight: this.dateHeight,
    });
    this.breakAutoLoad = false;

    let operation;
    if (tabName === 'incident') {
      operation = 'Incident';
    } else if (tabName === 'metric') {
      operation = 'Metric';
    } else if (tabName === 'deployment') {
      operation = 'Deployment';
    } else if (tabName === 'trace') {
      operation = 'Trace';
    } else if (tabName === 'logAlert') {
      operation = 'logAlert';
    } else {
      operation = 'logAnomaly';
    }

    this.state = {
      operation,
      events: [],
      filterList: [],
      eventList: [],
      jumpIncident: undefined,
      activeEvent: null,
      actionIncident: null,
      actionComposite: null,
      individualIncident: null,
      patternNameMap: {},

      isLoaded: false,
      isLoading: false,
      isLoadingParserData: false,

      // filter
      hideIgnore: hideIgnore !== 'false',
      componentFilter,
      instanceFilter,
      logicPodID,
      filterContainer,
      projectFilter: undefined,
      metricFilter: undefined,
      incidentStatusFilter: undefined,
      typeFilter: undefined,
      patternIdFilter: undefined,
      timeFilterStart: startHour ? String(startHour >= 23 ? 23 : startHour) : undefined,
      timeFilterEnd: startHour ? String(startHour >= 23 ? 24 : startHour + 1) : undefined,
      keywordFilter: '',
      keywordSearch: '',
      showEnableRCA: false,

      // take action
      showProjectSelector: false,
      onConfirmProjectSelect: null,
      showTimeSelectModal: false,
      showContextModal: false,
      selectStartTimestamp: null,
      selectEndTimestamp: null,
      contextKeywordFilter: '',
      showTakeLogActionModal: false,
      actionName: null,
      actionRootCauseKey: null,
      showActionModal: false,
      showSoftwareUpdateContextModal: false,
      showReportJiraModal: false,
      showReportServiceNowModal: false,

      // general modals
      showRCModal: false,
      needRC: false,
      needPT: false,
      showRecommendationsModal: false,
      showTriageReportModal: false,
      showTriggeredActionsModal: false,
      showPastIncidentRCModal: false,
      showCompareIncidentsModal: false,
      showNewCompareIncidentsModal: false,
      showCompareCommonLogsModal: false,
      showPatternConfigModel: false,
      showIndividualIncidentsModal: false,
      compareIncidents: [],

      hoverSearchObj: {},
      hoverNotesObj: {},
      isPopoverLoading: false,

      actionVisibleState: false,
      allExpand: tabName === 'incident',
      dateAllExpand: true,
      isAllChecked: false,
      showProjectLogSelector: false,
      onConfirmProjectLogSelect: null,

      showLogToMetricModal: false,

      reductionRate: '0%',
      filterEventsTotal: 0,
      likelyRootCauseCount: 0,
      noRootCauseCount: 0,
      fixedIncidentCount: 0,
      triggeredCount: 0,
      notificationCount: 0,
      validSrcCount: 0,
      ignoredSrcCount: 0,
      notValidSrcCount: 0,
      compositeAnomalyCount: 0,
      rootCauseFilter: null,

      importantCount: 0,
      recurrentIncidentCount: 0,

      activeMerges: [],
      showConfigPatternName: false,

      showFilterPanel: true,
      modalFlag: false,
      chartOption: {},
      isResetDateZoom: true,
      zoomStartTime: undefined,
      zoomEndTime: undefined,
      resetPatternFilter: +new Date(),
      showRecommendationsGPT: false,
      showGPTType: 'incident',
      activeRecommendation: null,
      showRecommendationModal: false,
      showCDFModal: false,

      hasTableData: false,

      showProjectSelectorV2: false,
    };
    this.eventListExport = [];

    this.projectListOptions = [];
    this.componentListOptions = [];
    this.instanceListOptions = [];
    this.podListOptions = [];
    this.containerListOptions = [];
    this.anomalyMetricOptions = [];
    this.patternIdFilterOptions = [];

    this.summarySettingsMap = {};
    this.incidentStatusCountMap = {};

    this.severityOptions = [
      { value: 'incident', label: 'Incident', color: 'currentColor' },
      { value: 'ignored', label: 'Ignored', color: 'green' },
      { value: 'important', label: 'Important', color: 'red' },
      { value: 'maintenance', label: 'Maintenance', color: 'green' },
    ];
    this.statusColorMap = R.fromPairs(R.map((item) => [item.value, item.color], statusOptions));
    this.severityColorMap = R.fromPairs(R.map((item) => [item.value, item.color], this.severityOptions));
    this.channelNameMap = {
      Teams: 'Teams channel',
      Slack: 'Slack channel',
    };

    this.fatherEvent = null;
    this.childEvent = null;
    this.individualRootFatherEvent = null;
    this.individualRootChildEvent = null;

    this.sourceRootCausesDataMap = {};
    this.systemChartRef = {};

    this.chartTimeMap = {};
  }

  async componentDidMount() {
    const { operation } = this.state;
    // get rare pattern mapping patternID before start
    await this.handleRarePatternMapFromJump();

    this.reloadData(this.props, operation);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { operation } = this.state;
    const nextAnomalyTimelines = get(nextProps.globalSystemTimelineInfo, [`RCA-${operation}`]);
    const anomalyTimelines = get(this.props.globalSystemTimelineInfo, [`RCA-${operation}`]);
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (
      nextProps.componentFilter !== this.props.componentFilter ||
      nextProps.instanceFilter !== this.props.instanceFilter ||
      nextProps.logicPodID !== this.props.logicPodID ||
      nextProps.filterContainer !== this.props.filterContainer ||
      nextProps.showEnableRCA !== this.props.showEnableRCA
    ) {
      this.setState(
        {
          componentFilter: nextProps.componentFilter,
          instanceFilter: nextProps.instanceFilter,
          logicPodID: nextProps.logicPodID,
          filterContainer: nextProps.filterContainer,
          isLoadingParserData: true,
          showEnableRCA: nextProps.showEnableRCA,
        },
        async () => {
          await sleep(100);
          const { sortBy, sortDirection, events } = this.state;
          const filterList = this.filterData(events);
          const chartOption = this.handleChartOption(filterList);
          const mergedList = buildMergeData(filterList, sortBy, sortDirection, this);
          this.setState(
            {
              filterList,
              eventList: mergedList,
              isLoadingParserData: false,
              chartOption,
              isResetDateZoom: true,
              zoomStartTime: undefined,
              zoomEndTime: undefined,
            },
            async () => {
              if (this.refList) await this.refList.scrollToPosition(1);
              if (this.refList) this.refList.scrollToPosition(1);
            },
          );
        },
      );
    }
    if (
      nextProps.refresh !== this.props.refresh ||
      nextQuery.refreshTime !== query.refreshTime ||
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.systemId !== query.systemId ||
      nextQuery.reloadSystem !== query.reloadSystem
    ) {
      if (
        nextQuery.systemId !== query.systemId ||
        nextQuery.customerName !== query.customerName ||
        nextQuery.startTime !== query.startTime ||
        nextQuery.endTime !== query.endTime ||
        nextQuery.forceRefreshTime !== query.forceRefreshTime
      ) {
        this.fatherEvent = null;
        this.childEvent = null;
        let newState = {};
        if (nextQuery.systemId !== query.systemId) {
          this.breakAutoLoad = false;
          newState = {
            rootCauseFilter: null,
            projectFilter: undefined,
            metricFilter: undefined,
            typeFilter: undefined,
            patternIdFilter: undefined,
            timeFilterStart: undefined,
            timeFilterEnd: undefined,
            keywordFilter: undefined,
            incidentStatusFilter: undefined,
          };
        }
        this.setState({ hoverSearchObj: {}, hoverNotesObj: {}, ...newState });
      }
      if (!this.breakAutoLoad) {
        this.reloadData(nextProps, operation);
      }
    } else if (nextAnomalyTimelines !== anomalyTimelines) {
      const notCurrentSystemData = nextAnomalyTimelines?.id === nextQuery.systemId;
      if (notCurrentSystemData) {
        this.parseData(nextProps);
      }
    } else if (nextProps.activeKey !== this.props.activeKey) {
      this.setState({ hoverSearchObj: {}, hoverNotesObj: {} }, () => {
        this.cellMeasureCache.clearAll();
        if (this.refList) this.refList.forceUpdateGrid();
        this.forceUpdate();
      });
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  refresh() {
    const { operation } = this.state;
    if (['logAlert', 'logAnomaly'].includes(operation)) {
      this.setState({ isLoadingParserData: true });
      R.forEach(
        (item) => {
          this.reloadData(this.props, item);
        },
        ['logAlert', 'logAnomaly'],
      );
    } else {
      this.reloadData(this.props, operation);
    }
  }

  @autobind
  reloadData(props, operation) {
    const { createLoadAction, location, isAdmin, globalInfo, flagClearJumpInstanceName } = props;
    const { environmentId, startTime, endTime, customerName, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const allTabInfoDailyMap = systemInfo?.allTabInfoDailyMap || {};

    if (
      ((isAdmin && customerName) || !isAdmin) &&
      environmentId &&
      startTime &&
      endTime &&
      systemId &&
      systemInfo
      //  &&
      // systemInfo.hasAllInstanceInfo
    ) {
      this.breakAutoLoad = true;
      this.setState({ isLoading: true });
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      const startTimestamps = [];
      R.forEach((ts) => {
        const timestamp = ts * 86400000;
        startTimestamps.push(timestamp);
      }, R.range(startTimestamp / 86400000, endTimestamp / 86400000));

      this.setState({ isLoaded: false }, () => {
        let instanceInfoMap;
        if (flagClearJumpInstanceName) {
          instanceInfoMap = {};
          R.forEach((time) => {
            const instanceLevelCountSet =
              R.find((item) => item.tabName === mapKey[operation], allTabInfoDailyMap[time] || [])
                ?.instanceLevelCountSet || [];
            const instanceInfo = R.find((item) => item.i === flagClearJumpInstanceName, instanceLevelCountSet) || {};
            let isContainer = false;
            if (R.includes('_', instanceInfo?.i || '')) isContainer = true;
            let hasChunk = !R.isNil(instanceInfo?.c) && (instanceInfo?.c || []).length > 0;
            if (isContainer) hasChunk = !R.isNil(instanceInfo?.cidc) && (instanceInfo?.cidc || []).length > 0;
            if (hasChunk) {
              instanceInfoMap[time] = {
                id: instanceInfo?.i,
                chunk: isContainer ? instanceInfo?.cidc : instanceInfo?.c,
                isContainer,
              };
            }
          }, startTimestamps);
          // if (R.keys(instanceInfoMap).length === 0) instanceInfoMap = undefined;
        }

        createLoadAction(
          ActionTypes.LOAD_ROOT_CAUSE_TIMELINES,
          {
            level: 'system',
            operation,
            environmentName: environmentId,
            customerName: systemInfo.ownerUserName,
            systemId,
            startTimestamps,
            ...(instanceInfoMap ? { instanceInfoMap } : {}),
          },
          false,
          false,
          this.callbackSetState,
        );
      });
    }
  }

  @autobind
  callbackSetState() {
    this.breakAutoLoad = false;
    this.setState({ isLoaded: true, isLoading: false }, () => {});
  }

  @autobind
  async handleRarePatternMapFromJump() {
    const { replace, location, credentials } = this.props;
    const query = parseLocation(location);
    const {
      customerName,
      eventProjectName,
      eventPatternId,
      eventTimestamp,
      eventLogInstanceName,
      eventInstanceName,
      eventPatternType,
    } = query;

    if (eventPatternType === 'rare' && eventProjectName && eventPatternId && eventTimestamp) {
      const projectName =
        customerName !== credentials.userName ? `${eventProjectName}@${customerName}` : eventProjectName;
      const data = await fetchGet(getEndpoint('lograrepatternmapping'), {
        ...credentials,
        projectName,
        instanceName: eventLogInstanceName || eventInstanceName,
        timestamp: eventTimestamp,
        patternId: eventPatternId,
      });
      const { patternId } = data || {};
      if (patternId) {
        replace(buildLocation(location.pathname, {}, { ...query, eventPatternId: patternId }));
      }
    }
  }

  @autobind
  handleCompositeAnomalyTimelines(eventList, props) {
    const { projects, tabName, location, globalInfo } = props;

    const query = parseLocation(location);
    const { environmentId, systemId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    eventList = R.filter((event) => event.timeLineType !== 'future', eventList);

    eventList = handleComposite(eventList, projects, instanceComponentMap, tabName, this.getMappingProjectInfo);
    return eventList;
  }

  @autobind
  async parseData(props) {
    const { tabName, handleUpdateStats, replace, location, globalInfo, globalSystemTimelineInfo, credentials } = props;
    const { hasKubernetes } = props;
    const query = parseLocation(location);
    const { environmentId, systemId, startTime, endTime } = query;
    const { eventProjectName, eventPatternId, eventTimestamp, eventInstanceName, cancelActiveIncident } = query;
    const { jumpComponentName, jumpInstanceName, jumpPodId, jumpContainer, jumpPatternId } = query;
    const { operation } = this.state;
    const { sortBy, sortDirection } = this.state;

    this.setState({ isLoadingParserData: true });
    await sleep(300);

    getChartTimeMap(this.chartTimeMap, startTime, endTime);

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    const anomalyEventList = get(globalSystemTimelineInfo, [`RCA-${operation}`, 'anomalyTimelines'], []);
    const compositeEventList = get(globalSystemTimelineInfo, [`RCA-${operation}`, 'compositeAnomalyTimeLines'], []);

    const {
      eventList: events,
      hasTableData,
      eventProjectNameAll,
      eventListExport,
      incidentStatusCountMap,
      projectListOptions,
      componentListOptions,
      instanceListOptions,
      podListOptions,
      containerListOptions,
      anomalyMetricOptions,
      patternIdFilterOptions,
      ignoredPatternIdFilterOptions,
      filterEventsTotal,
      likelyRootCauseCount,
      noRootCauseCount,
      fixedIncidentCount,
      triggeredCount,
      importantCount,
      notificationCount,
      notValidSrcCount,
      compositeAnomalyCount,
      validSrcCount,
      ignoredSrcCount,
      recurrentIncidentCount,
    } = tidyEventList(
      false,
      this,
      anomalyEventList,
      compositeEventList,
      instanceComponentMap,
      credentials,
      this.getMappingProjectInfo,
      hasKubernetes,
    );
    let eventList = events;

    const startTsParse = moment.utc().valueOf();
    console.debug(`For events component/instance duration: ${(moment.utc().valueOf() - startTsParse) / 1000} sec`);

    // set ecport events
    this.eventListExport = eventListExport;
    const eventListCount = eventListExport.length;

    this.projectListOptions = projectListOptions;

    this.componentListOptions = componentListOptions;

    this.instanceListOptions = instanceListOptions;
    this.podListOptions = podListOptions;
    this.containerListOptions = containerListOptions;

    this.anomalyMetricOptions = anomalyMetricOptions;

    this.patternIdFilterOptions = patternIdFilterOptions;

    this.ignoredPatternIdFilterOptions = ignoredPatternIdFilterOptions;

    this.incidentStatusCountMap = incidentStatusCountMap;

    eventList = sortFunction(eventList, null, null);
    R.addIndex(R.forEach)((item, index) => {
      item.sortNum = index + 1;
    }, eventList || []);

    this.summarySettingsMap = await this.getLogsummarysettings(eventProjectNameAll);

    let filterEventPodAndHost = R.filter((item) => R.includes('Kubernetes', item.cloudType || ''), eventList);
    filterEventPodAndHost = R.map((item) => {
      if (item.category !== 'metric') {
        const mappingProjectInfo = this.getMappingProjectInfo({ event: item });
        if (mappingProjectInfo.dataType === 'Metric') {
          const newItem = { ...item, projectName: mappingProjectInfo.projectName || item.projectName };
          return newItem;
        } else {
          return item;
        }
      } else {
        return item;
      }
    }, filterEventPodAndHost || []);
    const groupPodAndHost = R.groupBy((item) => item.projectName, filterEventPodAndHost);
    const podAndHostData = await this.getPodAndHostData(groupPodAndHost);

    eventList = R.map((event) => {
      const { cloudType, category, instanceListStr, startTimestamp, endTimestamp } = event || {};
      let { projectName } = event || {};
      if (R.includes('Kubernetes', cloudType || '') && category !== 'metric') {
        const { projectName: mappingProjectName } = this.getMappingProjectInfo({ event });
        projectName = mappingProjectName || projectName;
      }
      const podAndHostTimes = podAndHostData[`${projectName}-${instanceListStr}`];
      let thenPodAndHost =
        R.find(
          (item) =>
            (item[1].s <= startTimestamp && startTimestamp < item[1].e) ||
            (item[1].s <= endTimestamp && endTimestamp < item[1].e),
          podAndHostTimes || [],
        ) || [];
      thenPodAndHost = thenPodAndHost[0]?.h && thenPodAndHost[0]?.p ? thenPodAndHost : [];
      return {
        ...event,
        ...(thenPodAndHost.length > 0
          ? { pod: thenPodAndHost[0].p, host: thenPodAndHost[0].h }
          : { pod: null, host: null }),
      };
    }, eventList || []);

    const pId = eventPatternId || jumpPatternId;
    const iName = eventInstanceName || jumpInstanceName;
    const findIName = R.find((item) => item.patternId === pId && item.instanceName === iName, patternIdFilterOptions);
    let iNameFilter;
    if (findIName) iNameFilter = findIName.value;

    this.setState({ patternIdFilter: iNameFilter || this.state.patternIdFilter }, () => {
      const actionComposite = this.getActiveIncident(eventList);
      const filterList = this.filterData(eventList);
      const chartOption = this.handleChartOption(filterList);
      const mergedList = buildMergeData(filterList, sortBy, sortDirection, this);
      handleUpdateStats({
        stats: { [tabName]: { totalCount: eventListCount } },
        activeKeyItem: tabName,
      });
      console.debug(`Parser data list all duration: ${(moment.utc().valueOf() - startTsParse) / 1000} sec`);

      // if has jump options in params, then highlight the search one
      const { jumpFatherIncident, jumpIncident, composite } = getJumpInfo({
        props,
        mergedList,
        eventProjectName,
        eventPatternId,
        eventTimestamp,
        isJWT: false,
      });
      // just update event list
      this.setState(
        {
          isLoadingParserData: false,
          events: eventList,
          filterList,
          filterEventsTotal,
          likelyRootCauseCount,
          noRootCauseCount,
          fixedIncidentCount,
          triggeredCount,
          importantCount,
          notificationCount,
          notValidSrcCount,
          compositeAnomalyCount,
          validSrcCount,
          ignoredSrcCount,
          recurrentIncidentCount,
          eventList: mergedList,
          jumpIncident,
          chartOption,
          isResetDateZoom: true,
          zoomStartTime: undefined,
          zoomEndTime: undefined,
          actionComposite,
          hasTableData,
        },
        () => {
          // reset jump params
          if (eventProjectName && eventPatternId && eventTimestamp && !composite) {
            replace(
              buildLocation(
                location.pathname,
                {},
                {
                  ...query,
                  redirect: undefined,
                  eventProjectName: undefined,
                  eventPatternId: undefined,
                  eventTimestamp: undefined,
                  eventLogInstanceName: undefined,
                  eventInstanceName: undefined,
                  eventComponentName: undefined,
                  eventPatternType: undefined,
                  eventRootCauseMetric: undefined,
                  eventRootCauseKey: undefined,

                  // reset filter params
                  hideIgnore: undefined,
                },
              ),
            );
          }

          if (
            jumpComponentName ||
            jumpInstanceName ||
            jumpPatternId ||
            cancelActiveIncident ||
            jumpContainer ||
            jumpPodId
          ) {
            replace(
              buildLocation(
                location.pathname,
                {},
                {
                  ...query,
                  redirect: undefined,
                  jumpComponentName: undefined,
                  jumpInstanceName: undefined,
                  jumpPatternId: undefined,
                  cancelActiveIncident: undefined,
                  jumpPodId: undefined,
                  jumpContainer: undefined,
                },
              ),
            );
          }

          // scorll to jump event
          if (jumpFatherIncident && jumpIncident) {
            this.fatherEvent = jumpFatherIncident;
            this.childEvent = jumpIncident;
            positionEvent({ composite, self: this });
          } else {
            this.fatherEvent = this.individualRootFatherEvent || this.fatherEvent;
            this.childEvent = this.individualRootChildEvent || this.childEvent;
            positionEvent({ self: this });
          }
        },
      );
    });

    return true;
  }

  @autobind
  getActiveIncident(eventList) {
    const { showIndividualIncidentsModal, actionComposite } = this.state;
    const findEvent = R.find((event) => event.id === actionComposite?.id, eventList || []);

    if (showIndividualIncidentsModal && actionComposite && findEvent) {
      return findEvent;
    }

    return actionComposite;
  }

  @autobind
  getPodAndHostData(groupPodAndHost) {
    const { location, credentials } = this.props;
    let { startTime, endTime } = parseLocation(location);
    startTime = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf() - 86400000;
    endTime = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();

    const projectInsMap = {};

    const request = [];
    R.forEachObjIndexed((events, projectName) => {
      let instanceList = [];
      R.forEach((item) => {
        const instance = R.includes('_', item.instanceListStr)
          ? R.split('_', item.instanceListStr)[1]
          : item.instanceListStr;
        instanceList.push(instance);
        projectInsMap[instance] = projectName;
      }, events || []);
      instanceList = R.uniq(instanceList);
      request.push(
        fetchGet(getEndpoint('instanceidentity'), {
          ...credentials,
          projectName,
          customerName: events[0]?.projectOwner,
          startTime,
          endTime,
          instanceList: JSON.stringify(instanceList),
        }),
      );
    }, groupPodAndHost || {});

    return Promise.all(request)
      .then((data) => {
        const podAndHostMap = {};
        R.forEach((item) => {
          const { coverageMap } = JSON.parse(item?.k8CoverageMap || '{}');
          R.forEachObjIndexed((value, instance) => {
            podAndHostMap[`${projectInsMap[instance]}-${instance}`] = value;
          }, coverageMap || {});
        }, data || []);

        return podAndHostMap;
      })
      .catch((err) => {
        console.error(err.message || String(err));
        return {};
      });
  }

  @autobind
  getLogsummarysettings(eventProjectNameAll) {
    const { credentials } = this.props;
    const projects = [];
    R.forEach((p) => {
      const { projectName, category } = p;
      if (!R.includes(category, ['metric'])) projects.push(projectName);
    }, eventProjectNameAll || []);

    const request = [];
    R.forEach((projectName) => {
      request.push(
        fetchGet(getEndpoint('logsummarysettings'), {
          ...credentials,
          projectName,
        }),
      );
    }, projects || []);

    return Promise.all(request)
      .then((data) => {
        const summarySettingsMap = {};
        R.addIndex(R.forEach)((projectName, idx) => {
          summarySettingsMap[projectName] = data[idx];
        }, projects || []);
        return summarySettingsMap;
      })
      .catch((err) => {
        console.err(err.message || String(err));
        return {};
      });
  }

  @autobind
  filterData(eventList) {
    let filterList = eventList || [];

    filterList = R.map((event) => {
      if (event.isCompositeAnomaly) {
        return { ...event, anomalyTimelines: filterEventListTechnology(this.state, event.anomalyTimelines) };
      }
      return event;
    }, filterList);

    filterList = filterEventListTechnology(this.state, filterList);

    this.cellMeasureCache.clearAll();
    if (this.refList) this.refList.forceUpdateGrid();
    this.forceUpdate();

    return filterList;
  }

  @autobind
  handleIgnoreFilterChange(hideIgnore, event) {
    this.setState({ hideIgnore, isLoadingParserData: true }, () => {
      const { sortBy, sortDirection, events } = this.state;
      const filterList = this.filterData(events);
      const chartOption = this.handleChartOption(filterList);
      const mergedList = buildMergeData(filterList, sortBy, sortDirection, this);
      this.setState(
        {
          filterList,
          eventList: mergedList,
          isLoadingParserData: false,
          chartOption,
          isResetDateZoom: true,
          zoomStartTime: undefined,
          zoomEndTime: undefined,
        },
        () => {
          if (this.refList) this.refList.scrollToRow(0);
        },
      );
    });
  }

  @autobind
  getLogAlertPatternServlet(projectFilter) {
    const { activeKey, credentials, location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    const findProject = R.find((item) => item.noOwnerProject === projectFilter, this.projectListOptions) || {};
    if (activeKey === 'logAlert' && findProject?.dataType === 'Alert') {
      return fetchGet(getEndpoint('alertreductionratio'), {
        ...credentials,
        customerName: findProject.projectOwner,
        projectName: findProject.projectName,
        startTime: startTimestamp,
        endTime: endTimestamp,
      })
        .then((data) => {
          const { success, message: msg, reductionRate } = data || {};
          if (success || success === undefined) {
            this.setState({ reductionRate: reductionRate || '0%' });
          } else {
            message.error(msg);
          }
        })
        .catch((err) => {
          message.error(err.message || String(err));
        });
    }
  }

  @autobind
  onChangeFilterComponet(componentFilter) {
    const { onChangeFilterComponent } = this.props;
    onChangeFilterComponent(componentFilter);
  }

  @autobind
  onChangeFilterInstance(instanceFilter) {
    const { onChangeFilterInstance } = this.props;
    onChangeFilterInstance(instanceFilter);
  }

  @autobind
  onChangeFilterPod(podFilter) {
    const { onChangeFilterPhysicalPodID } = this.props;
    onChangeFilterPhysicalPodID(podFilter);
  }

  @autobind
  onChangeFilterContainer(containerFilter) {
    const { onChangeFilterContainer } = this.props;
    onChangeFilterContainer(containerFilter);
  }

  @autobind
  onChangeTime(type, value) {
    let { timeFilterStart, timeFilterEnd } = this.state;
    if (type === 'start') {
      timeFilterStart = value;
      timeFilterEnd =
        timeFilterStart && timeFilterEnd && Number(timeFilterStart) >= Number(timeFilterEnd)
          ? String(Number(timeFilterStart) + 1)
          : timeFilterEnd;
    } else {
      timeFilterEnd = value;
      timeFilterStart =
        timeFilterStart && timeFilterEnd && Number(timeFilterStart) >= Number(timeFilterEnd)
          ? String(Number(timeFilterEnd) - 1)
          : timeFilterStart;
    }
    const needFilter = Boolean(timeFilterStart && timeFilterEnd) || !(timeFilterStart || timeFilterEnd);

    this.setState({ timeFilterStart, timeFilterEnd, isLoadingParserData: needFilter }, () => {
      if (needFilter) {
        const { sortBy, sortDirection, events } = this.state;
        const filterList = this.filterData(events);
        const chartOption = this.handleChartOption(filterList);
        const mergedList = buildMergeData(filterList, sortBy, sortDirection, this);
        this.setState({
          filterList,
          eventList: mergedList,
          isLoadingParserData: false,
          chartOption,
          isResetDateZoom: true,
          zoomStartTime: undefined,
          zoomEndTime: undefined,
        });
      }
    });
  }

  @autobind
  onChangeFilter(value, type) {
    this.setState({ [type]: value, isLoadingParserData: true }, async () => {
      await sleep(100);
      // if (type === 'projectFilter') await this.getLogAlertPatternServlet(value);
      const { sortBy, sortDirection, events } = this.state;
      const filterList = this.filterData(events);
      const chartOption = this.handleChartOption(filterList);
      const mergedList = buildMergeData(filterList, sortBy, sortDirection, this);
      this.setState(
        {
          filterList,
          eventList: mergedList,
          isLoadingParserData: false,
          chartOption,
          isResetDateZoom: true,
          zoomStartTime: undefined,
          zoomEndTime: undefined,
        },
        async () => {
          if (this.refList) await this.refList.scrollToPosition(1);
          if (this.refList) this.refList.scrollToPosition(1);
        },
      );
    });
  }

  @autobind
  renderTopBar() {
    const { intl, tabName, activeKey, isReadUser, location } = this.props;
    const { systemId } = parseLocation(location);
    const { projectFilter, timeFilterStart, timeFilterEnd, reductionRate, hideIgnore } = this.state;
    const { eventList, zoomStartTime, zoomEndTime } = this.state;
    const hasErrCompareIncidents = R.all(R.equals(true))(
      R.map((event) => {
        return R.all(R.equals(-1))(
          R.map(
            (item) => R.findIndex((citem) => citem.isChecked && !citem.isCompositeAnomaly, item.children || []),
            event.children || [],
          ),
        );
      }, eventList || []),
    );
    const findProject = R.find((item) => item.noOwnerProject === projectFilter, this.projectListOptions);

    return (
      <div className="flex-col" style={{ paddingBottom: 8, paddingLeft: 8 }}>
        <div className="flex-row flex-space-between flex-center-align flex-wrap">
          <div className="flex-row flex-center-align">
            {tabName === 'incident' && (
              <Button
                size="small"
                type="primary"
                onClick={this.handleNewCompareIncidentsClick}
                style={{ margin: '8px 16px 0 0' }}
                disabled={hasErrCompareIncidents}
              >
                {intl.formatMessage(logMessages.compareIncidents)}
              </Button>
            )}
            {tabName === 'incident' && (
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
              >
                <Button
                  size="small"
                  type="primary"
                  onClick={this.handleCompareCommonLogsClick}
                  style={{ margin: '8px 16px 0 0' }}
                  disabled={hasErrCompareIncidents || isReadUser}
                >
                  {intl.formatMessage(logMessages.compareCommonLogs)}
                </Button>
              </Popover>
            )}
            {tabName === 'incident' && (
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
              >
                <Button
                  size="small"
                  type="primary"
                  onClick={this.handleDeleteIncidentsClick}
                  style={{ margin: '8px 16px 0 0' }}
                  disabled={hasErrCompareIncidents || isReadUser}
                >
                  {intl.formatMessage(logMessages.deleteIncidents)}
                </Button>
              </Popover>
            )}
            {/* {activeKey === 'logAlert' && findProject?.dataType === 'Alert' && (
              <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                <span style={{ marginRight: 8 }}>{intl.formatMessage(logMessages.alertReductionRatio)}:</span>
                <span style={{ color: '#32C67F' }}>{reductionRate}</span>
              </div>
            )} */}
            {zoomStartTime && zoomEndTime && (
              <div className="flex-row flex-center-align" style={{ margin: '8px 16px 0 0' }}>
                <div style={{ marginRight: 8 }}>{`${moment
                  .utc(zoomStartTime)
                  .format(Defaults.ShortDateTimeFormat)} ~ ${moment
                  .utc(zoomEndTime - 10 * 60 * 1000)
                  .format(Defaults.ShortDateTimeFormat)}`}</div>
                <div className="font-14 clickable" onClick={() => customizeRestore(this, systemId)}>
                  <CloseCircleOutlined />
                </div>
              </div>
            )}
          </div>
          <div className="flex-row flex-center-align">
            {false && (
              <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                <Switch size="small" checked={hideIgnore} onChange={this.handleIgnoreFilterChange} />
                <span style={{ marginLeft: 8 }}>{intl.formatMessage(logMessages.hideIgnoredEvents)}</span>
              </div>
            )}
            {false && (
              <div className="flex-row flex-center-align" style={{ margin: '8px 8px 0 0' }}>
                <Select
                  allowClear
                  showArrow={false}
                  size="small"
                  style={{ width: 100 }}
                  placeholder={intl.formatMessage(appFieldsMessages.startTime)}
                  value={timeFilterStart}
                  onChange={(value) => this.onChangeTime('start', value)}
                >
                  {R.range(0, 24).map((item) => (
                    <Select.Option key={String(item)} title={String(item)}>
                      {`${item}:00`}
                    </Select.Option>
                  ))}
                </Select>
                <span style={{ padding: '0 4px' }}>~</span>
                <Select
                  allowClear
                  showArrow={false}
                  size="small"
                  style={{ width: 100 }}
                  placeholder={intl.formatMessage(appFieldsMessages.endTime)}
                  value={timeFilterEnd}
                  onChange={(value) => this.onChangeTime('end', value)}
                >
                  {R.range(1, 25).map((item) => (
                    <Select.Option key={String(item)} title={String(item)}>
                      {`${item}:00`}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            {false && (
              <div className="flex-rowflex-center-align" style={{ margin: '8px 16px 0 0' }}>
                <Button size="small" onClick={this.handleSystemIgnoredIncidentClick} style={{ marginLeft: 8 }}>
                  {intl.formatMessage(appMenusMessages.globalSystemIgnoredIncident)}
                </Button>
              </div>
            )}
            <Button
              size="small"
              onClick={this.handleExport}
              style={{ margin: '8px 16px 0 0' }}
              disabled={this.eventListExport.length === 0}
            >
              {intl.formatMessage(appButtonsMessages.export)}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  rootCauseChainRender({ rowData, onlyCloseModal }) {
    const { intl, credentials, activeKey } = this.props;

    const { hasRootCause, cloudType, isCompositeAnomaly } = rowData;
    const event = getEventInAnomalyTimelines(rowData);
    return (
      <div className="flex-row full-width flex-center-justify flex-center-align">
        <Popover
          title={hasRootCause && !isCompositeAnomaly ? intl.formatMessage(eventMessages.likelyRootCauses) : null}
          content={
            hasRootCause && !isCompositeAnomaly ? (
              <RootCausesPopover
                intl={intl}
                incident={event}
                credentials={credentials}
                sourceRootCausesDataMap={this.sourceRootCausesDataMap}
                handleChangeMap={(dataMap) => {
                  this.sourceRootCausesDataMap = dataMap;
                }}
                needRC
              />
            ) : hasRootCause && isCompositeAnomaly ? (
              intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
            ) : (
              intl.formatMessage(eventMessages.likelyRootCauses)
            )
          }
          mouseEnterDelay={0.3}
          placement="left"
        >
          <Button
            size="small"
            disabled={!hasRootCause}
            icon={
              <SearchOutlined style={{ fontSize: 16, ...(!hasRootCause ? {} : { color: 'var(--primary-color)' }) }} />
            }
            style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}
            onClick={() => {
              if (!isCompositeAnomaly) {
                if (onlyCloseModal) {
                  this.setState({ showIndividualIncidentsModal: false }, () => {
                    this.handleRCClick({
                      event,
                      needRC: true,
                      handleCallBack: (activeEvent) => {
                        this.setState({ showIndividualIncidentsModal: true, individualIncident: activeEvent });
                      },
                    });
                  });
                } else {
                  this.handleRCClick({ event, needRC: true });
                }
              }
            }}
          />
        </Popover>
        <Popover
          mouseEnterDelay={0.3}
          placement="left"
          content={
            isCompositeAnomaly
              ? intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
              : intl.formatMessage(logMessages.recommendations)
          }
        >
          <Button
            size="small"
            icon={<FormOutlined style={{ fontSize: 14, color: 'var(--primary-color)' }} />}
            style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}
            onClick={() => {
              if (!isCompositeAnomaly) this.setState({ showRecommendationModal: true, activeEvent: rowData });
            }}
          />
        </Popover>
        <Popover
          mouseEnterDelay={0.3}
          placement="left"
          content={
            isCompositeAnomaly
              ? intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
              : intl.formatMessage(logMessages[LLMTitleMap[activeKey]])
          }
        >
          <Button
            size="small"
            icon={<RobotIcon style={{ fontSize: 20, color: 'var(--primary-color)' }} />}
            style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}
            onClick={() => {
              if (!isCompositeAnomaly)
                this.setState({ showRecommendationsGPT: true, activeRecommendation: rowData, showGPTType: activeKey });
            }}
          />
        </Popover>
        {cloudType === 'LogCDF' && (
          <Popover
            mouseEnterDelay={0.3}
            placement="left"
            content={
              isCompositeAnomaly
                ? intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
                : 'Model drift anomaly'
            }
          >
            <Button
              size="small"
              icon={<AreaChartOutlined style={{ fontSize: 16, color: 'var(--primary-color)' }} />}
              style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}
              onClick={() => {
                if (!isCompositeAnomaly) this.setState({ showCDFModal: true, activeEvent: rowData });
              }}
            />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  peersListItem(tabName, events, jumpIncident) {
    return ({ key, index: rowIndex, style, parent, columnIndex, ...rest }) => {
      const { eventList } = this.state;
      const rowData = events[rowIndex];
      if (!rowData) return null;
      const { intl, activeKey, currentTheme, isReadUser, location } = this.props;
      const query = parseLocation(location);
      const { systemId } = query;
      const { isImportant, hasImportant, id, isRootEvent, hasRootCause, mergeKey, isCompositeAnomaly } = rowData;
      const { isAllCompositeAnomaly, isIgnored, isTimeRoot, startTimestamp, recurringIncidentTimes } = rowData;
      const { hasCompositeRecurringIncident, children } = rowData;

      const showChartIconChildrens = [];
      if (!!children && children.length > 0 && !isTimeRoot && isRootEvent) {
        R.forEach((event) => {
          if (event.isCompositeAnomaly) {
            showChartIconChildrens.push(...R.filter((e) => !!e.instanceListStr, event?.anomalyTimelines || []));
          } else if (event.instanceListStr) showChartIconChildrens.push(event);
        }, children || []);
      }
      let enableChartIconChildrens = [];
      if (!!showChartIconChildrens && showChartIconChildrens.length > 0) {
        enableChartIconChildrens = R.filter((e) => !!e.hasMetricProject, showChartIconChildrens);
      }

      const active = jumpIncident && jumpIncident.id === id;

      const dateData = R.find(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(eventList);
      const dateIdx = R.findIndex(R.propEq('id', moment.utc(startTimestamp).format(Defaults.DateFormat)))(eventList);
      const rootEvent = R.find(R.propEq('mergeKey', mergeKey))(dateData?.children || []);
      const rootEventIdx = R.findIndex(R.propEq('mergeKey', mergeKey))(dateData?.children || []);

      return (
        <CellMeasurer
          key={key}
          cache={this.cellMeasureCache}
          parent={parent}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
        >
          <div style={{ ...style }}>
            {isTimeRoot && (
              <div
                className="event-list-category-row clickable"
                style={{ height: this.dateHeight - 1, background: currentTheme === 'dark' ? '#4b4a4a' : '#d0d0d0' }}
                onClick={() => handleClickDateRow(dateIdx, rowData, this)}
              >
                <div className="row-column flex-center-justify" style={{ minWidth: 56 }} />
                <div
                  className="row-column font-14 bold"
                  style={{ minWidth: 70 + 180 + 210, maxWidth: 70 + 180 + 210, paddingRight: 16 }}
                >
                  {rowData.dateEnFormat}
                </div>
                <div className="row-column flex-grow" />
                <div className="row-column text-center" style={{ minWidth: 130, maxWidth: 130 }}>
                  <span className="full-width">{rowData.count}</span>
                </div>
                <div className="row-column" style={{ width: 30 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
                <div className="row-column flex-center-justify" style={{ width: 60 }} />
              </div>
            )}
            {!isTimeRoot && isRootEvent && (
              <div
                className="event-list-category-row clickable"
                style={{
                  height: this.rowMinHeight - 1,
                  backgroundColor: 'var(--item-active-bg2)',
                }}
                onClick={() => handleCategoryClick({ dateIdx, rootEventIdx, rowData, self: this })}
              >
                <div className="row-column flex-center-justify" style={{ minWidth: 56 }} />
                <div className="row-column" style={{ minWidth: 70, maxWidth: 70, paddingRight: 16 }} />
                <div className="row-column" style={{ minWidth: 180, maxWidth: 180, paddingRight: 16 }}>
                  {this.fatherInstanceRenderer(rowData, rootEventIdx, dateIdx)}
                </div>
                <div className="row-column" style={{ minWidth: 210, maxWidth: 210, paddingRight: 16 }}>
                  {showChartIconChildrens.length > 0 && (
                    <Tooltip title="Metric anomalies" mouseEnterDelay={0.3} placement="top">
                      <Button
                        size="small"
                        disabled={enableChartIconChildrens.length === 0}
                        icon={<LineChartOutlined style={{ fontSize: 16 }} />}
                        style={{
                          border: 'none',
                          backgroundColor: 'rgba(0,0,0,0)',
                          marginRight: 4,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleLineChartJumpv2({ events: enableChartIconChildrens });
                        }}
                      />
                    </Tooltip>
                  )}
                  {impactedRender(rowData, this)}
                </div>
                <div className="row-column" style={{ flex: 1, minWidth: 140 }} />
                <div className="row-column" style={{ minWidth: 130, maxWidth: 130, paddingRight: 16 }}>
                  {hasRootCause && (
                    <SearchOutlined style={{ fontSize: 16, width: '100%', color: 'var(--primary-color)' }} />
                  )}
                </div>
                <div className="row-column" style={{ minWidth: 90, maxWidth: 90 }} />
                {(activeKey === 'trace' || activeKey === 'logAlert') && (
                  <div className="row-column" style={{ minWidth: 40 }} />
                )}
                {activeKey !== 'incident' && <div className="row-column" style={{ minWidth: 65 }} />}
                <div className="row-column text-center" style={{ minWidth: 70, maxWidth: 70 }} />
                {tabName === 'incident' && <div className="row-column" style={{ minWidth: 110, maxWidth: 110 }} />}
                <div className="row-column text-center" style={{ minWidth: 130, maxWidth: 130 }}>
                  <span className="full-width">{rowData.count}</span>
                </div>
                <div className="row-column" style={{ width: 30 }}>
                  {rendererExpand(rowIndex, rowData)}
                </div>
                <div className="row-column flex-center-justify" style={{ width: 60 }}>
                  {hasImportant ? (
                    <ExclamationCircleFilled
                      className={`${hasImportant ? 'isImportantColor' : 'hover-color'}`}
                      style={{ color: currentTheme === 'dark' ? '#e6e6e6' : '#cacaca', fontSize: 16 }}
                    />
                  ) : (
                    <div style={{ width: 16 }} />
                  )}
                  {!isReadUser && !isAllCompositeAnomaly ? (
                    <Popover
                      title={null}
                      content={
                        isReadUser
                          ? intl.formatMessage(eventMessages.isReadUserDisable)
                          : isIgnored
                          ? intl.formatMessage(eventMessages.noIgnorePattern)
                          : intl.formatMessage(eventMessages.ignorePattern)
                      }
                      mouseEnterDelay={0.3}
                      placement="left"
                    >
                      {isIgnored ? (
                        <EyeInvisibleOutlined
                          className="hover-color"
                          style={{
                            color: currentTheme === 'dark' ? '#e6e6e6' : '#949393',
                            fontSize: 14,
                            marginLeft: 8,
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (!isReadUser)
                              handleIgnoreClick({
                                event: rowData,
                                category: 'ignore',
                                rowIndex: rootEventIdx,
                                dateIdx,
                                self: this,
                              });
                          }}
                        />
                      ) : (
                        <EyeOutlined
                          className="hover-color"
                          style={{
                            color: currentTheme === 'dark' ? '#e6e6e6' : '#949393',
                            fontSize: 14,
                            marginLeft: 8,
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (!isReadUser)
                              handleIgnoreClick({
                                event: rowData,
                                category: 'ignore',
                                rowIndex: rootEventIdx,
                                dateIdx,
                                self: this,
                              });
                          }}
                        />
                      )}
                    </Popover>
                  ) : (
                    <div style={{ width: 14, marginLeft: 8 }} />
                  )}
                </div>
              </div>
            )}
            {!isTimeRoot && !isRootEvent && (
              <div className="full-width">
                <div className="flex-grow flex-min-width event-list">
                  <div
                    className={`incident-event-list-row${active ? ' active' : ''}`}
                    onClick={() => this.setState({ jumpIncident: rowData })}
                    style={{
                      borderBottom: '1px solid var(--virtualized-table-border-color)',
                      height: this.rowMinHeight - 1,
                    }}
                  >
                    <div className="row-column flex-center-justify" style={{ minWidth: 56 }}>
                      {isCompositeAnomaly && (
                        <Popover
                          placement="right"
                          title={null}
                          content={intl.formatMessage(eventMessages.compositeIncident)}
                          trigger="hover"
                        >
                          <ApartmentOutlined style={{ fontSize: 14, marginRight: 8, color: 'var(--primary-color)' }} />
                        </Popover>
                      )}
                      {(recurringIncidentTimes.length > 0 || hasCompositeRecurringIncident) && (
                        <Popover
                          placement="right"
                          title={isCompositeAnomaly ? null : intl.formatMessage(eventMessages.recurrentIncidents)}
                          content={
                            isCompositeAnomaly
                              ? intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
                              : recurrentIncidentsViewList(rowData, intl, systemId, false, this)
                          }
                          trigger="hover"
                        >
                          <RecurrentIncidentsIcon style={{ fontSize: 17, color: 'var(--primary-color)' }} />
                        </Popover>
                      )}
                    </div>
                    <div className="row-column" style={{ minWidth: 70, maxWidth: 70, paddingRight: 16 }}>
                      {timeRenderer(rowData)}
                    </div>
                    <div className="row-column" style={{ minWidth: 180, maxWidth: 180, paddingRight: 16 }}>
                      {false && this.instanceRenderer(rowData)}
                    </div>
                    <div className="row-column" style={{ minWidth: 210, maxWidth: 210, paddingRight: 16 }}>
                      {this.impactInstanceRenderer(rowData, rootEvent)}
                    </div>
                    <div
                      className="row-column"
                      style={{ flex: 1, paddingRight: 10, minWidth: 140, minHeight: this.rowMinHeight }}
                    >
                      {this.rendererContent(rowData, false)}
                    </div>
                    <div className="row-column" style={{ minWidth: 130, maxWidth: 130, paddingRight: 16 }}>
                      {this.rootCauseChainRender({ rowData })}
                    </div>
                    <div className="row-column" style={{ minWidth: 90, maxWidth: 90 }}>
                      <Popover
                        content={`Duration: ${CellRenderers.humanizeDuration({
                          period: rowData.duration + 1,
                          intl,
                          showSeconds: true,
                          showZero: true,
                          showZeroToOne: true,
                        })}`}
                        mouseEnterDelay={0.3}
                      >
                        {CellRenderers.humanizeDuration({
                          period: rowData.duration + 1,
                          intl,
                          showSeconds: true,
                          showZero: true,
                          shortDisplay: true,
                          showZeroToOne: true,
                        })}
                      </Popover>
                    </div>
                    {(activeKey === 'trace' || activeKey === 'logAlert') && (
                      <div className="row-column flex-center-justify flex-wrap" style={{ minWidth: 40 }}>
                        {traceRenderer(rowData)}
                      </div>
                    )}
                    {activeKey !== 'incident' && (
                      <div className="row-column flex-center-justify flex-wrap" style={{ minWidth: 65 }}>
                        {renderCategory(rowData, this)}
                      </div>
                    )}
                    <div className="row-column flex-wrap" style={{ minWidth: 70, maxWidth: 70 }}>
                      {statusRenderer(rowData, this)}
                    </div>
                    {tabName === 'incident' && (
                      <>
                        <div className="row-column" style={{ minWidth: 110, maxWidth: 110 }}>
                          {rendererIncidentStatus(rowData, rowIndex, rootEvent, rootEventIdx, dateIdx, this)}
                        </div>
                      </>
                    )}
                    <div
                      className="row-column"
                      style={{ minWidth: 130 + 30, maxWidth: 130 + 30, justifyContent: 'center' }}
                    >
                      {this.controlRenderer(rowData, rowIndex, rootEvent, tabName, dateIdx)}
                    </div>
                    <div className="row-column flex-center-justify" style={{ minWidth: 60, height: 39 }}>
                      <Popover
                        title={null}
                        content={
                          isReadUser
                            ? intl.formatMessage(eventMessages.isReadUserDisable)
                            : isCompositeAnomaly
                            ? intl.formatMessage(eventMessages.viewIndividualIncidentsDetails)
                            : intl.formatMessage(
                                isImportant ? eventMessages.removeImportantFlag : eventMessages.markAsImportant,
                              )
                        }
                        mouseEnterDelay={0.3}
                        placement="right"
                      >
                        <ExclamationCircleFilled
                          className={`${isImportant ? 'isImportantColor' : 'hover-color'}`}
                          style={{
                            color: currentTheme === 'dark' ? '#e6e6e6' : '#cacaca',
                            fontSize: 16,
                          }}
                          onClick={(event) => {
                            if (!isReadUser && !isCompositeAnomaly)
                              handleIgnoreClick({
                                event: rowData,
                                category: 'important',
                                rowIndex: rootEventIdx,
                                dateIdx,
                                rootEvent,
                                self: this,
                              });
                          }}
                        />
                      </Popover>
                      {tabName === 'incident' ? (
                        <div style={{ marginLeft: 8 }}>
                          {!isCompositeAnomaly ? (
                            rendererChecked(rowIndex, rowData, rootEvent, rootEventIdx, dateIdx, this)
                          ) : (
                            <div style={{ width: 16 }} />
                          )}
                        </div>
                      ) : (
                        <div style={{ marginLeft: 8 }}>
                          <div style={{ width: 14, height: 14 }} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleNewCompareIncidentsClick() {
    const { eventList } = this.state;
    const compareIncidents = R.unnest(
      R.unnest(
        R.map(
          (event) =>
            R.map(
              (item) =>
                R.sortWith([R.ascend(R.prop('startTimestamp'))])(
                  R.filter((citem) => citem.isChecked, item.children || []),
                ),
              event.children,
            ),
          eventList || [],
        ),
      ),
    );

    this.setState({ showNewCompareIncidentsModal: true, compareIncidents });
  }

  @autobind
  handleCompareCommonLogsClick() {
    const { eventList } = this.state;
    const compareIncidents = R.unnest(
      R.unnest(
        R.map(
          (event) =>
            R.map(
              (item) =>
                R.sortWith([R.ascend(R.prop('startTimestamp'))])(
                  R.filter((citem) => citem.isChecked, item.children || []),
                ),
              event.children,
            ),
          eventList || [],
        ),
      ),
    );

    this.setState({ showCompareCommonLogsModal: true, compareIncidents });
  }

  @autobind
  handleDeleteIncidentsClick() {
    const { intl } = this.props;
    const { eventList } = this.state;
    const compareIncidents = R.unnest(
      R.unnest(
        R.map(
          (event) => R.map((item) => R.filter((citem) => citem.isChecked, item.children || []), event.children),
          eventList || [],
        ),
      ),
    );

    this.delectSelectIncidentsModal = Modal.confirm({
      title: <div>{intl.formatMessage(appMessages.continueConfirm)}</div>,
      icon: <ExclamationCircleOutlined />,
      content: null,
      okType: 'danger',
      onOk: (close) => {
        return this.handleDeleteSelectIncidentClick(compareIncidents);
      },
    });
  }

  @autobind
  handleDeleteSelectIncidentClick(compareIncidents) {
    const { credentials, intl, location, globalInfo } = this.props;
    const { systemId, environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    if (this.delectSelectIncidentsModal) {
      this.delectSelectIncidentsModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }
    this.props.updateLastActionInfo();

    const inputrecords = R.map((item) => {
      const { projectName, projectOwner, instanceName, incidentTimestamp, startTimestamp, patternId } = item;
      return {
        projectName,
        instanceName,
        timestamp: incidentTimestamp || startTimestamp,
        patternId,
        customerName: projectOwner,
      };
    }, compareIncidents);

    fetchPost(getEndpoint('incident'), {
      ...credentials,
      inputrecords: JSON.stringify(inputrecords),
      systemName: systemId,
      customerName: systemInfo.ownerUserName,
    })
      .then((data) => {
        if (this.delectSelectIncidentsModal) this.delectSelectIncidentsModal.destroy();
        this.setState({ isAllChecked: false });
        const description = [];
        R.forEachObjIndexed((val, key) => {
          key = JSON.parse(key);
          val = JSON.parse(val);
          const { projectName, timestamp } = key;
          const { msg, success } = val;
          if (!success) {
            description.push({ projectName, time: moment.utc(timestamp).format(Defaults.DateTimeFormat), msg });
          }
        }, data || {});
        if (R.isEmpty(description)) {
          this.refresh();
          message.success(intl.formatMessage(appMessages.apiSuccess));
        } else {
          notification.open({
            message: 'Failed to delete',
            description: (
              <div className="flex-col flex-center-justify">
                {R.map(
                  (item) => (
                    <div key={item.projectName + item.time}>
                      {item.time}
                      <span className="bold" style={{ marginLeft: 4 }}>
                        {item.projectName}
                      </span>
                      :{item.msg}
                    </div>
                  ),
                  description,
                )}
              </div>
            ),
          });
          this.refresh();
        }
      })
      .catch((err) => {
        if (this.delectSelectIncidentsModal) {
          this.delectSelectIncidentsModal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
        }
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  instanceListStrRender(rowData) {
    const { intl } = this.props;
    const { instanceListStr, containerInfo } = rowData;
    return (
      <Popover
        content={
          <div style={{ maxWidth: 300, wordBreak: 'break-all' }}>
            <div className="flex-row">
              <div className="light-label bold" style={{ width: 100, flexShrink: 0 }}>
                {intl.formatMessage(eventMessages.instanceName)}:&nbsp;
              </div>
              {instanceListStr}
            </div>
            {containerInfo && (
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 100, flexShrink: 0 }}>
                  {intl.formatMessage(eventMessages.containerName)}:&nbsp;
                </div>
                {containerInfo.containerName}
              </div>
            )}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <div className="hidden-line-with-ellipsis">{instanceListStr}</div>
      </Popover>
    );
  }

  @autobind
  componentNameRender(rowData, isChild) {
    const { intl } = this.props;
    const { componentName } = rowData;
    return (
      <div className="max-width flex-row flex-center-align">
        <Popover
          content={
            <div style={{ maxWidth: 300, wordBreak: 'break-all' }}>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 110, flexShrink: 0 }}>
                  {intl.formatMessage(eventMessages.componentName)}:&nbsp;
                </div>
                {componentName}
              </div>
            </div>
          }
          mouseEnterDelay={0.3}
          placement="right"
          align={{ offset: [12, 0] }}
        >
          <div className="hidden-line-with-ellipsis">{componentName}</div>
        </Popover>
        {isChild && (
          <Popover content={intl.formatMessage(eventMessages.setComponentName)} mouseEnterDelay={0.3} placement="top">
            <EditOutlined
              className="primary-color hover-show"
              style={{ marginLeft: 2 }}
              onClick={() => this.handleChangeComponentNameClick({ event: rowData })}
            />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  componentRenderer(rowData) {
    const { intl, isAdmin, userName } = this.props;
    const {
      componentListStr,
      containerName,
      patternId,
      patternName,
      projectOwner,
      category,
      anomalyLogInstance,
      componentName,
    } = rowData;

    let { projectName } = rowData;
    projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
    const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
    return (
      <Popover
        title={null}
        content={
          <div style={{ maxWidth: 300 }}>
            <div className="flex-row">
              <div className="light-label bold" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </div>
              {projectName}
            </div>
            <div className="flex-row">
              <div className="light-label bold" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </div>
              {componentName}
            </div>
            <div className="flex-row">
              <div className="light-label bold" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.pattern)}:
              </div>
              {
                Defaults.PatternIdNameStr(
                  { patternName: get(this.state.patternNameMap, [key, patternId]) || patternName, patternId },
                  { hasFullName: true, hasPrefix: true },
                ).patternNameStr
              }
            </div>
            {containerName && (
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </div>
                {containerName}
              </div>
            )}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <div className="flex-row flex-center-align" style={{ width: 235 }}>
          <span className="hidden-line-with-ellipsis" style={{ width: 123, display: 'inline-block' }}>
            {componentListStr}
          </span>
        </div>
      </Popover>
    );
  }

  @autobind
  fatherInstanceRenderer(rowData, rootEventIdx, dateIdx, callback) {
    const { intl, isAdmin, userName, currentTheme, activeKey, isReadUser } = this.props;
    const { containerName, projectOwner, category, anomalyLogInstance, patternId, patternName, lastChildren } = rowData;
    const { componentName, projectDisplayName, isAllCompositeAnomaly } = rowData;
    let { projectName } = rowData;
    projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
    const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;

    const { category: chCategory, rawData: chRawData, isCompositeAnomaly } = lastChildren;
    const { anomalyWords: chAnomalyWords, outlierValue: chOutlierValue } = lastChildren;
    const rootCauseDetailsArr = get(lastChildren, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    let rawDataJson;
    if (chCategory !== 'metric') {
      try {
        rawDataJson = JSON.parse(chRawData);
      } catch (error) {
        // console.debug(error)
      }
    }

    const patternIdAndName = Defaults.PatternIdNameStr(
      { patternName: get(this.state.patternNameMap, [key, patternId]) || patternName, patternId },
      {},
    ).patternNameStr;

    const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
    const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });

    return (
      <div className="max-width flex-row flex-center-align">
        {activeKey === 'incident' && (
          <Popover title={null} content={intl.formatMessage(DashboardMessages.detectedIncident)} mouseEnterDelay={0.3}>
            {patternIcon}
          </Popover>
        )}
        <Popover
          title={null}
          content={
            <div style={{ width: 480, maxHeight: 350, overflow: 'overlay', wordBreak: 'break-all', paddingRight: 10 }}>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80, flexShrink: 0 }}>
                  {intl.formatMessage(appFieldsMessages.project)}:
                </div>
                {projectDisplayName || projectName}
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80, flexShrink: 0 }}>
                  {intl.formatMessage(appFieldsMessages.component)}:
                </div>
                {componentName}
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.pattern)}:
                </div>
                {`[${patternId}]${String(patternId || '') !== patternIdAndName ? ` ${patternIdAndName}` : ''}`}
              </div>
              {containerName && (
                <div className="flex-row">
                  <div className="light-label bold" style={{ width: 80 }}>
                    {intl.formatMessage(appFieldsMessages.container)}:
                  </div>
                  {containerName}
                </div>
              )}
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 110, flexShrink: 0 }}>
                  {intl.formatMessage(eventMessages.shortDescription)}:
                </div>
                {chCategory === 'metric' && (
                  <div>
                    {R.addIndex(R.map)(
                      (event, index) =>
                        EventRenderers.RenderMetricAnomalySummary({ intl, event, index, hideAvg: isCompositeAnomaly }),
                      rootCauseDetailsArr,
                    )}
                  </div>
                )}
                {chCategory === 'log' && (
                  <>
                    {chAnomalyWords && chAnomalyWords.length > 0 && (
                      <LogRenderers.RenderAnomalyWords
                        style={{ wordBreak: 'break-all', marginBottom: 12 }}
                        anomalyWordList={chAnomalyWords}
                      />
                    )}
                    {chOutlierValue && !R.isEmpty(chOutlierValue) && (
                      <LogRenderers.RenderOutlierValue
                        style={{ wordBreak: 'break-all', marginBottom: 12 }}
                        outlierValue={chOutlierValue}
                      />
                    )}
                    <div style={{ wordWrap: 'break-word', width: 350 }}>
                      {!rawDataJson && (
                        <div style={{ whiteSpace: 'break-spaces' }}>
                          {R.join(
                            '\n',
                            R.filter((x) => Boolean(x), (chRawData || '').split('\n')),
                          )}
                        </div>
                      )}
                      {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} currentTheme={currentTheme} />}
                    </div>
                  </>
                )}
              </div>
            </div>
          }
          mouseEnterDelay={0.3}
          placement="right"
        >
          <div className="flex-grow hidden-line-with-ellipsis">
            <div className="hidden-line-with-ellipsis">{`[${patternId}]`}</div>
            {String(patternId || '') !== patternIdAndName && (
              <div className="hidden-line-with-ellipsis">{patternIdAndName}</div>
            )}
          </div>
        </Popover>
        {!isReadUser && !isAllCompositeAnomaly && (
          <Popover content={intl.formatMessage(eventMessages.setPatternName)} mouseEnterDelay={0.3} placement="top">
            <EditOutlined
              className="primary-color"
              style={{ marginLeft: 2 }}
              onClick={(e) => {
                e.stopPropagation();
                if (!rowData.isExpand) {
                  if (callback) {
                    callback(dateIdx, rootEventIdx, rowData);
                  } else {
                    handleCategoryClick({ dateIdx, rootEventIdx, rowData, self: this });
                  }
                } else {
                  this.fatherEvent = rowData;
                }
                this.handleChangePatternNameClick({ event: rowData?.children[0] || {} });
              }}
            />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  getMappingProjectInfo({ event }) {
    const { globalInfo, location } = this.props;
    const query = parseLocation(location);
    const { environmentId, systemId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const globaInstaceMappingProject = get(systemInfo, ['globaInstaceMappingProject'], {});
    const { instanceListStr = '', realInstanceName = '' } = event || {};

    const mappingProjectMap = get(globaInstaceMappingProject, systemId, {});
    const instanceList = R.keys(mappingProjectMap || {});
    const findInstance = R.filter(
      (ins) =>
        realInstanceName === ins ||
        instanceListStr === ins ||
        R.includes(ins, realInstanceName) ||
        R.includes(realInstanceName, ins) ||
        R.includes(ins, instanceListStr) ||
        R.includes(instanceListStr, ins),
      instanceList || [],
    );
    const mappingProjectInfos = [];
    R.forEach((ins) => {
      const mappingProjects = get(mappingProjectMap, ins, []);
      mappingProjectInfos.push(...R.filter((p) => p.instanceName === ins && p.dataType === 'Metric', mappingProjects));
    }, findInstance || []);

    const findMappingProjects = mappingProjectInfos[0] || {};

    return findMappingProjects;
  }

  @autobind
  impactInstanceRenderer(rowData, rootEvent) {
    const { intl, activeKey, timezoneOffset, location, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};
    const { componentName, hasMetricProject, category, reporterRecordMap, isCompositeAnomaly } = rowData;
    const { pod, host, projectName, projectOwner } = rowData;
    let { containerInfo, instanceListStr } = rowData;
    const hasPodAndHost = pod && host;

    if (isCompositeAnomaly) {
      return (
        <Button
          size="small"
          onClick={() => {
            this.individualRootFatherEvent = rootEvent;
            this.individualRootChildEvent = rowData;
            this.setState({ showIndividualIncidentsModal: true, actionComposite: rowData });
          }}
        >
          {intl.formatMessage(eventMessages.viewIndividualIncidents)}
        </Button>
      );
    }

    if (!instanceListStr) {
      return <div />;
    }

    if (!containerInfo && R.includes('_', instanceListStr)) {
      containerInfo = {
        containerName: R.split('_', instanceListStr)[0],
        instanceName: R.split('_', instanceListStr)[1],
      };
      instanceListStr = R.split('_', instanceListStr)[1];
    }

    let reporterRecordSet = [
      ...get(reporterRecordMap, 'ignore', []),
      ...get(reporterRecordMap, 'unIgnore', []),
      ...get(reporterRecordMap, 'importance', []),
      ...get(reporterRecordMap, 'unImportance', []),
    ];
    reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))])(reporterRecordSet);
    const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, instanceListStr, {
      pn: projectName,
      owner: projectOwner,
    });
    const width = instanceDisplayName ? 140 : 110;

    return (
      <div className="max-width flex-row flex-center-align">
        <Tooltip title="Metric anomalies" mouseEnterDelay={0.3} placement="top">
          <Button
            size="small"
            disabled={!hasMetricProject}
            icon={<LineChartOutlined style={{ fontSize: 16 }} />}
            style={{
              border: 'none',
              backgroundColor: 'rgba(0,0,0,0)',
              marginRight: 4,
            }}
            onClick={() => {
              if (activeKey === 'incident') {
                this.handleLineChartJump({ event: rowData });
              } else {
                // eslint-disable-next-line no-lonely-if
                if (category === 'metric') {
                  this.handleLineChartClick({ event: rowData });
                } else {
                  this.handleLineChartJump({ event: rowData });
                }
              }
            }}
          />
        </Tooltip>
        <div className="flex-grow overflow-hidden">
          <div className="flex-row">
            <Popover
              content={
                <div className="flex-col" style={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}>
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                      {intl.formatMessage(eventMessages.componentName)}:&nbsp;
                    </div>
                    {componentName}
                  </div>
                  {hasPodAndHost && (
                    <>
                      <div className="flex-row">
                        <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                          Host ID:&nbsp;
                        </div>
                        {host}
                      </div>
                      <div className="flex-row">
                        <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                          {intl.formatMessage(appFieldsMessages.physicalPodID)}:&nbsp;
                        </div>
                        {pod}
                      </div>
                    </>
                  )}
                  {containerInfo && (
                    <div className="flex-row">
                      <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                        {intl.formatMessage(eventMessages.containerName)}:&nbsp;
                      </div>
                      {containerInfo.containerName}
                    </div>
                  )}
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                      {intl.formatMessage(hasPodAndHost ? appFieldsMessages.logicPodID : eventMessages.instanceName)}
                      :&nbsp;
                    </div>
                    {instanceListStr}
                  </div>
                  {instanceDisplayName && (
                    <div className="flex-row">
                      <div className="light-label bold" style={{ width: 140, flexShrink: 0 }}>
                        {intl.formatMessage(appFieldsMessages.instanceDisplayName)}
                        :&nbsp;
                      </div>
                      {instanceDisplayName}
                    </div>
                  )}
                  {reporterRecordSet.length > 0 && <Divider style={{ margin: '5px 0' }} />}
                  <div className="flex-col overflow-y-auto">
                    {R.addIndex(R.map)((item, idx) => {
                      const { timestamp, reporterName, action } = item;
                      const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
                      return (
                        <div key={idx} className="flex-row flex-center-align">
                          <div className="light-label bold" style={{ marginRight: 5 }}>
                            {intl.formatMessage(appFieldsMessages.time)}:
                          </div>
                          <div style={{ marginRight: 5 }}>
                            {moment.utc(customerTimestamp).format(Defaults.ShortTimeOnlyFormat)}
                          </div>
                          <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                            {intl.formatMessage(eventActionMessages.reporter)}:
                          </div>
                          <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                          <span>{reporterName}</span>
                          <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                            {intl.formatMessage(eventActionMessages.action)}:
                          </div>
                          <div>{action}</div>
                        </div>
                      );
                    }, reporterRecordSet)}
                  </div>
                </div>
              }
              mouseEnterDelay={0.3}
              placement="right"
              align={{ offset: [12, 0] }}
            >
              <div className="overflow-hidden">
                <div className="hidden-line-with-ellipsis">{containerInfo ? containerInfo.containerName : ''}</div>
              </div>
            </Popover>
          </div>
          <Popover
            content={
              <div className="flex-col" style={{ maxWidth: 400, maxHeight: 400, wordBreak: 'break-all' }}>
                <div className="flex-row">
                  <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                    {intl.formatMessage(eventMessages.componentName)}:&nbsp;
                  </div>
                  {componentName}
                </div>
                {hasPodAndHost && (
                  <>
                    <div className="flex-row">
                      <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                        Host ID:&nbsp;
                      </div>
                      {host}
                    </div>
                    <div className="flex-row">
                      <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                        {intl.formatMessage(appFieldsMessages.physicalPodID)}:&nbsp;
                      </div>
                      {pod}
                    </div>
                  </>
                )}
                {containerInfo && (
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                      {intl.formatMessage(eventMessages.containerName)}:&nbsp;
                    </div>
                    {containerInfo.containerName}
                  </div>
                )}
                <div className="flex-row">
                  <div className="light-label bold" style={{ width, flexShrink: 0 }}>
                    {intl.formatMessage(hasPodAndHost ? appFieldsMessages.logicPodID : eventMessages.instanceName)}
                    :&nbsp;
                  </div>
                  {instanceListStr}
                </div>
                {instanceDisplayName && (
                  <div className="flex-row">
                    <div className="light-label bold" style={{ width: 140, flexShrink: 0 }}>
                      {intl.formatMessage(appFieldsMessages.instanceDisplayName)}
                      :&nbsp;
                    </div>
                    {instanceDisplayName}
                  </div>
                )}
                {reporterRecordSet.length > 0 && <Divider style={{ margin: '5px 0' }} />}
                <div className="flex-col overflow-y-auto">
                  {R.addIndex(R.map)((item, idx) => {
                    const { timestamp, reporterName, action } = item;
                    const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
                    return (
                      <div key={idx} className="flex-row flex-center-align">
                        <div className="light-label bold" style={{ marginRight: 5 }}>
                          {intl.formatMessage(appFieldsMessages.time)}:
                        </div>
                        <div style={{ marginRight: 5 }}>
                          {moment.utc(customerTimestamp).format(Defaults.ShortTimeOnlyFormat)}
                        </div>
                        <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                          {intl.formatMessage(eventActionMessages.reporter)}:
                        </div>
                        <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                        <span>{reporterName}</span>
                        <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                          {intl.formatMessage(eventActionMessages.action)}:
                        </div>
                        <div>{action}</div>
                      </div>
                    );
                  }, reporterRecordSet)}
                </div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="right"
            align={{ offset: [12, 0] }}
          >
            <div className="overflow-hidden">
              <div className="hidden-line-with-ellipsis">{instanceDisplayName || instanceListStr}</div>
            </div>
          </Popover>
        </div>
      </div>
    );
  }

  @autobind
  instanceRenderer(rowData) {
    const { intl, isAdmin, userName, timezoneOffset, activeKey } = this.props;
    const {
      containerName,
      projectOwner,
      category,
      anomalyLogInstance,
      patternId,
      patternName,
      reporterRecordMap,
      componentName,
      projectDisplayName,
    } = rowData;
    let { projectName } = rowData;

    projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
    const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
    let reporterRecordSet = [
      ...get(reporterRecordMap, 'ignore', []),
      ...get(reporterRecordMap, 'unIgnore', []),
      ...get(reporterRecordMap, 'importance', []),
      ...get(reporterRecordMap, 'unImportance', []),
    ];

    reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))])(reporterRecordSet);

    const patternIdAndName = Defaults.PatternIdNameStr(
      { patternName: get(this.state.patternNameMap, [key, patternId]) || patternName, patternId },
      { hasFullName: true, hasPrefix: true },
    ).patternNameStr;

    const patternStyle = { color: '#FF5142', fontSize: 20, marginRight: 4 };
    const patternIcon = getPatternNameIcon({ patternIdAndName, patternStyle });

    return (
      <div className="max-width flex-row flex-center-align">
        {activeKey === 'incident' && (
          <Popover title={null} content={intl.formatMessage(DashboardMessages.detectedIncident)} mouseEnterDelay={0.3}>
            {patternIcon}
          </Popover>
        )}
        <Popover
          title={null}
          content={
            <div style={{ width: 370, maxHeight: 150, overflow: 'overlay', wordBreak: 'break-all' }}>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80, flexShrink: 0 }}>
                  {intl.formatMessage(appFieldsMessages.project)}:
                </div>
                {projectDisplayName || projectName}
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80, flexShrink: 0 }}>
                  {intl.formatMessage(appFieldsMessages.component)}:
                </div>
                {componentName}
              </div>
              <div className="flex-row">
                <div className="light-label bold" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.pattern)}:
                </div>
                {
                  Defaults.PatternIdNameStr(
                    { patternName: get(this.state.patternNameMap, [key, patternId]) || patternName, patternId },
                    { hasFullName: true, hasPrefix: true },
                  ).patternNameStr
                }
              </div>
              {containerName && (
                <div className="flex-row">
                  <div className="light-label bold" style={{ width: 80 }}>
                    {intl.formatMessage(appFieldsMessages.container)}:
                  </div>
                  {containerName}
                </div>
              )}
              {reporterRecordSet.length > 0 && <Divider style={{ margin: '5px 0' }} />}
              <div className="flex-col overflow-y-auto">
                {R.addIndex(R.map)((item, idx) => {
                  const { timestamp, reporterName, action } = item;
                  const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
                  return (
                    <div key={idx} className="flex-row flex-center-align">
                      <div className="light-label bold" style={{ marginRight: 5 }}>
                        {intl.formatMessage(appFieldsMessages.time)}:
                      </div>
                      <div style={{ marginRight: 5 }}>
                        {moment.utc(customerTimestamp).format(Defaults.ShortTimeOnlyFormat)}
                      </div>
                      <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                        {intl.formatMessage(eventActionMessages.reporter)}:
                      </div>
                      <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
                      <span>{reporterName}</span>
                      <div className="light-label bold" style={{ margin: '0 5px 0 10px' }}>
                        {intl.formatMessage(eventActionMessages.action)}:
                      </div>
                      <div>{action}</div>
                    </div>
                  );
                }, reporterRecordSet)}
              </div>
            </div>
          }
          mouseEnterDelay={0.3}
          placement="right"
          align={{ offset: [12, 0] }}
        >
          <span className="hidden-line-with-ellipsis inline-block">{patternIdAndName}</span>
        </Popover>
      </div>
    );
  }

  @autobind
  patternRenderer(rowData) {
    const { intl, location, globalInfo, credentials, isAdmin, userName } = this.props;

    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const ownerUserName = get(systemInfo, 'ownerUserName');

    const { isRootEvent, category, patternName, patternId, anomalyLogInstance, projectOwner, startTimestamp } = rowData;
    let { projectName } = rowData;
    projectName = isAdmin || projectOwner !== userName ? `${projectName}@${projectOwner}` : projectName;
    const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;

    const RendererPatternTitle = ({ rowData }) => {
      const { patternNameMap } = this.state;

      const [patternName, setPatternName] = useState(get(patternNameMap, [key, patternId]));
      const [loading, setLoading] = useState(false);
      useEffect(() => {
        if (patternName === undefined) {
          setLoading(true);
          this.props.updateLastActionInfo();
          fetchGet(getEndpoint('getpatternname', 1), {
            ...credentials,
            customerName: ownerUserName,
            projectName,
            instanceName: category === 'log' ? anomalyLogInstance : null,
            patternId,
            startTime: startTimestamp,
          })
            .then((data) => {
              const { patternName } = data;
              if (!patternNameMap[key]) patternNameMap[key] = {};
              patternNameMap[key][patternId] = patternName || '';

              this.setState({ patternNameMap });
              setLoading(false);
              setPatternName(patternName || '');
            })
            .catch((err) => {
              message.error(intl.formatMessage(appMessages.apiFaild));
            });
        }
      }, []);
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName, patternId },
        { hasFullName: true, hasPrefix: true },
      );
      return (
        <div>
          {loading && <Spin size="small" />}
          {!loading && <div>{patternNameStr}</div>}
        </div>
      );
    };

    return (
      <div className="max-width flex-row flex-center-align">
        <Popover content={<RendererPatternTitle rowData={rowData} />} mouseEnterDelay={0.3} placement="top">
          <div className="hidden-line-with-ellipsis">
            {
              Defaults.PatternIdNameStr(
                { patternName: get(this.state.patternNameMap, [key, patternId]) || patternName, patternId },
                { hasFullName: true, hasPrefix: true },
              ).patternNameStr
            }
          </div>
        </Popover>
        {!isRootEvent && (
          <Popover content={intl.formatMessage(eventMessages.setPatternName)} mouseEnterDelay={0.3} placement="top">
            <EditOutlined
              className="primary-color"
              style={{ marginLeft: 2 }}
              onClick={() => this.handleChangePatternNameClick({ event: rowData })}
            />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  shortDescriptionInfo(rowData) {
    const { intl, credentials, currentTheme } = this.props;
    const { category, rawData, anomalyWords, outlierValue, projectOwner, frequencyStr, anomalyFeatureStr } = rowData;
    const { count, isCompositeAnomaly, generateFromGenericRule, predictionSourceInfoList } = rowData;
    let { projectName } = rowData;

    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const summarySettings = [];

    const rootCauseDetailsArr = get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
      if (rawDataJson) {
        R.forEach((s) => {
          const v = get(rawDataJson, s.replaceAll('->', '.'));
          if (v) summarySettings.push(s);
        }, this.summarySettingsMap[projectName] || []);
      }
    } catch (error) {
      // console.debug(error)
    }

    let content = (
      <>
        {category === 'metric' && (
          <div>
            {R.addIndex(R.map)(
              (event, index) =>
                EventRenderers.RenderMetricAnomalySummary({ intl, event, index, hideAvg: isCompositeAnomaly }),
              rootCauseDetailsArr,
            )}
          </div>
        )}
        {category === 'log' && (
          <>
            {count && count > 1 && (
              <div className="flex-row flex-center-align" style={{ color: 'var(--blue)' }}>
                <span style={{ marginRight: 4 }}>{intl.formatMessage(appFieldsMessages.count)}:</span>
                <span style={{ wordBreak: 'break-all' }}>{count}</span>
              </div>
            )}
            {anomalyWords && anomalyWords.length > 0 && (
              <LogRenderers.RenderAnomalyWords
                style={{ wordBreak: 'break-all', marginBottom: 12 }}
                anomalyWordList={anomalyWords}
              />
            )}
            {outlierValue && !R.isEmpty(outlierValue) && (
              <LogRenderers.RenderOutlierValue
                style={{ wordBreak: 'break-all', marginBottom: 12 }}
                outlierValue={outlierValue}
              />
            )}
            <div style={{ wordWrap: 'break-word' }}>
              {!rawDataJson && !R.isEmpty(frequencyStr) && <div style={{ color: 'var(--blue)' }}>{frequencyStr}</div>}
              {!rawDataJson && anomalyFeatureStr && (
                <div className="max-width flex-col" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
                  {R.addIndex(R.map)((item, index) => {
                    const [label, values] = item;
                    const content = R.addIndex(R.map)(
                      ({ value }, i) => (
                        <span style={{ margin: '0 4px' }} key={`featureStr${index}${i}`}>
                          {value}
                          {values.length - 1 === i ? '' : ','}
                        </span>
                      ),
                      values,
                    );
                    return (
                      <div className="flex-row" key={`${index}+${label}`}>
                        <div style={{ whiteSpace: 'nowrap' }}>{label} :</div>
                        <div className="flex-row flex-wrap">{content}</div>
                      </div>
                    );
                  }, anomalyFeatureStr)}
                </div>
              )}
              {!rawDataJson && (
                <div style={{ whiteSpace: 'break-spaces' }}>
                  {R.join(
                    '\n',
                    R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                  )}
                </div>
              )}
              {rawDataJson && (
                <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                  <LogRenderers.RenderLogContent
                    intl={intl}
                    rawData={rawData}
                    rawDataJson={rawDataJson}
                    owner={rowData}
                    summarySettings={summarySettings}
                    enableExpansion={false}
                    currentTheme={currentTheme}
                    frequencyStr={frequencyStr}
                    anomalyFeatureStr={anomalyFeatureStr}
                    clearStyle
                    noExpand
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    );

    if (generateFromGenericRule) {
      content = (
        <div className="flex-row flex-wrap">
          {category === 'log' && (
            <>
              {count && count > 1 && (
                <div className="flex-row flex-center-align" style={{ color: 'var(--blue)' }}>
                  <span style={{ marginRight: 4 }}>{intl.formatMessage(appFieldsMessages.count)}:</span>
                  <span style={{ wordBreak: 'break-all' }}>{count}</span>
                </div>
              )}
              {anomalyWords && anomalyWords.length > 0 && (
                <LogRenderers.RenderAnomalyWords
                  style={{ wordBreak: 'break-all', marginBottom: 12 }}
                  anomalyWordList={anomalyWords}
                />
              )}
              {outlierValue && !R.isEmpty(outlierValue) && (
                <LogRenderers.RenderOutlierValue
                  style={{ wordBreak: 'break-all', marginBottom: 12 }}
                  outlierValue={outlierValue}
                />
              )}
              {!R.isEmpty(frequencyStr) && (
                <div style={{ color: 'var(--blue)', wordBreak: 'break-all' }}>{frequencyStr}</div>
              )}
              {anomalyFeatureStr && (
                <div className="max-width flex-col" style={{ wordBreak: 'break-all', color: 'var(--blue)' }}>
                  {R.addIndex(R.map)((item, index) => {
                    const [label, values] = item;
                    const content = R.addIndex(R.map)(
                      ({ value }, i) => (
                        <span style={{ margin: '0 4px' }} key={`featureStr${index}${i}`}>
                          {value}
                          {values.length - 1 === i ? '' : ','}
                        </span>
                      ),
                      values,
                    );
                    return (
                      <div className="flex-row" key={`${index}+${label}`}>
                        <div style={{ whiteSpace: 'nowrap' }}>{label} :</div>
                        <div className="flex-row flex-wrap">{content}</div>
                      </div>
                    );
                  }, anomalyFeatureStr)}
                </div>
              )}
            </>
          )}
          {R.addIndex(R.map)((event, index) => {
            const { sourceDetail } = event || {};
            const { type, content } = sourceDetail || {};
            const needAnd = index !== predictionSourceInfoList.length - 1;
            let rawDataJson;
            try {
              rawDataJson = JSON.parse(content);
            } catch (error) {
              // console.debug(error)
            }
            return (
              <div className={`flex-row ${rawDataJson ? 'full-width' : ''}`}>
                {type === 'Metric' &&
                  EventRenderers.RenderMetricAnomalySummary({
                    intl,
                    event: sourceDetail || {},
                    index,
                    hideAvg: isCompositeAnomaly,
                    needAnd,
                  })}
                {type !== 'Metric' && (
                  <>
                    {!rawDataJson && (
                      <div style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
                        {R.join(
                          '\n',
                          R.filter((x) => Boolean(x), (content || '').split('\n')),
                        )}
                        {needAnd && <span style={{ margin: '0px 4px' }}>and</span>}
                      </div>
                    )}
                    {rawDataJson && (
                      <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align flex-wrap">
                        <LogRenderers.RenderLogContent
                          intl={intl}
                          rawData={content}
                          rawDataJson={rawDataJson}
                          owner={sourceDetail}
                          summarySettings={summarySettings}
                          enableExpansion={false}
                          currentTheme={currentTheme}
                          clearStyle
                          noExpand
                        />
                        {needAnd && <span style={{ margin: '0px 4px' }}>and</span>}
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          }, predictionSourceInfoList || [])}
        </div>
      );
    }

    return (
      <Popover
        placement="right"
        content={
          <div className="overflow-y-auto overflow-x-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
            {content}
          </div>
        }
        trigger={['click', 'hover']}
      >
        <div className="flex-row flex-center-align" style={{ minHeight: 20 }}>
          <InfoCircleOutlined className="primary-color" style={{ marginLeft: 2 }} />
        </div>
      </Popover>
    );
  }

  @autobind
  rendererContent(rowData, flag = true) {
    const { intl, credentials, currentTheme, activeKey } = this.props;
    const { category, rawData, projectOwner, frequencyStr, anomalyFeatureStr } = rowData;
    const { count, projectCouldType, patternId, isCompositeAnomaly } = rowData;
    const { generateFromGenericRule, predictionSourceInfoList } = rowData;
    let { projectName } = rowData;

    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const summarySettings = [];

    const rootCauseDetailsArr = get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    let content;
    let rawDataJson;
    if (category === 'metric') {
      const summaryList = R.map((event) => {
        return EventRenderers.BuildMetricAnomalySummary({ event, hideAvg: isCompositeAnomaly });
      }, rootCauseDetailsArr);
      content = R.join('\n', summaryList);
    } else {
      try {
        rawDataJson = JSON.parse(rawData);
        if (rawDataJson) {
          R.forEach((s) => {
            const v = get(rawDataJson, s.replaceAll('->', '.'));
            if (v) summarySettings.push(s);
          }, this.summarySettingsMap[projectName] || []);
        }
      } catch (error) {
        // console.debug(error)
      }
    }

    let compositeSourceContent;
    if (generateFromGenericRule) {
      const summaryList = R.map((event) => {
        const { sourceDetail } = event || {};
        const { type, content } = sourceDetail || {};
        if (type === 'Metric') {
          return EventRenderers.BuildMetricAnomalySummary({
            event: sourceDetail || {},
            hideAvg: isCompositeAnomaly,
          });
        } else {
          return content;
        }
      }, predictionSourceInfoList || []);
      compositeSourceContent = R.join(' and ', summaryList);
    }

    return (
      <div className="max-width flex-row hover-display flex-center-align" style={{ height: '100%' }}>
        {projectCouldType !== 'LogToMetric' && category === 'log' && (
          <Tooltip title="Log anomalies" mouseEnterDelay={0.3} placement="top">
            <Button
              size="small"
              disabled={category !== 'log'}
              icon={<Icon component={BarChartIcon} style={{ fontSize: 20, color: 'var(--icon-color)' }} />}
              style={{
                border: 'none',
                backgroundColor: 'rgba(0,0,0,0)',
                marginRight: 2,
                flexShrink: 0,
              }}
              onClick={() => {
                if (activeKey === 'incident') {
                  this.handleLogJumpClick({ event: rowData });
                } else {
                  this.handleJumpClick({ event: rowData, isPredicted: false, patternSearch: patternId });
                }
              }}
            />
          </Tooltip>
        )}
        {projectCouldType === 'LogToMetric' && (
          <Tooltip title={intl.formatMessage(eventMessages.logToMetricContext)} mouseEnterDelay={0.3} placement="top">
            <Button
              size="small"
              disabled={false}
              icon={<UnorderedListOutlined style={{ fontSize: 16 }} />}
              style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)', marginRight: 2, flexShrink: 0 }}
              onClick={() => {
                this.setState({ showLogToMetricModal: true, activeEvent: rowData });
              }}
            />
          </Tooltip>
        )}
        <div
          className={`${
            flag ? 'hidden-line-with-ellipsis' : 'hidden-line-with-ellipsis-multiline'
          } flex-col flex-center-justify`}
        >
          {generateFromGenericRule && <div className="hidden-line-with-ellipsis">{compositeSourceContent}</div>}
          {!generateFromGenericRule && (
            <>
              {category === 'metric' ? (
                <div className="hidden-line-with-ellipsis">{content}</div>
              ) : (
                <>
                  {count && count > 1 && false && (
                    <div className="flex-row flex-center-align" style={{ color: 'var(--blue)' }}>
                      <span style={{ marginRight: 4 }}>{intl.formatMessage(appFieldsMessages.count)}:</span>
                      <span className="hidden-line-with-ellipsis">{count}</span>
                    </div>
                  )}
                  {!R.isEmpty(frequencyStr) && !rawDataJson && false && (
                    <div style={{ color: 'var(--blue)' }} className="hidden-line-with-ellipsis">
                      {frequencyStr}
                    </div>
                  )}
                  {!rawDataJson && anomalyFeatureStr && false && (
                    <div style={{ color: 'var(--blue)' }} className="hidden-line-with-ellipsis">
                      {R.addIndex(R.map)((item, index) => {
                        const [label] = item;
                        const values = R.addIndex(R.map)(
                          (v, i) => (
                            <span style={{ margin: '0 4px' }}>
                              {v.value}
                              {item[1].length - 1 === i ? '' : ','}
                            </span>
                          ),
                          item[1],
                        );
                        return (
                          <span key={label + index}>
                            {label}:{values}
                          </span>
                        );
                      }, anomalyFeatureStr)}
                    </div>
                  )}
                  {!rawDataJson && <div className="hidden-line-with-ellipsis">{rawData}</div>}
                  {rawDataJson && (
                    <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                      <LogRenderers.RenderLogContent
                        intl={intl}
                        rawData={rawData}
                        rawDataJson={rawDataJson}
                        owner={rowData}
                        summarySettings={summarySettings || []}
                        enableExpansion={false}
                        currentTheme={currentTheme}
                        clearStyle
                        infoRender={this.shortDescriptionInfo(rowData)}
                        takeNum={2}
                        inLine
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {(!rawDataJson || (summarySettings.length === 0 && rawDataJson) || generateFromGenericRule) &&
          this.shortDescriptionInfo(rowData)}
      </div>
    );
  }

  @autobind
  rendererAnomalyScore(rowData) {
    const { anomalyRatio } = rowData;
    return <div>{numeral(anomalyRatio).format(anomalyRatio > 1 ? '0,0' : '0,0.00')}</div>;
  }

  @autobind
  rendererRC(rowData) {
    const { intl } = this.props;
    const { hasRootCause } = rowData;
    if (!hasRootCause) return <span style={{ paddingLeft: 10 }}>--</span>;

    return (
      <Button size="small" type="link" onClick={() => this.handleRCClick({ event: rowData, needRC: true })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <SearchOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  rendererPT(rowData) {
    const { intl } = this.props;
    const { hasTrailing } = rowData;
    if (!hasTrailing) return <span style={{ paddingLeft: 10 }}>--</span>;

    return (
      <Button size="small" type="link" onClick={() => this.handleRCClick({ event: rowData, needPT: true })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <SearchOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  rendererHistoricalIncients(rowData) {
    const { intl } = this.props;
    const { hasHistoricalIncidents } = rowData;
    if (!hasHistoricalIncidents) return <span style={{ paddingLeft: 10 }}>--</span>;

    return (
      <Button size="small" type="link" onClick={() => this.handlePastIncidentRCClick({ event: rowData })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <SearchOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  rendererRecommendations(rowData) {
    const { intl } = this.props;
    return (
      <Button size="small" type="link" onClick={() => this.handleRecommendationsClick({ event: rowData })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <SearchOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  rendererTriggeredActions(rowData) {
    const { intl } = this.props;
    const { triggeredActionList } = rowData;
    if (!triggeredActionList || triggeredActionList.length === 0) return <span style={{ paddingLeft: 10 }}>--</span>;

    return (
      <Button size="small" type="link" onClick={() => this.handleTriggeredActionsClick({ event: rowData })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <SearchOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  rendererTriageReport(rowData) {
    const { intl } = this.props;
    return (
      <Button size="small" type="link" onClick={() => this.handleTriageReportClick({ event: rowData })}>
        {intl.formatMessage(appFieldsMessages.details)}
        <EditOutlined className="primary-color" style={{ marginLeft: 2 }} />
      </Button>
    );
  }

  @autobind
  statusCategoryRenderer(rowData) {
    const { intl, tabName } = this.props;
    const {
      category,

      isImportant,
      isIgnored,
      isRCAFlag,
      isNewAlert,
      isIncident,
      isFixedIncident,
      leadToIncident,
      causedByChange,
      typeList,
      hasRootCause,
      hasTrailing,
    } = rowData;

    return (
      <div className="flex-row flex-wrap full-width">
        {hasRootCause && (
          <Tooltip title={intl.formatMessage(eventMessages.likelyRootCauses)} mouseEnterDelay={0.3} placement="top">
            <SearchOutlined style={{ fontSize: 14, margin: '0 4px 2px 0' }} />
          </Tooltip>
        )}
        {tabName !== 'incident' && hasTrailing && (
          <Tooltip title={intl.formatMessage(queryFieldMessages.trailingEvents)} mouseEnterDelay={0.3} placement="top">
            <IncidentPredictIcon style={{ fontSize: 14, margin: '0 4px 2px 0' }} />
          </Tooltip>
        )}
        <Tooltip title={intl.formatMessage(eventActionMessages.triageReport)} mouseEnterDelay={0.3} placement="top">
          <ReadOutlined style={{ fontSize: 14, margin: '0 4px 2px 0' }} />
        </Tooltip>
        {isImportant ? (
          <GoodIcon style={{ fontSize: 14, margin: '0 4px 2px 0', color: '#32C880' }} />
        ) : (
          <GoodOutlinedIcon style={{ fontSize: 14, margin: '0 4px 2px 0' }} />
        )}
        {isIgnored ? (
          <EyeInvisibleOutlined style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }} />
        ) : (
          <EyeOutlined style={{ fontSize: 14, margin: '0 4px 2px 0' }} />
        )}
        {!isIncident &&
          (isRCAFlag ? (
            <RootCauseIcon style={{ fontSize: 14, margin: '0 4px 2px 0', color: '#32C880' }} />
          ) : (
            <RootCauseIcon style={{ fontSize: 14, margin: '0 4px 2px 0' }} />
          ))}
        {isFixedIncident && (
          <Tooltip title="Is fixed incident" mouseEnterDelay={0.3}>
            <FixedIcon style={{ color: '#ff5142', fontSize: 14, marginRight: 4 }} />
          </Tooltip>
        )}
        {leadToIncident && (
          <ExclamationCircleOutlined style={{ fontSize: 14, margin: '0 4px 2px 0', color: '#FF5142' }} />
        )}
        {causedByChange && <ChangeEventIcon style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'orange' }} />}
        {isNewAlert && <FlagNewIcon style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }} />}
        {category === 'log' &&
          !isIncident &&
          R.map((type) => {
            return CellRenderers.logShortTypeRenderer({ intl, type });
          }, typeList)}
      </div>
    );
  }

  @autobind
  async RenderIncidentRCATooltipContent(intl, credentials, incident) {
    const {
      projectOwner,
      anomalyLogInstance,
      instanceList,
      startTimestamp,
      endTimestamp,
      rootCauseTableKey,
      patternId,
      type,
      category,
    } = incident;
    let { projectName } = incident;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
    const event = {
      nid: patternId,
      eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
    };
    const { logRootCauseEvents, metricRootCauseEvents } = await fetchPost(getEndpoint('logCausalInfoServlet', 1), {
      ...credentials,
      projectName,
      instanceName: anomalyLogInstance || instanceList[0],
      startTime,
      endTime,
      operation: 'rootCauseEvents',
      rootCauseTableKey: JSON.stringify(rootCauseTableKey),
      event: JSON.stringify(event),
    })
      .then((data) => {
        return data || {};
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isPopoverLoading: false });
      });
    return { logRootCauseEvents, metricRootCauseEvents };
  }

  @autobind
  async RenderIncidentNotesToolTipContent(intl, credentials, environmentId, systemId, incident) {
    const {
      anomalyLogInstance,
      instanceList,
      patternId,
      projectOwner,
      projectName,
      instanceName,
      incidentTimestamp,
      isIncident,
    } = incident;
    const request = [];
    request.push(
      fetchGet(getEndpoint('logpatterntriage'), {
        ...credentials,
        customerName: projectOwner,
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        patternId,
      }),
    );
    if (isIncident) {
      request.push(
        fetchGet(getEndpoint('get-teams'), {
          ...credentials,
          environmentName: environmentId,
          systemName: systemId,
          customerName: projectOwner,
          projectName,
          instanceName,
          incidentTimestamp,
        }),
      );
      request.push(
        fetchGet(getEndpoint('get-slack'), {
          ...credentials,
          environmentName: environmentId,
          systemName: systemId,
          customerName: projectOwner,
          projectName,
          instanceName,
          incidentTimestamp,
        }),
      );
    }
    const { newTriageReportList, newRresponseItems, newResponseItems } = await Promise.all(request)
      .then(async (data) => {
        let newTriageReportList = [];
        let newRresponseItems = [];
        let newResponseItems = [];
        if (data[0]) {
          const summaryNotesData = data[0];
          let triageReportList = [];
          triageReportList = parseJSON(get(summaryNotesData, 'triageReportList')) || [];
          triageReportList = R.map((item) => {
            const { instanceName, triageReport } = item;
            const { timestamp, fieldName, report, triageReporterManagement, reportSource } = triageReport;
            const key = `${instanceName}-${timestamp}-${fieldName}-${report}`;

            let reporterRecordSet = [];
            const { reporterMap } = triageReporterManagement || {};
            R.forEachObjIndexed((val, action) => {
              const { reporterDetailMap } = val || {};
              R.forEach((item) => {
                const { reporterName, reportTimestampSet } = item;
                R.forEach((timestamp) => {
                  reporterRecordSet.push({
                    timestamp,
                    reporterName,
                    action,
                  });
                }, reportTimestampSet || []);
              }, R.values(reporterDetailMap || {}));
            }, reporterMap);
            reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))], reporterRecordSet);

            return {
              instanceName,
              key,
              timestamp,
              fieldName,
              report,
              triageReporterManagement,
              reporterRecordSet,
              reportSource,
            };
          }, triageReportList);
          triageReportList = R.sortWith([R.descend(R.prop('timestamp'))], triageReportList);
          newTriageReportList = triageReportList;
        }
        if (data[1]) {
          const { success } = data[1] || {};
          if (success) {
            await fetchGet(getEndpoint('summarize-teams'), {
              ...credentials,
              environmentName: environmentId,
              systemName: systemId,
              customerName: projectOwner,
              projectName,
              instanceName,
              incidentTimestamp,
              amount: 'full',
            })
              .then((data) => {
                const { success, responseItems } = data || {};
                if (success) {
                  newRresponseItems = responseItems || [];
                }
              })
              .catch((err) => {
                message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
                this.setState({ isPopoverLoading: false });
              });
          }
        }
        if (data[2]) {
          const { success } = data[2] || {};
          if (success) {
            await fetchGet(getEndpoint('summarize-slack'), {
              ...credentials,
              environmentName: environmentId,
              systemName: systemId,
              customerName: projectOwner,
              projectName,
              instanceName,
              incidentTimestamp,
              amount: 'full',
            })
              .then((data) => {
                const { success, responseItems } = data || {};
                if (success) {
                  newResponseItems = responseItems || [];
                }
              })
              .catch((err) => {
                message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
                this.setState({ isPopoverLoading: false });
              });
          }
        }
        return { newTriageReportList, newRresponseItems, newResponseItems };
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isPopoverLoading: false });
      });
    return { newTriageReportList, newRresponseItems, newResponseItems };
  }

  @autobind
  controlRenderer(rowData, rowIndex, rootEvent, tabName, dateIdx) {
    const { intl, location, credentials, updateLastActionInfo, activeKey, isReadUser } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const { isPopoverLoading, hoverNotesObj } = this.state;
    const {
      category,
      patternId,
      isIncident,
      hasHistoricalIncidents,
      triggeredActionList,
      isRCAFlag,
      instanceName,
      hasTrailing,
      isCompositeAnomaly,
    } = rowData;
    rowIndex = R.findIndex(R.propEq('id', rowData.id))(rootEvent.children || []);
    return (
      <Dropdown
        name={intl.formatMessage(eventMessages.actions)}
        align={{ offset: [80, 0] }}
        onOpenChange={(actionVisibleState) => this.setState({ actionVisibleState })}
        itemClick={({ key }) => {
          switch (key) {
            case 'patternConfig':
              this.handlePatternConfigClick({ event: rowData });
              break;
            case 'setPatternName':
              this.handleChangePatternNameClick({ event: rowData });
              break;
            case 'lineChart':
              this.handleLineChartClick({ event: rowData });
              break;
            case 'lineChartForLog':
              this.handleLineChartJump({ event: rowData });
              break;
            case 'details':
              this.handleJumpClick({
                event: rowData,
                isPredicted: false,
                patternSearch: patternId,
              });
              break;
            case 'context':
              this.handleLogContextClick({ event: rowData });
              break;
            case 'patternTrend':
              this.handleTrendPatternsClick({ event: rowData });
              break;
            case 'softwareUpdateContext':
              this.handleSoftwareUpdateContextClick({ event: rowData });
              break;
            case 'historicalIncients':
              this.handlePastIncidentRCClick({ event: rowData });
              break;
            case 'recommendations':
              this.handleRecommendationsClick({ event: rowData });
              break;
            case 'triggeredActions':
              this.handleTriggeredActionsClick({ event: rowData });
              break;
            case 'sendEmailAlert':
              this.handleMailAlertClick({ event: rowData });
              break;
            case 'escalateIncident':
              this.handleEscalateIncidentClick({ event: rowData, rowIndex, rootEvent });
              break;
            case 'createChannelOnTeams':
              EventActionHandle.handleCreateChannelOnTeamsClick({
                intl,
                credentials,
                updateLastActionInfo,
                environmentId,
                systemId,
                event: rowData,
                onReload: this.refresh,
              });
              break;
            case 'createChannelOnSlack':
              EventActionHandle.handleCreateChannelOnSlackClick({
                intl,
                credentials,
                updateLastActionInfo,
                environmentId,
                systemId,
                event: rowData,
                onReload: this.refresh,
              });
              break;
            case 'reportJira':
              this.handleReportJiraClick({ event: rowData });
              break;
            case 'reportServiceNow':
              this.handleReportServiceNowClick({ event: rowData });
              break;
            case 'rerunRCA':
              this.handleRerunRCAClick({ event: rowData });
              break;
            case 'deleteIncident':
              Modal.confirm({
                title: <div>{intl.formatMessage(appMessages.continueConfirm)}</div>,
                icon: <ExclamationCircleOutlined />,
                content: null,
                okType: 'danger',
                onOk: (close) => {
                  return this.handleDeleteIncidentClick({ event: rowData });
                },
              });
              break;
            case 'setRunRCA':
              handleIgnoreClick({ event: rowData, category: 'rootcause', rowIndex, rootEvent, dateIdx, self: this });
              break;
            case 'configNotes':
              this.handleTriageReportClick({ event: rowData });
              break;
            case 'trailingEvents':
              this.handleRCClick({ event: rowData, needPT: true });
              break;
            default:
              break;
          }
        }}
        butStyle={{ width: 120 }}
      >
        <>
          {!isCompositeAnomaly && (
            <Menu.Item key="configNotes" icon={<FormOutlined />} disabled={isReadUser}>
              <Popover
                content={
                  <Spin spinning={isPopoverLoading}>
                    <div style={{ maxWidth: 300 }}>
                      <div className="flex-row">
                        <div className="light-label bold">
                          {intl.formatMessage(eventActionMessages.viewOperatorNotes)}
                        </div>
                      </div>
                      <Divider style={{ margin: '5px 0' }} />
                      <div className="flex-row flex-space-between">
                        <div className="light-label bold" style={{ marginRight: 10 }}>
                          {intl.formatMessage(eventActionMessages.summaryNotes)}
                        </div>
                        {R.has(rowData.patternId, hoverNotesObj) &&
                          R.filter(
                            (i) => i?.instanceName === instanceName && i?.reportSource !== 'ServiceNow',
                            hoverNotesObj[rowData.patternId].newTriageReportList || [],
                          ).length}
                      </div>
                      <div className="flex-row flex-space-between">
                        <div className="light-label bold" style={{ marginRight: 10 }}>
                          {intl.formatMessage(eventActionMessages.serviceNowNotes)}
                        </div>
                        {R.has(rowData.patternId, hoverNotesObj) &&
                          R.filter(
                            (i) => i?.instanceName === instanceName && i?.reportSource === 'ServiceNow',
                            hoverNotesObj[rowData.patternId].newTriageReportList || [],
                          ).length}
                      </div>
                      {isIncident && (
                        <>
                          <div className="flex-row flex-space-between">
                            <div className="light-label bold" style={{ marginRight: 10 }}>
                              {intl.formatMessage(eventActionMessages.microsoftTeams)}
                            </div>
                            {R.has(rowData.patternId, hoverNotesObj) &&
                              hoverNotesObj[rowData.patternId].newRresponseItems.length}
                          </div>
                          <div className="flex-row flex-space-between">
                            <div className="light-label bold" style={{ marginRight: 10 }}>
                              {intl.formatMessage(eventActionMessages.slackNotes)}
                            </div>
                            {R.has(rowData.patternId, hoverNotesObj) &&
                              hoverNotesObj[rowData.patternId].newResponseItems.length}
                          </div>
                        </>
                      )}
                    </div>
                  </Spin>
                }
                mouseEnterDelay={0.3}
                placement="left"
                onVisibleChange={async (visible) => {
                  if (visible) {
                    if (!R.has(rowData.patternId, hoverNotesObj)) {
                      this.setState({ isPopoverLoading: true });
                      const { newTriageReportList, newRresponseItems, newResponseItems } =
                        await this.RenderIncidentNotesToolTipContent(
                          intl,
                          credentials,
                          environmentId,
                          systemId,
                          rowData,
                        );
                      this.setState((prevState) => ({
                        hoverNotesObj: update(prevState.hoverNotesObj, {
                          [rowData.patternId]: {
                            $apply: () => ({
                              newTriageReportList,
                              newRresponseItems,
                              newResponseItems,
                            }),
                          },
                        }),
                        isPopoverLoading: update(prevState.isPopoverLoading, {
                          $set: false,
                        }),
                      }));
                    }
                  }
                }}
                zIndex={10001}
              >
                {intl.formatMessage(appFieldsMessages.Notes)}
              </Popover>
            </Menu.Item>
          )}
          {tabName !== 'incident' && !isCompositeAnomaly && (
            <Menu.Item key="trailingEvents" icon={<IncidentPredictIcon />} disabled={isReadUser || !hasTrailing}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(queryFieldMessages.trailingEvents)}
              </Popover>
            </Menu.Item>
          )}
          <Menu.Divider />

          {activeKey === 'metric' && (
            <Menu.Item key="patternConfig" icon={<SettingOutlined />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.investigationPatternConfig)}
              </Popover>
            </Menu.Item>
          )}
          {activeKey === 'incident' && (
            <Menu.Item key="patternConfig" icon={<SettingOutlined />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.investigationPatternConfig)}
              </Popover>
            </Menu.Item>
          )}
          {activeKey === 'logAnomaly' && (
            <Menu.Item key="patternConfig" icon={<SettingOutlined />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.investigationPatternConfig)}
              </Popover>
            </Menu.Item>
          )}
          {activeKey === 'logAlert' && (
            <Menu.Item key="patternConfig" icon={<SettingOutlined />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.investigationPatternConfig)}
              </Popover>
            </Menu.Item>
          )}
          {!isCompositeAnomaly && (
            <Menu.Item key="sendEmailAlert" icon={<MailOutlined />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.sendEmailAlert)}
              </Popover>
            </Menu.Item>
          )}

          {activeKey === 'metric' && !isCompositeAnomaly && (
            <Menu.Item key="escalateIncident" icon={<UploadOutlined />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.escalateIncident)}
              </Popover>
            </Menu.Item>
          )}
          {isIncident && !isCompositeAnomaly && (
            <Menu.Item key="createChannelOnTeams" icon={<TeamOutlined />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.createChannelOnTeams)}
              </Popover>
            </Menu.Item>
          )}
          {isIncident && !isCompositeAnomaly && (
            <Menu.Item key="createChannelOnSlack" icon={<SlackIcon />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.createChannelOnSlack)}
              </Popover>
            </Menu.Item>
          )}
          <Menu.Item key="reportJira" icon={<JiraIcon />} disabled={isReadUser}>
            <Popover
              content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
              zIndex={10001}
            >
              {intl.formatMessage(eventMessages.reportJira)}
            </Popover>
          </Menu.Item>
          <Menu.Item key="reportServiceNow" icon={<ServiceNowIcon />} disabled={isReadUser}>
            <Popover
              content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
              zIndex={10001}
            >
              {intl.formatMessage(eventMessages.reportServiceNow)}
            </Popover>
          </Menu.Item>

          {!isIncident && !isCompositeAnomaly && (
            <Menu.Item
              key="setRunRCA"
              icon={<RootCauseIcon style={{ color: isRCAFlag ? '#32C880' : '' }} />}
              disabled={isReadUser}
            >
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(isRCAFlag ? eventMessages.disableRunRCA : eventMessages.enableRunRCA)}
              </Popover>
            </Menu.Item>
          )}

          <Menu.Divider />
          {/* {category === 'metric' && (
            <Menu.Item key="lineChart" icon={<LineChartOutlined />}>
              {intl.formatMessage(eventMessages.lineChart)}
            </Menu.Item>
          )}
          {category === 'log' && hasMetricProject && (
            <Menu.Item key="lineChartForLog" icon={<LineChartOutlined />}>
              {intl.formatMessage(eventMessages.lineChart)}
            </Menu.Item>
          )}
          {category === 'log' && (
            <Menu.Item key="details" icon={<UnorderedListOutlined />}>
              {intl.formatMessage(appMenusMessages.logAnalysis)}
            </Menu.Item>
          )} */}
          {/* <Menu.Item key="setPatternName" icon={<EditOutlined />}>
            {intl.formatMessage(eventMessages.setPatternName)}
          </Menu.Item> */}
          {category === 'log' && !isCompositeAnomaly && (
            <Menu.Item key="patternTrend" icon={<TrendIcon />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.patternTrend)}
              </Popover>
            </Menu.Item>
          )}
          {!isCompositeAnomaly && (
            <Menu.Item key="context" icon={<UnorderedListOutlined />}>
              {intl.formatMessage(eventMessages.showContext)}
            </Menu.Item>
          )}
          {isIncident && hasHistoricalIncidents && !isCompositeAnomaly && false && (
            <Menu.Item key="historicalIncients" icon={<HistoryOutlined />}>
              {intl.formatMessage(eventMessages.historicalIncients)}
            </Menu.Item>
          )}
          {/* <Menu.Item key="recommendations" icon={<CommentOutlined />}>
            {intl.formatMessage(logMessages.recommendations)}
          </Menu.Item> */}
          {triggeredActionList && triggeredActionList.length > 0 && !isReadUser && (
            <Menu.Item key="triggeredActions" icon={<SavedActionIcon />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(logMessages.triggeredActions)}
              </Popover>
            </Menu.Item>
          )}

          {!isCompositeAnomaly && <Menu.Divider />}
          {!isCompositeAnomaly && (
            <Menu.Item key="rerunRCA" icon={<ApartmentOutlined />} disabled={isReadUser}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
                zIndex={10001}
              >
                {intl.formatMessage(eventMessages.rerunRCA)}
              </Popover>
            </Menu.Item>
          )}

          {/* {isIncident && (
            <Menu.Item key="deleteIncident" icon={<DeleteOutlined />}>
              {intl.formatMessage(eventMessages.deleteIncident)}
            </Menu.Item>
          )} */}
        </>
      </Dropdown>
    );
  }

  @autobind
  handleEscalateIncidentClick({ event, rowIndex, rootEvent }) {
    const { intl, location, globalInfo } = this.props;
    const { metricRootCause, projectName, isPrediction } = event;
    const { environmentId, systemId } = parseLocation(location);

    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    this.escalateIncidentModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <div className="flex-row" style={{ fontSize: 14 }}>
          {intl.formatMessage(eventMessages.escalateThisIncident)}
        </div>
      ),
      onOk: () => {
        if (this.escalateIncidentModal) {
          this.escalateIncidentModal.update({
            okButtonProps: { loading: true },
            cancelButtonProps: { disabled: true },
          });
        }
        this.props.updateLastActionInfo();
        fetchPost(getEndpoint('escalateMetricIncident'), {
          rootCause: metricRootCause,
          projectName,
          isPrediction,
          customerName: systemInfo.ownerUserName,
        })
          .then((res) => {
            message.success(intl.formatMessage(appMessages.apiSuccess));
            if (this.escalateIncidentModal) this.escalateIncidentModal.destroy();
            this.fatherEvent = rootEvent;
            this.childEvent = event;
            this.refresh();
          })
          .catch((err) => {
            message.error(intl.formatMessage(appMessages.apiFaild));
            if (this.escalateIncidentModal) {
              this.escalateIncidentModal.update({
                okButtonProps: { loading: false },
                cancelButtonProps: { disabled: false },
              });
            }
          });
      },
    });
  }

  @autobind
  handleRCClick({ event, needRC, needPT, handleCallBack }) {
    this.props.handleChangeRCAModal({
      activeEvent: { ...event, summarySettingsMap: this.summarySettingsMap },
      needRC,
      needPT,
      ...(handleCallBack ? { handleCallBack } : {}),
    });
  }

  @autobind
  handlePastIncidentRCClick({ event }) {
    this.setState({ showPastIncidentRCModal: true, activeEvent: event });
  }

  @autobind
  handleRecommendationsClick({ event }) {
    this.setState({ showRecommendationsModal: true, activeEvent: event });
  }

  @autobind
  handleTriageReportClick({ event }) {
    this.setState({ showTriageReportModal: true, activeEvent: event });
  }

  @autobind
  handleTriggeredActionsClick({ event }) {
    this.setState({ showTriggeredActionsModal: true, activeEvent: event });
  }

  @autobind
  async handleMailAlertClick({ event }) {
    const { intl, location, credentials, isAdmin, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const ownerUserName = get(systemInfo, 'ownerUserName');

    this.emailAlertModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: (
        <div className="flex-row flex-center-align flex-center-justify">
          <Spin spinning />
        </div>
      ),
      okButtonProps: { disabled: true },
      onOk: () => {
        if (this.emailAlertModal) this.emailAlertModal.destroy();
      },
    });

    const emailAddress = await fetchGet(getEndpoint('healthviewsetting', 2), {
      ...credentials,
      customerName: ownerUserName,
      environmentName: environmentId,
      shareUserFlag: ownerUserName !== credentials.userName && !isAdmin,
    })
      .then((data) => {
        const systemConfigs = [];
        R.forEachObjIndexed((system, systemId) => {
          const { environmentName } = system.key || {};
          systemConfigs.push({ ...system, systemId, id: systemId, environmentId: environmentName });
        }, data);
        const systemConfigInfo = get(data, systemId);
        return systemConfigInfo ? systemConfigInfo.alertEmail : '';
      })
      .catch((error) => {
        console.error('[IF_API] ', error);
        return '';
      });

    this.handleMailAlertModal(event, emailAddress);
  }

  @autobind
  handleMailAlertModal(event, emailAddress) {
    const { intl } = this.props;
    this.emailAddressList = R.map((email) => R.trim(email), R.split(',', emailAddress || ''));

    // handle content change
    const onChangeMailList = (e) => {
      const value = e.target.value || '';
      const hasErrorEmailAddress = !R.reduce(
        (a, b) => a && b,
        true,
        R.map((email) => Regex.email.test(R.trim(email)), value.split(',')),
      );
      this.emailAddressList = R.map((email) => R.trim(email), R.split(',', value));
      if (this.emailAlertModal) {
        this.emailAlertModal.update({ okButtonProps: { disabled: hasErrorEmailAddress } });
      }
    };

    this.emailAlertModal.update({
      content: (
        <div>
          <div>{`${intl.formatMessage(eventMessages.sendEmailToAddress)}:`}</div>
          <Input.TextArea rows={3} defaultValue={emailAddress} onChange={onChangeMailList} />
        </div>
      ),
      okButtonProps: { disabled: false },
      onOk: this.handleMailAlertSumbit(event),
    });
  }

  @autobind
  handleMailAlertSumbit(incident) {
    return () => {
      const { intl, location, credentials, userName } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const { projectName, projectOwner, startTimestamp, endTimestamp, type, instanceName, patternId } = incident;

      if (this.emailAlertModal) {
        this.emailAlertModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      this.props.updateLastActionInfo();
      return Promise.all([
        fetchPost(getEndpoint('globalalertemail', 2), {
          ...credentials,
          environmentName: environmentId,
          systemName: systemId,
          projectName,
          customerName: projectOwner || userName,
          startTime: startTimestamp,
          endTime: endTimestamp,
          type,
          instanceName,
          patternId,
          emailAddressList: JSON.stringify(this.emailAddressList),
          operation: 'Email',
        }),
      ])
        .then((results) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.emailAlertModal) this.emailAlertModal.destroy();
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.emailAlertModal) {
            this.emailAlertModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  handleLogJumpClick({ event }) {
    this.setState({
      showProjectLogSelector: true,
      onConfirmProjectLogSelect: this.handleLogJumpConfirm({ event }),
      activeEvent: event,
    });
  }

  @autobind
  handleLogJumpConfirm({ event }) {
    return (projectName, logProjects) => {
      const { anomalyLogInstance, instanceName, type: eventType, patternId, projectOwner } = event;

      let isAlert = false;
      let isIncident = false;
      if (projectName) {
        const findProject = R.find((item) => item.projectNameReal === projectName, logProjects || []);
        if (findProject) {
          isAlert = findProject.dataType === 'Alert';
          isIncident = findProject.dataType === 'Incident';
        }
      }

      const query = {
        projectName,
        instanceName: anomalyLogInstance || instanceName,
        startTime: moment.utc(event.startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(event.endTimestamp).format(Defaults.DateFormat),
        activeTab: eventType === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        isJump: true,
        customerName: projectOwner,
        anomalyType: eventType.toLowerCase(),
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    };
  }

  @autobind
  handleJumpClick({ isPredicted, patternSearch, event }) {
    const { isAdmin, isReadUser, isLocalAdmin, location, userName, projects } = this.props;
    const { customerName, startTime, endTime, environmentId } = parseLocation(location);
    const {
      category,
      projectOwner,
      user,
      anomalyLogInstance,
      instanceName,
      patternId,
      type: eventType,
      fromStorage,
    } = event;
    let { projectName } = event;
    const isSharedUser = (projectOwner || user) !== userName;
    if (isAdmin || isReadUser || isLocalAdmin || isSharedUser) {
      projectName = `${projectName}@${projectOwner || user || customerName}`;
    }
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);

    if (category !== 'log') {
      const query = {
        projectName,
        instanceGroup,
        startTime,
        endTime,

        patternSearch,
        statusFilterAll: isPredicted && fromStorage ? 'historicalPredictions' : 'all',
        customerName: projectOwner,
      };
      window.open(buildUrl(BaseUrls.MetricEvents, {}, query), '_blank');
    } else if (category === 'log') {
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const query = {
        projectName,
        instanceName: anomalyLogInstance || instanceName,
        startTime: moment.utc(event.startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(event.endTimestamp).format(Defaults.DateFormat),
        activeTab: eventType === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: projectOwner,
        anomalyType: eventType.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  handleLogContextClick({ event }) {
    const { startTimestamp } = event;
    const activeEvent = {
      ...event,
      instanceName: event.anomalyLogInstance || event.instanceName,
      timestamp: event.startTimestamp,
    };
    this.setState({
      showTimeSelectModal: true,
      activeEvent,
      selectInstance: activeEvent.instanceName,
      selectStartTimestamp: startTimestamp - 9 * 60 * 1000,
      selectEndTimestamp: startTimestamp + 60 * 1000,
    });
  }

  @autobind
  onCloseTimeSelect(props) {
    const { projectName, instanceName, startTimestamp, endTimestamp, keywordFilter } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState({
        showTimeSelectModal: false,
        showContextModal: true,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp: startTimestamp,
        selectEndTimestamp: endTimestamp,
        contextKeywordFilter: keywordFilter,
      });
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  handleCausalGraphClick({ event }) {
    const { location } = this.props;
    const { causalGraphInfo, rootCauseFilterCount } = this.state;
    const { startTime, endTime, causalKey, joinDependency, duration } = causalGraphInfo;
    const { customerName, environmentId } = parseLocation(location);
    const { projectName, instanceName, patternId, type, startTimestamp, endTimestamp } = event;
    const rootCauseDetailsArr = get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    const causalInstance =
      rootCauseDetailsArr && rootCauseDetailsArr.length > 0
        ? rootCauseDetailsArr[0].instanceId || rootCauseDetailsArr[0].instanceName
        : instanceName;
    const metric =
      rootCauseDetailsArr && rootCauseDetailsArr.length > 0
        ? rootCauseDetailsArr[0].rootCauseMetric || rootCauseDetailsArr[0].metricName
        : null;

    if (causalKey && startTime && endTime) {
      const query = {
        causalKey,
        customerName,
        causalGraphVersion: 'newCausal',
        tasksStartTime: startTime,
        tasksEndTime: endTime,
        joinDependency,
      };
      window.open(buildUrl(BaseUrls.CausalAnalysis, {}, query), '_blank');
    } else {
      const query = {
        customerName,
        environmentId,
        projectName,
        instanceName: causalInstance,
        startTimestamp: startTime || startTimestamp,
        endTimestamp: endTime || endTimestamp,
        needCausalProperty: true,
        nid: patternId,
        type,
        metric,
        causalGraphVersion: 'newCausal',
        duration,
        count: rootCauseFilterCount,
        probability: '0.0',
        joinDependency,
        showHaveDataGraph: true,
      };
      window.open(buildUrl(BaseUrls.CausalGroupAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleTrendPatternsClick({ event }) {
    const { isAdmin, isReadUser, isLocalAdmin, location, userName } = this.props;
    const { customerName, startTime, endTime } = parseLocation(location);
    const { projectOwner, user, anomalyLogInstance, instanceName, patternId } = event;
    let { projectName } = event;
    const isSharedUser = (projectOwner || user) !== userName;
    if (isAdmin || isReadUser || isLocalAdmin || isSharedUser) {
      projectName = `${projectName}@${projectOwner || user || customerName}`;
    }
    const startTimeObj = moment.utc(startTime, Defaults.DateFormat).subtract(7, 'days').startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');

    const query = {
      t: '953de6a33d8a4b96ac9c100bf69ba3fc',
      projectName,
      instanceName: JSON.stringify([anomalyLogInstance || instanceName]),
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      pattern: patternId,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
  }

  @autobind
  handleLineChartClick({ event }) {
    const { isAdmin, isReadUser, isLocalAdmin, location, userName } = this.props;
    const { customerName, environmentId, systemId } = parseLocation(location);
    const { projectOwner, metricList, instanceList } = event;
    let { projectName } = event;
    const isSharedUser = projectOwner !== userName;
    if (isAdmin || isReadUser || isLocalAdmin || isSharedUser) {
      projectName = `${projectName}@${projectOwner || customerName}`;
    }
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    const query = {
      startTime: moment.utc(event.startTimestamp).format(Defaults.DateFormat),
      endTime: moment.utc(event.endTimestamp).format(Defaults.DateFormat),
      customerName,
      environmentId,
      systemId,
      projectName,
      instanceGroup,
      modelType,
      startTimestamp: moment.utc(event.startTimestamp).startOf('day').valueOf(),
      endTimestamp: moment.utc(event.endTimestamp).endOf('day').valueOf(),
      justSelectMetric: R.join(',', metricList),
      justInstanceList: R.join(',', instanceList),
      withBaseline: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  getLogValidationBenchmarkMetric(incidentData, systemInfo) {
    if (!incidentData) {
      return Promise.resolve();
    }
    const { systemName, userName } = systemInfo?.systemKey || {};
    return fetchGet(getEndpoint('log-validation-benchmark-metric'), {
      ...this.props.credentials,
      incidentData: JSON.stringify(isArray(incidentData) ? incidentData : [incidentData] || []),
      systemName,
      userName,
    })
      .then((data) => {
        return isArray(incidentData) ? data || {} : (data || {})?.[incidentData];
      })
      .catch((error) => {
        return {};
      });
  }

  @autobind
  handleLineChartJump({ event }) {
    const { location, globalInfo, activeKey } = this.props;
    const { customerName, environmentId, systemId } = parseLocation(location);

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const metricProjects = R.filter((project) => project.dataType === 'Metric', projectNameSet);
    const { rawData } = event;

    this.getLogValidationBenchmarkMetric(rawData, systemInfo).then((info) => {
      const { metricName, projectName } = info || {};

      if (projectName) {
        const projectNameReal =
          R.find((project) => project.projectName === projectName, metricProjects)?.projectNameReal || projectName;
        this.handleLineChartsJumpConfirm({ event })(projectNameReal, metricName);
      } else if (metricProjects.length > 1) {
        this.setState({
          showProjectSelector: true,
          onConfirmProjectSelect: (projectName, params) => {
            this.handleLineChartsJumpConfirm({ event })(projectName, metricName, params);
          },
          activeEvent: event,
        });
      } else {
        const { projectNameReal } = metricProjects[0];
        const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
        let modelType = 'Holistic';
        if (instanceGroup !== 'All') modelType = 'splitByEnv';
        const query = {
          startTime: moment.utc(event.startTimestamp).format(Defaults.DateFormat),
          endTime: moment.utc(event.endTimestamp).format(Defaults.DateFormat),
          customerName,
          environmentId,
          systemId,
          projectName: projectNameReal,
          instanceGroup,
          modelType,
          startTimestamp: moment.utc(event.startTimestamp).startOf('day').valueOf(),
          endTimestamp: moment.utc(event.endTimestamp).endOf('day').valueOf(),
          justInstanceList: event.containerInfo
            ? `${event.containerInfo.containerName}_${event.containerInfo.instanceName}`
            : event.realInstanceName,
          withBaseline: true,
        };

        if (metricName) {
          query.justSelectMetric = metricName;
        }

        if (event.category === 'metric' && activeKey === 'incident') {
          query.justSelectMetric = R.join(',', event.metricList);
          query.justInstanceList = R.join(',', event.instanceList);
        }
        window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
      }
    });
  }

  @autobind
  handleLineChartJumpv2({ events }) {
    const { location, globalInfo } = this.props;
    const { environmentId, systemId } = parseLocation(location);

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    let rawDataArr = R.map((e) => e.rowData, events);
    rawDataArr = rawDataArr.filter((rawDataArr) => !!rawDataArr);

    this.getLogValidationBenchmarkMetric(rawDataArr.length > 0 ? rawDataArr : undefined, systemInfo).then((infos) => {
      const projectNameMetricMapping = {};
      R.forEach((info) => {
        const { metricName, projectName } = info || {};
        if (projectName) {
          projectNameMetricMapping[projectName] = metricName;
        }
      }, infos || []);
      this.setState({
        showProjectSelectorV2: true,
        activeEvent: events,
        onConfirmProjectSelect: (projectName, params) => {
          this.handleLineChartsJumpConfirmV2({ events })(projectName, projectNameMetricMapping, params);
        },
      });
    });
  }

  @autobind
  handleLineChartsJumpConfirm({ event }) {
    return (projectName, logMetric, params) => {
      const { location, activeKey } = this.props;
      const { customerName, environmentId, systemId } = parseLocation(location);
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';
      const query = {
        startTime: moment.utc(event.startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(event.endTimestamp).format(Defaults.DateFormat),
        customerName,
        environmentId,
        systemId,
        projectName,
        instanceGroup,
        modelType,
        startTimestamp: moment.utc(event.startTimestamp).startOf('day').valueOf(),
        endTimestamp: moment.utc(event.endTimestamp).endOf('day').valueOf(),
        justInstanceList: event.containerInfo
          ? `${event.containerInfo.containerName}_${event.containerInfo.instanceName}`
          : event.realInstanceName,
        withBaseline: true,
      };
      if (event.category === 'metric' && activeKey === 'incident') {
        query.justSelectMetric = R.join(',', event.metricList);
        query.justInstanceList = R.join(',', event.instanceList);
      }
      if (logMetric) {
        query.justSelectMetric = logMetric;
      }
      if (params) {
        const { ins, cms } = params || {};
        const cn = event?.containerInfo?.containerName;
        const findcn = R.find((item) => item === cn, cms || []);
        query.justInstanceList = `${findcn ? `${findcn}_` : ''}${ins}`;
      }

      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    };
  }

  @autobind
  handleLineChartsJumpConfirmV2({ events }) {
    return (projectName, logMetricMap, params) => {
      const { location } = this.props;
      const { customerName, environmentId, systemId } = parseLocation(location);
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';
      let startTimestamp = Number.MAX_SAFE_INTEGER;
      let endTimestamp = 0;
      R.forEach((e) => {
        if (e.startTimestamp < startTimestamp) {
          // eslint-disable-next-line prefer-destructuring
          startTimestamp = e.startTimestamp;
        }
        // eslint-disable-next-line no-self-compare
        if (e.endTimestamp > endTimestamp) {
          // eslint-disable-next-line prefer-destructuring
          endTimestamp = e.endTimestamp;
        }
      }, events);

      const query = {
        startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(endTimestamp).format(Defaults.DateFormat),
        customerName,
        environmentId,
        systemId,
        projectName,
        instanceGroup,
        modelType,
        startTimestamp: moment.utc(startTimestamp).startOf('day').valueOf(),
        endTimestamp: moment.utc(endTimestamp).endOf('day').valueOf(),
        justInstanceList: [],
      };
      if (logMetricMap && logMetricMap[projectName]) {
        query.justSelectMetric = R.join(',', logMetricMap[projectName]);
      }
      if (params) {
        const { ins, cms } = params || {};
        const instanceList = [];
        const metricList = [];
        R.forEach((event) => {
          const cn = event?.containerInfo?.containerName;
          const findcn = R.find((item) => item === cn, cms || []);
          R.forEach((i) => {
            instanceList.push(`${findcn ? `${findcn}_` : ''}${i}`);
            let instace1 = event?.realInstanceName || '';
            instace1 = R.includes('_', instace1) ? R.split('_', instace1)[1] : instace1;
            let instace2 = event?.instanceListStr || event?.sourceInstanceName || '';
            instace2 = R.includes('_', instace2) ? R.split('_', instace2)[1] : instace2;
            if (
              instace1 === i ||
              instace2 === i ||
              R.includes(i, instace1) ||
              R.includes(instace1, i) ||
              R.includes(i, instace2) ||
              R.includes(instace2, i)
            ) {
              metricList.push(...(event.metricList || []));
            }
          }, ins || []);
        }, events);
        if (instanceList.length > 0) {
          query.justInstanceList = R.join(',', R.uniq(instanceList));
        }
        if (metricList.length > 0) {
          query.justSelectMetric = R.join(',', R.uniq(metricList));
        }
        if (logMetricMap && logMetricMap[projectName]) {
          query.justSelectMetric = logMetricMap[projectName];
        }
      }
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    };
  }

  @autobind
  handleChangePatternNameClick({ event }) {
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const actionIncident = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    this.setState({ showTakeLogActionModal: true, actionName: 'setPatternName', actionIncident });
  }

  @autobind
  handleChangeComponentNameClick({ event }) {
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const actionIncident = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    this.setState({ showTakeLogActionModal: true, actionName: 'setComponentName', actionIncident });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    const { actionName, actionIncident = {} } = this.state;
    let newState = {};
    if (actionName === 'setPatternName') {
      newState = { patternIdFilter: undefined, resetPatternFilter: +new Date() };
      this.fatherEvent = {
        ...actionIncident,
        mergeKey: `${actionIncident.projectName}-${actionIncident.componentName}-${actionIncident.patternId}-${patternName}`,
      };
      this.childEvent = null;
      this.individualRootFatherEvent = this.fatherEvent;
      this.individualRootChildEvent = null;
    }

    this.setState(
      { showTakeLogActionModal: false, actionName: null, actionIncident: null, patternNameMap: {}, ...newState },
      () => {
        this.refresh();
      },
    );
  }

  @autobind
  handleSoftwareUpdateContextClick({ event }) {
    this.setState({ showSoftwareUpdateContextModal: true });
  }

  @autobind
  handleReportJiraClick({ event }) {
    this.setState({ showReportJiraModal: true, activeEvent: event });
  }

  @autobind
  handleReportServiceNowClick({ event }) {
    this.setState({ showReportServiceNowModal: true, activeEvent: event });
  }

  @autobind
  handleRerunRCAClick({ event }) {
    const { intl, location, credentials } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const {
      projectName,
      projectOwner,
      anomalyLogInstance,
      instanceName,
      startTimestamp,
      endTimestamp,
      patternId,
      type,
    } = event;

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('onDemandRCA'), {
      ...credentials,
      projectName,
      customerName: projectOwner,
      environmentName: environmentId,
      systemName: systemId,
      instanceName: anomalyLogInstance || instanceName,
      type,
      operation: 'preceding',
      startTime: startTimestamp,
      endTime: endTimestamp,
      patternId,
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${errMsg}`);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  handleDeleteIncidentClick({ event }) {
    const { intl, credentials, location, globalInfo } = this.props;
    const { systemId, environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const { instanceName, patternId, incidentTimestamp, startTimestamp } = event || {};
    const { projectName, projectOwner } = event;
    const inputrecords = [
      {
        projectName,
        instanceName,
        timestamp: incidentTimestamp || startTimestamp,
        patternId,
        customerName: projectOwner,
      },
    ];
    this.props.updateLastActionInfo();
    return fetchDelete(getEndpoint('incident'), {
      ...credentials,
      inputrecords: JSON.stringify(inputrecords),
      systemName: systemId,
      customerName: systemInfo.ownerUserName,
    })
      .then((data) => {
        const description = [];
        R.forEachObjIndexed((val, key) => {
          key = JSON.parse(key);
          const { projectName, timestamp } = key;
          const { msg, success } = val;
          if (!success) {
            description.push({ projectName, time: moment.utc(timestamp).format(Defaults.DateTimeFormat), msg });
          }
        }, data);
        if (R.isEmpty(description)) {
          this.refresh();
          message.success(intl.formatMessage(appMessages.apiSuccess));
        } else {
          notification.open({
            message: 'Failed to delete',
            description: (
              <div className="flex-col flex-center-justify">
                {R.map(
                  (item) => (
                    <div key={item.projectName + item.time}>
                      {item.time}
                      <span className="bold" style={{ marginLeft: 4 }}>
                        {item.projectName}
                      </span>
                      :{item.msg}
                    </div>
                  ),
                  description,
                )}
              </div>
            ),
          });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  handlePatternConfigClick({ event }) {
    this.setState({ showPatternConfigModel: true, activeEvent: event });
  }

  @autobind
  getProjectNameList() {
    const { location, systemsMap } = this.props;
    const { systemId } = parseLocation(location);
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  handleExport(close) {
    this.exportModal = Modal.confirm({
      title: 'Export all anomaly events as CSV?',
      content: null,
      closable: true,
      onOk: this.handleOkComfirmCSV,
    });
  }

  @autobind
  async handleOkComfirmCSV(close) {
    // start download data
    if (this.exportModal) {
      this.exportModal.update({
        okButtonProps: { loading: true },
        cancelButtonProps: { disabled: true },
      });
    }
    await sleep(300);

    const { location, credentials, userInfo, globalInfo, activeKey } = this.props;
    const { patternNameMap } = this.state;
    const { startTime, endTime, systemId, environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const systemName = systemInfo ? systemInfo.name : systemId;
    const fname = `${systemName} ${startTime}~${endTime} anomaly events.csv`;
    let csvString = `${R.join(',', [
      'Pattern type',
      'Is fixed',
      'Anomaly score',
      'Start time',
      'End time',
      'Pattern ID',
      'Pattern Name',
      'Project',
      'Component',
      'Instance',
      'Short description',
      'Log root cause chain top 1',
      'Log root cause chain top 2',
      'Log root cause chain top 3',
      'Metric root cause chain top 1',
      'Metric root cause chain top 2',
      'Metric root cause chain top 3',
    ])}\r\n`;

    const csvData = [];

    let eventListExport = [...(this.eventListExport || [])];

    const incidents = R.filter((item) => item.isIncident, eventListExport);
    const incidentsRootCauseMap = {};
    await Promise.all(
      R.map((item) => {
        // get incident pattern name
        const {
          patternId,
          anomalyLogInstance,
          category,
          projectOwner,
          type,
          instanceList,
          rootCauseTableKey,
          startTimestamp,
          endTimestamp,
        } = item;
        let { projectName } = item;
        projectName =
          userInfo.isAdmin || projectOwner !== userInfo.userName ? `${projectName}@${projectOwner}` : projectName;
        const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
        const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
        const event = {
          nid: patternId,
          eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
        };
        return fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          function: 'root cause',
          projectName,
          instanceName: anomalyLogInstance || instanceList[0],
          startTime,
          endTime,
          operation: 'rootCauseEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        });
      }, incidents),
    ).then((results) => {
      R.addIndex(R.forEach)((data, idx) => {
        const logRootCauseEvents = get(data, ['logRootCauseEvents'], []);
        const metricRootCauseEvents = get(data, ['metricRootCauseEvents'], []);
        incidentsRootCauseMap[incidents[idx].id] = {
          log: logRootCauseEvents,
          metric: metricRootCauseEvents,
        };
      }, results);
    });

    const getExportRootCauses = (rootCauseEvents) => {
      return R.map((rc) => {
        const rcList = [];
        const predictionSourceInfoList = get(rc, ['rootCauseChain', 'predictionSourceInfoList'], []);
        R.forEach((sourceInfo) => {
          const { type, sourceDetail, probability, count, eventTimestamp, componentName, metricInstanceName } =
            sourceInfo;
          const { nid, content, avgValue, percentage, metricDirection } = parseJSON(sourceDetail) || {};
          let data = '';
          if (type === 'Log') {
            data = content;
          } else {
            data = EventRenderers.BuildMetricAnomalySummary({
              event: { rootCauseMetric: content, metricValue: avgValue, pct: percentage, direction: metricDirection },
            });
          }
          rcList.push({
            sourceDetail: data,
            nid,
            probability,
            count,
            eventTimestamp: moment.utc(eventTimestamp).format(Defaults.DateTimeFormat),
            componentName,
            metricInstanceName,
          });
        }, predictionSourceInfoList);
        return rcList;
      }, rootCauseEvents || []);
    };

    eventListExport = R.sortWith(
      [R.ascend(R.prop('type')), R.descend(R.prop('patternId')), R.ascend(R.prop('startTimestamp'))],
      eventListExport,
    );
    R.forEach((item) => {
      // get incident pattern name
      const {
        id,
        isIncident,
        patternId,
        patternName,
        anomalyLogInstance,
        category,
        projectOwner,
        rawData,
        rootCausesDetailsList,
        outlierValue,
      } = item;
      let { projectName } = item;
      projectName =
        userInfo.isAdmin || projectOwner !== userInfo.userName ? `${projectName}@${projectOwner}` : projectName;
      const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
      const incidentPatternName = get(patternNameMap, [key, patternId]);
      const activePatternName = incidentPatternName !== undefined ? incidentPatternName : patternName;
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName: activePatternName, patternId },
        { hasPrefix: true, hasFullName: false },
      );

      let shortDescription = '';
      if (category === 'log') {
        try {
          const rawDataJson = JSON.parse(rawData);
          shortDescription = JSON.stringify(rawDataJson, null, 4);
        } catch (error) {
          shortDescription = R.join(
            '\n',
            R.filter((x) => Boolean(x), (rawData || '').split('\n')),
          );
        }
      } else {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, rootCausesDetailsList);
        shortDescription = R.join('\n', summaryList);
      }

      let logRC = [];
      let metricRC = [];
      if (isIncident) {
        const { log: logRootCauseEvents, metric: metricRootCauseEvents } = incidentsRootCauseMap[id] || {};
        logRC = getExportRootCauses(logRootCauseEvents || []);
        metricRC = getExportRootCauses(metricRootCauseEvents || []);
      }

      let logAnomalyShortDescription = '';
      if (activeKey === 'logAnomaly') {
        logAnomalyShortDescription += `count: ${item.count || 1} \n`;
        if (outlierValue && !R.isEmpty(outlierValue)) {
          let outlierValueStr = '';
          R.forEach(([word, value]) => {
            const valueStr = value;
            outlierValueStr += `${word} (${valueStr}) is higher than normal; `;
          }, R.toPairs(outlierValue || {}));
          logAnomalyShortDescription += `Outlier features: ${outlierValueStr}\n`;
        }
        logAnomalyShortDescription += `${shortDescription}`;
      } else {
        logAnomalyShortDescription += `${shortDescription}`;
      }

      csvData.push(
        R.join(',', [
          item.type,
          item.isFixedIncident ? 'true' : '',
          item.anomalyRatio,
          moment.utc(item.startTimestamp).format(Defaults.DateTimeFormat),
          moment.utc(item.endTimestamp).format(Defaults.DateTimeFormat),
          patternId,
          patternNameStr,
          item.projectName,
          item.componentListStr,
          item.instanceListStr,
          `"${R.replace(/"/g, '""', logAnomalyShortDescription)}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(logRC[0], null, 4) || '')}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(logRC[1], null, 4) || '')}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(logRC[2], null, 4) || '')}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(metricRC[0], null, 4) || '')}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(metricRC[1], null, 4) || '')}"`,
          `"${R.replace(/"/g, '""', JSON.stringify(metricRC[2], null, 4) || '')}"`,
        ]),
      );
    }, eventListExport);
    csvString += R.join('\r\n', csvData);

    // download csv file
    downloadFile(csvString, fname);

    // close confrim modal after
    close();
  }

  @autobind
  handleSystemIgnoredIncidentClick() {
    const { location } = this.props;
    const { customerName, startTime, endTime, environmentId, systemId } = parseLocation(location);
    const query = {
      customerName,
      startTime,
      endTime,
      environmentId,
      systemId,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemIgnoredIncident, {}, query), '_blank');
  }

  @autobind
  handleChartOption(eventList) {
    const { location, globalInfo } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime, environmentId, systemId } = query;

    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    const { allDayAnomalyList = [], filterTimestamps = [] } = this.chartTimeMap[`${startTime}-${endTime}`] || {};

    return getChartOption(this, eventList, allDayAnomalyList, filterTimestamps, instanceDisplayNameMap);
  }

  render() {
    const {
      tabName,
      isLoadingSystem,
      intl,
      location,
      userInfo,
      globalInfo,
      activeKey,
      activeKeyListFlag,
      onSearchBarShowChangeCb,
    } = this.props;
    const { credentials, isAdmin, userName, currentTheme, projects, hasKubernetes } = this.props;
    const { environmentId, systemId } = parseLocation(location);
    const {
      showFilterPanel,
      notificationCount,
      notValidSrcCount,
      validSrcCount,
      ignoredSrcCount,
      compositeAnomalyCount,
      actionComposite,
      individualIncident,
      componentFilter,
      instanceFilter,
      logicPodID,
      filterContainer,
    } = this.state;
    const { filterEventsTotal, patternNameMap, activeEvent, actionIncident, hoverNotesObj } = this.state;
    const { isLoaded, isLoading, isLoadingParserData, chartOption, patternIdFilter } = this.state;
    const {
      importantCount,
      likelyRootCauseCount,
      noRootCauseCount,
      fixedIncidentCount,
      triggeredCount,
      resetPatternFilter,
      hasTableData,
      recurrentIncidentCount,
    } = this.state;

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    let incidentPatternName = null;
    let projectName;
    if (activeEvent) {
      const { category, patternId, anomalyLogInstance, projectOwner } = activeEvent;
      projectName =
        userInfo.userName !== projectOwner ? `${activeEvent.projectName}@${projectOwner}` : activeEvent.projectName;
      const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;
      incidentPatternName = get(patternNameMap, [key, patternId]);
    }

    return (
      <div
        className={`full-height flex-row ${isLoading || isLoadingSystem ? 'loading ' : ''} ${
          isLoaded ? ' loaded' : ''
        }`}
        style={{ paddingTop: 8 }}
      >
        <Container className="flex-grow flex-min-height flex-col">
          <Spin
            spinning={isLoadingSystem || isLoading || isLoadingParserData || !activeKeyListFlag}
            wrapperClassName="full-width full-height spin-full-width"
          >
            <div className="full-width full-height flex-row">
              <div
                className="flex-col full-height"
                style={{
                  width: showFilterPanel ? 270 : 0,
                  padding: '8px 0 0 8px',
                  position: 'relative',
                  flexShrink: 0,
                }}
              >
                <Tooltip
                  title={showFilterPanel ? 'Close filters' : 'Show filters'}
                  mouseEnterDelay={0.3}
                  placement="top"
                >
                  <div
                    className="clickable"
                    style={{ position: 'absolute', right: -17, top: 5, fontSize: 18, color: 'gray', zIndex: 99 }}
                    onClick={() => {
                      this.setState({ showFilterPanel: !showFilterPanel });
                    }}
                  >
                    <RightSquareOutlined rotate={showFilterPanel ? 180 : 0} />
                  </div>
                </Tooltip>
                <Card
                  style={{ width: '100%', height: '100%', display: showFilterPanel ? 'unset' : 'none' }}
                  bodyStyle={{ height: '100%', padding: 8, overflowY: 'auto' }}
                >
                  <Container className="flex-col flex-min-height flex-min-width full-height">
                    <RootCauseRenderSearchBar
                      intl={intl}
                      location={location}
                      globalInfo={globalInfo}
                      activeKey={activeKey}
                      tabName={tabName}
                      filterEventsTotal={filterEventsTotal}
                      importantCount={importantCount}
                      recurrentIncidentCount={recurrentIncidentCount}
                      likelyRootCauseCount={likelyRootCauseCount}
                      noRootCauseCount={noRootCauseCount}
                      fixedIncidentCount={fixedIncidentCount}
                      triggeredCount={triggeredCount}
                      notificationCount={notificationCount}
                      notValidSrcCount={notValidSrcCount}
                      validSrcCount={validSrcCount}
                      ignoredSrcCount={ignoredSrcCount}
                      compositeAnomalyCount={compositeAnomalyCount}
                      patternIdFilterOptions={this.patternIdFilterOptions}
                      ignoredPatternIdFilterOptions={this.ignoredPatternIdFilterOptions}
                      projectListOptions={this.projectListOptions}
                      anomalyMetricOptions={this.anomalyMetricOptions}
                      resetPatternFilter={resetPatternFilter}
                      patternIdFilter={patternIdFilter}
                      incidentStatusCountMap={this.incidentStatusCountMap}
                      componentListOptions={this.componentListOptions}
                      instanceListOptions={this.instanceListOptions}
                      podListOptions={this.podListOptions}
                      containerListOptions={this.containerListOptions}
                      componentFilter={componentFilter}
                      instanceFilter={instanceFilter}
                      logicPodID={logicPodID}
                      filterContainer={filterContainer}
                      hasKubernetes={hasKubernetes}
                      onChange={({ type, value }) => {
                        if (type === 'keywordFilter') {
                          this.onChangeFilter(value, 'keywordSearch');
                        } else if (type === 'incidentStatus') {
                          this.onChangeFilter(value, 'incidentStatusFilter');
                        } else if (type === 'componentFilter') {
                          this.onChangeFilterComponet(value);
                        } else if (type === 'instanceFilter') {
                          this.onChangeFilterInstance(value);
                        } else if (type === 'podFilter') {
                          this.onChangeFilterPod(value);
                        } else if (type === 'containerFilter') {
                          this.onChangeFilterContainer(value);
                        } else {
                          this.onChangeFilter(value, type);
                        }
                      }}
                      showChangeCb={showFilterPanel ? onSearchBarShowChangeCb : () => ({ childCount: 0, headCount: 0 })}
                    />
                  </Container>
                </Card>
              </div>
              {!hasTableData && (
                <div className="flex-grow flex-row flex-center-align flex-center-justify content-bg">
                  <Empty
                    imageStyle={{ height: 200 }}
                    description={
                      <span className="flex-col">
                        <span style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 8 }}>No results found</span>
                        <span style={{ fontSize: 14 }}>Try adjusting the date range</span>
                      </span>
                    }
                  />
                </div>
              )}
              {hasTableData && (
                <div className="full-width full-height flex-col">
                  <div style={{ height: 130 }} className="log-entries-echart">
                    <AutoSizer>
                      {({ width, height }) => (
                        <EChart
                          setRef={(chart) => setChartRef(this.systemChartRef, systemId, chart)}
                          width={width}
                          height={height}
                          option={chartOption}
                          renderer="svg"
                          onEvents={{
                            click: onSystemChartClick(this, systemId),
                            datazoom: handleChartZoom(this, systemId),
                            restore: handleChartRestore(this, systemId),
                            rendered: handleChartFinish(this, systemId),
                          }}
                        />
                      )}
                    </AutoSizer>
                  </div>
                  {this.renderTopBar()}
                  {/* 布局在peersListItem */}
                  {tableViewList(false, this, intl, this.peersListItem)}
                </div>
              )}
            </div>
          </Spin>
        </Container>

        {this.state.showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={this.state.actionName}
            incident={actionIncident}
            incidentPatternName={incidentPatternName}
            projectName={actionIncident.fullProjectName}
            instanceGroup={environmentId}
            eventType={actionIncident.type}
            onClose={(reload) =>
              this.setState({ showTakeLogActionModal: false, actionName: null, actionIncident: null }, () => {
                if (reload) this.refresh();
              })
            }
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {this.state.showConfigPatternName && (
          <ConfigPatterName
            intl={intl}
            instanceGroup={environmentId}
            credentials={credentials}
            activeMerges={this.state.activeMerges}
            onClose={(reload) =>
              this.setState({ showConfigPatternName: false, activeMerges: [], patternIdFilter: null }, () => {
                if (reload) this.refresh();
              })
            }
          />
        )}
        {this.state.showActionModal && (
          <EventActionModal
            suggestActions={get(activeEvent, 'suggestActionList', [])}
            incident={activeEvent}
            projectName={projectName}
            projectNameList={this.getProjectNameList()}
            actionRootCauseKey={this.state.actionRootCauseKey}
            onClose={(needReload) => {
              this.setState({ showActionModal: false, activeEvent: null });
            }}
          />
        )}
        {this.state.showSoftwareUpdateContextModal && (
          <GlobalRenderers.SoftwareUpdateContextModal
            intl={intl}
            incident={activeEvent}
            onCancel={() => this.setState({ showSoftwareUpdateContextModal: false })}
          />
        )}
        {this.state.showProjectSelector && (
          <ProjectSelectorModal
            system={systemInfo}
            incident={activeEvent}
            activeKey={activeKey}
            onConfirm={this.state.onConfirmProjectSelect}
            onClose={() => this.setState({ showProjectSelector: false, activeEvent: null })}
          />
        )}
        {this.state.showProjectSelectorV2 && (
          <ProjectSelectorModalV2
            system={systemInfo}
            incidents={activeEvent}
            activeKey={activeKey}
            onConfirm={this.state.onConfirmProjectSelect}
            onClose={() => this.setState({ showProjectSelectorV2: false, activeEvent: null })}
          />
        )}
        {this.state.showProjectLogSelector && (
          <ProjectLogSelectorModal
            system={systemInfo}
            incident={activeEvent}
            activeKey={activeKey}
            onConfirm={this.state.onConfirmProjectLogSelect}
            onClose={() => this.setState({ showProjectLogSelector: false, activeEvent: null })}
          />
        )}
        {this.state.showTimeSelectModal && (
          <TimeSelectModal
            projectName={projectName}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
            timeIntervals={1}
            showKeywordSearch
          />
        )}
        {this.state.showContextModal && (
          <EventContextModal
            incident={activeEvent}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            keywordFilter={this.state.contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
            useTimeRange
          />
        )}
        {this.state.showReportJiraModal && (
          <ReportJiraModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showReportJiraModal: false })}
          />
        )}
        {this.state.showReportServiceNowModal && (
          <ReportServiceNowModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showReportServiceNowModal: false })}
          />
        )}
        {this.state.showPatternConfigModel && (
          <PatternConfigModal
            title={intl.formatMessage(eventMessages.investigationPatternConfig)}
            intl={intl}
            incident={activeEvent}
            instanceName={this.state.selectInstance}
            activeKey={activeKey}
            projectName={projectName}
            onClose={() => this.setState({ showPatternConfigModel: false })}
          />
        )}
        {this.state.showIndividualIncidentsModal && (
          <IndividualIncidentsModal
            activeKey={activeKey}
            tabName={tabName}
            incident={actionComposite}
            individualIncident={individualIncident}
            refresh={this.refresh}
            summarySettingsMap={this.summarySettingsMap}
            refreshLoading={isLoadingSystem || isLoading || isLoadingParserData || !activeKeyListFlag}
            controlRenderer={this.controlRenderer}
            rootCauseChainRender={this.rootCauseChainRender}
            getMappingProjectInfo={this.getMappingProjectInfo}
            getPodAndHostData={this.getPodAndHostData}
            fatherInstanceRenderer={this.fatherInstanceRenderer}
            impactInstanceRenderer={this.impactInstanceRenderer}
            handleLineChartJump={this.handleLineChartJumpv2}
            rendererContent={this.rendererContent}
            onClose={() => {
              this.fatherEvent = this.individualRootFatherEvent;
              this.childEvent = this.individualRootChildEvent;
              this.individualRootFatherEvent = null;
              this.individualRootChildEvent = null;
              this.setState({ showIndividualIncidentsModal: false, actionComposite: null, individualIncident: null });
            }}
            onChangeIndividualIncident={() => this.setState({ individualIncident: null })}
          />
        )}

        {/* General modals */}
        {this.state.showRCModal && (
          <LikelyRootCausesModal
            incident={activeEvent}
            environmentId={environmentId}
            systemId={systemId}
            projectName={projectName}
            functionRC="root cause"
            needRC={this.state.needRC}
            needPT={this.state.needPT}
            onClose={() => this.setState({ showRCModal: false, activeEvent: null, needRC: false, needPT: false })}
          />
        )}
        {this.state.showRecommendationsModal && (
          <RecommendationsModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showRecommendationsModal: false, activeEvent: null })}
          />
        )}
        {this.state.showTriageReportModal && (
          <TriageReportModal
            tabName={tabName}
            environmentId={environmentId}
            systemId={systemId}
            incident={activeEvent}
            projectName={projectName}
            onClose={async (flag) => {
              if (flag) {
                this.setState({
                  hoverNotesObj: R.omit([`${activeEvent.patternId}`])(hoverNotesObj),
                });
              }
              this.setState({ showTriageReportModal: false, activeEvent: null });
            }}
          />
        )}
        {this.state.showTriggeredActionsModal && (
          <TriggeredActionsModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => this.setState({ showTriggeredActionsModal: false, activeEvent: null })}
            onReload={this.refresh}
          />
        )}
        {this.state.showPastIncidentRCModal && (
          <PastIncidentRCModal
            environmentId={environmentId}
            systemId={systemId}
            incident={activeEvent}
            onClose={() => this.setState({ showPastIncidentRCModal: false, activeEvent: null })}
          />
        )}
        {this.state.showCompareIncidentsModal && (
          <CompareIncidentsModal
            environmentId={environmentId}
            systemId={systemId}
            compareIncidents={this.state.compareIncidents}
            onClose={() => this.setState({ showCompareIncidentsModal: false, compareIncidents: [] })}
          />
        )}
        {this.state.showNewCompareIncidentsModal && (
          <CompareIncidentsNewModal
            environmentId={environmentId}
            systemId={systemId}
            summarySettingsMap={this.summarySettingsMap}
            compareIncidents={this.state.compareIncidents}
            onClose={() => this.setState({ showNewCompareIncidentsModal: false, compareIncidents: [] })}
          />
        )}
        {this.state.showCompareCommonLogsModal && (
          <CompareCommonLogsModal
            environmentId={environmentId}
            systemId={systemId}
            compareIncidents={this.state.compareIncidents}
            onClose={() => this.setState({ showCompareCommonLogsModal: false, compareIncidents: [] })}
          />
        )}
        {this.state.showLogToMetricModal && (
          <LogToMetricContext
            intl={intl}
            rowData={activeEvent}
            credentials={credentials}
            isAdmin={isAdmin}
            userName={userName}
            currentTheme={currentTheme}
            summarySettingsMap={this.summarySettingsMap}
            projects={projects}
            location={location}
            globalInfo={globalInfo}
            onClose={() => this.setState({ showLogToMetricModal: false, activeEvent: null })}
          />
        )}
        {this.state.modalFlag && (
          <ModalRenderContent
            props={this.props}
            rowData={activeEvent}
            statusColorMap={this.statusColorMap}
            statusOptions={statusOptions}
            title={intl.formatMessage(appFieldsMessages.status)}
            onClose={(flag) => {
              this.setState({ modalFlag: false, activeEvent: null }, () => {
                if (flag) this.refresh();
              });
            }}
          />
        )}

        {this.state.showRecommendationsGPT && (
          <RecommendationsGPT
            incident={this.state.activeRecommendation}
            incidentType="Incident"
            title={intl.formatMessage(logMessages[LLMTitleMap[this.state.showGPTType]])}
            onCancel={() => this.setState({ showRecommendationsGPT: false, activeRecommendation: null })}
          />
        )}

        {this.state.showRecommendationModal && (
          <RecommendationIncidendModal
            environmentId={environmentId}
            systemId={systemId}
            incident={activeEvent}
            projectName={projectName}
            onClose={async (flag) => {
              this.setState({ showRecommendationModal: false, activeEvent: null });
            }}
          />
        )}
        {this.state.showCDFModal && (
          <CDFModal
            incident={activeEvent}
            projectName={projectName}
            onClose={() => {
              this.setState({ showCDFModal: false, activeEvent: null });
            }}
          />
        )}
      </div>
    );
  }
}

const RootCauseList = injectIntl(RootCauseListCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset, globalInfo, projects, projectDisplayMap, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isReadUser, isLocalAdmin, userName } = state.auth.userInfo;
    const { globalSystemTimelineInfo } = state.dashboard;
    const { currentTheme } = state.app;
    return {
      credentials,
      userInfo,
      isAdmin,
      userName,
      projects,
      projectDisplayMap,
      systemsMap,
      isReadUser,
      isLocalAdmin,
      location,
      loadStatus,
      timezoneOffset,
      globalInfo,
      globalSystemTimelineInfo,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(RootCauseList);
