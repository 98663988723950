/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ** */

import * as R from 'ramda';
import moment from 'moment';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getCausalRelationTaskList = (credentials: Credentials, params: Object) => {
  const { causalKey, customerName, tasksStartTimestamp, tasksEndTimestamp, interval, category } = params;

  return fetchGet(getEndpoint('micausalrelationtask', 1), {
    ...credentials,
    causalKey,
    customerName,
    startTime: tasksStartTimestamp ? tasksStartTimestamp - interval * 86400000 : undefined,
    endTime: tasksEndTimestamp,
    category,
  }).then((data) => {
    // parse relations tasks
    const incidentList = R.sort(
      (incidentA, incidentB) => incidentB.startTimestamp - incidentA.startTimestamp,
      R.map((incidentResult) => {
        const { causalRelationCompositeKey, startTime, endTime } = incidentResult.relationTaskKey || {};
        const { userName, causalGroupKey, dailyTimestamp } = causalRelationCompositeKey || {};
        const relationKey = `${userName}-${causalGroupKey}-${dailyTimestamp}`;
        return {
          ...incidentResult,
          causalKey,
          customerName,
          startTimestamp: startTime,
          endTimestamp: endTime,
          id: relationKey,
          relationKey,
          fileName: relationKey,
          manualTask: category === 'On-demand',
        };
      }, data || []),
    );
    const cronTasks = R.filter((item) => !item.manualTask, incidentList);
    const manualTasks = R.filter((item) => item.manualTask, incidentList);

    // merge causal relation tasks
    let taskList = [];
    if (cronTasks.length > 0) {
      const startTimestamp = moment.utc(tasksStartTimestamp).startOf('day').valueOf();
      const endTimestamp = moment.utc(tasksEndTimestamp).startOf('day').valueOf();
      const days = (endTimestamp - startTimestamp) / 86400000 + 1;

      R.forEach((num) => {
        const dayTimestamp = startTimestamp + num * 86400000;
        const taskStartTime = dayTimestamp - R.max(interval - 1, 0) * 86400000;
        const taskEndTime = moment.utc(dayTimestamp).endOf('day').valueOf();

        // reduce sub tasks
        const subTasks = [];
        R.forEach((item) => {
          if (item.startTimestamp >= taskStartTime && item.endTimestamp <= taskEndTime) {
            subTasks.push(item);
          }
        }, cronTasks || []);

        // set status
        const subTaskSuccess = R.filter((item) => item.status.toLowerCase() === 'finished', subTasks).length;
        const subTaskEmpty = R.filter((item) => item.status.toLowerCase() === 'emptyresult', subTasks).length;
        const hasTimeout = R.find((item) => item.status.toLowerCase() === 'timeout', subTasks);
        const hasFailure = R.find((item) => item.status.toLowerCase() === 'failure', subTasks);
        const hasCreating = R.find((item) => item.status.toLowerCase() === 'creating', subTasks);
        const hasInit = R.find((item) => item.status.toLowerCase() === 'initializing', subTasks);
        let status = 'Empty';
        if (subTasks.length > 0) {
          if (subTaskEmpty === subTasks.length) {
            status = 'Empty';
          } else if (subTaskSuccess + subTaskEmpty === subTasks.length) {
            status = 'Success';
          } else if (hasCreating || hasInit) {
            status = 'Updating';
          } else if ((hasTimeout || hasFailure) && subTaskSuccess > 0) {
            status = 'Partial Success';
          } else {
            status = 'Fail';
          }
        }

        const id = `${customerName}-${causalKey}-${dayTimestamp}`;
        const autoIncident = {
          id,
          relationKey: id,
          fileName: id,
          causalKey,
          customerName,
          startTimestamp: taskStartTime,
          endTimestamp: taskEndTime,
          subTasks,
          status,
        };
        taskList.push(autoIncident);
      }, R.range(0, days));
      taskList = R.sortWith([R.descend(R.prop('startTimestamp'))], taskList);
    }

    return {
      incidentList: [...taskList, ...manualTasks],
    };
  });
};

export default getCausalRelationTaskList;
